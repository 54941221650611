import React from "react";
import { StyleSheet, Image } from "react-native";
import dayjs from "dayjs";
import { Text, View } from "@components/atoms/Themed";
import { graphql, useFragment } from "react-relay/hooks";
import { ReportSummaryData$key } from "@generated/ReportSummaryData.graphql";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { LinearGradient } from "expo-linear-gradient";
import numberFormat from "@lib/util/numberFormat";
import openContact from "@lib/util/openContact";
import { reportSymbol, reportWave } from "@components/atoms/AssetImage";

const summaryQuery = graphql`
  fragment ReportSummaryData on Sales {
    __typename
    ... on SalesSummary {
      date
      id
      totalMargin
      totalPayment
      totalSales
      totalTaxDeduction
    }
    ... on SalesError {
      message
    }
  }
`;

export default function ReportSummary({
  summaryFragment,
  searchType,
}: {
  summaryFragment: ReportSummaryData$key;
  searchType: "month" | "year";
}) {
  const data = useFragment<ReportSummaryData$key>(
    summaryQuery,
    summaryFragment
  );
  if (data.__typename !== "SalesSummary") {
    return (
      <View>
        <Text>{data.__typename === "SalesError" ? data.message : "errpr"}</Text>
      </View>
    );
  }
  return (
    <View>
      <View>
        <LinearGradient
          colors={Colors.grBlue}
          end={{
            x: 1,
            y: 0,
          }}
          start={{
            x: 0,
            y: 0,
          }}
          style={styles.summary}
        >
          <Image source={reportSymbol} style={styles.icon} />

          <Image source={reportWave} style={styles.wave} />

          <View style={styles.wrapper}>
            <Text style={styles.column}>
              {dayjs(data.date).format(
                searchType === "month" ? "YYYY年M月" : "YYYY年"
              )}{" "}
              売り上げ
            </Text>

            <Spacer height={12} />

            <Text style={styles.price}>
              {numberFormat(data.totalSales, "¥")}
            </Text>
            <Spacer height={33} />

            <View style={styles.row}>
              <Text style={styles.column}>システム利用料</Text>
              <Text style={styles.value}>
                {data.totalMargin !== null
                  ? numberFormat(data.totalMargin, "-¥")
                  : "--"}
              </Text>
            </View>

            <Spacer height={8} />

            <View style={styles.row}>
              <Text style={styles.column}>源泉徴収</Text>
              <Text style={styles.value}>
                {data.totalTaxDeduction !== null
                  ? numberFormat(data.totalTaxDeduction, "-¥")
                  : "--"}
              </Text>
            </View>

            <Spacer height={8} />

            <Divider />

            <Spacer height={8} />

            <View style={styles.row}>
              <Text style={styles.column}>振込額</Text>
              <Text style={styles.value}>
                {numberFormat(data.totalPayment, "¥")}
              </Text>
            </View>
          </View>
        </LinearGradient>
      </View>

      <Spacer height={16} />
      <Text style={styles.guide}>
        振込手数料はお客様負担となります。上記の振込額から手数料が引かれた金額が翌月末に振り込まれます。
      </Text>
      <Text style={styles.guide}>
        万が一、振込に不備があった場合は
        <Text onPress={openContact} style={styles.hyperlinkGuide}>
          運営までご連絡
        </Text>
        ください。
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  summary: {
    position: "relative",
    backgroundColor: Colors.blue,
    borderRadius: 6,
  },
  wrapper: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  icon: {
    width: 62,
    height: 44,
    position: "absolute",
    top: 24,
    right: 16,
  },
  wave: {
    width: "100%",
    height: 66,
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  column: {
    ...Fonts.lr100,
    color: Colors.white,
  },
  price: {
    ...Fonts.sfxlr100,
    color: Colors.white,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  value: {
    ...Fonts.sfmr100,
    color: Colors.white,
  },
  guide: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  hyperlinkGuide: {
    color: Colors.gray,
    ...Fonts.sr140,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: Colors.black,
  },
});
