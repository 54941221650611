/**
 * @generated SignedSource<<07caa710268b6443d1c2d6e1a2fcbd97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailEditScreenQuery$variables = {};
export type EmailEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"EmailEdit">;
  };
};
export type EmailEditScreenQuery = {
  response: EmailEditScreenQuery$data;
  variables: EmailEditScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailEdit"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EmailEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e682588b6250767d0f42be65738ab57",
    "id": null,
    "metadata": {},
    "name": "EmailEditScreenQuery",
    "operationKind": "query",
    "text": "query EmailEditScreenQuery {\n  influencer {\n    ...EmailEdit\n    id\n  }\n}\n\nfragment EmailEdit on Influencer {\n  ...EmailEditConfirm\n  ...EmailEditForm\n}\n\nfragment EmailEditConfirm on Influencer {\n  newEmail\n}\n\nfragment EmailEditForm on Influencer {\n  email\n}\n"
  }
};

(node as any).hash = "62121e10b53638e5bd782f9b2733314f";

export default node;
