/**
 * @generated SignedSource<<5f483c06d18b07e2db17f9d0e9a2f219>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupRegenerateCodeMutationInput = {
  clientMutationId?: string | null;
  email: string;
  uid: string;
};
export type AuthSignupResendMutation$variables = {
  input: SignupRegenerateCodeMutationInput;
};
export type AuthSignupResendMutation$data = {
  readonly signupRegenerateCode: {
    readonly __typename: "SignupRegenerateCodeError";
    readonly message: string;
  } | {
    readonly __typename: "SignupRegenerateCodeToken";
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AuthSignupResendMutation = {
  response: AuthSignupResendMutation$data;
  variables: AuthSignupResendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "signupRegenerateCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "SignupRegenerateCodeToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "SignupRegenerateCodeError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthSignupResendMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthSignupResendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "538f59a6dc8e64326570ba02796fd0e4",
    "id": null,
    "metadata": {},
    "name": "AuthSignupResendMutation",
    "operationKind": "mutation",
    "text": "mutation AuthSignupResendMutation(\n  $input: SignupRegenerateCodeMutationInput!\n) {\n  signupRegenerateCode(input: $input) {\n    __typename\n    ... on SignupRegenerateCodeToken {\n      token\n    }\n    ... on SignupRegenerateCodeError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccbac137b613be9b5c0f501e2b10b778";

export default node;
