/**
 * @generated SignedSource<<ae834c15b15685169958a8ca2d5f0e2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type TicketPost$data = {
  readonly campaign: {
    readonly " $fragmentSpreads": FragmentRefs<"CampaignVisitReservationCampaign">;
  };
  readonly id: string;
  readonly status: PostStatus;
  readonly " $fragmentType": "TicketPost";
};
export type TicketPost$key = {
  readonly " $data"?: TicketPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketPost">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignVisitReservationCampaign"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "f9478121175dd16a425afa94f28b8499";

export default node;
