import React, { useState, useRef, useEffect, useCallback } from "react";
import { TextInput, Platform } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import { useTheme } from "@react-navigation/native";
import {
  CommonProps,
  KeyboardTypeMap,
} from "@components/molecules/TextInput/types";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import InnerLabel, { Ref } from "@components/molecules/TextInput/InnerLabel";
import styles from "@components/molecules/TextInput/style";
import { Controller } from "react-hook-form";

interface Props extends CommonProps {
  /** 初期の行数。 */
  numberOfLines?: number;
  /** カウンターの表示有無 */
  useCounter?: boolean;
  onChange?: (value: string) => void;
}

const MaxHeight = 300;
const MinHeight = 40;
const PaddingVertical = 8;
const RowHeight = 17;

export default function TextArea({
  label,
  innerLabel,
  name,
  control,
  required,
  disabled,
  placeholder,
  numberOfLines = 5,
  style,
  placeholderTextColor = Colors.gray,
  inputAccessoryViewID,
  maxLength,
  useCounter,
  onChange,
}: Props) {
  const { colors, dark } = useTheme();
  const [initialHeight, setInitialHeight] = useState<number | null>(null);
  const [dynamicHeight, setDynamicHeight] = useState<number | null>(null);
  const [counter, setCounter] = useState<number>(0);
  const [onFocused, setOnFocused] = useState<boolean>(false);
  const initialHeightForMultipleLine = useCallback((line: number) => {
    const baseHeight = RowHeight * line;
    return baseHeight > MaxHeight
      ? MaxHeight
      : baseHeight < MinHeight + PaddingVertical
      ? MinHeight + PaddingVertical
      : baseHeight;
  }, []);

  const ref = useRef<Ref>();
  useEffect(() => {
    const height = initialHeightForMultipleLine(numberOfLines);
    setInitialHeight(height);
    setDynamicHeight(height);
  }, [initialHeightForMultipleLine, numberOfLines]);

  if (dynamicHeight === null || initialHeight === null) {
    return null;
  }
  return (
    <>
      <View style={styles.container}>
        {label !== undefined && (
          <OuterLabel label={label} required={required === true} />
        )}

        <Controller
          control={control}
          name={name}
          render={({
            field: { value, onBlur, onChange: onChangeText },
            fieldState: { invalid },
          }) => (
            <TextInput
              allowFontScaling={false}
              autoCapitalize="none"
              autoCorrect={false}
              blurOnSubmit={false}
              editable={disabled !== true}
              inputAccessoryViewID={inputAccessoryViewID}
              keyboardType={KeyboardTypeMap.text[Platform.OS]}
              maxLength={maxLength}
              multiline
              numberOfLines={numberOfLines}
              onBlur={() => {
                setOnFocused(false);
                if (innerLabel === true && value === "") {
                  ref.current?.onBlur();
                }
                onBlur();
              }}
              onChangeText={(text) => {
                onChangeText(text);
                setCounter(text.length);
                if (onChange !== undefined) {
                  onChange(text);
                }
              }}
              onContentSizeChange={({ nativeEvent }) => {
                if (Platform.OS !== "web") {
                  const updateHeight =
                    nativeEvent.contentSize.height + PaddingVertical;
                  if (updateHeight > MaxHeight) {
                    setDynamicHeight(MaxHeight);
                  } else if (updateHeight > initialHeight) {
                    setDynamicHeight(updateHeight);
                  } else if (dynamicHeight !== initialHeight) {
                    setDynamicHeight(initialHeight);
                  }
                }
              }}
              onFocus={() => {
                setOnFocused(true);
                if (innerLabel === true) {
                  ref.current?.onFocus();
                }
              }}
              placeholder={innerLabel === true ? "" : placeholder}
              placeholderTextColor={placeholderTextColor}
              selectionColor={colors.text}
              style={[
                styles.form,
                {
                  color: colors.text,
                  backgroundColor: dark
                    ? colors.background
                    : disabled === true
                    ? Colors.gray20
                    : colors.card,
                },
                style,
                { height: dynamicHeight },
                innerLabel !== undefined &&
                  (onFocused || value !== "") && { paddingTop: 22 },
                invalid ? styles.formError : null,
              ]}
              underlineColorAndroid="transparent"
              value={value === null ? "" : value}
            />
          )}
        />
        {innerLabel !== undefined && (
          <InnerLabel
            innerRef={ref}
            placeholder={placeholder !== undefined ? placeholder : ""}
          />
        )}
      </View>

      {useCounter === true && (
        <Text style={styles.counter}>
          {maxLength !== undefined
            ? `${counter}/${maxLength}文字`
            : `${counter}文字`}
        </Text>
      )}
    </>
  );
}
