/**
 * @generated SignedSource<<3915f43be49c673b775ef1bdac730734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PasswordScreenQuery$variables = {};
export type PasswordScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"PasswordUser">;
  };
};
export type PasswordScreenQuery = {
  response: PasswordScreenQuery$data;
  variables: PasswordScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PasswordUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PasswordScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "437361fe22590e35cbd6800c438fcc68",
    "id": null,
    "metadata": {},
    "name": "PasswordScreenQuery",
    "operationKind": "query",
    "text": "query PasswordScreenQuery {\n  influencer {\n    ...PasswordUser\n    id\n  }\n}\n\nfragment PasswordEditInfluencer on Influencer {\n  email\n}\n\nfragment PasswordUser on Influencer {\n  hasPassword\n  ...PasswordEditInfluencer\n}\n"
  }
};

(node as any).hash = "dd3e873dd1cf455b7a8b2a61fa51405e";

export default node;
