/**
 * @generated SignedSource<<13c832ab6141895351d5b96bb8c34a35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignMain$data = {
  readonly advertiserName: string | null;
  readonly banners: ReadonlyArray<{
    readonly file: string | null;
  }>;
  readonly gifting: boolean;
  readonly id: string;
  readonly isFavoriteCheck: boolean;
  readonly mCategory: {
    readonly name: string;
  };
  readonly mediaType: MediaType;
  readonly prType: CampaignPrType | null;
  readonly socialNetwork: {
    readonly castEndAt: any | null;
    readonly castStartAt: any | null;
    readonly postEndOn: any | null;
    readonly postStartOn: any | null;
    readonly recruitmentEndOn: any | null;
    readonly recruitmentStartOn: any | null;
  };
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SNSBadge_mediaType">;
  readonly " $fragmentType": "CampaignMain";
};
export type CampaignMain$key = {
  readonly " $data"?: CampaignMain$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignMain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignMain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gifting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavoriteCheck",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Banner",
      "kind": "LinkedField",
      "name": "banners",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "file",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MCategory",
      "kind": "LinkedField",
      "name": "mCategory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialNetwork",
      "kind": "LinkedField",
      "name": "socialNetwork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recruitmentStartOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recruitmentEndOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postStartOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postEndOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castStartAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castEndAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SNSBadge_mediaType"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "4037dbe552b835004f58e3604890bd7c";

export default node;
