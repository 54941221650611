import React, { Suspense } from "react";
import { Wrapper, Card, View } from "@components/atoms/Themed";
import Ripple from "@components/atoms/Ripple";
import Icon, { IconName } from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import { StyleSheet, Text } from "react-native";
import ReactNavigation from "@react-navigation/bottom-tabs";
import { navigate } from "@navigation/navigate";
import { useTheme } from "@react-navigation/native";
import { NavigationMenuQuery } from "@generated/NavigationMenuQuery.graphql";
import useAccount from "@hooks/useAccount";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import _ from "lodash";
import { RootTabParamList, initialRoute } from "../../../types";

const menuQuery = graphql`
  query NavigationMenuQuery($influencerId: ID!) {
    commentReadTimeTotal(influencerId: $influencerId) {
      unreadTotalComments
    }
  }
`;

type Props = {
  params: ReactNavigation.BottomTabBarProps;
};

function Badge({
  queryReference,
}: {
  queryReference: PreloadedQuery<NavigationMenuQuery>;
}) {
  const { commentReadTimeTotal } = usePreloadedQuery<NavigationMenuQuery>(
    menuQuery,
    queryReference
  );
  if (commentReadTimeTotal.unreadTotalComments > 0) {
    return <View style={styles.badge} />;
  }
  return null;
}

export default function NavigationMenu({ params }: Props) {
  const { state, descriptors } = params;
  const { colors } = useTheme();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<NavigationMenuQuery>(menuQuery);
  const { state: influencerId } = useAccount();
  React.useEffect(() => {
    loadQuery({
      influencerId,
    });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, influencerId]);

  return (
    <Card bordered style={styles.container}>
      <Wrapper>
        <View style={styles.menus}>
          {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const isFocused = state.index === index;
            return (
              <Ripple
                key={`menu-${route.name}`}
                onPress={() =>
                  navigate(initialRoute, {
                    screen: route.name as keyof RootTabParamList,
                  })
                }
                rippleColor={isFocused ? Colors.green : colors.text}
                style={styles.menu}
              >
                {route.name === "Todo" && (
                  <Suspense fallback={null}>
                    {!_.isNil(queryReference) && (
                      <Badge queryReference={queryReference} />
                    )}
                  </Suspense>
                )}
                <Icon
                  color={isFocused ? Colors.green : colors.text}
                  name={options.tabBarLabel as IconName}
                  size={24}
                  skipDarkModeColor
                />
                <Text
                  style={[
                    styles.label,
                    { color: isFocused ? Colors.green : colors.text },
                  ]}
                >
                  {options.title}
                </Text>
              </Ripple>
            );
          })}
        </View>
      </Wrapper>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  menus: {
    height: 60,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  menu: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 10,
    lineHeight: 10,
    marginTop: 4,
  },
  badge: {
    width: 8,
    height: 8,
    borderRadius: 4,
    position: "absolute",
    top: 8,
    right: 16,
    backgroundColor: Colors.orange,
  },
});
