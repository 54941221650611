import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import Password from "@components/templates/Password";
import { PasswordScreenQuery } from "@generated/PasswordScreenQuery.graphql";
import _ from "lodash";

const passwordScreen = graphql`
  query PasswordScreenQuery {
    influencer {
      ...PasswordUser
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<PasswordScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<PasswordScreenQuery>(
    passwordScreen,
    queryReference
  );
  return <Password passwordFragment={influencer} />;
}

export default function PasswordScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<PasswordScreenQuery>(passwordScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="パスワード" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
