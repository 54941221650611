/**
 * @generated SignedSource<<5e41d256fcfe570e3272a7750c0499ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneEditForm$data = {
  readonly phoneNumber: string | null;
  readonly " $fragmentType": "PhoneEditForm";
};
export type PhoneEditForm$key = {
  readonly " $data"?: PhoneEditForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhoneEditForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhoneEditForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "a87df0b8602b6c40e5fb4c471c381b74";

export default node;
