import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Ticket from "@components/templates/Ticket";
import SafeAreaView from "@components/molecules/SafeAreaView";
import { View } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import Loading from "@components/atoms/Loading";
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from "react-relay/hooks";
import { TicketScreenQuery } from "@generated/TicketScreenQuery.graphql";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const ticketQuery = graphql`
  query TicketScreenQuery($id: ID!) {
    post(id: $id) {
      ...TicketPost
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<TicketScreenQuery>;
}) {
  const data = usePreloadedQuery<TicketScreenQuery>(
    ticketQuery,
    queryReference
  );

  return <Ticket postFragment={data.post} />;
}

export default function TicketScreen({
  route,
}: RootStackScreenProps<"Ticket">) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<TicketScreenQuery>(ticketQuery);
  const { colors } = useTheme();
  React.useEffect(() => {
    loadQuery(
      {
        id: route.params.id,
      },
      { fetchPolicy: "network-only" }
    );

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route]);

  return (
    <SafeAreaView>
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <Suspense fallback={<Loading size="large" />}>
          {!_.isNil(queryReference) && (
            <ScreenContent queryReference={queryReference} />
          )}
        </Suspense>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
