import Spacer from "@components/atoms/Spacer";
import { StyleSheet, TouchableOpacity, Modal, Text } from "react-native";
import React, { useState, useMemo, createRef, useCallback } from "react";
import Icon from "@components/atoms/Icon";
import { View } from "@components/atoms/Themed";
import { isVideo } from "@lib/util/file";
import Fonts from "@constants/Fonts";
import Colors, { DarkTheme } from "@constants/Colors";
import Toast from "@components/atoms/Toast";
import { TabView, SceneMap } from "react-native-tab-view";
import SafeAreaView from "@components/molecules/SafeAreaView";
import Video, { VideoRef } from "@components/atoms/Video";
import Scroller from "@components/molecules/BrowseImage/Scroller";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import downloadFile from "@lib/util/downloadFile";
import _ from "lodash";
import {
  Route,
  ImageItem,
  Item,
} from "@components/molecules/BrowseImage/types";

export default function BrowseImage({
  selectIndex,
  imageList,
  onClose,
  downloadable = false,
}: {
  selectIndex: number;
  imageList: ImageItem[];
  onClose: () => void;
  downloadable?: boolean;
}) {
  const [index, setIndex] = useState<number>(
    selectIndex > imageList.length - 1 ? 0 : selectIndex
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [routes] = useState<Route[]>(
    imageList.map((row) => ({
      key: row.id,
      title: isVideo({ uri: row.uri, contentType: row.contentType })
        ? "動画"
        : "画像",
      uri: row.uri,
    }))
  );
  const [items] = useState<Item[]>(
    imageList.map((row) => ({
      id: row.id,
      uri: row.uri,
      duration: _.isNumber(row.duration) ? row.duration : null,
      type: isVideo({ uri: row.uri, contentType: row.contentType })
        ? "video"
        : "image",
    }))
  );

  const videoRefs = useMemo(() => {
    const videos: {
      [index: string]: React.RefObject<VideoRef>;
    } = {};
    items.forEach((row) => {
      if (row.type === "video") {
        videos[row.id] = createRef<VideoRef>();
      }
    });
    return videos;
  }, [items]);

  const renderScenes = useMemo(() => {
    const screens: { [index: string]: () => JSX.Element } = {};
    items.forEach((row) => {
      screens[row.id] = () => (
        <View style={styles.imageWrap}>
          {row.type === "image" ? (
            <ResponsiveImage source={{ uri: row.uri }} />
          ) : (
            <Video
              duration={row.duration}
              innerRef={videoRefs[row.id]}
              uri={row.uri}
            />
          )}
        </View>
      );
    });
    return SceneMap(screens);
  }, [items, videoRefs]);

  const downloadAttachment = useCallback(async (): Promise<void> => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const target = imageList[index];
      const filename = _.last(target.uri.split("/"));
      if (filename !== undefined) {
        await downloadFile(target.uri, filename);
        setMessage("ダウンロードしました");
      }
    } catch (e: unknown) {
      setMessage("ダウンロードに失敗しました");
    } finally {
      setLoading(false);
    }
  }, [imageList, index, loading]);

  const changeScrees = useCallback(
    (nextIndex: number): void => {
      if (items[index].type === "video") {
        videoRefs[items[index].id]?.current?.pause();
      }
      setIndex(nextIndex);
    },
    [items, index, videoRefs]
  );

  return (
    <Modal onRequestClose={onClose} visible>
      <SafeAreaView backgroundColor={DarkTheme.colors.background}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.button}>
              <TouchableOpacity onPress={onClose} style={styles.action}>
                <Icon color={Colors.white} name="close" size={24} />
              </TouchableOpacity>
            </View>
            <View style={styles.title}>
              <Text style={styles.headerFont}>
                {items[index].type === "video" ? "動画" : "画像"}
              </Text>
            </View>
            <View style={styles.button}>
              {downloadable && (
                <TouchableOpacity
                  onPress={downloadAttachment}
                  style={styles.download}
                >
                  <Icon color={Colors.white} name="download" size={24} />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={styles.content}>
            <TabView
              navigationState={{ index, routes }}
              onIndexChange={changeScrees}
              renderScene={renderScenes}
              renderTabBar={() => null}
            />
          </View>

          <Text style={styles.thumbnailMaxMinText}>
            {index + 1}/{imageList.length}
          </Text>
          <Spacer height={16} />
          <Scroller
            items={items}
            onPress={(p, i) => changeScrees(i)}
            selected={index}
          />
          <Spacer height={16} />
        </View>
      </SafeAreaView>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    height: 44,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: 50,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  download: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerFont: {
    textAlign: "center",
    ...Fonts.xlb100,
    color: Colors.white,
  },
  content: {
    flex: 1,
    paddingVertical: 16,
  },
  action: {
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 16,
  },
  imageWrap: {
    flex: 1,
  },
  thumbnailMaxMinText: {
    color: Colors.white,
    textAlign: "center",
    ...Fonts.lr100,
  },
});
