/**
 * @generated SignedSource<<b656e7761d5630a713090a6b37222187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalInformationScreenQuery$variables = {};
export type PersonalInformationScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"PersonalInformation">;
  };
};
export type PersonalInformationScreenQuery = {
  response: PersonalInformationScreenQuery$data;
  variables: PersonalInformationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalInformationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalInformation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonalInformationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prefectureName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cityName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "townName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerInfo",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invoiceStatus",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d217c6eece8eee12156449867a8f456e",
    "id": null,
    "metadata": {},
    "name": "PersonalInformationScreenQuery",
    "operationKind": "query",
    "text": "query PersonalInformationScreenQuery {\n  influencer {\n    ...PersonalInformation\n    id\n  }\n}\n\nfragment PersonalInformation on Influencer {\n  firstName\n  lastName\n  firstNameKana\n  lastNameKana\n  phoneNumber\n  email\n  hasPassword\n  prefectureName\n  cityName\n  townName\n  bankAccount {\n    id\n  }\n  profile {\n    gender\n    birthday\n    invoiceStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c77fb6d46d79b3c847466ea1f3c33b7c";

export default node;
