/* eslint import/extensions:0 */
/* eslint @typescript-eslint/no-var-requires:0 */
import { ImageRequireSource } from "react-native";
// SNS
const instagram: ImageRequireSource = require("@assets/SNS-icon/instagram.png");
const youtube: ImageRequireSource = require("@assets/SNS-icon/youtube.png");
const tiktok: ImageRequireSource = require("@assets/SNS-icon/tikTok.png");
const twitter: ImageRequireSource = require("@assets/SNS-icon/x.png");
const cast: ImageRequireSource = require("@assets/SNS-icon/casting.png");
const line: ImageRequireSource = require("@assets/SNS-icon/line.png");
const email: ImageRequireSource = require("@assets/SNS-icon/email.png");
const other: ImageRequireSource = require("@assets/SNS-icon/other.png");

const serviceIcon: ImageRequireSource = require("@assets/images/icon.png");
const squareIcon: ImageRequireSource = require("@assets/images/squareIcon.png");

const goodWork: ImageRequireSource = require("@assets/images/goodWork.png");
const ticket: ImageRequireSource = require("@assets/images/ticket.png");

const toDo: ImageRequireSource = require("@assets/Png-icon/dotDefault.png");
const inProgress: ImageRequireSource = require("@assets/Png-icon/dotSelected.png");
const done: ImageRequireSource = require("@assets/Png-icon/dotFinish.png");

const emptyCandidate: ImageRequireSource = require("@assets/images/emptyCandidate.png");
const emptyFavorite: ImageRequireSource = require("@assets/images/emptyFavorite.png");
const emptyWork: ImageRequireSource = require("@assets/images/emptyWork.png");
const emptyUser: ImageRequireSource = require("@assets/images/emptyUserIcon.png");
const defaultBanner: ImageRequireSource = require("@assets/images/emptyBanner.png");

const reportSymbol: ImageRequireSource = require("@assets/images/reportSymbol.png");
const reportWave: ImageRequireSource = require("@assets/images/reportWave.png");
const login: ImageRequireSource = require("@assets/images/login.png");
const signUped: ImageRequireSource = require("@assets/images/signUped.png");
const thankYou: ImageRequireSource = require("@assets/images/thankYouIllust.png");

const chatSound: ImageRequireSource = require("@assets/sounds/pop.mp3");

export {
  instagram,
  youtube,
  tiktok,
  twitter,
  cast,
  line,
  email,
  other,
  emptyUser,
  serviceIcon,
  squareIcon,
  goodWork,
  ticket,
  toDo,
  inProgress,
  done,
  emptyCandidate,
  reportSymbol,
  reportWave,
  emptyFavorite,
  emptyWork,
  defaultBanner,
  login,
  signUped,
  thankYou,
  chatSound,
};
