import _ from "lodash";

export default function numberFormat(
  data: string | number | null,
  prefix?: string
): string {
  if (!_.isNil(data) && !_.isNaN(Number(data))) {
    return `${prefix !== undefined ? prefix : ""}${Number(
      data
    ).toLocaleString()}`;
  }
  return "";
}
