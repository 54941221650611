import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { graphql, useFragment } from "react-relay/hooks";
import Loading from "@components/atoms/Loading";
import { CampaignInputInfluencer$key } from "@generated/CampaignInputInfluencer.graphql";
import { CampaignInputCampaign$key } from "@generated/CampaignInputCampaign.graphql";
import { CampaignInputAddressPrefecture$key } from "@generated/CampaignInputAddressPrefecture.graphql";
import { CampaignInputCategoryMCategories$key } from "@generated/CampaignInputCategoryMCategories.graphql";
import checkRequirement from "@lib/util/requirementForApply";
import Address from "@components/organisms/CampaignInput/CampaignInputAddress";
import Category from "@components/organisms/CampaignInput/CampaignInputCategory";
import Profile from "@components/organisms/CampaignInput/CampaignInputProfile";
import Sns from "@components/organisms/CampaignInput/CampaignInputSns";
import EmailConfirm from "@components/organisms/CampaignInput/CampaignInputEmailConfirm";
import EmailForm from "@components/organisms/CampaignInput/CampaignInputEmailForm";
import PhoneConfirm from "@components/organisms/CampaignInput/CampaignInputPhoneConfirm";
import PhoneForm from "@components/organisms/CampaignInput/CampaignInputPhoneForm";
import CampaignInputInvoice from "@components/organisms/CampaignInput/CampaignInputInvoice";
import { goBack, replace } from "@navigation/navigate";

const influecnerQuery = graphql`
  fragment CampaignInputInfluencer on Influencer {
    linkingInstagram
    linkingTwitter
    profile {
      tiktokUrl
      ytChannel
    }
    ...CampaignInputProfileInfluencer
    ...CampaignInputSnsInfluencer
    ...CampaignInputAddressInfluencer
    ...CampaignInputCategoryCategories
    ...CampaignInputEmailConfirmEmail
    ...CampaignInputInvoiceInfluencer
    ...requirementForApplyInfluencer
  }
`;

const campaignQuery = graphql`
  fragment CampaignInputCampaign on Campaign {
    id
    parentMediaType
    ...CampaignInputSnsCampaign
    ...requirementForApplyCampaign
  }
`;

export default function CampaignInput({
  influencerFragment,
  campaignFragment,
  categoryFragment,
  prefectureFragment,
}: {
  influencerFragment: CampaignInputInfluencer$key;
  campaignFragment: CampaignInputCampaign$key;
  categoryFragment: CampaignInputCategoryMCategories$key;
  prefectureFragment: CampaignInputAddressPrefecture$key;
}) {
  const influencer = useFragment<CampaignInputInfluencer$key>(
    influecnerQuery,
    influencerFragment
  );
  const campaign = useFragment<CampaignInputCampaign$key>(
    campaignQuery,
    campaignFragment
  );
  const [token, setToken] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  const requiredErrors = checkRequirement(influencer, campaign);
  useEffect(() => {
    if (requiredErrors === null) {
      replace("CampaignApply", { id: campaign.id });
    }
  }, [requiredErrors, campaign]);

  const requiredCategory = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "CategoryRequired")
    );
    return !_.isNil(error);
  }, [requiredErrors]);

  const requiredEmail = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "EmailRequired")
    );
    return !_.isNil(error);
  }, [requiredErrors]);

  const requiredPhone = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "PhoneRequired")
    );
    return !_.isNil(error);
  }, [requiredErrors]);

  const requiredAddress = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "AddressRequired")
    );
    return !_.isNil(error);
  }, [requiredErrors]);

  const requiredProfile = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "ProfileRequired")
    );
    return !_.isNil(error);
  }, [requiredErrors]);

  const requiredSns = useMemo<boolean>(() => {
    switch (campaign.parentMediaType) {
      case "instagram":
        return influencer.linkingInstagram !== true;
      case "twitter":
        return influencer.linkingTwitter !== true;
      case "youtube":
        return _.isEmpty(influencer.profile.ytChannel);
      case "tiktok":
        return _.isEmpty(influencer.profile.tiktokUrl);
      default:
        return false;
    }
  }, [campaign, influencer]);

  const requiredInvoice = useMemo<boolean>(() => {
    const error = _.head(
      requiredErrors?.filter((row) => row.code === "InvoiceRequired")
    );
    return error !== null;
  }, [requiredErrors]);

  if (requiredProfile) {
    return (
      <Profile
        hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
        onClose={goBack}
        profileFragment={influencer}
      />
    );
  }
  if (requiredSns) {
    return (
      <Sns
        campaignFragment={campaign}
        hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
        onClose={goBack}
        snsFragment={influencer}
      />
    );
  }
  if (requiredEmail) {
    if (token !== null) {
      return (
        <EmailConfirm
          emailFragment={influencer}
          hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
          initialToken={token}
          onClose={() => setToken(null)}
        />
      );
    }
    return <EmailForm onClose={goBack} onComplete={setToken} />;
  }

  if (requiredPhone) {
    if (phone !== null) {
      return (
        <PhoneConfirm
          hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
          onClose={() => setPhone(null)}
          phone={phone}
        />
      );
    }
    return <PhoneForm onClose={goBack} onComplete={setPhone} />;
  }

  if (requiredCategory) {
    return (
      <Category
        categoryFragment={influencer}
        hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
        listFragment={categoryFragment}
        onClose={goBack}
      />
    );
  }
  if (requiredAddress) {
    return (
      <Address
        addressFragment={influencer}
        hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
        onClose={goBack}
        prefecturesFragment={prefectureFragment}
      />
    );
  }
  if (requiredInvoice) {
    return (
      <CampaignInputInvoice
        hasNext={requiredErrors !== null ? requiredErrors.length > 1 : false}
        onClose={goBack}
        profileFragment={influencer}
      />
    );
  }
  return <Loading mask />;
}
