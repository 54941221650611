import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import { squareIcon } from "@components/atoms/AssetImage";
import BackHome from "@components/organisms/Fallback/FallbackBackHome";

export default function FallbackNetwork() {
  return (
    <>
      <Spacer height={24} />
      <View style={styles.logoWrapper}>
        <View style={styles.squareIcon}>
          <ResponsiveImage height={148} source={squareIcon} width={595} />
        </View>
      </View>
      <Spacer height={50} />
      <View style={styles.textArea}>
        <Text style={styles.caption}>
          オフラインになっています。インターネット環境の良いところでページを再読み込みしてください。
        </Text>
      </View>
      <Spacer height={30} />
      <BackHome />
    </>
  );
}

const styles = StyleSheet.create({
  textArea: {
    paddingHorizontal: 16,
  },
  logoWrapper: {
    alignItems: "center",
  },
  squareIcon: {
    width: "100%",
    maxWidth: 390,
    height: 58,
  },
  caption: {
    ...Fonts.lr130,
  },
});
