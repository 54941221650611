import { atom, useRecoilState } from "recoil";
import { Audio } from "expo-av";

const popSoundAtom = atom<Audio.Sound | null>({
  key: "POP_SOUND",
  default: null,
});

export default function useSound() {
  const [state, setter] = useRecoilState(popSoundAtom);
  return {
    state,
    set: (data: Audio.Sound | null) => {
      setter(data);
    },
  };
}
