import React, { useMemo } from "react";
import Colors from "@constants/Colors";
import { Image, StyleSheet } from "react-native";
import { emptyUser, serviceIcon } from "@components/atoms/AssetImage";

type Props = {
  /** 画像URL */
  uri: string | null;
  /** アバターサイズ */
  size?: number;
  /** UserType */
  userType?: "Agent" | "Influencer" | null;
};

export default function Avatar({ uri, size = 32, userType = "Agent" }: Props) {
  const dynamicStyle = useMemo(
    () =>
      StyleSheet.create({
        image: {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      }),
    [size]
  );

  if (uri === null) {
    if (userType === "Influencer") {
      return (
        <Image
          source={emptyUser}
          style={[dynamicStyle.image, styles.bgWhite]}
        />
      );
    }
    return (
      <Image
        source={serviceIcon}
        style={[dynamicStyle.image, styles.bgWhite]}
      />
    );
  }
  return <Image key={uri} source={{ uri }} style={dynamicStyle.image} />;
}

const styles = StyleSheet.create({
  bgWhite: {
    backgroundColor: Colors.white,
  },
});
