/**
 * @generated SignedSource<<16b1594fe0da29cf7fd40fd528a18c70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupSnsMutationInput = {
  clientMutationId?: string | null;
  email: string;
  id: string;
  name: string;
  snsType: string;
};
export type AuthSnsSignupSignupMutation$variables = {
  input: SignupSnsMutationInput;
};
export type AuthSnsSignupSignupMutation$data = {
  readonly signupSns: {
    readonly __typename: "SignupSnsError";
    readonly errorType: string;
    readonly message: string;
  } | {
    readonly __typename: "SignupSnsLogin";
    readonly snsId: string;
    readonly snsType: string;
  } | {
    readonly __typename: "SignupSnsToken";
    readonly token: string;
    readonly uid: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AuthSnsSignupSignupMutation = {
  response: AuthSnsSignupSignupMutation$data;
  variables: AuthSnsSignupSignupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "signupSns",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "SignupSnsToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "snsType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "snsId",
            "storageKey": null
          }
        ],
        "type": "SignupSnsLogin",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errorType",
            "storageKey": null
          }
        ],
        "type": "SignupSnsError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthSnsSignupSignupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthSnsSignupSignupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3d074a5ff3e12c6b3b8b2fad0048821",
    "id": null,
    "metadata": {},
    "name": "AuthSnsSignupSignupMutation",
    "operationKind": "mutation",
    "text": "mutation AuthSnsSignupSignupMutation(\n  $input: SignupSnsMutationInput!\n) {\n  signupSns(input: $input) {\n    __typename\n    ... on SignupSnsToken {\n      uid\n      token\n    }\n    ... on SignupSnsLogin {\n      snsType\n      snsId\n    }\n    ... on SignupSnsError {\n      message\n      errorType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "922bc98a3c350e90cbba3f17cf089025";

export default node;
