import React, { Suspense, useEffect } from "react";
import Loading from "@components/atoms/Loading";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import SnsAssociation from "@components/templates/SnsAssociation";
import { SnsAssociationScreenQuery } from "@generated/SnsAssociationScreenQuery.graphql";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const screenQuery = graphql`
  query SnsAssociationScreenQuery {
    influencer {
      ...SnsAssociation
    }
  }
`;

function ScreenContent({
  queryReference,
  tab,
}: {
  queryReference: PreloadedQuery<SnsAssociationScreenQuery>;
  tab: string;
}) {
  const { influencer } = usePreloadedQuery<SnsAssociationScreenQuery>(
    screenQuery,
    queryReference
  );
  return <SnsAssociation snsFragment={influencer} tab={tab} />;
}

export default function SnsAssociationScreen({
  route,
}: RootStackScreenProps<"SnsAssociation">): JSX.Element {
  const { tab } = route.params;
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SnsAssociationScreenQuery>(screenQuery);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} tab={tab} />
      )}
    </Suspense>
  );
}
