import React, { useState, useMemo } from "react";
import { StyleSheet } from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import { graphql, useFragment } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import { goBack, navigate } from "@navigation/navigate";
import NavigationBar from "@components/molecules/NavigationBar";
import { SnsLogin$key } from "@generated/SnsLogin.graphql";
import OAuth from "@components/molecules/OAuth";
import Ripple from "@components/atoms/Ripple";
import Dialog from "@components/molecules/dialog/Dialog";
import _ from "lodash";

const snsAssociation = graphql`
  fragment SnsLogin on Influencer {
    lineId
    appleId
    email
    hasPassword
  }
`;

export default function SnsLogin({
  snsFragment,
}: {
  snsFragment: SnsLogin$key;
}) {
  const { lineId, appleId, email, hasPassword } = useFragment(
    snsAssociation,
    snsFragment
  );
  const [error, setError] = useState<string | null>(null);
  const [requireEmail, setRequireEmail] = useState<boolean>(false);
  const canUnlink = useMemo<boolean>(
    () => !!(email !== "" && hasPassword),
    [email, hasPassword]
  );

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="SNSログイン" />
      <Spacer height={8} />
      <Wrapper>
        <Card>
          <View style={[styles.item, styles.noBordered]}>
            <View style={styles.title}>
              <Text style={styles.text}>Line</Text>
            </View>

            {!_.isEmpty(lineId) ? (
              <Text style={styles.active}>連携済</Text>
            ) : (
              <Text style={styles.text}>未設定</Text>
            )}
            <Spacer width={24} />
            {_.isEmpty(lineId) ? (
              <OAuth action="Linking" callbackUrl="sns_login" type="Line">
                <View style={[styles.button, styles.activeButton]}>
                  <Text style={[styles.buttonText, styles.activeText]}>
                    連携
                  </Text>
                </View>
              </OAuth>
            ) : canUnlink ? (
              <OAuth
                action="Linking"
                active
                callbackUrl="sns_login"
                type="Line"
              >
                <View style={styles.button}>
                  <Text style={styles.buttonText}>解除</Text>
                </View>
              </OAuth>
            ) : (
              <Ripple onPress={() => setRequireEmail(true)}>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>解除</Text>
                </View>
              </Ripple>
            )}
          </View>
          {!_.isEmpty(appleId) && (
            <View bordered style={styles.item}>
              <View style={styles.title}>
                <Text style={styles.text}>Apple</Text>
              </View>
              <Text style={styles.active}>連携済</Text>
              <Spacer width={24} />
              {canUnlink ? (
                <OAuth
                  action="Linking"
                  active
                  callbackUrl="sns_login"
                  type="Apple"
                >
                  <View style={styles.button}>
                    <Text style={styles.buttonText}>解除</Text>
                  </View>
                </OAuth>
              ) : (
                <Ripple onPress={() => setRequireEmail(true)}>
                  <View style={styles.button}>
                    <Text style={styles.buttonText}>解除</Text>
                  </View>
                </Ripple>
              )}
            </View>
          )}
        </Card>
      </Wrapper>
      {requireEmail && (
        <Dialog
          buttons={[
            { title: "閉じる", type: "white" },
            {
              title: "登録画面へ",
              type: "blueGradient",
              onPress: () => {
                setRequireEmail(false);
                if (_.isEmpty(email)) {
                  navigate("EmailEdit");
                } else {
                  navigate("Password");
                }
              },
            },
          ]}
          message="Email・パスワード登録を行ってください"
          onClose={() => setRequireEmail(false)}
          subline="アカウント保全のためSNSログインを解除する前にEmail・パスワード登録を行ってください。"
        />
      )}
      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.dialogError }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  guide: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  guideText: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
  linkText: {
    color: Colors.blue,
    ...Fonts.lb130,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  noBordered: {
    borderTopWidth: 0,
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  active: {
    color: Colors.green,
    ...Fonts.xlb100,
    textDecorationLine: "underline",
    textDecorationColor: Colors.green,
    textDecorationStyle: "solid",
  },
  buttonText: {
    ...Fonts.lr130,
  },
  activeText: {
    ...Fonts.lb130,
    color: Colors.white,
  },
  button: {
    width: 64,
    height: 32,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray30,
  },
  activeButton: {
    border: 0,
    backgroundColor: Colors.green,
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
