import React, { useMemo } from "react";
import {
  TextStyle,
  Text,
  View,
  StyleSheet,
  DimensionValue,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Ripple from "@components/atoms/Ripple";
import Loading from "@components/atoms/Loading";
import Colors from "@constants/Colors";

export default function GradientButton({
  title,
  onPress,
  loading,
  textStyle,
  width = 285,
  height = 48,
  disabled,
  gradientColor = Colors.grBlue,
  borderRadius = 4,
  borderWidth = 0,
  borderColor = Colors.gray20,
}: {
  title: string;
  onPress: () => void;
  textStyle?: TextStyle;
  loading?: boolean;
  width?: DimensionValue;
  height?: DimensionValue;
  disabled?: boolean;
  gradientColor?: string[];
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
}): JSX.Element {
  const dynamicStyle = useMemo(
    () =>
      StyleSheet.create({
        ripple: {
          width,
          height,
        },
        button: {
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          borderRadius,
        },
        linearGradient: {
          borderWidth,
          borderRadius,
          borderColor,
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        },
        disabled: {
          borderWidth,
          borderRadius,
          borderColor,
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: Colors.gray20,
        },
      }),
    [width, height, borderRadius, borderColor, borderWidth]
  );
  return (
    <Ripple
      disabled={disabled === true || loading === true}
      onPress={onPress}
      rippleColor={(textStyle?.color as string) ?? Colors.white}
      style={dynamicStyle.ripple}
    >
      {disabled === true ? (
        <View style={dynamicStyle.disabled}>
          <Text style={[styles.text, textStyle, styles.disabledText]}>
            {title}
          </Text>
        </View>
      ) : (
        <View style={dynamicStyle.linearGradient}>
          <LinearGradient
            colors={gradientColor}
            end={{
              x: 1,
              y: 0,
            }}
            start={{
              x: 0,
              y: 0,
            }}
            style={dynamicStyle.button}
          >
            <Text style={[styles.text, textStyle]}>{title}</Text>
          </LinearGradient>
          {loading === true && <Loading mask size="small" />}
        </View>
      )}
    </Ripple>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },
  text: {
    fontSize: 16,
    fontWeight: "700",
    color: Colors.white,
  },
  disabledText: {
    color: Colors.gray,
  },
});
