import React, { Suspense, useCallback } from "react";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import Loading from "@components/atoms/Loading";
import NavigationBar from "@components/molecules/NavigationBar";
import { canGoBack, goBack, replace } from "@navigation/navigate";
import SafeAreaView from "@components/molecules/SafeAreaView";
import CampaignFilter from "@components/templates/CampaignFilter";
import { CampaignFilterScreenQuery } from "@generated/CampaignFilterScreenQuery.graphql";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const narrowDownQuery = graphql`
  query CampaignFilterScreenQuery {
    ...CampaignFilter
  }
`;

function ScreenContent({
  queryReference,
  tab,
}: {
  queryReference: PreloadedQuery<CampaignFilterScreenQuery>;
  tab: string;
}): JSX.Element {
  const data = usePreloadedQuery<CampaignFilterScreenQuery>(
    narrowDownQuery,
    queryReference
  );
  return <CampaignFilter listFragment={data} tab={tab} />;
}

export default function CampaignFilterScreen({
  route,
}: RootStackScreenProps<"CampaignFilter">): JSX.Element {
  const { tab } = route.params;
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignFilterScreenQuery>(narrowDownQuery);

  React.useEffect(() => {
    loadQuery({});

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  const onClose = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      replace("Root", { screen: "Home" });
    }
  }, []);

  return (
    <SafeAreaView>
      <NavigationBar onClose={onClose} title="絞り込み" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} tab={tab} />
        )}
      </Suspense>
    </SafeAreaView>
  );
}
