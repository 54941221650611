/**
 * @generated SignedSource<<05ed6e4a419bc97d505bfb78427ff8e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type PostDraft$data = {
  readonly campaign: {
    readonly mediaType: MediaType;
  };
  readonly id: string;
  readonly status: PostStatus;
  readonly " $fragmentSpreads": FragmentRefs<"PostDraftInstagram" | "PostDraftOther" | "PostDraftTiktok" | "PostDraftTwitter" | "PostDraftYoutube">;
  readonly " $fragmentType": "PostDraft";
};
export type PostDraft$key = {
  readonly " $data"?: PostDraft$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostDraft">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostDraft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostDraftYoutube"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostDraftTwitter"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostDraftTiktok"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostDraftOther"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostDraftInstagram"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "79d57494e8c9456239b4d35719e9795a";

export default node;
