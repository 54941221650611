import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View, Wrapper, Card } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import Button from "@components/atoms/Button";
import GradientText from "@components/atoms/GradientText";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { replace } from "@navigation/navigate";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import { signUped } from "@components/atoms/AssetImage";

export default function SignupThanks() {
  return (
    <Card style={styles.thanksContainer}>
      <ScrollView>
        <Wrapper>
          <Card style={styles.container}>
            <Text style={styles.title}>登録完了</Text>

            <Spacer height={16} />

            <GradientText
              grColor={Colors.grPink}
              height={56}
              style={styles.thanks}
            >
              Thank You
            </GradientText>
            <View style={styles.imageBackground}>
              <ResponsiveImage height={299} source={signUped} width={358} />
            </View>

            <Spacer height={20} />

            <Text style={styles.text}>
              アカウントの登録が完了しました。早速、キャンペーンを見てみましょう。マイページを充実させることでより多くのキャンペーンに申し込みが可能になります。
            </Text>

            <Spacer height={20} />
            <Button
              onPress={() => replace("Root", { screen: "MyPage" })}
              title="マイページを編集"
            />

            <Spacer height={16} />

            <GradientButton
              onPress={() => replace("Root", { screen: "Home" })}
              title="早速キャンペーンを見てみる"
            />
          </Card>
        </Wrapper>
      </ScrollView>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 32,
    paddingHorizontal: 16,
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    ...Fonts.xxlb140,
  },
  thanks: {
    ...Fonts.enxlb100,
  },
  thanksContainer: {
    flex: 1,
  },
  text: {
    ...Fonts.lr130,
  },
  imageBackground: {
    height: 299,
    maxWidth: 358,
    width: "100%",
  },
});
