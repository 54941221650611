import React, { useMemo } from "react";
import { useTheme } from "@react-navigation/native";
import { StyleProp, TextStyle, TextInput, Platform } from "react-native";
import { Text } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";

type Prop = {
  children: string;
  style?: StyleProp<TextStyle>;
};

export default function SelectableText({ children, style }: Prop) {
  const { colors } = useTheme();
  const customStyle = useMemo(
    () => [
      {
        ...Fonts.mr100,
        color: colors.text,
        backgroundColor: Colors.transparent,
      },
      style ?? style,
    ],
    [colors, style]
  );

  if (Platform.OS !== "ios") {
    return (
      <Text selectable style={style}>
        {children}
      </Text>
    );
  }
  return (
    <TextInput
      defaultValue={children}
      editable={false}
      multiline
      scrollEnabled={false}
      style={customStyle}
    />
  );
}
