import { isURL } from "@lib/util/validate";
import { YoutubeUrlPattern, TiktokUrlPattern } from "@constants/App";
import { graphql, useFragment } from "react-relay/hooks";
import { requirementForApplyInfluencer$key } from "@generated/requirementForApplyInfluencer.graphql";
import { requirementForApplyCampaign$key } from "@generated/requirementForApplyCampaign.graphql";

const influencerQuery = graphql`
  fragment requirementForApplyInfluencer on Influencer {
    firstName
    lastName
    email
    postalCode
    prefectureName
    cityName
    townName
    linkingInstagram
    linkingTwitter
    phoneNumber
    profile {
      commonName
      gender
      birthday
      tiktokUrl
      ytChannel
      invoiceStatus
    }
    influencerCategories {
      categoryName
    }
  }
`;

const campaignQuery = graphql`
  fragment requirementForApplyCampaign on Campaign {
    parentMediaType
    hasDelivery
  }
`;

export type ApplyErrorType =
  | "InstagramUnlinked"
  | "TwitterUnlinked"
  | "YoutubeUnlinked"
  | "TiktokUnlinked"
  | "AddressRequired"
  | "ProfileRequired"
  | "EmailRequired"
  | "PhoneRequired"
  | "CategoryRequired"
  | "InvoiceRequired";

export type ApplyError = {
  code: ApplyErrorType;
  message: string;
};

export type UrlErrorType = "EmptyUrl" | "InvalidUrl" | "InvalidDmain";

function validateYoutubeUrl(url: string): boolean {
  return !isURL(url) || !YoutubeUrlPattern.test(url);
}

function validateTiktokUrl(url: string): boolean {
  return !isURL(url) || !TiktokUrlPattern.test(url);
}

export default function RequirementForApply(
  influencerFragment: requirementForApplyInfluencer$key,
  campaignFragment: requirementForApplyCampaign$key
): ApplyError[] | null {
  const { parentMediaType, hasDelivery } =
    useFragment<requirementForApplyCampaign$key>(
      campaignQuery,
      campaignFragment
    );
  const influencer = useFragment<requirementForApplyInfluencer$key>(
    influencerQuery,
    influencerFragment
  );
  const errors: ApplyError[] = [];

  // SNS連携済みか？
  switch (parentMediaType) {
    case "instagram":
      if (influencer.linkingInstagram !== true) {
        errors.push({
          code: "InstagramUnlinked",
          message: "Instagramアカウントとの連携を行ってください。",
        });
      }
      break;
    case "youtube":
      if (
        influencer.profile.ytChannel === null ||
        validateYoutubeUrl(influencer.profile.ytChannel)
      ) {
        errors.push({
          code: "YoutubeUnlinked",
          message: "YouTubeチャンネルURLを登録してください。",
        });
      }
      break;
    case "tiktok":
      if (
        influencer.profile.tiktokUrl === null ||
        validateTiktokUrl(influencer.profile.tiktokUrl)
      ) {
        errors.push({
          code: "TiktokUnlinked",
          message: "TikTokアカウントURLを登録してください。",
        });
      }
      break;
    case "twitter":
      if (influencer.linkingTwitter !== true) {
        errors.push({
          code: "TwitterUnlinked",
          message: "Xアカウントとの連携を行ってください。",
        });
      }
      break;
    case "cast":
    case "other":
    default:
  }
  // プロフィール情報を入力済みか？
  if (
    influencer.profile.commonName === null ||
    influencer.profile.birthday === null ||
    influencer.profile.gender === null
  ) {
    errors.push({
      code: "ProfileRequired",
      message: "プロフィール情報を登録してください。",
    });
  }
  if (influencer.email === null) {
    errors.push({
      code: "EmailRequired",
      message: "Emailを登録してください。",
    });
  }
  if (influencer.phoneNumber === null) {
    errors.push({
      code: "PhoneRequired",
      message: "電話番号を登録してください。",
    });
  }
  // カテゴリ登録済みか？
  if (influencer.influencerCategories.length === 0) {
    errors.push({
      code: "CategoryRequired",
      message: "カテゴリ情報を登録してください。",
    });
  }

  // 提供商品がある場合は氏名・住所登録済みか？
  if (hasDelivery) {
    if (
      influencer.firstName === null ||
      influencer.lastName === null ||
      influencer.postalCode === null ||
      influencer.prefectureName === null ||
      influencer.cityName === null ||
      influencer.townName === null
    ) {
      errors.push({
        code: "AddressRequired",
        message:
          "氏名・住所・電話番号を登録してください。商品や書類の送り先住所になります。",
      });
    }
  }

  // インボイス制度設定しているかどうか？
  if (influencer.profile.invoiceStatus === "unset") {
    errors.push({
      code: "InvoiceRequired",
      message: "インボイス登録の申請の有無を設定してください。",
    });
  }

  return errors.length > 0 ? errors : null;
}
