import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { graphql, useFragment } from "react-relay/hooks";
import { MyPageCategory$key } from "@generated/MyPageCategory.graphql";
import Spacer from "@components/atoms/Spacer";
import { navigate } from "@navigation/navigate";

const myPageCategory = graphql`
  fragment MyPageCategory on Influencer {
    influencerCategories {
      id
      categoryName
    }
  }
`;

export default function MyPageCategory({
  categoryFragment,
}: {
  categoryFragment: MyPageCategory$key;
}) {
  const { influencerCategories } = useFragment(
    myPageCategory,
    categoryFragment
  );

  return (
    <Card style={styles.container}>
      <View style={styles.item}>
        <View style={styles.title}>
          <Text style={styles.page}>得意なジャンル</Text>
        </View>
        <TouchableOpacity onPress={() => navigate("CategoryEdit")}>
          <Text style={styles.link}>編集</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.categories}>
        {influencerCategories.length > 0 ? (
          influencerCategories.map((category) => (
            <View key={category.id}>
              <Spacer height={4} />
              <View style={styles.category}>
                <View style={styles.tag}>
                  <Text style={styles.categoryText}>
                    {category.categoryName}
                  </Text>
                </View>
                <Spacer width={8} />
              </View>
              <Spacer height={4} />
            </View>
          ))
        ) : (
          <Text style={styles.text}>
            得意なジャンルが設定されてません。{"\n"}
            自分にあったキャンペーンを見つけやすくなります。
          </Text>
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
  },
  title: {
    flex: 1,
  },
  page: {
    ...Fonts.xlb160,
  },
  categories: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  category: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tag: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 9.5,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray30,
  },
  categoryText: {
    ...Fonts.lr100,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  link: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
});
