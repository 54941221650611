import * as Phone from "libphonenumber-js";
import dayjs from "dayjs";
import checkUrl from "validator/lib/isURL";
import checkEmail from "validator/lib/isEmail";

export function isURL(value: string): boolean {
  if (value.trim() !== value) {
    return false;
  }
  return checkUrl(value, { require_tld: false });
}

export function isEmail(value: string): boolean {
  if (value.trim() !== value) {
    return false;
  }
  const result = checkEmail(value);
  return result;
}

export function isPhoneNumber(
  value: string,
  region: Phone.CountryCode
): boolean {
  if (value.trim() !== value) {
    return false;
  }
  try {
    const phoneNumber = Phone.parsePhoneNumber(value, region);
    if (region !== undefined && phoneNumber.country !== region) {
      return false;
    }

    return phoneNumber.isValid();
  } catch (error) {
    return false;
  }
}

export function isDate(value: string | Date): boolean {
  try {
    const day = dayjs(value);
    return day.isValid();
  } catch (e: unknown) {
    return false;
  }
}

export function isNumberString(value: string): boolean {
  if (value.trim() !== value) {
    return false;
  }
  return !Number.isNaN(Number(value));
}
