/**
 * @generated SignedSource<<d8c19ae53eaf6800c989468e7855be3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInfluencerMutationInput = {
  allowNotification?: boolean | null;
  buildingName?: string | null;
  cityName?: string | null;
  clientMutationId?: string | null;
  firstName?: string | null;
  firstNameKana?: string | null;
  lastName?: string | null;
  lastNameKana?: string | null;
  phoneNumber?: string | null;
  postalCode?: string | null;
  prefectureName?: string | null;
  townName?: string | null;
};
export type PersonalInformationEditUpdateMutation$variables = {
  input: UpdateInfluencerMutationInput;
};
export type PersonalInformationEditUpdateMutation$data = {
  readonly updateInfluencer: {
    readonly __typename: "Influencer";
    readonly firstName: string | null;
    readonly firstNameKana: string | null;
    readonly lastName: string | null;
    readonly lastNameKana: string | null;
  } | {
    readonly __typename: "UpdateInfluencerError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type PersonalInformationEditUpdateMutation = {
  response: PersonalInformationEditUpdateMutation$data;
  variables: PersonalInformationEditUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstNameKana",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastNameKana",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UpdateInfluencerError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalInformationEditUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonalInformationEditUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7280136d58b02c6b61fef1e424082d8b",
    "id": null,
    "metadata": {},
    "name": "PersonalInformationEditUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonalInformationEditUpdateMutation(\n  $input: UpdateInfluencerMutationInput!\n) {\n  updateInfluencer(input: $input) {\n    __typename\n    ... on Influencer {\n      firstName\n      lastName\n      firstNameKana\n      lastNameKana\n      id\n    }\n    ... on UpdateInfluencerError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6faa2ecae9c8df2b1fe133775e92ee57";

export default node;
