import React, { useState, useRef } from "react";
import {
  TextInput,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { View } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Icon from "@components/atoms/Icon";
import { useTheme } from "@react-navigation/native";
import {
  CommonProps,
  KeyboardTypeMap,
} from "@components/molecules/TextInput/types";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import InnerLabel, { Ref } from "@components/molecules/TextInput/InnerLabel";
import commonStyle from "@components/molecules/TextInput/style";
import { Controller } from "react-hook-form";

export default function PasswordField({
  label,
  name,
  control,
  innerLabel,
  required,
  disabled,
  placeholder,
  style,
  placeholderTextColor = Colors.gray,
  inputAccessoryViewID,
}: CommonProps): JSX.Element {
  const { colors, dark } = useTheme();
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [onFocused, setOnFocused] = useState<boolean>(false);
  const ref = useRef<Ref>();
  return (
    <View style={commonStyle.container}>
      {label !== undefined && (
        <OuterLabel label={label} required={required === true} />
      )}
      <Controller
        control={control}
        name={name}
        render={({
          field: { value, onBlur, onChange },
          fieldState: { invalid },
        }) => (
          <TextInput
            allowFontScaling={false}
            autoCapitalize="none"
            autoCorrect={false}
            blurOnSubmit={false}
            editable={disabled !== true}
            inputAccessoryViewID={inputAccessoryViewID}
            keyboardType={KeyboardTypeMap.password[Platform.OS]}
            maxLength={20}
            onBlur={() => {
              setOnFocused(false);
              if (innerLabel === true && value === "") {
                ref.current?.onBlur();
              }
              onBlur();
            }}
            onChangeText={onChange}
            onFocus={() => {
              setOnFocused(true);
              if (innerLabel === true) {
                ref.current?.onFocus();
              }
            }}
            placeholder={innerLabel === true ? "" : placeholder}
            placeholderTextColor={placeholderTextColor}
            secureTextEntry={!passwordVisibility}
            selectionColor={colors.text}
            style={[
              commonStyle.form,
              {
                color: colors.text,
                backgroundColor: dark ? colors.background : colors.card,
              },
              style,
              innerLabel !== undefined &&
                (onFocused || value !== "") && { paddingTop: 22 },
              invalid ? commonStyle.formError : null,
            ]}
            underlineColorAndroid="transparent"
            value={value}
          />
        )}
      />

      {innerLabel !== undefined && (
        <InnerLabel
          innerRef={ref}
          placeholder={placeholder !== undefined ? placeholder : ""}
        />
      )}
      <TouchableOpacity
        onPress={() => setPasswordVisibility(!passwordVisibility)}
        style={styles.passwordIconWrapper}
      >
        {passwordVisibility ? (
          <Icon color={colors.text} name="eye" size={20} />
        ) : (
          <Icon color={colors.text} name="eyeClose" size={20} />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  passwordIconWrapper: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
  },
});
