import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Icon from "@components/atoms/Icon";
import Tag from "@components/atoms/Tag";
import { graphql, useFragment } from "react-relay/hooks";
import { MyPagePersonalInformation$key } from "@generated/MyPagePersonalInformation.graphql";
import Spacer from "@components/atoms/Spacer";
import { navigate } from "@navigation/navigate";
import { useTheme } from "@react-navigation/native";

const mypagePersonalInformation = graphql`
  fragment MyPagePersonalInformation on Influencer {
    firstName
    lastName
    firstNameKana
    lastNameKana
    phoneNumber
    email
    prefectureName
    cityName
    townName
    bankAccount {
      id
    }
    profile {
      gender
      birthday
    }
  }
`;

export default function MyPagePersonalInformation({
  personalInformationFragment,
}: {
  personalInformationFragment: MyPagePersonalInformation$key;
}) {
  const { colors } = useTheme();
  const data = useFragment(
    mypagePersonalInformation,
    personalInformationFragment
  );

  const fillPeronalInfo = useMemo(
    () =>
      !!(
        data.prefectureName !== null &&
        data.cityName !== null &&
        data.townName !== null &&
        data.firstName !== null &&
        data.lastName !== null &&
        data.firstNameKana !== null &&
        data.lastNameKana !== null &&
        data.email !== null &&
        data.phoneNumber !== null
      ),
    [data]
  );

  return (
    <Card style={styles.container}>
      <View style={[styles.item, styles.noBordered]}>
        <Text style={styles.page}>個人情報</Text>
      </View>
      <TouchableOpacity onPress={() => navigate("PersonalInformation")}>
        <View style={[styles.item, styles.noBordered]}>
          <View style={styles.title}>
            <Text style={styles.text}>本人情報</Text>
            {!fillPeronalInfo && (
              <>
                <Spacer width={16} />
                <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                  <Text style={styles.alert}>未設定</Text>
                </Tag>
              </>
            )}
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("BankEdit")}>
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>報酬振り込み口座</Text>
            {data.bankAccount === null && (
              <>
                <Spacer width={16} />
                <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                  <Text style={styles.alert}>未設定</Text>
                </Tag>
              </>
            )}
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  noBordered: {
    borderTopWidth: 0,
  },
  page: {
    ...Fonts.xlb160,
  },
  title: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  alert: {
    ...Fonts.sb100,
    color: Colors.white,
  },
});
