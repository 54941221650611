/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import ReactNavigationBottom, {
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import ReactNavigationTop, {
  createMaterialTopTabNavigator,
} from "@react-navigation/material-top-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import useAccount from "@hooks/useAccount";
import useColorScheme from "@hooks/useColorScheme";
import { Platform } from "react-native";
import TodoScreen from "@screens/TodoScreen";
import FavoriteScreen from "@screens/FavoriteScreen";
import ChatScreen from "@screens/ChatScreen";
import ThankYouScreen from "@screens/ThankYouScreen";
import AuthEnterScreen from "@screens/AuthEnterScreen";
import AuthLoginScreen from "@screens/AuthLoginScreen";
import AuthReissueScreen from "@screens/AuthReissueScreen";
import AuthSignupScreen from "@screens/AuthSignupScreen";
import AuthSnsSignupScreen from "@screens/AuthSnsSignupScreen";
import AuthLandingScreen from "@screens/AuthLandingScreen";
import RecruitingScreen from "@screens/RecruitingScreen";
import useMessage from "@hooks/useMessage";
import Fonts from "@constants/Fonts";
import Colors, { LightTheme, DarkTheme } from "@constants/Colors";
import useExecptionalError from "@hooks/useExecptionalError";
import Root from "@navigation/Root";
import NavigationMenu from "@components/organisms/Navigation/NavigationMenu";
import NavigationTodo from "@components/organisms/Navigation/NavigationTodo";
import NavigationReport from "@components/organisms/Navigation/NavigationReport";
import NavigationCampaigns from "@components/organisms/Navigation/NavigationCampaigns";
import { navigationRef, replace } from "@navigation/navigate";
import LinkingConfiguration from "@navigation/LinkingConfiguration";
import MessageScreen from "@screens/MessageScreen";
import CampaignDetailScreen from "@screens/CampaignDetailScreen";
import CampaignInputScreen from "@screens/CampaignInputScreen";
import CampaignApplyScreen from "@screens/CampaignApplyScreen";
import FallbackScreen from "@screens/FallbackScreen";
import MyPageScreen from "@screens/MyPageScreen";
import CategoryEditScreen from "@screens/CategoryEditScreen";
import ProfileEditScreen from "@screens/ProfileEditScreen";
import PersonalInformationScreen from "@screens/PersonalInformationScreen";
import BankEditScreen from "@screens/BankEditScreen";
import SnsAssociationScreen from "@screens/SnsAssociationScreen";
import CampaignApplyHistoryScreen from "@screens/CampaignApplyHistoryScreen";
import PersonalInformationEditScreen from "@screens/PersonalInformationEditScreen";
import AddressEditScreen from "@screens/AddressEditScreen";
import PhoneEditScreen from "@screens/PhoneEditScreen";
import EmailEditScreen from "@screens/EmailEditScreen";
import PasswordScreen from "@screens/PasswordScreen";
import SnsLoginScreen from "@screens/SnsLoginScreen";
import InvoiceScreen from "@screens/InvoiceScreen";
import AuthLogoutScreen from "@screens/AuthLogoutScreen";
import AccountDeletionScreen from "@screens/AccountDeletionScreen";
import PrivacyPolicyScreen from "@screens/PrivacyPolicyScreen";
import TermsOfUseScreen from "@screens/TermsOfUseScreen";
import LpScreen from "@screens/LpScreen";
import ChangePasswordScreen from "@screens/ChangePasswordScreen";
import SignupThanksScreen from "@screens/SignupThanksScreen";
import TicketScreen from "@screens/TicketScreen";
import PostDraftScreen from "@screens/PostDraftScreen";
import PostReportScreen from "@screens/PostReportScreen";
import ReportMonthlyScreen from "@screens/ReportMonthlyScreen";
import ReportYearlyScreen from "@screens/ReportYearlyScreen";
import CampaignsRecommendedScreen from "@screens/CampaignsRecommendedScreen";
import CampaignsNewScreen from "@screens/CampaignsNewScreen";
import CampaignsFinishedScreen from "@screens/CampaignsFinishedScreen";
import CampaignsPopularScreen from "@screens/CampaignsPopularScreen";
import CampaignsClosingScreen from "@screens/CampaignsClosingScreen";
import CampaignFilterScreen from "@screens/CampaignFilterScreen";
import OAuthWebScreen from "@screens/OAuthWebScreen";
import OAuthAppScreen from "@screens/OAuthAppScreen";
import NavigationBar from "@components/molecules/NavigationBar";
import {
  RootStackParamList,
  RootTabParamList,
  TodoTabParamList,
  RootTabScreenProps,
  CampaignsTabParamList,
  ReportsTabParamList,
} from "../types";

export default function Navigation() {
  const colorScheme = useColorScheme();
  const { state: error } = useExecptionalError();
  const { state: message } = useMessage();
  const { state: influencerId } = useAccount();
  const useColor = colorScheme === "dark" ? DarkTheme : LightTheme;
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={useColor}
    >
      <Root>
        {error !== null ? (
          <FallbackScreen />
        ) : (
          <RootNavigator logined={influencerId !== ""} />
        )}
        {message !== null && <MessageScreen message={message} />}
      </Root>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator({ logined }: { logined: boolean }) {
  return (
    <Stack.Navigator>
      {logined ? (
        <>
          <Stack.Screen name="Root" options={{ headerShown: false }}>
            {() => <BottomTabNavigator />}
          </Stack.Screen>

          <Stack.Screen
            component={CampaignDetailScreen}
            name="CampaignDetail"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={ChatScreen}
            name="Chat"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={ThankYouScreen}
            name="ThankYou"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={CampaignApplyScreen}
            name="CampaignApply"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={ProfileEditScreen}
            name="ProfileEdit"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={CategoryEditScreen}
            name="CategoryEdit"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={PersonalInformationScreen}
            name="PersonalInformation"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={BankEditScreen}
            name="BankEdit"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={SnsAssociationScreen}
            name="SnsAssociation"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={ReportsTabNavigator}
            name="Report"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={CampaignApplyHistoryScreen}
            name="CampaignApplyHistory"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={PersonalInformationEditScreen}
            name="PersonalInformationEdit"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={AddressEditScreen}
            name="AddressEdit"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={PhoneEditScreen}
            name="PhoneEdit"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={EmailEditScreen}
            name="EmailEdit"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={PasswordScreen}
            name="Password"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={SnsLoginScreen}
            name="SnsLogin"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={InvoiceScreen}
            name="Invoice"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={AuthLogoutScreen}
            name="AuthLogout"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={AccountDeletionScreen}
            name="AccountDeletion"
            options={{ headerShown: false }}
          />

          <Stack.Group screenOptions={{ presentation: "modal" }}>
            <Stack.Screen
              component={CampaignInputScreen}
              name="CampaignInput"
              options={{ headerShown: false }}
            />
            <Stack.Screen
              component={CampaignFilterScreen}
              name="CampaignFilter"
              options={{ headerShown: false }}
            />
          </Stack.Group>
          <Stack.Group screenOptions={{ presentation: "fullScreenModal" }}>
            <Stack.Screen
              component={TicketScreen}
              name="Ticket"
              options={{ headerShown: false }}
            />

            <Stack.Screen
              component={PostDraftScreen}
              name="PostDraft"
              options={{ headerShown: false }}
            />

            <Stack.Screen
              component={PostReportScreen}
              name="PostReport"
              options={{ headerShown: false }}
            />
          </Stack.Group>
        </>
      ) : (
        <>
          <Stack.Screen
            component={AuthEnterScreen}
            name="Auth"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={AuthLandingScreen}
            name="AuthLanding"
            options={{ headerShown: false }}
          />

          <Stack.Screen
            component={AuthLoginScreen}
            name="AuthLogin"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={AuthSignupScreen}
            name="AuthSignup"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={AuthSnsSignupScreen}
            name="AuthSnsSignup"
            options={{ headerShown: false }}
          />
        </>
      )}

      <Stack.Screen
        component={SignupThanksScreen}
        name="SignupThanks"
        options={{ headerShown: false }}
      />

      <Stack.Screen
        component={OAuthWebScreen}
        name="OAuthWeb"
        options={{ headerShown: false }}
      />

      <Stack.Screen
        component={OAuthAppScreen}
        name="OAuthApp"
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={AuthReissueScreen}
        name="AuthReissue"
        options={{ headerShown: false }}
      />

      <Stack.Screen
        component={TermsOfUseScreen}
        name="TermsOfUse"
        options={{ headerShown: false }}
      />

      <Stack.Screen
        component={PrivacyPolicyScreen}
        name="PrivacyPolicy"
        options={{ headerShown: false }}
      />

      <Stack.Screen
        component={ChangePasswordScreen}
        name="ChangePassword"
        options={{ headerShown: false }}
      />

      {Platform.OS === "web" && (
        <Stack.Screen
          component={LpScreen}
          name="Lp"
          options={{ headerShown: false }}
        />
      )}
    </Stack.Navigator>
  );
}

const ReportsTab = createMaterialTopTabNavigator<ReportsTabParamList>();

function NavigationReportTab(
  params: ReactNavigationTop.MaterialTopTabBarProps
) {
  return <NavigationReport params={params} />;
}

function ReportsTabNavigator() {
  return (
    <>
      <NavigationBar
        onBack={() => replace("Root", { screen: "MyPage" })}
        title="レポート"
      />
      <ReportsTab.Navigator
        screenOptions={{
          tabBarLabelStyle: { ...Fonts.xlr100 },
          tabBarActiveTintColor: Colors.green,
          tabBarInactiveTintColor: Colors.black,
          swipeEnabled: false,
        }}
        tabBar={NavigationReportTab}
      >
        <ReportsTab.Screen
          component={ReportMonthlyScreen}
          name="Monthly"
          options={{
            title: "月別",
          }}
        />
        <ReportsTab.Screen
          component={ReportYearlyScreen}
          name="Yearly"
          options={{
            title: "年別",
          }}
        />
      </ReportsTab.Navigator>
    </>
  );
}

const CampaignsTab = createMaterialTopTabNavigator<CampaignsTabParamList>();

function NavigationCampaignsTab(
  params: ReactNavigationTop.MaterialTopTabBarProps
) {
  return <NavigationCampaigns params={params} />;
}
function CampaignsTabNavigator(data: RootTabScreenProps<"Home">) {
  const { route } = data;
  return (
    <CampaignsTab.Navigator
      initialRouteName={route.params?.tab}
      screenOptions={{
        tabBarLabelStyle: { ...Fonts.xlr100 },
        tabBarActiveTintColor: Colors.green,
        tabBarInactiveTintColor: Colors.black,
        tabBarScrollEnabled: true,
      }}
      tabBar={NavigationCampaignsTab}
    >
      <CampaignsTab.Screen
        component={CampaignsRecommendedScreen}
        name="Recommended"
        options={{
          title: "オススメ",
        }}
      />
      <CampaignsTab.Screen
        component={CampaignsNewScreen}
        name="New"
        options={{
          title: "新着",
        }}
      />
      <CampaignsTab.Screen
        component={CampaignsPopularScreen}
        name="Popular"
        options={{
          title: "人気",
        }}
      />
      <CampaignsTab.Screen
        component={CampaignsClosingScreen}
        name="Closing"
        options={{
          title: "締切間近",
        }}
      />
      <CampaignsTab.Screen
        component={CampaignsFinishedScreen}
        name="Finished"
        options={{
          title: "募集終了",
        }}
      />
    </CampaignsTab.Navigator>
  );
}

const TodoTab = createMaterialTopTabNavigator<TodoTabParamList>();
function NavigationTodoTab(params: ReactNavigationTop.MaterialTopTabBarProps) {
  return <NavigationTodo params={params} />;
}
function TodoTabNavigator(data: RootTabScreenProps<"Todo">) {
  const { route } = data;
  return (
    <TodoTab.Navigator
      initialRouteName={
        route.params !== undefined ? route.params.tab : "Working"
      }
      screenOptions={{
        tabBarLabelStyle: { ...Fonts.xlr100 },
        tabBarActiveTintColor: Colors.green,
        tabBarInactiveTintColor: Colors.black,
      }}
      tabBar={NavigationTodoTab}
    >
      <TodoTab.Screen
        component={TodoScreen}
        name="Working"
        options={{
          title: "進行中",
        }}
      />
      <TodoTab.Screen
        component={RecruitingScreen}
        name="Recruiting"
        options={{
          title: "応募中",
        }}
      />
    </TodoTab.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function NavigationMenuTab(params: ReactNavigationBottom.BottomTabBarProps) {
  return <NavigationMenu params={params} />;
}

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator tabBar={NavigationMenuTab}>
      <BottomTab.Screen
        component={CampaignsTabNavigator}
        name="Home"
        options={{
          title: "ホーム",
          tabBarLabel: "home",
          headerShown: false,
        }}
      />

      <BottomTab.Screen
        component={FavoriteScreen}
        name="Favorite"
        options={{
          title: "お気に入り",
          tabBarLabel: "heartEmpty",
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        component={TodoTabNavigator}
        name="Todo"
        options={{
          title: "ワーク",
          tabBarLabel: "checklist",
          headerShown: false,
        }}
      />

      <BottomTab.Screen
        component={MyPageScreen}
        name="MyPage"
        options={{
          title: "マイページ",
          tabBarLabel: "user",
          headerShown: false,
        }}
      />
    </BottomTab.Navigator>
  );
}
