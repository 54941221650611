/**
 * @generated SignedSource<<a96f34a38f011c8833514335bf522a07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignTabs = "Closing" | "Finished" | "New" | "Popular" | "Recommended";
export type CategoryTypes = "body_care" | "child_teaching" | "dance" | "diet" | "diy" | "fashion" | "gadget" | "game" | "gourmet_sweet_report" | "hair_care" | "kids" | "life_style" | "makeup" | "movie" | "nail" | "other" | "pet" | "ranking" | "skin_care" | "travel" | "yoga";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
export type CampaignsClosingScreenQuery$variables = {
  first: number;
  selectedCategories?: ReadonlyArray<CategoryTypes> | null;
  selectedHasMoney?: boolean | null;
  selectedHasProduct?: boolean | null;
  selectedSns?: ReadonlyArray<ParentMediaType> | null;
  tab: CampaignTabs;
};
export type CampaignsClosingScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CampaignsCampaigns">;
};
export type CampaignsClosingScreenQuery = {
  response: CampaignsClosingScreenQuery$data;
  variables: CampaignsClosingScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedCategories"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedHasMoney"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedHasProduct"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedSns"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tab"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "selectedCategories",
    "variableName": "selectedCategories"
  },
  {
    "kind": "Variable",
    "name": "selectedHasMoney",
    "variableName": "selectedHasMoney"
  },
  {
    "kind": "Variable",
    "name": "selectedHasProduct",
    "variableName": "selectedHasProduct"
  },
  {
    "kind": "Variable",
    "name": "selectedSns",
    "variableName": "selectedSns"
  },
  {
    "kind": "Variable",
    "name": "tab",
    "variableName": "tab"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignsClosingScreenQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "CampaignsCampaigns"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignsClosingScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "CampaignConnection",
        "kind": "LinkedField",
        "name": "campaigns",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentMediaType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gifting",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFavoriteCheck",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Banner",
                    "kind": "LinkedField",
                    "name": "banners",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "file",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MCategory",
                    "kind": "LinkedField",
                    "name": "mCategory",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SocialNetwork",
                    "kind": "LinkedField",
                    "name": "socialNetwork",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recruitmentEndOn",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "tab",
          "selectedSns",
          "selectedCategories",
          "selectedHasMoney",
          "selectedHasProduct"
        ],
        "handle": "connection",
        "key": "CampaignsList_campaigns",
        "kind": "LinkedHandle",
        "name": "campaigns"
      }
    ]
  },
  "params": {
    "cacheID": "1fcc591ef73e2192544e8d6c37d531f7",
    "id": null,
    "metadata": {},
    "name": "CampaignsClosingScreenQuery",
    "operationKind": "query",
    "text": "query CampaignsClosingScreenQuery(\n  $first: Int!\n  $tab: CampaignTabs!\n  $selectedSns: [ParentMediaType!]\n  $selectedCategories: [CategoryTypes!]\n  $selectedHasMoney: Boolean\n  $selectedHasProduct: Boolean\n) {\n  ...CampaignsCampaigns_Ph0Av\n}\n\nfragment CampaignBigCampaign on Campaign {\n  id\n  parentMediaType\n  title\n  gifting\n  prType\n  isFavoriteCheck\n  banners {\n    file\n    id\n  }\n  mCategory {\n    name\n    id\n  }\n  socialNetwork {\n    recruitmentEndOn\n    id\n  }\n}\n\nfragment CampaignCampaign on Campaign {\n  id\n  parentMediaType\n  title\n  gifting\n  prType\n  isFavoriteCheck\n  banners {\n    file\n    id\n  }\n  mCategory {\n    name\n    id\n  }\n  socialNetwork {\n    recruitmentEndOn\n    id\n  }\n}\n\nfragment CampaignsCampaigns_Ph0Av on Query {\n  campaigns(first: $first, tab: $tab, selectedSns: $selectedSns, selectedCategories: $selectedCategories, selectedHasMoney: $selectedHasMoney, selectedHasProduct: $selectedHasProduct) {\n    edges {\n      node {\n        id\n        ...CampaignBigCampaign\n        ...CampaignCampaign\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "609b8195d12eb0774145f51a0c8c80a0";

export default node;
