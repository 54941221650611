import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import GradientButton from "@components/atoms/GradientButton";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import SNSIcon from "@components/atoms/SNSIcon";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { CampaignInputSnsInfluencer$key } from "@generated/CampaignInputSnsInfluencer.graphql";
import { CampaignInputSnsCampaign$key } from "@generated/CampaignInputSnsCampaign.graphql";
import { CampaignInputSnsMutation } from "@generated/CampaignInputSnsMutation.graphql";
import {
  KeyboardId,
  YoutubeUrlPattern,
  TiktokUrlPattern,
} from "@constants/App";
import OAuth from "@components/molecules/OAuth";
import openURL from "@lib/util/openUrl";
import Icon from "@components/atoms/Icon";

const profileQuery = graphql`
  fragment CampaignInputSnsInfluencer on Influencer {
    linkingInstagram
    linkingTwitter
    profile {
      tiktokUrl
      ytChannel
    }
  }
`;

const campaignQuery = graphql`
  fragment CampaignInputSnsCampaign on Campaign {
    id
    parentMediaType
  }
`;

const profileMutation = graphql`
  mutation CampaignInputSnsMutation(
    $input: UpdateInfluencerInfoMutationInput!
  ) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          tiktokUrl
          ytChannel
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type Props = {
  snsFragment: CampaignInputSnsInfluencer$key;
  campaignFragment: CampaignInputSnsCampaign$key;
  hasNext: boolean;
  onClose: () => void;
};

type ProfileInput = {
  ytChannel: string | null;
  tiktokUrl: string | null;
};

const ytChannelSchema = string()
  .trim()
  .matches(YoutubeUrlPattern, "チャンネルURLを入力してください")
  .default(null)
  .toNull();

const tiktokUrlSchema = string()
  .trim()
  .matches(TiktokUrlPattern, "アカウントURLを入力してください")
  .default(null)
  .toNull();

export default function CampaignInputSns({
  snsFragment,
  campaignFragment,
  hasNext,
  onClose,
}: Props) {
  const { profile, linkingInstagram, linkingTwitter } =
    useFragment<CampaignInputSnsInfluencer$key>(profileQuery, snsFragment);

  const { parentMediaType, id } = useFragment<CampaignInputSnsCampaign$key>(
    campaignQuery,
    campaignFragment
  );
  const [commit] = useMutation<CampaignInputSnsMutation>(profileMutation);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ProfileInput>({
    defaultValues: {
      ytChannel: profile.ytChannel ?? "",
      tiktokUrl: profile.tiktokUrl ?? "",
    },
    mode: "onChange",
    resolver: yupResolver(
      object().shape({
        ytChannel:
          parentMediaType === "youtube"
            ? ytChannelSchema.required()
            : ytChannelSchema.notRequired(),
        tiktokUrl:
          parentMediaType === "tiktok"
            ? tiktokUrlSchema.required()
            : tiktokUrlSchema.notRequired(),
      })
    ),
  });

  const save = useCallback(async () => {
    await handleSubmit(async (profileInput: ProfileInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...profileInput,
            },
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setError(updateInfluencerInfo.message);
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  useEffect(() => {
    let mediaIsValid = true;
    if (parentMediaType === "instagram") {
      mediaIsValid = linkingInstagram === true;
    } else if (parentMediaType === "twitter") {
      mediaIsValid = linkingTwitter === true;
    }
    setIsSubmittable(isValid && mediaIsValid);
  }, [isValid, parentMediaType, linkingInstagram, linkingTwitter]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="SNS連携" />

      <Divider />

      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />

            <Card style={styles.item}>
              <Text style={styles.message}>SNS連携を行ってください</Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.item}>
              {parentMediaType === "youtube" ? (
                <>
                  <View style={styles.sns}>
                    <SNSIcon name={parentMediaType} style={styles.socialIcon} />
                    <Spacer width={8} />
                    <View style={styles.title}>
                      <Text>チャンネルURL</Text>
                    </View>
                  </View>

                  <Spacer height={8} />
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    name="ytChannel"
                    placeholder="https://www.youtube.com/"
                    type="url"
                  />
                  {!isValid && errors.ytChannel !== undefined && (
                    <ErrorText error={errors.ytChannel.message} />
                  )}
                  <Spacer height={16} />
                  <TouchableOpacity
                    onPress={() => openURL("https://youtube.com/")}
                    style={styles.open}
                  >
                    <Text>YouTubeを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                </>
              ) : parentMediaType === "tiktok" ? (
                <>
                  <View style={styles.sns}>
                    <SNSIcon name={parentMediaType} style={styles.socialIcon} />
                    <Spacer width={8} />
                    <View style={styles.title}>
                      <Text>プロフィールURL</Text>
                    </View>
                  </View>

                  <Spacer height={8} />
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    name="tiktokUrl"
                    placeholder="https://www.tiktok.com/@"
                    type="url"
                  />
                  {!isValid && errors.tiktokUrl !== undefined && (
                    <ErrorText error={errors.tiktokUrl.message} />
                  )}
                  <Spacer height={16} />
                  <TouchableOpacity
                    onPress={() => openURL("https://tiktok.com/")}
                    style={styles.open}
                  >
                    <Text>TikTokを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                </>
              ) : parentMediaType === "instagram" ? (
                <>
                  <View style={styles.label}>
                    <Text style={styles.labelColumn}>SNS連携</Text>
                  </View>
                  <Spacer height={4} />
                  <View style={styles.sns}>
                    <SNSIcon name={parentMediaType} style={styles.socialIcon} />
                    <Spacer width={8} />
                    <View style={styles.title}>
                      <Text style={styles.text}>Instagram</Text>
                    </View>

                    <Spacer width={24} />
                    {linkingInstagram === true ? (
                      <View style={styles.link}>
                        <Text style={styles.linked}>連携済み</Text>
                      </View>
                    ) : (
                      <OAuth
                        action="Linking"
                        callbackUrl={`/campaign/input/${id}`}
                        type="Instagram"
                        useGuide
                      >
                        <View style={[styles.link, styles.button]}>
                          <Text style={styles.buttonText}>連携する</Text>
                        </View>
                      </OAuth>
                    )}
                  </View>
                  {linkingInstagram !== true && (
                    <>
                      <Spacer height={4} />
                      <Text style={styles.error}>
                        Instagram連携を行ってください。
                      </Text>
                      <Text style={styles.error}>
                        Instagramのパスワード変更やアプリ仕様の変更から再度連携が必要な場合があります。
                        お手数ですが再度Instagramの連携いただくようにお願いします。
                      </Text>
                    </>
                  )}
                </>
              ) : parentMediaType === "twitter" ? (
                <View style={styles.sns}>
                  <SNSIcon name={parentMediaType} style={styles.socialIcon} />
                  <Spacer width={8} />
                  <View style={styles.title}>
                    <Text style={styles.text}>Twitter</Text>
                  </View>
                  <Spacer width={24} />
                  {linkingTwitter === true ? (
                    <View style={styles.link}>
                      <Text style={styles.linked}>連携済み</Text>
                    </View>
                  ) : (
                    <OAuth
                      action="Linking"
                      callbackUrl={`/campaign/input/${id}`}
                      type="Twitter"
                    >
                      <View style={[styles.link, styles.button]}>
                        <Text style={styles.buttonText}>連携する</Text>
                      </View>
                    </OAuth>
                  )}
                </View>
              ) : null}
            </Card>
            <Spacer height={80} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isSubmittable}
          loading={loading}
          onPress={save}
          title={hasNext ? "次へ" : "情報を登録し、応募する"}
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
  label: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelColumn: {
    marginRight: 4,
  },
  icon: {
    width: 24,
    height: 24,
  },
  sns: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  buttonText: {
    ...Fonts.lb130,
    color: Colors.white,
  },
  link: {
    width: 64,
    height: 32,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: Colors.green,
  },
  linked: {
    color: Colors.green,
    ...Fonts.xlb100,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  error: {
    color: Colors.orange,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
