import analytics from "@react-native-firebase/analytics";

type ClickType =
  | "LinkingApple"
  | "LinkingLine"
  | "LinkingInstagram"
  | "LinkingTwitter"
  | "LinkingYoutube"
  | "LinkingTiktok"
  | "Contact"
  | "OpenManual"
  | "OpenInstagramGuide";

export async function setUser(influencerId: string) {
  await analytics().setUserId(influencerId);
  await analytics().setUserProperties({
    id: influencerId,
  });
}

export async function start() {
  await analytics().logEvent("start");
}

export async function screenView(screen: string) {
  await analytics().logEvent("screen_view", {
    screen_name: screen,
  });
}

export async function landingForm() {
  await analytics().logEvent("landing_form");
}

export async function login() {
  await analytics().logEvent("login");
}

export async function signUp() {
  await analytics().logEvent("sign_up");
}

export async function removeAccount() {
  await analytics().logEvent("remove_account");
}

export async function clickAction(type: ClickType) {
  await analytics().logEvent("clickAction", { type });
}
