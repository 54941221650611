import React, { useMemo } from "react";
import {
  Platform,
  Button,
  StyleSheet,
  Keyboard,
  InputAccessoryView as RnInputAccessoryView,
  ReturnKeyType,
} from "react-native";
import { View } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import { env } from "@constants/Env";

type Props = {
  /** TextInput紐付け用のID */
  nativeID: string;
  /** クリックイベント */
  onPress?: () => void;
  /** 表示する文字 */
  label?: ReturnKeyType;
};

const availableEnv = ["staging", "production"];

export default function InputAccessoryView({
  nativeID,
  onPress = () => Keyboard.dismiss(),
  label = "done",
}: Props) {
  const { dark } = useTheme();
  const backgroundColor = useMemo<string>(
    () => (dark ? "#343332" : "#d1d3d8"),
    [dark]
  );
  if (Platform.OS === "web" || availableEnv.indexOf(env) === -1) {
    return null;
  }
  return (
    <RnInputAccessoryView nativeID={nativeID}>
      <View style={[styles.container, { backgroundColor }]}>
        <Button onPress={onPress} title={label} />
      </View>
    </RnInputAccessoryView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});
