import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from "react-relay/hooks";
import { CategoryEditScreenQuery } from "@generated/CategoryEditScreenQuery.graphql";
import CategoryEdit from "@components/templates/CategoryEdit";
import { goBack } from "@navigation/navigate";
import _ from "lodash";

const categoryEditScreen = graphql`
  query CategoryEditScreenQuery {
    influencer {
      ...CategoryEditInfluencerCategories
    }
    ...CategoryEditMCategories
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CategoryEditScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<CategoryEditScreenQuery>(
    categoryEditScreen,
    queryReference
  );
  return (
    <CategoryEdit categoryFragment={data.influencer} listFragment={data} />
  );
}

export default function CategoryEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CategoryEditScreenQuery>(categoryEditScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);
  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="興味あるカテゴリー" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
