import React, { useState, useCallback, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  DatePickerOptions,
  DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import dayjs from "dayjs";
import { StyleSheet, View, TextInput } from "react-native";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import { Style } from "@components/molecules/TextInput";
import "react-datepicker/dist/react-datepicker.css";
import * as Device from "expo-device";
import ja from "date-fns/locale/ja";
import { useTheme } from "@react-navigation/native";

registerLocale("ja", ja);

function DateInput({
  value,
  changeValue,
}: {
  value: Date | null;
  changeValue: (date: Date | null) => void;
}) {
  const { colors } = useTheme();
  const [text, setText] = useState<string>(dayjs(value).format("YYYY-MM-DD"));
  useEffect(() => {
    if (value !== null) {
      setText(dayjs(value).format("YYYY-MM-DD"));
    }
  }, [value]);

  return (
    <TextInput
      onChangeText={(dateText: string) => {
        setText(dateText);
        if (/\d{4}-\d{2}-\d{2}/.test(dateText)) {
          changeValue(dayjs(dateText).toDate());
        } else {
          changeValue(null);
        }
      }}
      placeholder="選択してください"
      style={[Style.form, { color: colors.text }]}
      value={text}
    />
  );
}

export default function DateTimePicker({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  display = "spinner",
  maximumDate,
  minimumDate,
  value,
  onChange,
}: DatePickerOptions) {
  const [date, setDate] = useState<Date>(value);
  const changeValue = useCallback(
    (selected: Date | null) => {
      const dateData = dayjs(selected);
      if (onChange !== undefined) {
        onChange(
          {
            type: "set",
            nativeEvent: {
              timestamp: Date.now(),
              utcOffset: 900,
            },
          } as DateTimePickerEvent,
          dayjs(dateData).isValid() ? dateData.toDate() : undefined
        );
      }
      if (dayjs(dateData).isValid()) {
        setDate(dayjs(dateData).toDate());
      }
    },
    [onChange]
  );
  if (date === null) {
    return null;
  }
  if (Device.deviceType === Device.DeviceType.PHONE) {
    return <DateInput changeValue={changeValue} value={date} />;
  }
  return (
    <DatePicker
      customInput={
        <View>
          <DateInput changeValue={changeValue} value={date} />
          <View style={styles.icon}>
            <Icon color={Colors.black} name="down" size={24} />
          </View>
        </View>
      }
      dateFormat="yyyy-MM-dd"
      maxDate={maximumDate}
      minDate={minimumDate}
      onChange={(data) => {
        if (onChange !== undefined) {
          changeValue(data === null ? null : data);
        }
        if (data !== null) {
          setDate(data);
        }
      }}
      selected={date}
    />
  );
}

const styles = StyleSheet.create({
  text: {
    lineHeight: 40,
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    paddingRight: 12,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
});
