import React, { useState, useCallback } from "react";
import {
  StyleSheet,
  Modal,
  TouchableWithoutFeedback,
  TextInput,
  View as RnView,
} from "react-native";
import Spacer from "@components/atoms/Spacer";
import Loading from "@components/atoms/Loading";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import useAccount from "@hooks/useAccount";
import { removeAccount as sendAnalytics } from "@lib/util/analytics";
import { graphql, useMutation } from "react-relay/hooks";
import Button from "@components/atoms/Button";
import Dialog from "@components/molecules/dialog/Dialog";
import { AccountDeletionMutation } from "@generated/AccountDeletionMutation.graphql";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import reloadApp from "@lib/util/realodApp";
import { useTheme } from "@react-navigation/native";
import { KeyboardId } from "@constants/App";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import { Style } from "@components/molecules/TextInput";
import { resolveError } from "@lib/util/error";

const removeMutation = graphql`
  mutation AccountDeletionMutation($input: RemoveAccountMutationInput!) {
    removeAccount(input: $input) {
      __typename
      ... on RemoveAccountSuccess {
        success
      }
      ... on RemoveAccountError {
        message
      }
    }
  }
`;

const ConfirmText = "削除する";

export default function AccountDeletion(): JSX.Element {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [commit] = useMutation<AccountDeletionMutation>(removeMutation);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [removed, setRemoved] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string | null>(null);
  const { state: influencerId } = useAccount();
  const { colors } = useTheme();

  const close = useCallback(() => {
    setConfirm(false);
    setConfirmText(null);
  }, []);

  const remove = useCallback(async () => {
    try {
      setConfirm(false);
      setLoading(true);
      await new Promise<void>((resolve, reject) => {
        commit({
          variables: {
            input: {
              influencerId,
            },
          },
          onCompleted({ removeAccount }) {
            if (removeAccount.__typename === "RemoveAccountSuccess") {
              resolve();
            } else {
              reject(
                removeAccount.__typename === "RemoveAccountError"
                  ? removeAccount.message
                  : "アカウント削除に失敗しました。"
              );
            }
          },
        });
      });
      await sendAnalytics();
      await SecureStoreManager.deleteAccessTokenSet();
      setRemoved(true);
    } catch (e: unknown) {
      setError(resolveError(e).message);
    } finally {
      setLoading(false);
    }
  }, [commit, influencerId]);

  return (
    <Wrapper>
      <Card style={styles.main}>
        <Text style={styles.title}>アカウントが削除されます</Text>

        <Spacer height={16} />

        <Text style={styles.text}>
          すべての情報（キャンペーンの履歴、本人情報など）が削除され、表示されなくなります。
          進行中の案件がある場合は完了するまでアカウントを削除できません。
        </Text>

        <Spacer height={24} />

        <View style={styles.button}>
          <Button
            backgroundColor={Colors.orange}
            border={false}
            onPress={() => setConfirm(true)}
            textStyle={styles.buttonText}
            title="アカウント削除"
            width={240}
          />
        </View>
      </Card>

      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.dialogError }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      {removed && (
        <Dialog
          message="アカウントを削除しました"
          onClose={() => reloadApp()}
          subline="Influencer Worksをご利用いただきありがとうございました"
        />
      )}
      {loading && <Loading mask />}
      {confirm && (
        <Modal animationType="none" onRequestClose={close} transparent visible>
          <View style={styles.confirm}>
            <Card
              style={[styles.message, { backgroundColor: colors.notification }]}
            >
              <Text style={styles.title}>アカウント削除</Text>
              <Spacer height={16} />
              <Text style={styles.text}>
                本当にアカウントを削除してもよろしいですか？よろしければ「削除する」と入力して削除ボタンを押してください。
              </Text>

              <Spacer height={24} />
              <TextInput
                inputAccessoryViewID={KeyboardId}
                onChangeText={(value: string) => setConfirmText(value)}
                placeholder={`「${ConfirmText}」と入力`}
                placeholderTextColor={Colors.gray}
                style={Style.form}
                value={confirmText ?? ""}
              />

              <Spacer height={24} />

              <View style={styles.buttons}>
                <View style={styles.confirmButton}>
                  <Button onPress={close} title="キャンセル" width="100%" />
                </View>
                <Spacer width={8} />
                <View style={styles.confirmButton}>
                  <Button
                    backgroundColor={Colors.orange}
                    disabled={confirmText !== ConfirmText}
                    onPress={remove}
                    textStyle={styles.removeText}
                    title="削除する"
                    width="100%"
                  />
                </View>
              </View>
            </Card>
            <TouchableWithoutFeedback onPress={close}>
              <RnView style={styles.backgroundLayer} />
            </TouchableWithoutFeedback>
          </View>
          <InputAccessoryView nativeID={KeyboardId} />
        </Modal>
      )}
    </Wrapper>
  );
}

const styles = StyleSheet.create({
  main: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  title: {
    ...Fonts.xxlb140,
    color: Colors.orange,
    textAlign: "center",
  },
  text: {
    ...Fonts.xlr160,
    color: Colors.gray,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: Colors.white,
    ...Fonts.xlr100,
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
  confirm: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 10,
  },
  message: {
    padding: 16,
    borderRadius: 6,
    maxWidth: 345,
    width: "90%",
  },
  buttons: {
    height: 50,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  confirmButton: {
    flex: 1,
  },
  backgroundLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: Colors.overlayDark,
  },
  removeText: {
    color: Colors.white,
  },
});
