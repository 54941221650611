import React, { useMemo, useCallback } from "react";
import { StyleSheet, Image, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import SNSIcon from "@components/atoms/SNSIcon";
import { graphql, useFragment } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import dayjs from "dayjs";
import { navigate } from "@navigation/navigate";
import {
  CampaignSmallCandidate$key,
  CandidateStatus,
  PostStatus,
} from "@generated/CampaignSmallCandidate.graphql";
import campaignBanner from "@lib/util/campaignBanner";
import numberFormat from "@lib/util/numberFormat";
import { isRejected } from "@lib/util/candidate";

const fragmentInput = graphql`
  fragment CampaignSmallCandidate on Candidate {
    id
    price
    customPrice
    status
    post {
      id
      status
      finishedAt
    }
    campaign {
      id
      title
      parentMediaType
      banners {
        file
      }
    }
  }
`;

export default function CampaignSmall({
  candidateFragment,
}: {
  candidateFragment: CampaignSmallCandidate$key;
}) {
  const candidate = useFragment(fragmentInput, candidateFragment);
  const onPress = useCallback(() => {
    if (candidate.post == null) {
      navigate("CampaignDetail", { id: candidate.campaign.id });
    } else {
      navigate("Chat", { id: candidate.post.id });
    }
  }, [candidate]);

  const label = useMemo(() => {
    if (candidate.post == null) {
      return isRejected(candidate.status as CandidateStatus)
        ? "不採用"
        : "応募中";
    }
    return candidate.post.status === ("finished" as PostStatus)
      ? dayjs(candidate.post.finishedAt).format("YYYY年M月D日")
      : "進行中";
  }, [candidate]);

  const price = useMemo(
    () => (candidate.post == null ? candidate.customPrice : candidate.price),
    [candidate]
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <Card style={[styles.container, styles.row]}>
        <Image
          source={campaignBanner(candidate.campaign.banners)}
          style={styles.image}
        />
        <Spacer width={16} />
        <View style={styles.item}>
          <View style={styles.row}>
            <SNSIcon
              name={candidate.campaign.parentMediaType}
              style={styles.socialIcon}
            />
            <Spacer width={8} />
            <Text ellipsizeMode="tail" numberOfLines={2} style={styles.title}>
              {candidate.campaign.title}
            </Text>
          </View>

          <Spacer height={8} />

          <View style={styles.row}>
            <View style={styles.item}>
              <Text style={styles.date}>{label}</Text>
            </View>
            {price > 0 && (
              <Text style={styles.price}>{numberFormat(price, "¥")}</Text>
            )}
          </View>
        </View>
      </Card>
      {isRejected(candidate.status as CandidateStatus) && (
        <View style={styles.mask} />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  image: {
    width: 63,
    height: 45,
  },
  item: {
    flex: 1,
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  title: {
    ...Fonts.lr100,
  },
  date: {
    ...Fonts.sb100,
    color: Colors.gray,
  },
  price: {
    ...Fonts.xlb100,
  },
  mask: {
    opacity: 0.4,
    backgroundColor: Colors.white,
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});
