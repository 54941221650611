import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { BankEditScreenQuery } from "@generated/BankEditScreenQuery.graphql";
import BankEdit from "@components/templates/BankEdit";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { goBack } from "@navigation/navigate";
import _ from "lodash";

const bankEditScreen = graphql`
  query BankEditScreenQuery {
    influencer {
      ...BankEdit
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<BankEditScreenQuery>;
}): JSX.Element {
  const { influencer } = usePreloadedQuery<BankEditScreenQuery>(
    bankEditScreen,
    queryReference
  );
  return <BankEdit bankFragment={influencer} />;
}

export default function BankEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<BankEditScreenQuery>(bankEditScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="報酬振り込み口座" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
