import React from "react";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import Divider from "@components/atoms/Divider";
import Spacer from "@components/atoms/Spacer";
import { CampaignProduct$key } from "@generated/CampaignProduct.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import TitleContent from "./TitleContent";

const campaignProduct = graphql`
  fragment CampaignProduct on Campaign {
    brandName
    mediaType
    productDetail
  }
`;

export default function CampaignProduct({
  campaignFragment,
}: {
  campaignFragment: CampaignProduct$key;
}) {
  const campaign = useFragment(campaignProduct, campaignFragment);
  return (
    <CampaignSection activeAccordion title="取り扱ってもらう商材の情報">
      <TitleContent
        content={campaign.brandName as string}
        title="商品・サービス・イベント名称（正式）"
      />
      {campaign.mediaType !== "cast" && (
        <>
          <Spacer height={16} />
          <Divider />
          <Spacer height={16} />
          <TitleContent
            content={campaign.productDetail as string}
            title="概要"
          />
        </>
      )}
    </CampaignSection>
  );
}
