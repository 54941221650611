/* eslint react/destructuring-assignment:0 */
import React from "react";
import Fallback from "@components/templates/Fallback";

type Props = {
  children: React.ReactNode;
};

type State = {
  error: string | null;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error: error.message };
  }

  componentDidCatch(error: Error) {
    this.setState({ error: error.message });
  }

  render() {
    if (this.state.error !== null) {
      return <Fallback message={this.state.error} />;
    }
    return this.props.children;
  }
}
