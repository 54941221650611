import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import days from "dayjs";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { Text, Card, View, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import {
  KeyboardId,
  PostMaxImageSize,
  PostMaxVideoSize,
  InstagramUrlPattern,
} from "@constants/App";
import AttachmentsUploader, {
  isUploadedFile,
} from "@components/molecules/AttachmentsUploader";
import { makeUploadablesFromFileList } from "@lib/util/relaySupport";
import { ImageFile } from "@components/molecules/ImagePicker";
import { MediaTypeOptions } from "expo-image-picker";
import Divider from "@components/atoms/Divider";
import Icon from "@components/atoms/Icon";
import openURL from "@lib/util/openUrl";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import {
  MediaProductType,
  PostReportInstagram$key,
} from "@generated/PostReportInstagram.graphql";
import { PostReportInstagramMutation } from "@generated/PostReportInstagramMutation.graphql";
import PostReportConfirmDialog from "@components/organisms/PostReport/PostReportConfirmDialog";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { UploadableMap } from "relay-runtime";
import { resolveError } from "@lib/util/error";
import _ from "lodash";

const chatReport = graphql`
  fragment PostReportInstagram on Post {
    id
    status
    campaign {
      mediaType
      socialNetwork {
        postStartOn
      }
    }
    postReports {
      url
      mediaProductType
      attachments {
        id
        file
        duration
        contentType
      }
    }
  }
`;

const postReportsMutation = graphql`
  mutation PostReportInstagramMutation(
    $input: CreatePostReportsMutationInput!
    $connections: [ID!]!
  ) {
    createPostReports(input: $input) {
      __typename
      ... on CreatePostReportsSuccess {
        post {
          id
          type
          status
          url
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on CreatePostReportsError {
        message
      }
    }
  }
`;

type InputData = {
  feedUrl: string | null;
  reelUrl: string | null;
  storyUrl: string | null;
};

export default function PostReportInstagram({
  postFragment,
  onClose,
  onConfirm,
  onChange,
  onLoading,
  hasFeed = false,
  hasReel = false,
  hasStory = false,
  hasLive = false,
}: {
  postFragment: PostReportInstagram$key;
  onClose: () => void;
  onConfirm: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
  hasFeed?: boolean;
  hasReel?: boolean;
  hasStory?: boolean;
  hasLive?: boolean;
}) {
  const data = useFragment(chatReport, postFragment);

  const originalFeedUrl = useMemo<string | null>(() => {
    if (data.postReports === null) {
      return null;
    }

    const post = data.postReports.find(
      (postReport) => postReport.mediaProductType === "instagram_feed"
    );
    if (post === undefined) {
      return null;
    }
    return post.url ?? null;
  }, [data]);

  const originalReelUrl = useMemo<string | null>(() => {
    if (data.postReports === null) {
      return null;
    }
    const post = data.postReports.find(
      (postReport) => postReport.mediaProductType === "instagram_reel"
    );
    if (post === undefined) {
      return null;
    }
    return post.url ?? null;
  }, [data]);

  const originalStoryUrl = useMemo<string | null>(() => {
    if (data.postReports === null) {
      return null;
    }
    const post = data.postReports.find(
      (postReport) => postReport.mediaProductType === "instagram_story"
    );

    if (post === undefined) {
      return null;
    }
    return post.url ?? null;
  }, [data]);

  const [error, setError] = useState<string | null>(null);
  const [storyImageList, setStoryImageList] = useState<ImageFile[]>([]);
  const [liveImageList, setLiveImageList] = useState<ImageFile[]>([]);
  const [postable, setPostable] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [commit] =
    useMutation<PostReportInstagramMutation>(postReportsMutation);

  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );
  const canPublish = useMemo<boolean>(
    () =>
      days().format("YYYYMMDD") >=
      days(data.campaign.socialNetwork.postStartOn).format("YYYYMMDD"),
    [data]
  );

  const storyUploaded = useMemo(() => {
    if (data.postReports === null) {
      return [];
    }
    const target = data.postReports.find(
      (postReport) => postReport.mediaProductType === "instagram_story"
    );
    return target !== undefined ? target.attachments.map((row) => row) : [];
  }, [data]);
  const liveUploaded = useMemo(() => {
    if (data.postReports === null) {
      return [];
    }
    const target = data.postReports.find(
      (postReport) => postReport.mediaProductType === "instagram_live"
    );
    return target !== undefined ? target.attachments.map((row) => row) : [];
  }, [data]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      feedUrl: originalFeedUrl,
      reelUrl: originalReelUrl,
      storyUrl: originalStoryUrl,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        feedUrl: string()
          .default(null)
          .trim()
          .test("allowEmpty", "入力してください", (value) => {
            if (hasFeed) {
              return !_.isEmpty(value);
            }
            return true;
          })
          .url("正しいURLを入力してください")
          .matches(InstagramUrlPattern, "投稿したURLを入力してください")
          .nullable(),
        reelUrl: string()
          .default(null)
          .trim()
          .test("allowEmpty", "入力してください", (value) => {
            if (hasReel) {
              return !_.isEmpty(value);
            }
            return true;
          })
          .url("正しいURLを入力してください")
          .matches(InstagramUrlPattern, "投稿したURLを入力してください")
          .nullable(),
        storyUrl: string()
          .default(null)
          .trim()
          .test("allowEmpty", "入力してください", (value) => {
            if (hasStory) {
              return !_.isEmpty(value);
            }
            return true;
          })
          .url("正しいURLを入力してください")
          .matches(InstagramUrlPattern, "投稿したURLを入力してください")
          .nullable(),
      })
    ),
  });

  useEffect(() => {
    setPostable(
      !(hasStory && storyImageList.length === 0) &&
        !(hasLive && liveImageList.length === 0) &&
        isValid &&
        canPublish
    );
  }, [canPublish, hasLive, hasStory, isValid, liveImageList, storyImageList]);

  const save = useCallback(async () => {
    await handleSubmit(async ({ feedUrl, reelUrl, storyUrl }: InputData) => {
      try {
        setConfirm(false);
        onLoading(true);
        let priority = 0;

        const postReports: {
          url?: string;
          mediaProductType: MediaProductType;
          priority: number;
          files?: File[];
        }[] = [];

        let uploadables: UploadableMap = {};

        if (hasFeed && feedUrl !== null) {
          postReports.push({
            url: feedUrl,
            mediaProductType: "instagram_feed",
            priority,
          });
          priority += 1;
        }

        if (hasReel && reelUrl !== null) {
          postReports.push({
            url: reelUrl,
            mediaProductType: "instagram_reel",
            priority,
          });
          priority += 1;
        }

        if (hasStory && storyUrl !== null) {
          const files: File[] = [];
          storyImageList.forEach((image) => {
            if (!isUploadedFile(image)) {
              files.push(image.file);
            }
          });
          postReports.push({
            url: storyUrl,
            mediaProductType: "instagram_story",
            priority,
            files,
          });

          const storyUploadables = await makeUploadablesFromFileList(
            `variables.input.postReports.${priority}.files`,
            storyImageList.map((row) => row.file)
          );
          uploadables = Object.assign(uploadables, storyUploadables);

          priority += 1;
        }

        if (hasLive) {
          const files: File[] = [];
          liveImageList.forEach((image) => {
            if (!isUploadedFile(image)) {
              files.push(image.file);
            }
          });
          postReports.push({
            mediaProductType: "instagram_live",
            priority,
            files,
          });
          const liveUploadables = await makeUploadablesFromFileList(
            `variables.input.postReports.${priority}.files`,
            liveImageList.map((row) => row.file)
          );
          uploadables = Object.assign(uploadables, liveUploadables);
          priority += 1;
        }

        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                postId: data.id,
                postReports,
              },
              connections: [connectionID],
            },
            uploadables,
            onCompleted({ createPostReports }) {
              if (createPostReports.__typename === "CreatePostReportsSuccess") {
                resolve();
              } else {
                reject(
                  createPostReports.__typename === "CreatePostReportsError"
                    ? createPostReports.message
                    : "保存できませんでした"
                );
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onLoading(false);
      }
    })();
  }, [
    commit,
    connectionID,
    data,
    hasFeed,
    hasLive,
    hasReel,
    hasStory,
    handleSubmit,
    liveImageList,
    onClose,
    onLoading,
    storyImageList,
  ]);

  return (
    <View style={styles.content}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                {days(data.campaign.socialNetwork.postStartOn).format("M月D日")}
                になったらキャンペーン内容をもとに、
                <Text style={[styles.text, styles.alert]}>
                  下書き作成した内容をInstagramで公開
                </Text>
                してください。
              </Text>
              <Spacer height={8} />
              <TouchableOpacity onPress={onConfirm} style={styles.draft}>
                <Text style={styles.draftText}>下書き内容を確認</Text>
              </TouchableOpacity>
            </Card>

            {hasFeed && (
              <>
                <Spacer height={16} />
                <Card style={styles.card}>
                  <Text style={styles.title}>フィード投稿</Text>
                  <Spacer height={8} />
                  <Text style={styles.text}>
                    公開したフィード投稿のURLを入力してください。
                  </Text>
                  <Spacer height={16} />
                  <TextField
                    control={control}
                    disabled={data.status === "wait_agency_confirm_report"}
                    inputAccessoryViewID={KeyboardId}
                    name="feedUrl"
                    onChange={(value: string) => {
                      onChange(value !== originalFeedUrl);
                    }}
                    placeholder="Instagram URL"
                    placeholderTextColor={Colors.gray}
                    type="url"
                  />
                  {!isValid && errors.feedUrl !== undefined && (
                    <ErrorText error={errors.feedUrl.message} />
                  )}
                  <Spacer height={8} />
                  <TouchableOpacity
                    onPress={() => openURL("https://www.instagram.com/")}
                    style={styles.open}
                  >
                    <Text style={styles.guide}>Instagramを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                  <Spacer height={8} />
                </Card>
              </>
            )}

            {hasReel && (
              <>
                <Spacer height={16} />
                <Card style={styles.card}>
                  <Text style={styles.title}>リール投稿</Text>
                  <Spacer height={8} />
                  <Text style={styles.text}>
                    公開したリール投稿のURLを入力してください。
                  </Text>
                  <Spacer height={16} />
                  <TextField
                    control={control}
                    disabled={data.status === "wait_agency_confirm_report"}
                    inputAccessoryViewID={KeyboardId}
                    name="reelUrl"
                    onChange={(value: string) => {
                      onChange(value !== originalReelUrl);
                    }}
                    placeholder="Instagram URL"
                    placeholderTextColor={Colors.gray}
                    type="url"
                  />
                  {!isValid && errors.reelUrl !== undefined && (
                    <ErrorText error={errors.reelUrl.message} />
                  )}
                  <Spacer height={8} />
                  <TouchableOpacity
                    onPress={() => openURL("https://www.instagram.com/")}
                    style={styles.open}
                  >
                    <Text style={styles.guide}>Instagramを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                  <Spacer height={8} />
                </Card>
              </>
            )}
            {hasStory && (
              <>
                <Spacer height={16} />
                <Card style={styles.card}>
                  <Text style={styles.title}>ストーリーズ</Text>
                  <Spacer height={8} />
                  <Text style={styles.text}>
                    公開したストーリーズのURLを入力してください。
                  </Text>
                  <Spacer height={16} />
                  <TextField
                    control={control}
                    disabled={data.status === "wait_agency_confirm_report"}
                    inputAccessoryViewID={KeyboardId}
                    name="storyUrl"
                    onChange={(value: string) => {
                      onChange(value !== originalStoryUrl);
                    }}
                    placeholder="Instagram URL"
                    placeholderTextColor={Colors.gray}
                    type="url"
                  />
                  {!isValid && errors.storyUrl !== undefined && (
                    <ErrorText error={errors.storyUrl.message} />
                  )}
                  <Spacer height={16} />
                  <Text style={styles.text}>
                    24時間で消えてしまうため、スクリーンショットのアップロードもお願いします。
                    (最大10枚、写真{PostMaxImageSize}MB、動画
                    {PostMaxVideoSize}MBまで)
                  </Text>
                  <Spacer height={16} />
                  <AttachmentsUploader
                    items={storyUploaded}
                    maxImageSize={PostMaxImageSize}
                    maxVideoSize={PostMaxVideoSize}
                    onChange={async (e: ImageFile[]) => {
                      setStoryImageList(e);
                      onChange(true);
                    }}
                    onMessage={setError}
                    pickupMediaType={MediaTypeOptions.All}
                    selectionLimit={10}
                    uploadable={data.status === "wait_publish"}
                  />
                  <Spacer height={16} />
                  <TouchableOpacity
                    onPress={() => openURL("https://www.instagram.com/")}
                    style={styles.open}
                  >
                    <Text style={styles.guide}>Instagramを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                  <Spacer height={8} />
                </Card>
              </>
            )}
            {hasLive && (
              <>
                <Spacer height={16} />
                <Card style={styles.card}>
                  <Text style={styles.title}>ライブ</Text>
                  <Spacer height={16} />
                  <Text style={styles.text}>
                    ライブ配信終了後に「ストーリーズでシェア」を行い、リプレイ動画のスクリーンショット画像をアップしてください(最大10枚、写真
                    {PostMaxImageSize}MB、動画
                    {PostMaxVideoSize}MBまで)
                  </Text>
                  <Spacer height={16} />
                  <AttachmentsUploader
                    items={liveUploaded}
                    maxImageSize={PostMaxImageSize}
                    maxVideoSize={PostMaxVideoSize}
                    onChange={(e: ImageFile[]) => {
                      setLiveImageList(e);
                      onChange(true);
                    }}
                    onMessage={setError}
                    pickupMediaType={MediaTypeOptions.All}
                    selectionLimit={10}
                    uploadable={data.status === "wait_publish"}
                  />
                  <Spacer height={16} />
                  <TouchableOpacity
                    onPress={() => openURL("https://www.instagram.com/")}
                    style={styles.open}
                  >
                    <Text style={styles.guide}>Instagramを開く</Text>
                    <Spacer width={4} />
                    <Icon color={Colors.gray} name="outer" size={16} />
                  </TouchableOpacity>
                  <Spacer height={8} />
                </Card>
              </>
            )}
          </Wrapper>

          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {data.status !== "wait_agency_confirm_report" && (
        <>
          <Divider />
          <Card style={styles.action}>
            {!canPublish && (
              <>
                <Text style={styles.alert}>
                  {days(data.campaign.socialNetwork.postStartOn).format(
                    "M月D日"
                  )}
                  になるまでお待ち下さい
                </Text>
                <Spacer height={8} />
              </>
            )}
            <GradientButton
              disabled={!postable}
              onPress={() => {
                setConfirm(true);
              }}
              textStyle={styles.actionText}
              title="報告する"
            />
          </Card>
        </>
      )}
      {confirm && (
        <PostReportConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
      {error !== null && (
        <ErrorDialog
          message={error}
          onClose={() => setError(null)}
          title="報告内容を修正してください"
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  scroll: {
    flexGrow: 1,
  },
  card: {
    padding: 16,
  },
  title: {
    ...Fonts.lb130,
    color: Colors.gray,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    color: Colors.gray,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  draft: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  draftText: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
