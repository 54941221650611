/**
 * @generated SignedSource<<2384791f24e9486fb81c85d43854c573>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PhoneRegenerateCodeMutationInput = {
  clientMutationId?: string | null;
};
export type CampaignInputPhoneConfirmCResendMutation$variables = {
  input: PhoneRegenerateCodeMutationInput;
};
export type CampaignInputPhoneConfirmCResendMutation$data = {
  readonly phoneRegenerateCode: {
    readonly __typename: "Influencer";
    readonly newPhone: string | null;
  } | {
    readonly __typename: "PhoneRegenerateCodeError";
    readonly errorType: string;
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CampaignInputPhoneConfirmCResendMutation = {
  response: CampaignInputPhoneConfirmCResendMutation$data;
  variables: CampaignInputPhoneConfirmCResendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newPhone",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "PhoneRegenerateCodeError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignInputPhoneConfirmCResendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "phoneRegenerateCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignInputPhoneConfirmCResendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "phoneRegenerateCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bb68c8041f71262ce554d966ab2d1c7",
    "id": null,
    "metadata": {},
    "name": "CampaignInputPhoneConfirmCResendMutation",
    "operationKind": "mutation",
    "text": "mutation CampaignInputPhoneConfirmCResendMutation(\n  $input: PhoneRegenerateCodeMutationInput!\n) {\n  phoneRegenerateCode(input: $input) {\n    __typename\n    ... on Influencer {\n      newPhone\n      id\n    }\n    ... on PhoneRegenerateCodeError {\n      errorType\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02cd4b629fddfcac276a556b175729b3";

export default node;
