import React from "react";
import { StyleSheet, FlatList } from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ReportSummary from "@components/organisms/Report/ReportSummary";
import CampaignSmall from "@components/molecules/List/CampaignSmall";
import { graphql, useFragment } from "react-relay/hooks";
import { ReportSales$key } from "@generated/ReportSales.graphql";
import { ReportPostList$key } from "@generated/ReportPostList.graphql";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";

const salesQuery = graphql`
  fragment ReportSales on Sales {
    ...ReportSummaryData
  }
`;

const postsQuery = graphql`
  fragment ReportPostList on Query
  @argumentDefinitions(
    date: { type: "String!" }
    searchType: { type: "ReportSearchType!" }
  ) {
    completedPosts(date: $date, searchType: $searchType) {
      id
      candidate {
        ...CampaignSmallCandidate
      }
    }
  }
`;

type Props = {
  salesFragment: ReportSales$key;
  postFragments: ReportPostList$key;
  searchType: "month" | "year";
};

export default function Report({
  salesFragment,
  postFragments,
  searchType,
}: Props) {
  const sale = useFragment(salesQuery, salesFragment);
  const { completedPosts } = useFragment(postsQuery, postFragments);
  return (
    <FlatList
      data={completedPosts}
      keyExtractor={(post) => post.id}
      ListEmptyComponent={
        <Card style={styles.empty}>
          <Wrapper>
            <View style={styles.row}>
              <View style={styles.title}>
                <Text style={styles.titleText}>完了キャンペーン</Text>
              </View>
            </View>
            <Spacer height={16} />
            <Text style={styles.emptyText}>
              ワークが完了したキャンペーンはありません。
            </Text>
          </Wrapper>
        </Card>
      }
      ListHeaderComponent={
        <Wrapper>
          <View style={styles.head}>
            <ReportSummary searchType={searchType} summaryFragment={sale} />
          </View>
        </Wrapper>
      }
      renderItem={({ item, index }) => (
        <Card style={styles.item}>
          <Wrapper>
            {index === 0 && (
              <>
                <Spacer height={24} />
                <View style={styles.row}>
                  <View style={styles.title}>
                    <Text style={styles.titleText}>
                      完了キャンペーン {completedPosts.length}
                    </Text>
                  </View>
                </View>
                <Spacer height={8} />
              </>
            )}
            <Spacer height={8} />
            <CampaignSmall candidateFragment={item.candidate} />
            <Spacer height={8} />
            {index !== completedPosts.length - 1 && <Divider />}
          </Wrapper>
        </Card>
      )}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  head: {
    padding: 16,
  },
  item: {
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    flex: 1,
  },
  titleText: {
    ...Fonts.lr100,
  },
  empty: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  emptyText: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
});
