/**
 * @generated SignedSource<<eece275b31b2ccb44d41cbb5bce15b62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddressEditPrefecture$data = {
  readonly prefectures: ReadonlyArray<{
    readonly name: string;
  }>;
  readonly " $fragmentType": "AddressEditPrefecture";
};
export type AddressEditPrefecture$key = {
  readonly " $data"?: AddressEditPrefecture$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddressEditPrefecture">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddressEditPrefecture",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MPrefecture",
      "kind": "LinkedField",
      "name": "prefectures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ea4460caecd6e7bdcbe853d3c861b1a9";

export default node;
