import { StyleSheet, ScrollView } from "react-native";
import React, { useState } from "react";
import { TicketPost$key } from "@generated/TicketPost.graphql";
import { TicketMutation } from "@generated/TicketMutation.graphql";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import { replace, canGoBack, goBack } from "@navigation/navigate";
import NavigationBar from "@components/molecules/NavigationBar";
import Dialog from "@components/molecules/dialog/Dialog";
import Button from "@components/atoms/Button";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Loading from "@components/atoms/Loading";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import CampaignVisitReservation from "@components/molecules/List/CampaignVisitReservation";
import { resolveError } from "@lib/util/error";

const ticketFragment = graphql`
  fragment TicketPost on Post {
    id
    status
    campaign {
      ...CampaignVisitReservationCampaign
    }
  }
`;

const ticketMutation = graphql`
  mutation TicketMutation(
    $input: UseTicketMutationInput!
    $connections: [ID!]!
  ) {
    useTicket(input: $input) {
      __typename
      ... on UseTicketSuccess {
        post {
          id
          status
          content
          attachments {
            file
            duration
            contentType
          }
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            stamp
            content
            extraInfo
            extraType
            createdAt
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            commentable {
              id
              userType
              avatar {
                file
              }
            }
          }
        }
      }
      ... on UseTicketError {
        message
      }
    }
  }
`;
export default function Ticket({
  postFragment,
}: {
  postFragment: TicketPost$key;
}) {
  const data = useFragment(ticketFragment, postFragment);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<TicketMutation>(ticketMutation);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );

  function onClose() {
    if (canGoBack()) {
      goBack();
    } else {
      replace("Chat", { id: data.id }); // route.params.id出なくて良い？
    }
  }

  async function save(): Promise<void> {
    try {
      setConfirm(false);
      setLoading(true);
      await new Promise<void>((resolve, reject) => {
        commit({
          variables: {
            input: {
              id: data.id,
            },
            connections: [connectionID],
          },
          onCompleted({ useTicket }) {
            if (useTicket.__typename === "UseTicketSuccess") {
              resolve();
            } else {
              reject(
                useTicket.__typename === "UseTicketError"
                  ? useTicket.message
                  : "チケットを利用できませんでした"
              );
            }
          },
        });
      });
      onClose();
    } catch (e: unknown) {
      setError(resolveError(e).message);
      setLoading(false);
    }
  }

  return (
    <View style={styles.container}>
      <NavigationBar
        onClose={() => {
          onClose();
        }}
        title="来店チケット"
      />
      <ScrollView>
        <Wrapper>
          <View style={styles.wrap}>
            <Spacer height={8} />
            <CampaignVisitReservation campaignFragment={data.campaign} />
          </View>
        </Wrapper>
      </ScrollView>
      <Card style={styles.action}>
        <Spacer height={8} />
        <Text style={styles.guidance}>この画面を従業員にお見せください</Text>
        <Spacer height={8} />
        <Button
          backgroundColor={
            data.status === "wait_visitation" ? Colors.orange : Colors.gray30
          }
          border={false}
          onPress={() => setConfirm(true)}
          textStyle={styles.whiteText}
          title={
            data.status === "wait_visitation"
              ? "使用済みにする"
              : "このチケットは使用済みです"
          }
        />
      </Card>
      {confirm && (
        <Dialog
          buttons={[
            { title: "閉じる", type: "white" },
            { title: "使用済みにする", type: "blueGradient", onPress: save },
          ]}
          message="使用確認"
          onClose={() => setConfirm(false)}
          subline="チケットを使用済みにしますか？"
        />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      {loading && <Loading mask />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrap: {
    flex: 1,
    paddingHorizontal: 16,
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  guidance: {
    color: Colors.gray,
    ...Fonts.lr130,
  },
  whiteText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
});
