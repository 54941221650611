/**
 * @generated SignedSource<<d06b8530c31e21a0c4da17be033938b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignDetailUnitPrice$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CampaignMainCampaignDetailUnitPrice">;
  readonly " $fragmentType": "CampaignDetailUnitPrice";
};
export type CampaignDetailUnitPrice$key = {
  readonly " $data"?: CampaignDetailUnitPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignDetailUnitPrice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDetailUnitPrice",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignMainCampaignDetailUnitPrice"
    }
  ],
  "type": "Candidate",
  "abstractKey": null
};

(node as any).hash = "61a8880821b9a9827c71562bcaf96e3e";

export default node;
