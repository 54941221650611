import React, { useMemo } from "react";
import { View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import { StyleSheet, ScrollView } from "react-native";
import {
  ChatStatus$key,
  ChatStatus$data,
  PostStatus,
} from "@generated/ChatStatus.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import StepChart from "@components/molecules/StepChart";

const chatQuery = graphql`
  fragment ChatStatus on Post {
    status
    campaign {
      prType
      mediaType
      hasDelivery
      hasExperienceGift
    }
  }
`;

type StatusContent = {
  text: string;
  status: "toDo" | "inProgress" | "done";
  isStart?: boolean;
};

enum Steps {
  ProductShipping,
  RecievedReport,
  Visitation,
  Draft,
  Review,
  Report,
  Confirmation,
  Finished,
}

function checkStatus(
  currentStatus: PostStatus,
  step: number
): "toDo" | "inProgress" | "done" {
  const getCurrentStep = (status: PostStatus) => {
    switch (status) {
      case "wait_product_shipped":
        return Steps.ProductShipping;
      case "product_shipped":
        return Steps.RecievedReport;
      case "wait_visitation":
        return Steps.Visitation;
      case "draft":
      case "admin_rejected":
      case "agency_rejected":
      case "draft_with_reject":
        return Steps.Draft;
      case "wait_admin_review":
      case "wait_agency_review":
        return Steps.Review;
      case "wait_publish":
        return Steps.Report;
      case "wait_agency_confirm_report":
        return Steps.Confirmation;
      case "finished":
        return Steps.Finished;
      default:
        return 0;
    }
  };

  return step < getCurrentStep(currentStatus)
    ? "done"
    : getCurrentStep(currentStatus) === step
    ? "inProgress"
    : "toDo";
}

function getSteps(
  campaign: ChatStatus$data["campaign"],
  status: PostStatus
): StatusContent[] {
  const statuses: StatusContent[] = [];
  if (campaign.hasDelivery) {
    statuses.push({
      text: "商品発送",
      status: checkStatus(status, Steps.ProductShipping),
    });
    statuses.push({
      text: "受取報告",
      status: checkStatus(status, Steps.RecievedReport),
    });
  }
  if (campaign.hasExperienceGift) {
    statuses.push({
      text: "訪問待ち",
      status: checkStatus(status, Steps.Visitation),
    });
  }
  if (campaign.mediaType !== "cast") {
    statuses.push({
      text: "下書き作成",
      status: checkStatus(status, Steps.Draft),
    });
    statuses.push({
      text: "レビュー",
      status: checkStatus(status, Steps.Review),
    });
  }
  statuses.push({
    text: "報告",
    status: checkStatus(status, Steps.Report),
  });
  statuses.push({
    text: "確認",
    status: checkStatus(status, Steps.Confirmation),
  });
  statuses.push({
    text: "お疲れ様",
    status: checkStatus(status, Steps.Finished),
  });
  return statuses;
}

type Props = {
  postFragment: ChatStatus$key;
};

export default function ChatStatus({ postFragment }: Props) {
  const data = useFragment(chatQuery, postFragment);

  const statusContents = useMemo<StatusContent[]>(
    () => getSteps(data.campaign, data.status as PostStatus),
    [data]
  );
  return (
    <View style={styles.container}>
      <ScrollView horizontal>
        <Spacer width={16} />
        {statusContents.map((row, index) => (
          <StepChart
            key={`status-${row.text}`}
            isEnd={index === statusContents.length - 1}
            isStart={index === 0}
            status={row.status}
            text={row.text}
          />
        ))}
        <Spacer width={16} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    height: 78,
  },
});
