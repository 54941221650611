/**
 * @generated SignedSource<<1b2c6240220b438680e0bdfbd8c6d858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailEdit$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailEditConfirm" | "EmailEditForm">;
  readonly " $fragmentType": "EmailEdit";
};
export type EmailEdit$key = {
  readonly " $data"?: EmailEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailEdit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailEdit",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailEditConfirm"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailEditForm"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "a3ac317af9f925d01f630b2c64a4fefa";

export default node;
