/**
 * @generated SignedSource<<4367b7f0614c98312168c57308ad7704>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailEditForm$data = {
  readonly email: string;
  readonly " $fragmentType": "EmailEditForm";
};
export type EmailEditForm$key = {
  readonly " $data"?: EmailEditForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailEditForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailEditForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "ace43541895c39bf502311858c5f4e59";

export default node;
