/**
 * @generated SignedSource<<d1acda7d16a116b8abd765dcb538089d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignStatus = "admin_rejected" | "cancelled" | "candidate_defined" | "pre_recruiting" | "recruited" | "recruiting" | "wait_admin_approve" | "wait_candidate_define";
export type CandidateStatus = "admin_approved" | "admin_rejected" | "agency_approved" | "agency_rejected" | "applied";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type ApplyingCampaign$data = {
  readonly campaign: {
    readonly banners: ReadonlyArray<{
      readonly file: string | null;
    }>;
    readonly id: string;
    readonly parentMediaType: ParentMediaType;
    readonly socialNetwork: {
      readonly recruitmentEndOn: any | null;
    };
    readonly status: CampaignStatus | null;
    readonly title: string | null;
  };
  readonly status: CandidateStatus | null;
  readonly " $fragmentType": "ApplyingCampaign";
};
export type ApplyingCampaign$key = {
  readonly " $data"?: ApplyingCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyingCampaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyingCampaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentMediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Banner",
          "kind": "LinkedField",
          "name": "banners",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SocialNetwork",
          "kind": "LinkedField",
          "name": "socialNetwork",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recruitmentEndOn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Candidate",
  "abstractKey": null
};
})();

(node as any).hash = "1a281ff26035c2378b0128ac78b42575";

export default node;
