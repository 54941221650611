import React from "react";
import { Image, ImageStyle, StyleProp } from "react-native";
import { CommentStamp } from "@constants/App";
import { goodWork, ticket } from "@components/atoms/AssetImage";

type Prop = {
  name: CommentStamp;
  style: StyleProp<Pick<ImageStyle, "width" | "height">>;
};

export default function Stamp({ name, style }: Prop) {
  switch (name) {
    case "good_work":
      return <Image source={goodWork} style={style} />;
    case "ticket":
      return <Image source={ticket} style={style} />;
    default:
      return null;
  }
}
