import React, { useCallback, useMemo } from "react";
import { StyleSheet, Image, ScrollView, Platform, Share } from "react-native";
import * as Clipboard from "expo-clipboard";
import { ScreenWidth } from "@constants/Layout";
import dayjs from "dayjs";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { ApplyPrice } from "@constants/App";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";
import Ripple from "@components/atoms/Ripple";
import Divider from "@components/atoms/Divider";
import FavoriteButton from "@components/molecules/FavoriteButton";
import campaignBanner from "@lib/util/campaignBanner";
import DeadLine from "@components/atoms/DeadLine";
import SNSBadge from "@components/atoms/SNSBadge";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import CategoryBadge from "@components/atoms/CategoryBadge";
import { webEndpoint } from "@constants/Env";
import { isExpired } from "@lib/util/date";
import { CampaignMain$key } from "@generated/CampaignMain.graphql";
import useMessage from "@hooks/useMessage";
import { CampaignMainCampaignDetailUnitPrice$key } from "@generated/CampaignMainCampaignDetailUnitPrice.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import DetailUnitPriceLabel from "@components/molecules/DetailUnitPriceLabel";
import { useTheme } from "@react-navigation/native";
import { serviceIcon, defaultBanner } from "@components/atoms/AssetImage";
import RewardProductLabel from "./RewardProductLabel";

const dateFormat = "YYYY年M月D日";

const campaignMain = graphql`
  fragment CampaignMain on Campaign {
    id
    title
    mediaType
    gifting
    prType
    advertiserName
    isFavoriteCheck
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentStartOn
      recruitmentEndOn
      postStartOn
      postEndOn
      castStartAt
      castEndAt
    }
    ...SNSBadge_mediaType
  }
`;

const campaignDetailUnitPrice = graphql`
  fragment CampaignMainCampaignDetailUnitPrice on Candidate {
    price
    customPrice
    status
  }
`;

export default function CampaignMain({
  campaignFragment,
  candidateFragment,
}: {
  campaignFragment: CampaignMain$key;
  candidateFragment: CampaignMainCampaignDetailUnitPrice$key | null;
}) {
  const campaign = useFragment(campaignMain, campaignFragment);
  const candidate = useFragment(campaignDetailUnitPrice, candidateFragment);
  const { colors } = useTheme();
  const { set: setMessage } = useMessage();
  const recruitmentPeriod = useMemo(
    () =>
      `${dayjs(campaign.socialNetwork.recruitmentStartOn).format(
        dateFormat
      )} 〜 ${dayjs(campaign.socialNetwork.recruitmentEndOn).format(
        dateFormat
      )}`,
    [campaign]
  );
  const postPeriod = useMemo(
    () =>
      `${dayjs(campaign.socialNetwork.postStartOn).format(
        dateFormat
      )} 〜 ${dayjs(campaign.socialNetwork.postEndOn).format(dateFormat)}`,
    [campaign]
  );
  const castPeriod = useMemo(
    () =>
      `${dayjs(campaign.socialNetwork.castStartAt).format(
        `${dateFormat} HH:mm`
      )} 〜 ${dayjs(campaign.socialNetwork.castEndAt).format(
        `${dateFormat} HH:mm`
      )}`,
    [campaign]
  );

  const restDay = useMemo(
    () => dayjs(campaign.socialNetwork.recruitmentEndOn).diff(dayjs(), "day"),
    [campaign]
  );

  const share = useCallback(async () => {
    try {
      if (Platform.OS === "web") {
        await Clipboard.setStringAsync(`${webEndpoint}campaign/${campaign.id}`);
        setMessage({
          title: "URLをコピーしました",
          mode: "toast",
        });
      } else {
        await Share.share({
          message:
            campaign.title !== null
              ? `${campaign.title}${"\n"}${webEndpoint}campaign/${campaign.id}`
              : `${webEndpoint}campaign/${campaign.id}`,
        });
      }
    } catch (error: unknown) {
      setMessage({
        title: "シェアできませんでした",
        mode: "toast",
      });
    }
  }, [campaign, setMessage]);

  return (
    <Card>
      {candidate !== null &&
        campaign.prType !== null &&
        candidate.status !== null && (
          <View style={styles.bar}>
            <DetailUnitPriceLabel
              customPrice={candidate.customPrice}
              price={candidate.price}
              prType={campaign.prType}
              status={candidate.status}
            />
          </View>
        )}
      <ScrollView horizontal>
        {campaign.banners.length > 0 ? (
          campaign.banners.map((banner, index) => {
            const key = `banner-${index}`;
            return (
              <View key={key} style={styles.imageBackground}>
                <ResponsiveImage
                  height={302}
                  source={campaignBanner([banner])}
                />
              </View>
            );
          })
        ) : (
          <View style={styles.imageBackground}>
            <ResponsiveImage height={256} source={defaultBanner} width={360} />
          </View>
        )}
      </ScrollView>
      <View style={styles.container}>
        <Spacer height={16} />
        <View style={[styles.flexRow, styles.spaceBetween]}>
          <SNSBadge fragment={campaign} />
          <FavoriteButton
            buttonSize={40}
            campaignId={campaign.id}
            iconSize={24}
            isFavoriteCheck={campaign.isFavoriteCheck}
            showMessage
          />
        </View>
        <Spacer height={8} />
        <View>
          <Text style={styles.title}>{campaign.title}</Text>
        </View>
        <Spacer height={8} />
        <CategoryBadge name={campaign.mCategory.name as string} />
        <Spacer height={8} />
        <View style={styles.flexRow}>
          {campaign.gifting && (
            <>
              <RewardProductLabel />
              <Spacer width={19} />
            </>
          )}
          {campaign.prType === "rewarded" && (
            <View style={[styles.flexRow, styles.alignItemsCenter]}>
              <Icon color={Colors.gray} name="money" size={18} />
              <Spacer width={3} />
              <Text style={styles.grayText12}>{ApplyPrice}</Text>
            </View>
          )}
        </View>
        <Spacer height={16} />
        <View>
          <View style={styles.flexRow}>
            <View style={styles.aganecy}>
              <Image source={serviceIcon} style={styles.image} />
              <Spacer width={8} />
              <Text style={styles.text}>
                {campaign.advertiserName as string}
              </Text>
            </View>
            <Spacer width={19} />
          </View>
        </View>
        <Spacer height={16} />
        <Divider />
        <Spacer height={16} />
        <View>
          {campaign.socialNetwork.recruitmentStartOn !== null &&
            campaign.socialNetwork.recruitmentEndOn !== null && (
              <>
                <View>
                  <Text>募集期間</Text>
                </View>
                <Spacer height={8} />

                <View style={styles.termWrap}>
                  {isExpired(campaign.socialNetwork.recruitmentEndOn) ? (
                    <>
                      <Text style={styles.expiredText}>
                        募集は終了しています
                      </Text>
                      <Spacer width={8} />
                      <Text style={styles.expiredTerm}>
                        {recruitmentPeriod}
                      </Text>
                    </>
                  ) : restDay <= 3 ? (
                    <>
                      <Text style={[styles.orangeText, styles.hopeTerm]}>
                        {recruitmentPeriod}
                      </Text>
                      <Spacer width={12} />
                      <DeadLine day={campaign.socialNetwork.recruitmentEndOn} />
                    </>
                  ) : (
                    <Text style={styles.hopeTerm}>{recruitmentPeriod}</Text>
                  )}
                </View>

                <Spacer height={16} />
              </>
            )}

          {campaign.socialNetwork.postStartOn !== null &&
            campaign.socialNetwork.postEndOn !== null && (
              <>
                <View>
                  <Text>投稿期間</Text>
                </View>
                <Spacer height={8} />
                {isExpired(campaign.socialNetwork.postEndOn) ? (
                  <View style={styles.termWrap}>
                    <Text style={styles.expiredText}>投稿は終了しています</Text>
                    <Spacer width={8} />
                    <Text style={styles.expiredTerm}>{postPeriod}</Text>
                  </View>
                ) : (
                  <View>
                    <Text style={styles.hopeTerm}>{postPeriod}</Text>
                  </View>
                )}
              </>
            )}

          {campaign.socialNetwork.castStartAt !== null &&
            campaign.socialNetwork.castEndAt !== null && (
              <>
                <View>
                  <Text>撮影日時</Text>
                </View>
                <Spacer height={8} />
                {isExpired(campaign.socialNetwork.castEndAt) ? (
                  <View style={styles.termWrap}>
                    <Text style={styles.expiredText}>撮影は終了しています</Text>
                    <Spacer width={8} />
                    <Text style={styles.expiredTerm}>{castPeriod}</Text>
                  </View>
                ) : (
                  <View>
                    <Text style={styles.hopeTerm}>{castPeriod}</Text>
                  </View>
                )}
              </>
            )}
        </View>
        <Spacer height={16} />
        <Divider />
        <View style={styles.share}>
          <Ripple onPress={share}>
            <View style={styles.sharePosition}>
              <Icon color={colors.text} name="share" size={18} />
              <Spacer width={8} />
              <Text>シェア</Text>
            </View>
          </Ripple>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  bar: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
  },
  imageBackground: {
    height: 302,
    width: ScreenWidth,
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: "bold",
  },
  termWrap: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  term: {
    backgroundColor: Colors.orange,
    borderRadius: 4,
    paddingVertical: 6.5,
    paddingHorizontal: 4,
  },
  hopeTerm: {
    ...Fonts.xlb160,
  },
  expiredText: {
    ...Fonts.xlb160,
    color: Colors.gray,
  },
  expiredTerm: {
    ...Fonts.sr100,
    color: Colors.gray,
  },
  share: {
    height: 57,
  },
  sharePosition: {
    height: 57,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  aganecy: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: 32,
    height: 32,
  },
  text: {
    ...Fonts.mr100,
    color: Colors.gray,
  },
  container: {
    paddingHorizontal: 16,
  },
  flexRow: {
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  grayText12: {
    fontSize: 12,
    color: Colors.gray,
  },
  orangeText: {
    color: Colors.orange,
  },
});
