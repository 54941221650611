/**
 * @generated SignedSource<<00aa59b760729ef0d174a0ea0cf4b8f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type CampaignStatus = "admin_rejected" | "cancelled" | "candidate_defined" | "pre_recruiting" | "recruited" | "recruiting" | "wait_admin_approve" | "wait_candidate_define";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type ChatCampaign$data = {
  readonly campaign: {
    readonly banners: ReadonlyArray<{
      readonly file: string | null;
    }>;
    readonly gifting: boolean;
    readonly id: string;
    readonly mCategory: {
      readonly name: string;
    };
    readonly parentMediaType: ParentMediaType;
    readonly prType: CampaignPrType | null;
    readonly status: CampaignStatus | null;
    readonly title: string | null;
  };
  readonly " $fragmentType": "ChatCampaign";
};
export type ChatCampaign$key = {
  readonly " $data"?: ChatCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gifting",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentMediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MCategory",
          "kind": "LinkedField",
          "name": "mCategory",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Banner",
          "kind": "LinkedField",
          "name": "banners",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "9bb8ef4e691939950c4a6168f8aa07bf";

export default node;
