import React, { useState, useEffect, useMemo, useCallback } from "react";
import Picker from "@react-native-community/datetimepicker";
import dayjs from "dayjs";
import {
  TouchableOpacity,
  Animated,
  Modal,
  TouchableWithoutFeedback,
  Platform,
  View as RnView,
} from "react-native";
import CustomIcon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import { Text, View, Card } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import { CommonProps } from "@components/molecules/TextInput/types";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import styles from "@components/molecules/TextInput/style";
import { Controller } from "react-hook-form";
import _ from "lodash";

export type Item = {
  label: string;
  value: string | number;
};

const AnimationPosition = 500;
const AnimationDuration = 200;

interface Props extends CommonProps {
  format?: string;
  initialValue?: string | Date | null;
}

export default function DateTimePicker({
  initialValue,
  name,
  control,
  placeholder = "選択してください",
  label,
  disabled = false,
  required,
  format = "YYYY-MM-DD",
}: Props) {
  const { colors, dark } = useTheme();
  const colorStyles = useMemo(
    () =>
      dark
        ? {
            color: colors.text,
            backgroundColor: colors.background,
          }
        : {
            color: colors.text,
            backgroundColor: colors.card,
          },
    [dark, colors]
  );
  const [open, setOpen] = useState<boolean>(false);
  const [animate] = useState<Animated.Value>(
    new Animated.Value(AnimationPosition)
  );
  const [selectedValue, setSelectedValue] = useState<Date | null>(
    !_.isNil(initialValue) ? dayjs(initialValue).toDate() : null
  );

  const animateItem = useCallback((): void => {
    const addAnimation = Animated.timing(animate, {
      toValue: open ? 0 : AnimationPosition,
      duration: AnimationDuration,
      useNativeDriver: false,
    });

    addAnimation.start(() => {
      addAnimation.stop();
    });
  }, [animate, open]);

  useEffect(() => {
    animateItem();
  }, [open, animateItem]);

  const displayName = useMemo(() => {
    if (selectedValue !== null) {
      return dayjs(selectedValue).format(format);
    }
    return placeholder;
  }, [selectedValue, placeholder, format]);

  const onClose = useCallback(() => {
    const addAnimation = Animated.timing(animate, {
      toValue: AnimationPosition,
      duration: AnimationDuration,
      useNativeDriver: false,
    });

    addAnimation.start(() => {
      addAnimation.stop();
      setOpen(false);
    });
  }, [animate]);

  return (
    <View style={styles.container}>
      {label !== undefined && (
        <OuterLabel label={label} required={required === true} />
      )}

      {!disabled ? (
        Platform.OS === "web" ? (
          <Controller
            control={control}
            name={name}
            render={({
              field: { value, onBlur, onChange },
              fieldState: { invalid },
            }) => (
              <Picker
                display="spinner"
                maximumDate={new Date()}
                onChange={(__, selectedDate) => {
                  onChange(
                    selectedDate !== undefined
                      ? dayjs(selectedDate).format(format)
                      : undefined
                  );
                  onBlur();
                }}
                style={[
                  invalid ? [styles.form, styles.formError] : styles.form,
                  colorStyles,
                ]}
                value={value ?? new Date()}
              />
            )}
          />
        ) : (
          <>
            <TouchableOpacity
              onPress={() => setOpen(true)}
              style={[styles.form, colorStyles]}
            >
              <Text style={styles.text}>{displayName}</Text>
              <View style={styles.icon}>
                <CustomIcon color={Colors.black} name="down" size={24} />
              </View>
            </TouchableOpacity>
            <Modal
              animationType="none"
              onRequestClose={onClose}
              transparent
              visible={open}
            >
              <View style={styles.modal}>
                <Animated.View style={{ top: animate }}>
                  <Controller
                    control={control}
                    name={name}
                    render={({ field: { value, onBlur, onChange } }) => (
                      <Card>
                        <Picker
                          display="spinner"
                          maximumDate={new Date()}
                          onChange={(__, selectedDate) => {
                            onChange(selectedDate);
                            setSelectedValue(selectedDate ?? new Date());
                            onBlur();
                          }}
                          value={value}
                        />
                      </Card>
                    )}
                  />
                </Animated.View>
                <TouchableWithoutFeedback onPress={onClose}>
                  <RnView style={styles.backgroundLayer} />
                </TouchableWithoutFeedback>
              </View>
            </Modal>
          </>
        )
      ) : (
        <View style={[styles.form, styles.disabled]}>
          <Text style={styles.text}>{displayName}</Text>
          <View style={styles.icon}>
            <CustomIcon color={Colors.black} name="down" size={24} />
          </View>
        </View>
      )}
    </View>
  );
}
