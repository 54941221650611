import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import CopyButton from "@components/atoms/CopyButton";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import AttachmentsUploader, {
  isUploadedFile,
} from "@components/molecules/AttachmentsUploader";
import { ImageFile } from "@components/molecules/ImagePicker";
import { MediaTypeOptions } from "expo-image-picker";
import {
  TextArea,
  TextField,
  CopyField,
  ErrorText,
} from "@components/molecules/TextInput";
import { makeUploadablesFromFileList } from "@lib/util/relaySupport";
import GradientButton from "@components/atoms/GradientButton";
import {
  KeyboardId,
  OtherContentMaxLength,
  PostMaxImageSize,
  PostMaxVideoSize,
} from "@constants/App";
import { PostDraftOther$key } from "@generated/PostDraftOther.graphql";
import { PostDraftOtherMutation } from "@generated/PostDraftOtherMutation.graphql";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import Divider from "@components/atoms/Divider";
import ConfirmDialog from "@components/organisms/PostDraft/PostDraftConfirmDialog";
import { PostDraftOtherRemoveMutation } from "@generated/PostDraftOtherRemoveMutation.graphql";
import { resolveError } from "@lib/util/error";

const chatDraft = graphql`
  fragment PostDraftOther on Post {
    id
    url
    content
    campaign {
      purpose
      avoidedSentence
      socialNetwork {
        hashTag
      }
    }
    attachments {
      id
      file
      duration
      contentType
    }
  }
`;

const postMutation = graphql`
  mutation PostDraftOtherMutation(
    $input: UpdatePostMutationInput!
    $connections: [ID!]!
  ) {
    updatePost(input: $input) {
      __typename
      ... on UpdatePostSuccess {
        post {
          id
          status
          url
          content
          attachments {
            file
            duration
            contentType
          }
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            stamp
            content
            extraInfo
            extraType
            createdAt
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            commentable {
              id
              userType
              avatar {
                file
              }
            }
          }
        }
      }
      ... on UpdatePostError {
        message
      }
    }
  }
`;

const removeImagesMutation = graphql`
  mutation PostDraftOtherRemoveMutation(
    $input: RemoveAttachmentsMutationInput!
  ) {
    removeAttachments(input: $input) {
      __typename
      ... on Attachments {
        attachments {
          id
        }
      }
      ... on RemoveAttachmentsError {
        message
      }
    }
  }
`;

type OtherInput = {
  url: string | null;
  content: string;
};

export default function PostDraftOther({
  postFragment,
  status,
  onClose,
  onChange,
  onLoading,
}: {
  postFragment: PostDraftOther$key;
  status: "draft" | "modify" | "review" | "approved";
  onClose: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
}) {
  const data = useFragment(chatDraft, postFragment);
  const [selectedImages, setSelectedImages] = useState<ImageFile[]>([]);
  const [commit] = useMutation<PostDraftOtherMutation>(postMutation);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [postable, setPostable] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [removeImageList, setRemoveImageList] = useState<string[] | null>(null);
  const [commitRemoveImages] =
    useMutation<PostDraftOtherRemoveMutation>(removeImagesMutation);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );
  const uploaded = useMemo(() => data.attachments.map((row) => row), [data]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OtherInput>({
    defaultValues: {
      content: data.content ?? "",
      url: data.url,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        content: string()
          .trim()
          .required("入力してください")
          .max(
            OtherContentMaxLength,
            `投稿内容は${OtherContentMaxLength}文字以内で入力してください`
          ),
        url: string()
          .default(null)
          .trim()
          .notRequired()
          .url("正しいURLを入力してください"),
      })
    ),
  });

  const canEdit = useMemo<boolean>(
    () => ["draft", "modify"].indexOf(status) !== -1,
    [status]
  );

  useEffect(() => {
    let requiredImage = true;

    const removeImageNum =
      removeImageList !== null ? removeImageList.length : 0;
    if (
      data.attachments.length - removeImageNum === 0 &&
      selectedImages.length === 0
    ) {
      requiredImage = false;
    }

    setPostable(isValid && canEdit && requiredImage);
  }, [isValid, canEdit, selectedImages, removeImageList, data]);

  const showGuide = useMemo<boolean>(
    () =>
      !!(status !== "approved" && data.campaign.socialNetwork.hashTag !== null),
    [status, data]
  );

  const save = useCallback(async () => {
    await handleSubmit(async ({ content, url }: OtherInput) => {
      try {
        setConfirm(false);
        onLoading(true);
        const files: File[] = [];
        selectedImages.forEach((image) => {
          if (!isUploadedFile(image)) {
            files.push(image.file);
          }
        });
        const uploadables = await makeUploadablesFromFileList(
          "variables.input.files",
          files
        );
        await new Promise<void>((resolve, reject) => {
          if (removeImageList !== null && removeImageList.length > 0) {
            commitRemoveImages({
              variables: {
                input: {
                  ids: removeImageList,
                },
              },
              onCompleted({ removeAttachments }) {
                if (removeAttachments.__typename === "Attachments") {
                  resolve();
                } else {
                  reject(
                    removeAttachments.__typename === "RemoveAttachmentsError"
                      ? removeAttachments.message
                      : "削除できませんでした"
                  );
                }
              },
            });
          } else {
            resolve();
          }
        });
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                id: data.id,
                url,
                content,
                files: selectedImages.map(() => null),
              },
              connections: [connectionID],
            },
            uploadables,
            onCompleted({ updatePost }) {
              if (updatePost.__typename === "UpdatePostSuccess") {
                resolve();
              } else {
                reject(
                  updatePost.__typename === "UpdatePostError"
                    ? updatePost.message
                    : "保存できませんでした"
                );
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onLoading(false);
      }
    })();
  }, [
    commit,
    commitRemoveImages,
    connectionID,
    data,
    onClose,
    onLoading,
    removeImageList,
    selectedImages,
    handleSubmit,
  ]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView keyboardVerticalOffset={48}>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />
            <Card style={styles.card}>
              <Text style={styles.text}>
                公開する内容がわかるURL、またはファイルの保存先のURLを入力してください。
              </Text>
              <Spacer height={16} />

              {canEdit ? (
                <>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    name="url"
                    onChange={() => onChange(true)}
                    placeholder="URL"
                    placeholderTextColor={Colors.gray}
                    type="url"
                  />
                  {!isValid && errors.url !== undefined && (
                    <ErrorText error={errors.url.message} />
                  )}
                </>
              ) : (
                <CopyField value={data.url} />
              )}
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                投稿する動画・画像をアップロードしてください。（最大10枚、写真
                {PostMaxImageSize}MB、動画{PostMaxVideoSize}MBまで）
              </Text>
              <Spacer height={16} />
              {!canEdit && uploaded.length === 0 ? (
                <Text>動画・画像は選択されていません。</Text>
              ) : (
                <AttachmentsUploader
                  items={uploaded}
                  maxImageSize={PostMaxImageSize}
                  maxVideoSize={PostMaxVideoSize}
                  onChange={(e: ImageFile[]) => {
                    setSelectedImages(e);
                    onChange(true);
                  }}
                  onMessage={setError}
                  onPickEnd={() => onLoading(false)}
                  onPickStart={() => onLoading(true)}
                  pickupMediaType={MediaTypeOptions.All}
                  removeImageIds={(imageIds) => {
                    setRemoveImageList([...imageIds]);
                    onChange(true);
                  }}
                  uploadable={canEdit}
                />
              )}
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.title}>撮影の仕方</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.purpose}</Text>
              <Spacer height={24} />
              <Text style={styles.title}>投稿に関するNG事項</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.avoidedSentence}</Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                投稿時の内容を記載してください。
                {showGuide && (
                  <Text style={styles.alert}>
                    指定されているタグやURLがある場合は、必ず投稿内容に加えてください。
                  </Text>
                )}
              </Text>
              {showGuide && (
                <>
                  <Spacer height={24} />
                  <View style={styles.row}>
                    <Text style={styles.title}>
                      ハッシュタグ・メンションタグ・記載URL等
                    </Text>
                    <Spacer width={8} />
                    <CopyButton value={data.campaign.socialNetwork.hashTag} />
                  </View>

                  <Spacer height={8} />
                  <Text style={styles.text}>
                    {data.campaign.socialNetwork.hashTag}
                  </Text>
                  <Spacer height={8} />
                </>
              )}

              <Spacer height={16} />

              {canEdit ? (
                <>
                  <TextArea
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="投稿内容"
                    maxLength={OtherContentMaxLength}
                    name="content"
                    numberOfLines={9}
                    onChange={() => onChange(true)}
                    useCounter
                  />
                  {!isValid && errors.content !== undefined && (
                    <ErrorText error={errors.content.message} />
                  )}
                </>
              ) : (
                <CopyField value={data.content} />
              )}
            </Card>
          </Wrapper>
          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {status !== "approved" && status !== "review" && (
        <>
          <Divider />
          <Card style={styles.action}>
            <GradientButton
              disabled={!postable}
              onPress={() => setConfirm(true)}
              textStyle={styles.actionText}
              title={
                status === "draft"
                  ? "下書きを作成する"
                  : status === "modify"
                  ? "下書きを修正する"
                  : "レビュー中"
              }
            />
            {!postable && (
              <>
                <Spacer height={8} />
                <Text style={styles.error}>入力内容に不備があります</Text>
              </>
            )}
          </Card>
          <InputAccessoryView nativeID={KeyboardId} />
        </>
      )}
      {confirm && (
        <ConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  title: {
    ...Fonts.lb100,
    color: Colors.gray,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 14,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  error: {
    color: Colors.orange,
  },
});
