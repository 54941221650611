import React, { useEffect } from "react";
import Loading from "@components/atoms/Loading";
import { lpUrl, appStoreUrl } from "@constants/Env";
import openURL from "@lib/util/openUrl";
import * as Device from "expo-device";

export default function Lp() {
  useEffect(() => {
    // TODO: trackingしたい情報があれば、ここで行う。
    // FirebaseやGTMにURL paramsやDevice情報を送信できる。

    const url: string = Device.osName === "iOS" ? appStoreUrl : lpUrl;
    openURL(url);
  }, []);

  return <Loading />;
}
