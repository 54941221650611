import { StyleSheet, TouchableOpacity, View, Platform } from "react-native";
import { ResizeMode, Video as RnVideo } from "expo-av";
import React, { useRef, useState, useImperativeHandle } from "react";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { Text } from "@components/atoms/Themed";
import { secondToDuration } from "@lib/util/date";

export type VideoRef = {
  pause: () => Promise<void>;
  play: () => Promise<void>;
};

export default function Video({
  uri,
  duration,
  useNativeControls = true,
  resizeMode = ResizeMode.CONTAIN,
  innerRef,
}: {
  uri: string;
  duration?: number | null;
  useNativeControls?: boolean;
  resizeMode?: ResizeMode;
  innerRef?: React.RefObject<VideoRef>;
}) {
  const [play, setPlay] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const videoRef = useRef<RnVideo>(null);

  useImperativeHandle(innerRef, () => ({
    async pause() {
      await videoRef?.current?.pauseAsync();
    },
    async play() {
      await videoRef?.current?.playAsync();
    },
  }));

  return (
    <View style={styles.container}>
      <RnVideo
        ref={videoRef}
        onPlaybackStatusUpdate={(res) => {
          if (res.isLoaded) {
            if (!loaded) {
              setLoaded(true);
            }
            if (res.didJustFinish) {
              videoRef?.current?.setPositionAsync(0);
            }
          }
        }}
        resizeMode={resizeMode}
        shouldPlay={play}
        source={{ uri }}
        style={styles.content}
        useNativeControls={useNativeControls}
        videoStyle={styles.video}
      />
      {!play && loaded && Platform.OS !== "web" && (
        <TouchableOpacity onPress={() => setPlay(true)} style={styles.button}>
          <View style={styles.play}>
            <Icon color={Colors.white} name="play" size={32} />
          </View>
          {duration !== null && duration !== undefined && (
            <View style={styles.duration}>
              <Text style={styles.durationText}>
                {secondToDuration(duration)}
              </Text>
            </View>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  video: {
    width: "100%",
    height: "100%",
  },
  button: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.overlayDarkLight,
  },
  play: {
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
  },
  duration: {
    padding: 4,
    backgroundColor: Colors.overlayDarkMedium,
  },
  durationText: {
    ...Fonts.mr100,
    color: Colors.white,
  },
});
