/* eslint import/extensions:0 */
import React from "react";
import { Image, ImageStyle, StyleProp } from "react-native";
import {
  instagram,
  youtube,
  tiktok,
  twitter,
  cast,
  line,
  email,
  other,
} from "@components/atoms/AssetImage";

export type IconName =
  | "instagram"
  | "youtube"
  | "tiktok"
  | "twitter"
  | "cast"
  | "other"
  | "line"
  | "email";

type Prop = {
  name: IconName;
  style: StyleProp<Pick<ImageStyle, "width" | "height">>;
};

export default function SNSIcon({ name, style }: Prop) {
  let logoImage = null;
  switch (name) {
    case "instagram":
      logoImage = instagram;
      break;
    case "youtube":
      logoImage = youtube;
      break;
    case "tiktok":
      logoImage = tiktok;
      break;
    case "twitter":
      logoImage = twitter;
      break;
    case "cast":
      logoImage = cast;
      break;
    case "line":
      logoImage = line;
      break;
    case "email":
      logoImage = email;
      break;
    case "other":
    default:
      logoImage = other;
  }
  return <Image source={logoImage} style={style} />;
}
