/**
 * @generated SignedSource<<9c3b699cf2cee506f946fefae8f112c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActorUserType = "Agent" | "Influencer";
export type MediaProductType = "instagram_feed" | "instagram_live" | "instagram_reel" | "instagram_story" | "media_product_none";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
export type CreatePostReportsMutationInput = {
  clientMutationId?: string | null;
  postId: string;
  postReports: ReadonlyArray<InputPostReport>;
};
export type InputPostReport = {
  files?: ReadonlyArray<any> | null;
  mediaProductType: MediaProductType;
  priority: number;
  supplementaryInfo?: string | null;
  url?: string | null;
};
export type PostReportTwitterMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePostReportsMutationInput;
};
export type PostReportTwitterMutation$data = {
  readonly createPostReports: {
    readonly __typename: "CreatePostReportsError";
    readonly message: string;
  } | {
    readonly __typename: "CreatePostReportsSuccess";
    readonly commentEdges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly action: string | null;
        readonly attachments: ReadonlyArray<{
          readonly contentType: string | null;
          readonly duration: number | null;
          readonly file: string;
          readonly id: string;
        }>;
        readonly commentable: {
          readonly avatar: {
            readonly file: string | null;
          } | null;
          readonly userType: ActorUserType | null;
        };
        readonly content: string | null;
        readonly createdAt: any;
        readonly extraInfo: string | null;
        readonly extraType: string | null;
        readonly id: string;
        readonly ogps: ReadonlyArray<{
          readonly description: string | null;
          readonly id: string;
          readonly image: string | null;
          readonly title: string;
          readonly url: string;
        }>;
        readonly post: {
          readonly id: string;
        };
        readonly stamp: string | null;
      };
    }>;
    readonly post: {
      readonly id: string;
      readonly status: PostStatus;
      readonly type: string | null;
      readonly url: string | null;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type PostReportTwitterMutation = {
  response: PostReportTwitterMutation$data;
  variables: PostReportTwitterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Post",
  "kind": "LinkedField",
  "name": "post",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stamp",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraInfo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Ogp",
  "kind": "LinkedField",
  "name": "ogps",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Post",
  "kind": "LinkedField",
  "name": "post",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "CreatePostReportsError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PostReportTwitterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createPostReports",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentEdge",
                "kind": "LinkedField",
                "name": "commentEdges",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Actor",
                        "kind": "LinkedField",
                        "name": "commentable",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreatePostReportsSuccess",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PostReportTwitterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createPostReports",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentEdge",
                "kind": "LinkedField",
                "name": "commentEdges",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Actor",
                        "kind": "LinkedField",
                        "name": "commentable",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "prependEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "commentEdges",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "type": "CreatePostReportsSuccess",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0eb09d11a4e5c21122ff7e16b62b34a",
    "id": null,
    "metadata": {},
    "name": "PostReportTwitterMutation",
    "operationKind": "mutation",
    "text": "mutation PostReportTwitterMutation(\n  $input: CreatePostReportsMutationInput!\n) {\n  createPostReports(input: $input) {\n    __typename\n    ... on CreatePostReportsSuccess {\n      post {\n        id\n        type\n        status\n        url\n      }\n      commentEdges {\n        cursor\n        node {\n          id\n          content\n          action\n          stamp\n          extraInfo\n          extraType\n          createdAt\n          commentable {\n            userType\n            avatar {\n              file\n              id\n            }\n            id\n          }\n          ogps {\n            id\n            title\n            image\n            url\n            description\n          }\n          attachments {\n            id\n            file\n            duration\n            contentType\n          }\n          post {\n            id\n          }\n        }\n      }\n    }\n    ... on CreatePostReportsError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e5a6b3453e372e0bfdf0a452f2e538c";

export default node;
