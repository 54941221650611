import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import { graphql, useFragment } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import { goBack } from "@navigation/navigate";
import NavigationBar from "@components/molecules/NavigationBar";
import { SnsAssociation$key } from "@generated/SnsAssociation.graphql";
import Youtube from "@components/organisms/SnsAssociation/SnsAssociationYoutube";
import Tiktok from "@components/organisms/SnsAssociation/SnsAssociationTiktok";

const snsAssociation = graphql`
  fragment SnsAssociation on Influencer {
    id
    ...SnsAssociationTiktok
    ...SnsAssociationYoutube
  }
`;

export default function SnsAssociation({
  snsFragment,
  tab,
}: {
  snsFragment: SnsAssociation$key;
  tab: string;
}) {
  const data = useFragment(snsAssociation, snsFragment);
  const title = useMemo<string>(() => {
    switch (tab) {
      case "tiktok":
        return "TikTok連携";
      case "youtube":
        return "YouTube連携";
      default:
        return "SNS連携";
    }
  }, [tab]);
  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title={title} />
      <Spacer height={8} />

      {tab === "tiktok" ? (
        <Tiktok tiktokFragment={data} />
      ) : (
        <Youtube youtubeFragment={data} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
