import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import { InvoiceScreenQuery } from "@generated/InvoiceScreenQuery.graphql";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import InvoiceEdit from "@components/templates/InvoiceEdit";
import _ from "lodash";

const invoiceEditScreen = graphql`
  query InvoiceScreenQuery {
    influencer {
      ...InvoiceEdit
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<InvoiceScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<InvoiceScreenQuery>(
    invoiceEditScreen,
    queryReference
  );
  return <InvoiceEdit profileFragment={influencer} />;
}
export default function InvoiceScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<InvoiceScreenQuery>(invoiceEditScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
