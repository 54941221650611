import React, { useState, useCallback } from "react";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "@components/atoms/Button";
import Divider from "@components/atoms/Divider";
import GradientButton from "@components/atoms/GradientButton";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import Spacer from "@components/atoms/Spacer";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Toast from "@components/atoms/Toast";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { KeyboardId } from "@constants/App";
import Fonts from "@constants/Fonts";
import { resolveError } from "@lib/util/error";
import { StyleSheet, ScrollView } from "react-native";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useMutation } from "react-relay/hooks";
import { CampaignInputPhoneConfirmCResendMutation } from "@generated/CampaignInputPhoneConfirmCResendMutation.graphql";
import { CampaignInputPhoneConfirmChangedMutation } from "@generated/CampaignInputPhoneConfirmChangedMutation.graphql";

const regenerateMutation = graphql`
  mutation CampaignInputPhoneConfirmCResendMutation(
    $input: PhoneRegenerateCodeMutationInput!
  ) {
    phoneRegenerateCode(input: $input) {
      __typename
      ... on Influencer {
        newPhone
      }
      ... on PhoneRegenerateCodeError {
        errorType
        message
      }
    }
  }
`;

const confirmMuation = graphql`
  mutation CampaignInputPhoneConfirmChangedMutation(
    $input: PhoneConfirmMutationInput!
  ) {
    phoneConfirm(input: $input) {
      __typename
      ... on Influencer {
        newPhone
        phoneNumber
      }
      ... on PhoneConfirmError {
        errorType
        message
      }
    }
  }
`;

type CodeInput = {
  code: string;
};

type Props = {
  phone: string;
  hasNext: boolean;
  onClose: () => void;
};

export default function CampaignInputPhoneConfirm({
  phone,
  hasNext,
  onClose,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CodeInput>({
    defaultValues: {
      code: "",
    },
    mode: "onChange",
    resolver: yupResolver(
      object().shape({
        code: string()
          .trim()
          .numberString("4桁の半角数字を入力してください")
          .length(4, "4桁の半角数字を入力してください")
          .required("4桁の半角数字を入力してください"),
      })
    ),
  });

  const [commitResend] =
    useMutation<CampaignInputPhoneConfirmCResendMutation>(regenerateMutation);
  const [commitSave] =
    useMutation<CampaignInputPhoneConfirmChangedMutation>(confirmMuation);

  const onResend = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await new Promise<void>((resolve, reject) => {
        commitResend({
          variables: {
            input: {},
          },
          onCompleted({ phoneRegenerateCode }) {
            if (phoneRegenerateCode.__typename === "PhoneRegenerateCodeError") {
              reject(phoneRegenerateCode.message);
            } else {
              resolve();
            }
          },
        });
      });
      setMessage("認証コードが再送されました");
    } catch (e: unknown) {
      setError(resolveError(e).message);
    } finally {
      setLoading(false);
    }
  }, [commitResend]);

  const onConfirm = useCallback(async (): Promise<void> => {
    await handleSubmit(async ({ code }: CodeInput): Promise<void> => {
      try {
        if (code.length !== 4) {
          throw new Error("認証コードは4桁の半角数字で入力してください");
        }
        setLoading(true);
        await new Promise<void>((resolve, reject) => {
          commitSave({
            variables: {
              input: {
                code,
              },
            },
            onCompleted({ phoneConfirm }) {
              if (phoneConfirm.__typename === "PhoneConfirmError") {
                reject(phoneConfirm.message);
              } else {
                resolve();
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [commitSave, onClose, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="認証コードを送信しました" />
      <Spacer height={8} />
      <ScrollView>
        <Wrapper>
          <Card style={styles.content}>
            <Text style={styles.message}>
              認証コードを{phone}
              に送信しました。届いた4桁の認証コードを入力してください。
            </Text>

            <Spacer height={24} />
            <TextField
              control={control}
              inputAccessoryViewID={KeyboardId}
              maxLength={4}
              name="code"
              type="numberString"
            />

            {!isValid && errors.code !== undefined && (
              <ErrorText error={errors.code.message} />
            )}

            <Spacer height={16} />

            <Text>
              ショートメッセージが届かない場合は、受信拒否設定の確認と再送信をお願いしたします。
            </Text>

            <Spacer height={40} />

            <View style={styles.button}>
              <Button
                height={32}
                loading={loading}
                onPress={onResend}
                textStyle={styles.buttonText}
                title="再送信"
                width={160}
              />
            </View>
          </Card>
        </Wrapper>
      </ScrollView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={onConfirm}
          title={hasNext ? "次へ" : "認証を完了する"}
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView
        label="send"
        nativeID={KeyboardId}
        onPress={onConfirm}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  message: {
    ...Fonts.xlr160,
  },
  content: {
    padding: 24,
    flex: 1,
  },
  button: {
    alignItems: "center",
  },
  buttonText: {
    ...Fonts.lr100,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
