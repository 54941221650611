import ReactNavigation from "@react-navigation/native";
import { oauthUniversalLinkPath } from "@constants/Env";
import { RootStackParamList, OAuthRedirectWeb } from "../types";

const pathConfig:
  | {
      initialRouteName?: keyof RootStackParamList | undefined;
      screens: ReactNavigation.PathConfigMap<RootStackParamList>;
    }
  | undefined = {
  screens: {
    Root: {
      screens: {
        Home: {
          screens: {
            Recommended: {
              path: "/",
            },
            New: {
              path: "campaigns/new",
            },
            Popular: {
              path: "campaigns/popular",
            },
            Closing: {
              path: "campaigns/closing",
            },
            Finished: {
              path: "campaigns/finished",
            },
          },
        },
        Favorite: {
          path: "favorite",
        },
        Todo: {
          screens: {
            Working: {
              path: "workings",
            },
            Recruiting: {
              path: "recruiting",
            },
          },
        },
        MyPage: {
          path: "mypage",
        },
      },
    },
    ThankYou: {
      path: "thanks",
    },
    CampaignApply: {
      path: "campaign/apply/:id",
      parse: {
        id: String,
      },
    },
    CampaignInput: {
      path: "campaign/input/:id",
      parse: {
        id: String,
      },
    },
    CampaignFilter: {
      path: "campaign/filter/:tab",
      parse: {
        tab: String,
      },
    },
    CampaignDetail: {
      path: "campaign/:id",
      parse: {
        id: String,
      },
    },
    Chat: {
      path: "chat/:id",
      parse: {
        id: String,
      },
    },
    Ticket: {
      path: "ticket/:id",
      parse: {
        id: String,
      },
    },
    PostDraft: {
      path: "post_draft/:id",
      parse: {
        id: String,
      },
    },
    PostReport: {
      path: "post_report/:id",
      parse: {
        id: String,
      },
    },
    Auth: {
      path: "auth",
    },
    AuthLogin: {
      path: "login",
    },
    AuthReissue: {
      path: "reissue",
    },
    AuthSignup: {
      path: "signup",
    },
    AuthSnsSignup: {
      path: "signup_by_sns",
    },
    AuthLogout: {
      path: "logout",
    },
    AccountDeletion: {
      path: "withdraw",
    },
    AuthLanding: {
      path: "start_for_free",
    },
    CategoryEdit: {
      path: "category",
    },
    ProfileEdit: {
      path: "profile/edit",
    },
    PersonalInformation: {
      path: "personal_information",
    },
    BankEdit: {
      path: "bank",
    },
    SnsAssociation: {
      path: "sns",
    },
    PersonalInformationEdit: {
      path: "personal_information/edit",
    },
    AddressEdit: {
      path: "address",
    },
    PhoneEdit: {
      path: "phone",
    },
    EmailEdit: {
      path: "email",
    },
    Password: {
      path: "password",
    },
    SnsLogin: {
      path: "sns_login",
    },
    Invoice: {
      path: "invoice",
    },
    Report: {
      screens: {
        Monthly: {
          path: "report/monthly",
        },
        Yearly: {
          path: "report/yearly",
        },
      },
    },
    SignupThanks: {
      path: "signup_thanks",
    },
    TermsOfUse: {
      path: "terms_of_use",
    },
    PrivacyPolicy: {
      path: "privacy_policy",
    },
    Lp: {
      path: "lp",
    },
    ChangePassword: {
      path: "reset_password/:token",
      parse: {
        token: String,
      },
    },
    OAuthWeb: {
      path: OAuthRedirectWeb,
    },
    OAuthApp: {
      path: oauthUniversalLinkPath,
    },
  },
};

export default pathConfig;
