import React, { useState, useCallback } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { graphql, useMutation } from "react-relay/hooks";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Button from "@components/atoms/Button";
import Divider from "@components/atoms/Divider";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import SNSIcon from "@components/atoms/SNSIcon";
import {
  TextField,
  PasswordField,
  ErrorText,
} from "@components/molecules/TextInput";
import Spacer from "@components/atoms/Spacer";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import onLogin from "@lib/util/onLogin";
import {
  AuthLoginMutation,
  AuthLoginMutation$data,
} from "@generated/AuthLoginMutation.graphql";
import useAccount from "@hooks/useAccount";
import Dialog from "@components/molecules/dialog/Dialog";
import { navigate } from "@navigation/navigate";
import OAuth from "@components/molecules/OAuth";
import AppleSignin from "@components/molecules/AppleSignin";
import { resolveError } from "@lib/util/error";

const loginQuery = graphql`
  mutation AuthLoginMutation($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      __typename
      ... on LoginToken {
        token
        refreshToken
      }
      ... on LoginError {
        message
      }
    }
  }
`;

const PasswordKeyboard = "PasswordKeyboard";
const EmailKeyboard = "EmailKeyboard";

type AuthInput = {
  email: string;
  password: string;
};

export default function AuthLogin() {
  const { set: setInfluencerId } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const [commit] = useMutation<AuthLoginMutation>(loginQuery);
  const [error, setError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AuthInput>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string()
          .trim()
          .email("正しいEmailを入力してください")
          .required("入力してください"),
        password: string().required("入力してください"),
      })
    ),
  });

  const login = useCallback(async () => {
    await handleSubmit(async (auth: AuthInput) => {
      try {
        const { email, password } = auth;
        setLoading(true);
        const logined = await new Promise<AuthLoginMutation$data["login"]>(
          (resolve) => {
            commit({
              variables: {
                email,
                password,
              },
              onCompleted({ login: result }) {
                resolve(result);
              },
            });
          }
        );
        if (logined.__typename === "LoginToken") {
          await onLogin(
            {
              token: logined.token,
              refreshToken: logined.refreshToken,
            },
            setInfluencerId
          );
        } else if (logined.__typename === "LoginError") {
          setError(logined.message);
        }
      } catch (e: unknown) {
        setError(resolveError(e).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [commit, setInfluencerId, handleSubmit]);

  return (
    <Card style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <View style={styles.content}>
              <View style={styles.loginButtonsWrapper}>
                <Text style={[Fonts.xlb100, styles.text]}>
                  SNSアカウントでログイン
                </Text>
              </View>
              <OAuth action="Login" type="Line">
                <View style={styles.button}>
                  <SNSIcon name="line" style={styles.icon} />
                  <Spacer width={4} />
                  <Text style={styles.buttonText}>LINEでログイン</Text>
                </View>
              </OAuth>

              {Platform.OS === "ios" && (
                <>
                  <Spacer height={16} />
                  <AppleSignin
                    isSignup={false}
                    onError={setError}
                    onLogin={(data) => onLogin(data, setInfluencerId)}
                    onSignup={(data) =>
                      navigate("AuthSnsSignup", {
                        id: data.id,
                        email: data.email,
                        name: data.commonName,
                        sns_type: "Apple",
                      })
                    }
                  />
                </>
              )}

              <Spacer height={25} />

              <Divider color={Colors.gray30} width={160} />

              <Spacer height={40} />

              <Text style={styles.emailLoginLabel}>
                メールアドレスでログイン
              </Text>

              <Spacer height={24} />

              <View style={styles.form}>
                <View style={styles.field}>
                  <TextField
                    control={control}
                    innerLabel
                    inputAccessoryViewID={EmailKeyboard}
                    name="email"
                    placeholder="メールアドレス"
                    type="email"
                  />
                  {!isValid && errors.email !== undefined && (
                    <ErrorText error={errors.email.message} />
                  )}
                  <Spacer height={24} />

                  <PasswordField
                    control={control}
                    innerLabel
                    inputAccessoryViewID={PasswordKeyboard}
                    name="password"
                    placeholder="パスワード"
                  />
                  {!isValid && errors.password !== undefined && (
                    <ErrorText error={errors.password.message} />
                  )}
                  <Text style={styles.hint}>
                    半角英字（大/小文字）、数字の組み合わせ8文字以上
                  </Text>
                  <Spacer height={24} />
                </View>
                <View style={styles.loginButton}>
                  <Button
                    disabled={!isValid}
                    loading={loading}
                    onPress={login}
                    title="ログイン"
                  />

                  <Spacer height={24} />
                  <TouchableOpacity
                    onPress={() => navigate("AuthReissue")}
                    style={styles.item}
                  >
                    <Text style={styles.grayText}>パスワードを忘れた方</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <Spacer height={80} />
            </View>
          </Wrapper>
        </ScrollView>
        {error !== null && (
          <Dialog
            buttons={[{ title: "OK", type: "white" }]}
            customStyle={{ messageStyle: styles.orangeText }}
            message="ログインエラー"
            onClose={() => setError(null)}
            subline={error}
          />
        )}
      </KeyboardAvoidingView>
      <InputAccessoryView nativeID={EmailKeyboard} />
      <InputAccessoryView
        label="send"
        nativeID={PasswordKeyboard}
        onPress={login}
      />
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    height: "100%",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  field: {
    paddingHorizontal: 16,
  },
  item: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: Colors.black,
    textAlign: "center",
  },
  loginButton: {
    alignItems: "center",
    justifyContent: "center",
  },
  hint: {
    ...Fonts.sr140,
    color: Colors.gray,
  },
  grayText: {
    color: Colors.gray,
    ...Fonts.lrlink130,
    textDecorationColor: Colors.gray,
  },
  orangeText: {
    color: Colors.orange,
    textAlign: "center",
  },
  emailLoginLabel: {
    ...Fonts.xlb100,
    color: Colors.black,
    textAlign: "center",
    marginBottom: 8,
  },
  loginButtonsWrapper: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  icon: {
    marginTop: 2,
    width: 24,
    height: 24,
  },
  button: {
    width: 280,
    height: 48,
    borderRadius: 4,
    paddingRight: 32,
    paddingLeft: 5,
    paddingVertical: 0,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: Colors.lineGreen,
    borderColor: Colors.lineGreen,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 18,
    lineHeight: 48,
    color: Colors.white,
    fontWeight: "500",
  },
});
