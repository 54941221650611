import React from "react";
import { Text, View, Card } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity, Image } from "react-native";
import Fonts from "@constants/Fonts";
import Icon from "@components/atoms/Icon";
import Divider from "@components/atoms/Divider";
import { useTheme } from "@react-navigation/native";
import { serviceIcon } from "@components/atoms/AssetImage";

export default function NavigationBar({
  title,
  onBack,
  onClose,
  headerRight,
  onTapRightElement,
  noBorder = false,
}: {
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
  headerRight?: JSX.Element;
  onTapRightElement?: () => void;
  noBorder?: boolean;
}) {
  const { colors } = useTheme();
  return (
    <>
      <Card style={styles.header}>
        <View style={styles.leftView}>
          {onBack !== undefined && (
            <TouchableOpacity onPress={onBack} style={styles.action}>
              <Icon color={colors.text} name="back" size={24} />
            </TouchableOpacity>
          )}
          {onClose !== undefined && (
            <TouchableOpacity onPress={onClose} style={styles.action}>
              <Icon color={colors.text} name="close" size={24} />
            </TouchableOpacity>
          )}
        </View>
        {title !== undefined ? (
          <View style={styles.headerText}>
            <Text style={styles.headerFont}>{title}</Text>
          </View>
        ) : (
          <View style={styles.wrapper}>
            <Image source={serviceIcon} style={styles.logo} />
          </View>
        )}
        {onTapRightElement !== undefined ? (
          <View style={styles.rightView}>
            <TouchableOpacity onPress={onTapRightElement} style={styles.action}>
              {headerRight}
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.rightView}>{headerRight}</View>
        )}
      </Card>
      {!noBorder && <Divider />}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 44,
    width: "100%",
  },
  wrapper: {
    justifyContent: "center",
  },
  headerText: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  headerFont: {
    textAlign: "center",
    ...Fonts.xlb100,
  },
  leftView: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  rightView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  action: {
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 16,
  },
  logo: {
    height: 32,
    width: 45,
  },
});
