/**
 * @generated SignedSource<<9895f9d61253297ddc65d7e65864c814>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SnsLogin$data = {
  readonly appleId: string | null;
  readonly email: string;
  readonly hasPassword: boolean;
  readonly lineId: string | null;
  readonly " $fragmentType": "SnsLogin";
};
export type SnsLogin$key = {
  readonly " $data"?: SnsLogin$data;
  readonly " $fragmentSpreads": FragmentRefs<"SnsLogin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SnsLogin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lineId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassword",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "9420debee1e3cf3c2a54310132a21ff7";

export default node;
