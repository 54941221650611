import {
  GestureResponderEvent,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import React from "react";
import Spacer from "@components/atoms/Spacer";
import { View, Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { Item } from "@components/molecules/BrowseImage/types";
import Icon from "@components/atoms/Icon";
import { ResizeMode, Video } from "expo-av";
import { secondToDuration } from "@lib/util/date";
import { isBase64Video } from "@lib/util/file";
import _ from "lodash";

function Separator() {
  return <Spacer height={16} />;
}

export default function Scroller({
  items,
  onPress,
  selected,
}: {
  items: Item[];
  onPress?: (e: GestureResponderEvent, i: number) => void;
  selected: number;
}) {
  const thumbnailImage = ({ item, index }: { item: Item; index: number }) => (
    <TouchableOpacity
      onPress={(e) => onPress !== undefined && onPress(e, index)}
      style={[
        styles.thumbnailImage,
        selected === index ? styles.selectedThumbnailImage : null,
      ]}
    >
      {item.type === "video" ? (
        <View style={styles.video}>
          {!isBase64Video(item.uri) ||
            (Platform.OS !== "web" && (
              <Video
                resizeMode={ResizeMode.CONTAIN}
                shouldPlay={false}
                source={{ uri: item.uri }}
                style={styles.image}
                useNativeControls={false}
                videoStyle={styles.image}
              />
            ))}
          <View style={styles.play}>
            <Icon color={Colors.white} name="play" size={32} />
          </View>
          {_.isNumber(item.duration) && (
            <View style={styles.duration}>
              <Text style={styles.durationText}>
                {secondToDuration(item.duration)}
              </Text>
            </View>
          )}
        </View>
      ) : (
        <Image source={{ uri: item.uri }} style={styles.image} />
      )}
      {selected === index && <View style={styles.mask} />}
    </TouchableOpacity>
  );

  return items.length > 4 ? (
    <FlatList
      data={items}
      horizontal
      ItemSeparatorComponent={Separator}
      ListFooterComponent={<Spacer width={16} />}
      ListHeaderComponent={<Spacer width={16} />}
      renderItem={({ item, index }) => thumbnailImage({ item, index })}
      style={styles.flatList}
    />
  ) : (
    <View style={styles.imageWrap}>
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          {index > 0 && <Spacer width={16} />}
          {thumbnailImage({ item, index })}
        </React.Fragment>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  thumbnailImage: {
    width: 64,
    height: 64,
    borderRadius: 4,
  },
  selectedThumbnailImage: {
    borderWidth: 1,
    borderColor: Colors.green,
  },
  flatList: {
    flexGrow: 0,
  },
  imageWrap: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
  },
  video: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.white,
  },
  videoImage: {
    flex: 1,
  },
  play: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  mask: {
    position: "absolute",
    backgroundColor: "black",
    width: 62,
    height: 62,
    borderRadius: 3,
    opacity: 0.4,
  },
  duration: {
    position: "absolute",
    right: 2,
    bottom: 2,
    paddingHorizontal: 2,
    backgroundColor: Colors.overlayDarkMedium,
  },
  durationText: {
    ...Fonts.xsr100,
    color: Colors.white,
  },
});
