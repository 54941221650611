import _ from "lodash";
import React, { useMemo } from "react";
import { StyleSheet, TextStyle } from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import GradientButton from "@components/atoms/GradientButton";
import FavoriteButton from "@components/molecules/FavoriteButton";
import Spacer from "@components/atoms/Spacer";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { graphql, useFragment } from "react-relay/hooks";
import {
  CampaignActionCampaign$key,
  CampaignActionCampaign$data,
  CampaignStatus,
} from "@generated/CampaignActionCampaign.graphql";
import {
  CampaignActionCandidates$key,
  CandidateStatus,
} from "@generated/CampaignActionCandidates.graphql";
import checkRequirement from "@lib/util/requirementForApply";
import { navigate, replace } from "@navigation/navigate";

const campaignActionCandidates = graphql`
  fragment CampaignActionCandidates on Influencer {
    candidates {
      id
      campaignId
      status
      post {
        id
      }
    }
    ...requirementForApplyInfluencer
  }
`;

const campaignActionCampaign = graphql`
  fragment CampaignActionCampaign on Campaign {
    id
    parentMediaType
    status
    isFavoriteCheck
    ...requirementForApplyCampaign
  }
`;

type Props = {
  campaignFragment: CampaignActionCampaign$key;
  candidatesFragment: CampaignActionCandidates$key;
};

const buttonHeight = 48;

export default function CampaignAction({
  campaignFragment,
  candidatesFragment,
}: Props) {
  const data = useFragment(campaignActionCandidates, candidatesFragment);
  const campaign = useFragment(campaignActionCampaign, campaignFragment);
  const candidate = useMemo(
    () => data.candidates.find((row) => row.campaignId === campaign.id),
    [data, campaign]
  );

  const error = _.head(checkRequirement(data, campaign));

  return (
    <Card style={styles.container}>
      <Wrapper>
        {error !== undefined &&
          campaign.status === "recruiting" &&
          candidate === undefined && (
            <>
              <Text style={styles.errorText}>
                応募に必要なプロフィール情報が登録できていません。先に登録してください。
              </Text>
              <Spacer height={16} />
            </>
          )}
        <View style={styles.wrap}>
          <View style={styles.buttonWrap}>
            {candidate !== undefined
              ? candidateButton({
                  candidateStatus: candidate.status as CandidateStatus,
                  postId: candidate?.post?.id,
                })
              : campaignButton(campaign, error !== undefined)}
          </View>

          <FavoriteButton
            buttonSize={40}
            campaignId={campaign.id}
            iconSize={24}
            isFavoriteCheck={campaign.isFavoriteCheck}
            showMessage
          />
        </View>
      </Wrapper>
    </Card>
  );
}

function candidateButton({
  candidateStatus,
  postId,
}: {
  candidateStatus: CandidateStatus;
  postId?: string;
}): JSX.Element {
  // 審査中条件
  const applyCondition: CandidateStatus[] = ["applied", "admin_approved"];

  // 不採用条件
  const rejectCondition: CandidateStatus[] = [
    "admin_rejected",
    "agency_rejected",
  ];

  // 採用条件
  const approvedCondition: CandidateStatus[] = ["agency_approved"];

  if (applyCondition.includes(candidateStatus)) {
    return (
      <ActionButton
        color={Colors.grGreen}
        onPress={() =>
          replace("Root", {
            screen: "Todo",
            params: { tab: "Recruiting" },
          })
        }
        title="ワークの「応募中」へ"
      />
    );
  }
  if (rejectCondition.includes(candidateStatus)) {
    return <DisableButton title="キャンペーン不採用" />;
  }
  if (approvedCondition.includes(candidateStatus)) {
    return (
      <ActionButton
        color={Colors.grPurple}
        onPress={() => navigate("Chat", { id: postId as string })}
        title="ワークチャットへ"
      />
    );
  }

  // 想定外でも、応募している状態ではあるのでワークに飛ばせるボタンを表示する
  return (
    <ActionButton
      color={Colors.grGreen}
      onPress={() =>
        replace("Root", {
          screen: "Todo",
          params: { tab: "Working" },
        })
      }
      title="ワークの「応募中」へ"
    />
  );
}

function campaignButton(
  campaign: CampaignActionCampaign$data,
  requireInput: boolean
) {
  const campaignStatus: CampaignStatus = campaign.status as CampaignStatus;
  if (campaignStatus === "pre_recruiting") {
    return <DisableButton title="募集期間前" />;
  }
  if (campaignStatus === "recruiting") {
    if (requireInput) {
      return (
        <NeedEditButton
          onPress={() => navigate("CampaignInput", { id: campaign.id })}
          title="プロフィール情報を登録する"
        />
      );
    }
    return (
      <ActionButton
        color={Colors.grBlue}
        onPress={() => navigate("CampaignApply", { id: campaign.id })}
        title="応募する"
      />
    );
  }
  return <DisableButton title="募集終了しています" />;
}

function ActionButton({
  title,
  textStyle,
  onPress,
  color,
}: {
  title: string;
  textStyle?: TextStyle;
  onPress: () => void;
  color: string[];
}) {
  return (
    <GradientButton
      gradientColor={color}
      height={buttonHeight}
      onPress={onPress}
      textStyle={textStyle}
      title={title}
    />
  );
}

function NeedEditButton({
  title,
  onPress,
}: {
  title: string;
  onPress: () => void;
}) {
  return (
    <GradientButton
      borderWidth={1}
      gradientColor={[Colors.white, Colors.white]}
      height={buttonHeight}
      onPress={onPress}
      textStyle={{ color: Colors.black }}
      title={title}
    />
  );
}

function DisableButton({ title }: { title: string }) {
  return (
    <GradientButton
      disabled
      height={buttonHeight}
      onPress={() => {}}
      textStyle={{ color: Colors.gray40 }}
      title={title}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  wrap: {
    height: 48,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonWrap: {
    flex: 1,
    alignItems: "center",
  },
  errorText: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
});
