import React from "react";
import { View, StyleSheet, Text } from "react-native";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";

type Props = {
  children: string;
};
export default function HashTag({ children }: Props): JSX.Element {
  return (
    <View style={styles.container}>
      <Text>{children}</Text>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.gray10,
    paddingHorizontal: 8,
    paddingVertical: 9.5,
    borderRadius: 4,
  },
  tag: {
    ...Fonts.lr100,
  },
});
