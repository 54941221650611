import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import CopyButton from "@components/atoms/CopyButton";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import AttachmentsUploader, {
  isUploadedFile,
} from "@components/molecules/AttachmentsUploader";
import { resolveError } from "@lib/util/error";
import { ImageFile } from "@components/molecules/ImagePicker";
import { MediaTypeOptions } from "expo-image-picker";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import { makeUploadablesFromFileList } from "@lib/util/relaySupport";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import {
  TextArea,
  CopyField,
  ErrorText,
} from "@components/molecules/TextInput";
import GradientButton from "@components/atoms/GradientButton";
import { PostDraftInstagram$key } from "@generated/PostDraftInstagram.graphql";
import { PostDraftInstagramMutation } from "@generated/PostDraftInstagramMutation.graphql";
import {
  KeyboardId,
  InstagramContentMaxLength,
  PostMaxImageSize,
  PostMaxVideoSize,
} from "@constants/App";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import Divider from "@components/atoms/Divider";
import ConfirmDialog from "@components/organisms/PostDraft/PostDraftConfirmDialog";
import { PostDraftInstagramRemoveMutation } from "@generated/PostDraftInstagramRemoveMutation.graphql";

const chatDraft = graphql`
  fragment PostDraftInstagram on Post {
    id
    content
    script
    attachments {
      id
      file
      duration
      contentType
    }
    campaign {
      mediaType
      purpose
      avoidedSentence
      socialNetwork {
        hashTag
        productUrl
        mentionTagContent
      }
    }
  }
`;

const postMutation = graphql`
  mutation PostDraftInstagramMutation(
    $input: UpdatePostMutationInput!
    $connections: [ID!]!
  ) {
    updatePost(input: $input) {
      __typename
      ... on UpdatePostSuccess {
        post {
          id
          status
          content
          attachments {
            file
            duration
            contentType
          }
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on UpdatePostError {
        message
      }
    }
  }
`;

const removeImagesMutation = graphql`
  mutation PostDraftInstagramRemoveMutation(
    $input: RemoveAttachmentsMutationInput!
  ) {
    removeAttachments(input: $input) {
      __typename
      ... on Attachments {
        attachments {
          id
        }
      }
      ... on RemoveAttachmentsError {
        message
      }
    }
  }
`;

type InputData = {
  content: string | null;
  script: string | null;
};

export default function PostDraftInstagram({
  postFragment,
  status,
  onClose,
  onChange,
  onLoading,
}: {
  postFragment: PostDraftInstagram$key;
  status: "draft" | "modify" | "review" | "approved";
  onClose: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
}) {
  const data = useFragment(chatDraft, postFragment);
  const [selectedImages, setSelectedImages] = useState<ImageFile[]>([]);
  const [postable, setPostable] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [removeImageList, setRemoveImageList] = useState<string[] | null>(null);
  const [commitRemoveImages] =
    useMutation<PostDraftInstagramRemoveMutation>(removeImagesMutation);
  const [commit] = useMutation<PostDraftInstagramMutation>(postMutation);

  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );

  const uploaded = useMemo(() => data.attachments.map((row) => row), [data]);

  const maxUploads = useMemo(
    () => (data.campaign.mediaType === "ins_story" ? 20 : 10),
    [data]
  );
  const canEdit = useMemo<boolean>(
    () => ["draft", "modify"].indexOf(status) !== -1,
    [status]
  );
  const hashTag = useMemo<string | null>(() => {
    const tags =
      data.campaign.socialNetwork.hashTag !== null
        ? data.campaign.socialNetwork.hashTag
            .split(",")
            .map((value) => `#${value}`)
        : [];
    if (tags.length > 0) {
      return tags.join(" ");
    }
    return null;
  }, [data]);

  const showGuide = useMemo<boolean>(
    () =>
      !!(
        status !== "approved" &&
        (hashTag !== null ||
          data.campaign.socialNetwork.mentionTagContent !== null ||
          data.campaign.socialNetwork.productUrl !== null)
      ),
    [data, hashTag, status]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      content: data.content,
      script: data.script,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        content: string()
          .default(null)
          .trim()
          .max(
            InstagramContentMaxLength,
            `投稿内容は${InstagramContentMaxLength}文字以内で入力してください`
          )
          .test("notEmpty", "入力してください", (value) => {
            if (
              data.campaign.mediaType !== "ins_live" &&
              (value === "" || value === null)
            ) {
              return false;
            }
            return true;
          })
          .nullable(),
        script: string()
          .default(null)
          .trim()
          .max(
            InstagramContentMaxLength,
            `台本は${InstagramContentMaxLength}文字以内で入力してください`
          )
          .test("notEmpty", "入力してください", (value) => {
            if (
              data.campaign.mediaType === "ins_live" &&
              (value === "" || value === null)
            ) {
              return false;
            }
            return true;
          })
          .nullable(),
      })
    ),
  });

  useEffect(() => {
    let requiredImage = true;
    if (data.campaign.mediaType !== "ins_live") {
      const removeImageNum =
        removeImageList !== null ? removeImageList.length : 0;

      if (
        data.attachments.length - removeImageNum === 0 &&
        selectedImages.length === 0
      ) {
        requiredImage = false;
      }
    }
    setPostable(isValid && canEdit && requiredImage);
  }, [isValid, canEdit, selectedImages, removeImageList, data]);

  const save = useCallback(async () => {
    await handleSubmit(async ({ script, content }: InputData) => {
      try {
        setConfirm(false);
        onLoading(true);
        const files: File[] = [];
        selectedImages.forEach((image) => {
          if (!isUploadedFile(image)) {
            files.push(image.file);
          }
        });
        const uploadables = await makeUploadablesFromFileList(
          "variables.input.files",
          files
        );
        await new Promise<void>((resolve, reject) => {
          if (removeImageList !== null && removeImageList.length > 0) {
            commitRemoveImages({
              variables: {
                input: {
                  ids: removeImageList,
                },
              },
              onCompleted({ removeAttachments }) {
                if (removeAttachments.__typename === "Attachments") {
                  resolve();
                } else {
                  reject(
                    removeAttachments.__typename === "RemoveAttachmentsError"
                      ? removeAttachments.message
                      : "削除できませんでした"
                  );
                }
              },
            });
          } else {
            resolve();
          }
        });

        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                id: data.id,
                content,
                script,
                files: selectedImages.map(() => null),
              },
              connections: [connectionID],
            },
            uploadables,
            onCompleted({ updatePost }) {
              if (updatePost.__typename === "UpdatePostSuccess") {
                resolve();
              } else {
                reject(
                  updatePost.__typename === "UpdatePostError"
                    ? updatePost.message
                    : "保存できませんでした"
                );
              }
            },
            onError: (e) => {
              reject(e);
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        onLoading(false);
        setError(resolveError(e).message);
      }
    })();
  }, [
    commit,
    commitRemoveImages,
    connectionID,
    data,
    onClose,
    onLoading,
    removeImageList,
    selectedImages,
    handleSubmit,
  ]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView keyboardVerticalOffset={48}>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />
            {data.campaign.mediaType === "ins_live" && (
              <>
                <Card style={styles.card}>
                  <Text style={styles.text}>
                    配信する大まかな台本を入力してください
                  </Text>
                  <Spacer height={16} />
                  {canEdit ? (
                    <>
                      <TextArea
                        control={control}
                        inputAccessoryViewID={KeyboardId}
                        maxLength={InstagramContentMaxLength}
                        name="script"
                        numberOfLines={9}
                        onChange={() => onChange(true)}
                        useCounter
                      />
                      {!isValid && errors.script !== undefined && (
                        <ErrorText error={errors.script.message} />
                      )}
                    </>
                  ) : (
                    <CopyField multiline value={data.script} />
                  )}
                </Card>
                <Spacer height={16} />
              </>
            )}
            <Card style={styles.card}>
              <Text style={styles.text}>
                {data.campaign.mediaType === "ins_live"
                  ? "画像や動画があれば"
                  : "Instagramに投稿する"}
                画像・動画をアップロードしてください。（最大
                {maxUploads}枚、写真{PostMaxImageSize}MB、動画{PostMaxVideoSize}
                MBまで）
              </Text>
              <Spacer height={16} />

              <AttachmentsUploader
                items={uploaded}
                maxImageSize={PostMaxImageSize}
                maxVideoSize={PostMaxVideoSize}
                onChange={(imageFiles: ImageFile[]) => {
                  setSelectedImages([...imageFiles]);
                  onChange(true);
                }}
                onMessage={setError}
                onPickEnd={() => onLoading(false)}
                onPickStart={() => onLoading(true)}
                pickupMediaType={MediaTypeOptions.All}
                removeImageIds={(imageIds) => {
                  setRemoveImageList([...imageIds]);
                  onChange(true);
                }}
                selectionLimit={maxUploads}
                uploadable={canEdit}
              />
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.title}>撮影の仕方</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.purpose}</Text>
              <Spacer height={24} />
              <Text style={styles.title}>投稿に関するNG事項</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.avoidedSentence}</Text>
            </Card>

            {data.campaign.mediaType !== "ins_live" && (
              <>
                <Spacer height={16} />

                <Card style={styles.card}>
                  <Text style={styles.text}>
                    投稿時の内容を記載してください。
                    {showGuide && (
                      <Text style={styles.alert}>
                        指定されているタグやURLがある場合は、必ず投稿内容に加えてください。
                      </Text>
                    )}
                  </Text>

                  {showGuide && (
                    <>
                      {hashTag !== null && (
                        <>
                          <Spacer height={24} />
                          <View style={styles.row}>
                            <Text style={styles.title}>指定ハッシュタグ</Text>
                            <Spacer width={8} />
                            <CopyButton value={hashTag} />
                          </View>

                          <Spacer height={8} />
                          <Text style={styles.text}>{hashTag}</Text>
                        </>
                      )}

                      {data.campaign.socialNetwork.mentionTagContent !==
                        null && (
                        <>
                          <Spacer height={24} />
                          <View style={styles.row}>
                            <Text style={styles.title}>指定メンションタグ</Text>
                            <Spacer width={8} />
                            <CopyButton
                              value={
                                data.campaign.socialNetwork.mentionTagContent
                              }
                            />
                          </View>
                          <Spacer height={8} />
                          <Text style={styles.text}>
                            {data.campaign.socialNetwork.mentionTagContent}
                          </Text>
                        </>
                      )}

                      {data.campaign.socialNetwork.productUrl !== null && (
                        <>
                          <Spacer height={24} />
                          <View style={styles.row}>
                            <Text style={styles.title}>掲載して欲しいURL</Text>
                            <Spacer width={8} />
                            <CopyButton
                              value={data.campaign.socialNetwork.productUrl}
                            />
                          </View>
                          <Spacer height={8} />
                          <Text style={styles.text}>
                            {data.campaign.socialNetwork.productUrl}
                          </Text>
                        </>
                      )}

                      <Spacer height={8} />
                    </>
                  )}

                  <Spacer height={16} />

                  {canEdit ? (
                    <>
                      <TextArea
                        control={control}
                        inputAccessoryViewID={KeyboardId}
                        maxLength={InstagramContentMaxLength}
                        name="content"
                        numberOfLines={9}
                        onChange={() => onChange(true)}
                        useCounter
                      />
                      {!isValid && errors.script !== undefined && (
                        <ErrorText error={errors.script.message} />
                      )}
                    </>
                  ) : (
                    <CopyField multiline value={data.content} />
                  )}
                </Card>
              </>
            )}
          </Wrapper>
          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {status !== "approved" && status !== "review" && (
        <>
          <Divider />
          <Card style={styles.action}>
            <GradientButton
              disabled={!postable}
              onPress={() => setConfirm(true)}
              textStyle={styles.actionText}
              title={
                status === "draft"
                  ? "下書きを作成する"
                  : status === "modify"
                  ? "下書きを修正する"
                  : "レビュー中"
              }
            />
            {!postable && (
              <>
                <Spacer height={8} />
                <Text style={styles.error}>入力内容に不備があります</Text>
              </>
            )}
          </Card>
          <InputAccessoryView nativeID={KeyboardId} />
        </>
      )}
      {confirm && (
        <ConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  title: {
    ...Fonts.lb100,
    color: Colors.gray,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 14,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  error: {
    color: Colors.orange,
  },
});
