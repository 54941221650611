/**
 * @generated SignedSource<<f7c8019e5e728f8cb39a601735711cca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailEditConfirm$data = {
  readonly newEmail: string | null;
  readonly " $fragmentType": "EmailEditConfirm";
};
export type EmailEditConfirm$key = {
  readonly " $data"?: EmailEditConfirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailEditConfirm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailEditConfirm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newEmail",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "c590a4189e84f5ef4d563a399d1ce7fe";

export default node;
