/**
 * @generated SignedSource<<57283bc05cda62fb55d44d9fef3093f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatPost$data = {
  readonly campaign: {
    readonly id: string;
    readonly title: string | null;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChatActionPost" | "ChatCampaign" | "ChatPostData" | "ChatReportProductReceivedPost" | "ChatStatus">;
  readonly " $fragmentType": "ChatPost";
};
export type ChatPost$key = {
  readonly " $data"?: ChatPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ChatPostRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ChatPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatCampaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatActionPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatReportProductReceivedPost"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatPostData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatStatus"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "b9d6e6cb37b7deb652352ba742b32481";

export default node;
