/**
 * @generated SignedSource<<0f242079d644ffbf40ade2bbd7f3e745>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
export type CreateFavoriteMutationInput = {
  campaignId: string;
  clientMutationId?: string | null;
};
export type FavoriteButtonCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateFavoriteMutationInput;
};
export type FavoriteButtonCreateMutation$data = {
  readonly createFavorite: {
    readonly __typename: "CreateFavoriteError";
    readonly message: string;
  } | {
    readonly __typename: "CreateFavoriteSuccess";
    readonly campaign: {
      readonly isFavoriteCheck: boolean;
    };
    readonly favoriteEdge: {
      readonly cursor: string;
      readonly node: {
        readonly campaign: {
          readonly banners: ReadonlyArray<{
            readonly file: string | null;
          }>;
          readonly gifting: boolean;
          readonly id: string;
          readonly mCategory: {
            readonly name: string;
          };
          readonly mediaType: MediaType;
          readonly prType: CampaignPrType | null;
          readonly socialNetwork: {
            readonly recruitmentEndOn: any | null;
          };
          readonly title: string | null;
        };
        readonly id: string;
      };
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type FavoriteButtonCreateMutation = {
  response: FavoriteButtonCreateMutation$data;
  variables: FavoriteButtonCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavoriteCheck",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gifting",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recruitmentEndOn",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "CreateFavoriteError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoriteButtonCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FavoriteEdge",
                "kind": "LinkedField",
                "name": "favoriteEdge",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Favorite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Campaign",
                        "kind": "LinkedField",
                        "name": "campaign",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Banner",
                            "kind": "LinkedField",
                            "name": "banners",
                            "plural": true,
                            "selections": [
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MCategory",
                            "kind": "LinkedField",
                            "name": "mCategory",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SocialNetwork",
                            "kind": "LinkedField",
                            "name": "socialNetwork",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CreateFavoriteSuccess",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FavoriteButtonCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FavoriteEdge",
                "kind": "LinkedField",
                "name": "favoriteEdge",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Favorite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Campaign",
                        "kind": "LinkedField",
                        "name": "campaign",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Banner",
                            "kind": "LinkedField",
                            "name": "banners",
                            "plural": true,
                            "selections": [
                              (v11/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MCategory",
                            "kind": "LinkedField",
                            "name": "mCategory",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SocialNetwork",
                            "kind": "LinkedField",
                            "name": "socialNetwork",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "prependEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "favoriteEdge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "type": "CreateFavoriteSuccess",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94a11eaaa4d578c5a98ad983214865a7",
    "id": null,
    "metadata": {},
    "name": "FavoriteButtonCreateMutation",
    "operationKind": "mutation",
    "text": "mutation FavoriteButtonCreateMutation(\n  $input: CreateFavoriteMutationInput!\n) {\n  createFavorite(input: $input) {\n    __typename\n    ... on CreateFavoriteSuccess {\n      campaign {\n        isFavoriteCheck\n        id\n      }\n      favoriteEdge {\n        cursor\n        node {\n          id\n          campaign {\n            id\n            title\n            mediaType\n            gifting\n            prType\n            banners {\n              file\n              id\n            }\n            mCategory {\n              name\n              id\n            }\n            socialNetwork {\n              recruitmentEndOn\n              id\n            }\n          }\n        }\n      }\n    }\n    ... on CreateFavoriteError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa8506e286b14502795d5f4737981f66";

export default node;
