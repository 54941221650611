import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import BackHome from "@components/organisms/Fallback/FallbackBackHome";

export default function FallbackServiceDown() {
  return (
    <>
      <Spacer height={50} />
      <View style={styles.textArea}>
        <Text style={styles.caption}>
          InfluencerWorksをご利用ありがとうございます。
          {"\n"}
          ただいまサービスに接続しにくい状況となっています。
          しばらく時間を開けてから「ページ再読み込み」を押してください。
          {"\n"}
          ご迷惑をおかけし申し訳ございませんが、よろしくお願いします。
        </Text>
      </View>
      <Spacer height={30} />
      <BackHome />
    </>
  );
}

const styles = StyleSheet.create({
  textArea: {
    paddingHorizontal: 16,
  },
  logoWrapper: {
    alignItems: "center",
  },
  squareIcon: {
    width: "100%",
    maxWidth: 390,
    height: 58,
  },
  caption: {
    ...Fonts.lr130,
  },
});
