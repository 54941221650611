import React, { useEffect, Suspense } from "react";
import { StyleSheet } from "react-native";
import CampaignApplyHistory from "@components/templates/CampaignApplyHistory";
import Loading from "@components/atoms/Loading";
import NavigationBar from "@components/molecules/NavigationBar";
import useAccount from "@hooks/useAccount";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { CampaignApplyHistoryScreenQuery } from "@generated/CampaignApplyHistoryScreenQuery.graphql";
import { goBack } from "@navigation/navigate";
import { View } from "@components/atoms/Themed";
import { PagingCandidate } from "@constants/App";
import _ from "lodash";

const campaignApplyHistoryScreenQuery = graphql`
  query CampaignApplyHistoryScreenQuery(
    $influencerId: ID!
    $orderBy: InfluencerCandidatesOrderBy
    $first: Int
  ) {
    ...CampaignApplyHistoryPagination
      @arguments(influencerId: $influencerId, orderBy: $orderBy, first: $first)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CampaignApplyHistoryScreenQuery>;
}): JSX.Element {
  const response = usePreloadedQuery<CampaignApplyHistoryScreenQuery>(
    campaignApplyHistoryScreenQuery,
    queryReference
  );
  return <CampaignApplyHistory candidatesFragment={response} />;
}

export default function CampaignApplyHistoryScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignApplyHistoryScreenQuery>(
      campaignApplyHistoryScreenQuery
    );
  const { state: influencerId } = useAccount();

  useEffect(() => {
    loadQuery(
      {
        influencerId,
        orderBy: { createdAt: "DESC" },
        first: PagingCandidate,
      },
      { fetchPolicy: "network-only" }
    );

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, influencerId]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="応募履歴" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
