import React, { useCallback, useState } from "react";
import { FlatList, StyleSheet, RefreshControl } from "react-native";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { View, Wrapper } from "@components/atoms/Themed";
import { FavoritesPagination$key } from "@generated/FavoritesPagination.graphql";
import Loading from "@components/atoms/Loading";
import Spacer from "@components/atoms/Spacer";
import { PagingFavorite } from "@constants/App";
import Campaign from "@components/molecules/List/CampaignHorizon";
import Tutorial from "@components/organisms/Favorites/FavoritesTutorial";

const favoritePaginationQuery = graphql`
  fragment FavoritesPagination on Query
  @refetchable(queryName: "FavoritesPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    favorites(first: $first, after: $after)
      @connection(key: "FavoriteList_favorites") {
      edges {
        node {
          id
          campaign {
            ...CampaignHorizonCampaign
          }
        }
      }
    }
  }
`;

function Separator() {
  return <Spacer height={1} />;
}

export default function Favorites({
  favorites,
  move,
}: {
  favorites: FavoritesPagination$key;
  move: (id: string) => void;
}) {
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    favoritePaginationQuery,
    favorites
  );
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const reload = useCallback(async () => {
    if (refreshing) {
      return;
    }
    setRefreshing(true);
    refetch(
      { first: PagingFavorite },
      {
        fetchPolicy: "network-only",
        onComplete: () => setRefreshing(false),
      }
    );
  }, [refetch, refreshing]);

  return (
    <View style={styles.container}>
      <FlatList
        data={data.favorites.edges}
        ItemSeparatorComponent={Separator}
        keyExtractor={(item) => item.node.id}
        ListEmptyComponent={Tutorial}
        ListHeaderComponent={<Spacer height={8} />}
        onEndReached={() => {
          if (hasNext && !paging) {
            setPaging(true);
            loadNext(PagingFavorite, { onComplete: () => setPaging(false) });
          }
        }}
        onEndReachedThreshold={0.5}
        refreshControl={
          <RefreshControl onRefresh={reload} refreshing={refreshing} />
        }
        renderItem={({ item }) => (
          <Wrapper>
            <Campaign
              campaignDetailOnPress={(id: string) => move(id)}
              campaignFragment={item.node.campaign}
            />
          </Wrapper>
        )}
      />
      {paging && (
        <View style={styles.paging}>
          <Loading />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  paging: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    paddingVertical: 12,
  },
});
