/**
 * @generated SignedSource<<722bd96dfd017dcc4a659942676c7e9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryEditInfluencerCategories$data = {
  readonly influencerCategories: ReadonlyArray<{
    readonly mCategoryId: string;
    readonly mCategoryKeyName: string;
    readonly otherContent: string | null;
  }>;
  readonly " $fragmentType": "CategoryEditInfluencerCategories";
};
export type CategoryEditInfluencerCategories$key = {
  readonly " $data"?: CategoryEditInfluencerCategories$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryEditInfluencerCategories">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryEditInfluencerCategories",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerCategory",
      "kind": "LinkedField",
      "name": "influencerCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mCategoryId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mCategoryKeyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "b51d967d73668043a92853463ede5151";

export default node;
