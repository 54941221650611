import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Icon from "@components/atoms/Icon";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import Divider from "@components/atoms/Divider";
import { CampaignReward$key } from "@generated/CampaignReward.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import Colors from "@constants/Colors";
import { ApplyPrice, ProposalPrice } from "@constants/App";
import numberFormat from "@lib/util/numberFormat";
import RewardProductLabel from "./RewardProductLabel";

const campaignReward = graphql`
  fragment CampaignReward on Campaign {
    giftingDetail
    askingPrice
    prType
  }
`;

export default function CampaignReward({
  campaignFragment,
}: {
  campaignFragment: CampaignReward$key;
}) {
  const campaign = useFragment(campaignReward, campaignFragment);
  return (
    <CampaignSection title="報酬">
      <RewardProductLabel />
      <Spacer height={11} />
      <View style={styles.flexRow}>
        <View style={styles.container}>
          {campaign.giftingDetail !== null ? (
            <View>
              <Text style={styles.content}>{campaign.giftingDetail}</Text>
            </View>
          ) : (
            <Text style={styles.content}>商品提供はありません</Text>
          )}
        </View>
      </View>
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <View style={styles.flexRowCenter}>
        <Icon color={Colors.gray} name="money" size={18} />
        <Spacer width={3} />
        <Text style={styles.grayText12}>{ApplyPrice}</Text>
      </View>

      <Spacer height={11} />
      {campaign.askingPrice != null && campaign.prType === "rewarded" ? (
        <>
          <View style={styles.flexRow}>
            <View style={styles.amount}>
              <Text style={Fonts.lb100}>{ProposalPrice}</Text>
            </View>
            <Spacer width={12} />
            <View style={styles.center}>
              <Text style={[Fonts.ensb100, styles.orangeText]}>
                ¥{numberFormat(campaign.askingPrice)}
              </Text>
            </View>
          </View>
          <Spacer height={8} />
          <Text style={[Fonts.sr100]}>
            {ProposalPrice}を参考に{ApplyPrice}を決めてください
          </Text>
        </>
      ) : (
        <Text style={[Fonts.sr100, styles.grayText]}>
          {campaign.prType === "rewarded"
            ? `${ApplyPrice}を決めてください`
            : "報酬金は発生しません"}
        </Text>
      )}
    </CampaignSection>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  direction: {
    paddingHorizontal: 2.5,
    justifyContent: "center",
  },
  amount: {
    justifyContent: "center",
  },
  content: {
    ...Fonts.lr130,
  },
  flexRow: {
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  orangeText: {
    color: Colors.orange,
  },
  grayText: {
    color: Colors.gray,
  },
  flexRowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  grayText12: {
    fontSize: 12,
    color: Colors.gray,
  },
});
