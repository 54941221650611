/**
 * @generated SignedSource<<6c61abb1081e0b0b66b207f3be821b5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostReportScreenQuery$variables = {
  id: string;
};
export type PostReportScreenQuery$data = {
  readonly post: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"PostReport">;
  };
};
export type PostReportScreenQuery = {
  response: PostReportScreenQuery$data;
  variables: PostReportScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "file",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostReportScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PostReport"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostReportScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaType",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SocialNetwork",
                "kind": "LinkedField",
                "name": "socialNetwork",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postStartOn",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "castEndAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PostReport",
            "kind": "LinkedField",
            "name": "postReport",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supplementaryInfo",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PostReport",
            "kind": "LinkedField",
            "name": "postReports",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaProductType",
                "storageKey": null
              },
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c97748bf1d44a81fdaa3c92c9ddfe66d",
    "id": null,
    "metadata": {},
    "name": "PostReportScreenQuery",
    "operationKind": "query",
    "text": "query PostReportScreenQuery(\n  $id: ID!\n) {\n  post(id: $id) {\n    id\n    ...PostReport\n  }\n}\n\nfragment PostReport on Post {\n  id\n  campaign {\n    mediaType\n    id\n  }\n  ...PostReportTwitter\n  ...PostReportYoutube\n  ...PostReportInstagram\n  ...PostReportOther\n  ...PostReportCast\n  ...PostReportTiktok\n}\n\nfragment PostReportCast on Post {\n  id\n  status\n  campaign {\n    socialNetwork {\n      castEndAt\n      id\n    }\n    id\n  }\n  postReport {\n    supplementaryInfo\n    url\n    attachments {\n      id\n      file\n      duration\n      contentType\n    }\n    id\n  }\n}\n\nfragment PostReportInstagram on Post {\n  id\n  status\n  campaign {\n    mediaType\n    socialNetwork {\n      postStartOn\n      id\n    }\n    id\n  }\n  postReports {\n    url\n    mediaProductType\n    attachments {\n      id\n      file\n      duration\n      contentType\n    }\n    id\n  }\n}\n\nfragment PostReportOther on Post {\n  id\n  status\n  campaign {\n    socialNetwork {\n      postStartOn\n      id\n    }\n    id\n  }\n  postReport {\n    supplementaryInfo\n    url\n    attachments {\n      id\n      file\n      duration\n      contentType\n    }\n    id\n  }\n}\n\nfragment PostReportTiktok on Post {\n  id\n  status\n  campaign {\n    socialNetwork {\n      postStartOn\n      id\n    }\n    id\n  }\n  postReport {\n    url\n    id\n  }\n}\n\nfragment PostReportTwitter on Post {\n  id\n  status\n  campaign {\n    socialNetwork {\n      postStartOn\n      id\n    }\n    id\n  }\n  postReport {\n    url\n    id\n  }\n}\n\nfragment PostReportYoutube on Post {\n  id\n  status\n  campaign {\n    socialNetwork {\n      postStartOn\n      id\n    }\n    id\n  }\n  postReport {\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf956746399bbcf0f408e1179c105219";

export default node;
