import React, { useState, useCallback } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import Divider from "@components/atoms/Divider";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { navigate } from "@navigation/navigate";
import { KeyboardId } from "@constants/App";
import { resolveError } from "@lib/util/error";
import { SnsSignupUser } from "../../../types";

type AccountInput = {
  name: string;
  email: string;
};

type Props = {
  signUp: (data: AccountInput) => Promise<void>;
  initialData: SnsSignupUser;
};

export default function AuthSnsSignupForm({ signUp, initialData }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AccountInput>({
    defaultValues: {
      name: initialData.name !== null ? initialData.name : "",
      email: initialData.email !== null ? initialData.email : "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        name: string().trim().required("入力してください"),
        email: string()
          .trim()
          .email("存在するEmailを入力してください")
          .required("入力してください"),
      })
    ),
  });

  const onPress = useCallback(async (): Promise<void> => {
    await handleSubmit(async (account: AccountInput): Promise<void> => {
      try {
        setLoading(true);
        await signUp(account);
      } catch (e: unknown) {
        setError(resolveError(e).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [signUp, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <View style={styles.message}>
            <Text style={styles.text}>
              ニックネームとEmailは後から変更可能です。
            </Text>
          </View>

          <Card style={styles.form}>
            <TextField
              control={control}
              inputAccessoryViewID={KeyboardId}
              label="ニックネーム"
              maxLength={30}
              name="name"
            />
            {!isValid && errors.name !== undefined && (
              <ErrorText error={errors.name.message} />
            )}
            <Text style={styles.guide}>30文字まで</Text>
            <Spacer height={24} />

            <TextField
              control={control}
              inputAccessoryViewID={KeyboardId}
              label="メールアドレス"
              name="email"
              type="email"
            />
            {!isValid && errors.email !== undefined && (
              <ErrorText error={errors.email.message} />
            )}
            <Text style={styles.guide}>
              ログイン時のメールアドレスになります。
            </Text>
          </Card>

          <Spacer height={24} />
          <Card style={styles.infoWrapper}>
            <Text style={styles.infoText}>登録することで</Text>
            <Spacer width={2} />
            <Text onPress={() => navigate("TermsOfUse")} style={styles.link}>
              利用規約
            </Text>
            <Spacer width={2} />
            <Text style={styles.infoText}>と</Text>
            <Spacer width={2} />
            <Text onPress={() => navigate("PrivacyPolicy")} style={styles.link}>
              プライバシーポリシー
            </Text>
            <Spacer width={2} />
            <Text style={styles.infoText}>に同意したものとみなします。</Text>
          </Card>

          <Spacer height={24} />

          <View style={styles.message}>
            <Text style={styles.text}>
              入力したメールアドレスへ認証URLを送付します。{"\n"}
              届いた認証URLをクリックし登録を完了してください。
            </Text>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={onPress}
          title="サインアップ"
        />
        <Spacer height={24} />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.gray10,
  },
  content: {
    flexGrow: 1,
  },
  message: {
    padding: 16,
  },
  text: {
    color: Colors.gray,
    ...Fonts.lr130,
  },
  form: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  infoWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  infoText: {
    ...Fonts.lr130,
  },
  link: {
    ...Fonts.lrlink130,
  },
  guide: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
