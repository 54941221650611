/**
 * @generated SignedSource<<0f370efcfdaa330cca7bf63e8b6b5a52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyPageSetting$data = {
  readonly allowNotification: boolean;
  readonly " $fragmentType": "MyPageSetting";
};
export type MyPageSetting$key = {
  readonly " $data"?: MyPageSetting$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyPageSetting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyPageSetting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowNotification",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "b013393b63e368eb663f34a4aea5e047";

export default node;
