import React, { useState } from "react";
import Dialog from "@components/molecules/dialog/Dialog";
import Loading from "@components/atoms/Loading";
import { ChatReportProductReceivedPost$key } from "@generated/ChatReportProductReceivedPost.graphql";
import { ChatReportProductReceivedMutation } from "@generated/ChatReportProductReceivedMutation.graphql";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import { resolveError } from "@lib/util/error";

const chatQuery = graphql`
  fragment ChatReportProductReceivedPost on Post {
    id
  }
`;

const postMutation = graphql`
  mutation ChatReportProductReceivedMutation(
    $input: ReportProductReceivedMutationInput!
    $connections: [ID!]!
  ) {
    reportProductReceived(input: $input) {
      __typename
      ... on ReportProductReceivedSuccess {
        post {
          id
          status
          draftedAt
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on ReportProductReceivedError {
        message
      }
    }
  }
`;

export default function ChatReportProductReceived({
  postFragment,
  onClose,
  onError,
}: {
  postFragment: ChatReportProductReceivedPost$key;
  onClose: () => void;
  onError: (error: string) => void;
}) {
  const data = useFragment(chatQuery, postFragment);
  const [commit] = useMutation<ChatReportProductReceivedMutation>(postMutation);
  const [loading, setLoading] = useState<boolean>(false);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );

  async function save() {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      await new Promise<void>((resolve, reject) => {
        commit({
          variables: {
            input: {
              id: data.id,
            },
            connections: [connectionID],
          },
          onCompleted({ reportProductReceived }) {
            if (
              reportProductReceived.__typename ===
              "ReportProductReceivedSuccess"
            ) {
              resolve();
            } else {
              reject(
                reportProductReceived.__typename ===
                  "ReportProductReceivedError"
                  ? reportProductReceived.message
                  : "更新に失敗しました。"
              );
            }
          },
        });
      });
      onClose();
    } catch (e: unknown) {
      setLoading(false);
      onError(resolveError(e).message);
    }
  }

  return (
    <>
      <Dialog
        buttons={[
          { title: "閉じる", type: "white" },
          {
            title: "受取りました",
            type: "blueGradient",
            onPress: () => save(),
          },
        ]}
        message="商品受取報告"
        onClose={onClose}
        subline="商品を受取りましたか？受取り後、商品をレビューして下書き作成に進んでください。"
      />
      {loading && <Loading mask />}
    </>
  );
}
