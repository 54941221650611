/**
 * @generated SignedSource<<4c7a1fcef0e6eaed9aed5988ba65270b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceStatus = "applied" | "unapplied" | "unset";
import { FragmentRefs } from "relay-runtime";
export type CampaignInputInvoiceInfluencer$data = {
  readonly profile: {
    readonly invoiceNo: string | null;
    readonly invoiceStatus: InvoiceStatus;
  };
  readonly " $fragmentType": "CampaignInputInvoiceInfluencer";
};
export type CampaignInputInvoiceInfluencer$key = {
  readonly " $data"?: CampaignInputInvoiceInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputInvoiceInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputInvoiceInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNo",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "b00d8347b4fff464473228f651074cd3";

export default node;
