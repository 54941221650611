import React, { useEffect, useState } from "react";
import {
  Image,
  View,
  StyleSheet,
  ImageURISource,
  ImageRequireSource,
} from "react-native";
import Loading from "@components/atoms/Loading";

type Props = {
  source: ImageURISource | ImageRequireSource;
  width?: number;
  height?: number;
};

interface Size {
  width: number;
  height: number;
}

function calculateSize(layout: Size, image: Size): Size {
  // 元画像がレイアウトよりも大きい場合はレイアウトにいっぱいに縮小する
  if (image.width > layout.width || image.height > layout.height) {
    if (image.width >= image.height) {
      const tmpHeight = (layout.width * image.height) / image.width;
      if (tmpHeight <= layout.height) {
        return {
          width: layout.width,
          height: tmpHeight,
        };
      }
      // リサイズ後の高さがレイアウトを超える場合、レイアウトサイズを最大サイズにする
      return {
        width: layout.width * (layout.height / tmpHeight),
        height: layout.height,
      };
    }
    const tmpWidth = (layout.height * image.width) / image.height;
    if (tmpWidth <= layout.width) {
      return {
        width: tmpWidth,
        height: layout.height,
      };
    }
    // リサイズ後の横幅がレイアウトを超える場合、レイアウトサイズを最大サイズにする
    return {
      width: layout.width,
      height: layout.height * (layout.width / tmpWidth),
    };
  }
  // 元画像がレイアウトよりも小さい場合はレイアウトにいっぱいに拡大する
  if (image.width >= image.height) {
    return {
      width: layout.width,
      height: layout.width * (image.height / image.width),
    };
  }
  return {
    width: image.height * (image.width / image.height),
    height: image.height,
  };
}

function isUriType(source: unknown): source is ImageURISource {
  return typeof source === "object";
}

export default function ResponsiveImage({ source, width, height }: Props) {
  const [layout, setLayout] = useState<Size | null>(null);
  const [originalSize, setOriginalSize] = useState<Size | null>(null);
  const [responsiveSize, setResponsiveSize] = useState<Size | null>(null);
  useEffect(() => {
    if (isUriType(source)) {
      const { uri } = source;
      if (uri !== undefined) {
        Image.getSize(uri, (remoteWidth, remoteHeight) => {
          setOriginalSize({
            width: remoteWidth,
            height: remoteHeight,
          });
        });
      }
    } else if (width !== undefined && height !== undefined) {
      setOriginalSize({
        width,
        height,
      });
    }
  }, [source, width, height]);

  useEffect(() => {
    if (layout !== null && originalSize !== null) {
      const calculated = calculateSize(layout, originalSize);
      setResponsiveSize({
        width: calculated.width,
        height: calculated.height,
      });
    }
  }, [layout, originalSize]);

  return (
    <View
      onLayout={({ nativeEvent }) => {
        const { layout: rnLayout } = nativeEvent;
        setLayout({
          width: rnLayout.width,
          height: rnLayout.height,
        });
      }}
      style={styles.container}
    >
      {responsiveSize !== null ? (
        <Image
          source={source}
          style={{
            width: responsiveSize.width,
            height: responsiveSize.height,
          }}
        />
      ) : (
        <Loading mask />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
