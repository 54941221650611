/**
 * @generated SignedSource<<f108038e2431eeeb331e5c23efbea9fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailChangeMutationInput = {
  clientMutationId?: string | null;
  newEmail: string;
};
export type EmailEditFormMutation$variables = {
  input: EmailChangeMutationInput;
};
export type EmailEditFormMutation$data = {
  readonly emailChange: {
    readonly __typename: "EmailChangeDuplication";
    readonly errorType: string;
    readonly message: string;
  } | {
    readonly __typename: "EmailChangeToken";
    readonly token: string;
  } | {
    readonly __typename: "EmailChangeUnknown";
    readonly errorType: string;
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type EmailEditFormMutation = {
  response: EmailEditFormMutation$data;
  variables: EmailEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "emailChange",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "EmailChangeToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "EmailChangeDuplication",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "EmailChangeUnknown",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailEditFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailEditFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1f53e8da21f4ddc002073d0ecd8206cc",
    "id": null,
    "metadata": {},
    "name": "EmailEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation EmailEditFormMutation(\n  $input: EmailChangeMutationInput!\n) {\n  emailChange(input: $input) {\n    __typename\n    ... on EmailChangeToken {\n      token\n    }\n    ... on EmailChangeDuplication {\n      errorType\n      message\n    }\n    ... on EmailChangeUnknown {\n      errorType\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b52e303d9d1da8d51c680d029291675";

export default node;
