import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import PostReport from "@components/templates/PostReport";
import SafeAreaView from "@components/molecules/SafeAreaView";
import { View } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from "react-relay/hooks";
import { PostReportScreenQuery } from "@generated/PostReportScreenQuery.graphql";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const chatQuery = graphql`
  query PostReportScreenQuery($id: ID!) {
    post(id: $id) {
      id
      ...PostReport
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<PostReportScreenQuery>;
}) {
  const data = usePreloadedQuery<PostReportScreenQuery>(
    chatQuery,
    queryReference
  );

  return <PostReport postFragment={data.post} />;
}

export default function PostReportScreen({
  route,
}: RootStackScreenProps<"PostReport">) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<PostReportScreenQuery>(chatQuery);
  const { colors } = useTheme();
  React.useEffect(() => {
    loadQuery(
      {
        id: route.params.id,
      },
      { fetchPolicy: "network-only" }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route]);

  return (
    <SafeAreaView>
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <Suspense fallback={<Loading size="large" />}>
          {!_.isNil(queryReference) && (
            <ScreenContent queryReference={queryReference} />
          )}
        </Suspense>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
