import React, { useState, useCallback } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { KeyboardId } from "@constants/App";
import { graphql, useMutation } from "react-relay/hooks";
import { EmailEditFormMutation } from "@generated/EmailEditFormMutation.graphql";
import NavigationBar from "@components/molecules/NavigationBar";
import Divider from "@components/atoms/Divider";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { resolveError } from "@lib/util/error";

const saveMutation = graphql`
  mutation CampaignInputEmailFormMutation($input: EmailChangeMutationInput!) {
    emailChange(input: $input) {
      __typename
      ... on EmailChangeToken {
        token
      }
      ... on EmailChangeDuplication {
        errorType
        message
      }
      ... on EmailChangeUnknown {
        errorType
        message
      }
    }
  }
`;

type InputData = {
  email: string;
};

export default function CampaignInputEmailForm({
  onComplete,
  onClose,
}: {
  onComplete: (token: string) => void;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<EmailEditFormMutation>(saveMutation);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string().trim().email().required("入力してください"),
      })
    ),
  });

  const onPress = useCallback(async () => {
    await handleSubmit(async ({ email }: InputData) => {
      try {
        setLoading(true);
        const token = await new Promise<string>((resolve, reject) => {
          commit({
            variables: {
              input: {
                newEmail: email,
              },
            },
            onCompleted({ emailChange }) {
              if (emailChange.__typename === "EmailChangeToken") {
                resolve(emailChange.token);
              } else {
                reject(
                  emailChange.__typename === "EmailChangeDuplication" ||
                    emailChange.__typename === "EmailChangeUnknown"
                    ? emailChange.message
                    : "Emailを変更できませんでした"
                );
              }
            },
          });
        });
        onComplete(token);
      } catch (e: unknown) {
        setError(resolveError(e).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [commit, onComplete, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="メールアドレス登録" />
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <View style={styles.form}>
                <TextField
                  control={control}
                  inputAccessoryViewID={KeyboardId}
                  label="メールアドレス"
                  name="email"
                  type="email"
                />
                {!isValid && errors.email !== undefined && (
                  <ErrorText error={errors.email.message} />
                )}

                <Text style={styles.guide}>
                  連絡がとれるEmailを登録してください。
                </Text>
              </View>
            </Card>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={onPress}
          title="メールアドレスを登録する"
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
  text: {
    color: Colors.gray,
    ...Fonts.lr130,
  },
  form: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  guide: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
