import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import CopyButton from "@components/atoms/CopyButton";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import {
  TextField,
  CopyField,
  ErrorText,
} from "@components/molecules/TextInput";
import GradientButton from "@components/atoms/GradientButton";
import CustomIcon from "@components/atoms/Icon";
import { KeyboardId, YoutubeUrlPattern } from "@constants/App";
import { PostDraftYoutube$key } from "@generated/PostDraftYoutube.graphql";
import { PostDraftYoutubeMutation } from "@generated/PostDraftYoutubeMutation.graphql";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import openURL from "@lib/util/openUrl";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import Divider from "@components/atoms/Divider";
import ConfirmDialog from "@components/organisms/PostDraft/PostDraftConfirmDialog";
import { resolveError } from "@lib/util/error";

const chatDraft = graphql`
  fragment PostDraftYoutube on Post {
    id
    url
    campaign {
      purpose
      avoidedSentence
      socialNetwork {
        ytHashTag
        productUrl
        productSupply
      }
    }
  }
`;

const postMutation = graphql`
  mutation PostDraftYoutubeMutation(
    $input: UpdatePostMutationInput!
    $connections: [ID!]!
  ) {
    updatePost(input: $input) {
      __typename
      ... on UpdatePostSuccess {
        post {
          id
          status
          url
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on UpdatePostError {
        message
      }
    }
  }
`;

type InputData = {
  url: string;
};

export default function PostDraftYoutube({
  postFragment,
  status,
  onClose,
  onChange,
  onLoading,
}: {
  postFragment: PostDraftYoutube$key;
  status: "draft" | "modify" | "review" | "approved";
  onClose: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
}) {
  const data = useFragment(chatDraft, postFragment);
  const [commit] = useMutation<PostDraftYoutubeMutation>(postMutation);
  const [postable, setPostable] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );
  const canEdit = useMemo<boolean>(
    () => ["draft", "modify"].indexOf(status) !== -1,
    [status]
  );
  const hashTag = useMemo<string | null>(() => {
    const tags =
      data.campaign.socialNetwork.ytHashTag !== null
        ? data.campaign.socialNetwork.ytHashTag
            .split(",")
            .map((value) => `#${value}`)
        : [];
    if (tags.length > 0) {
      return tags.join(" ");
    }
    return null;
  }, [data]);

  const showGuide = useMemo<boolean>(
    () =>
      !!(
        status !== "approved" &&
        (hashTag !== null ||
          data.campaign.socialNetwork.productUrl !== null ||
          data.campaign.socialNetwork.productSupply !== null)
      ),
    [hashTag, status, data]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      url: data.url ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        url: string()
          .trim()
          .url("動画URLを入力してください")
          .matches(YoutubeUrlPattern, "動画URLを入力してください")
          .required("入力してください"),
      })
    ),
  });

  useEffect(() => {
    setPostable(isValid && canEdit);
  }, [isValid, canEdit]);

  const save = useCallback(async () => {
    await handleSubmit(async ({ url }: InputData) => {
      try {
        setConfirm(false);
        onLoading(true);
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                id: data.id,
                url,
              },
              connections: [connectionID],
            },
            onCompleted({ updatePost }) {
              if (updatePost.__typename === "UpdatePostSuccess") {
                resolve();
              } else {
                reject(
                  updatePost.__typename === "UpdatePostError"
                    ? updatePost.message
                    : "保存できませんでした"
                );
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onLoading(false);
      }
    })();
  }, [commit, connectionID, data, onClose, onLoading, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />
            <Card style={styles.card}>
              <Text style={styles.text}>
                キャンペーン内容をもとに動画を作成し、YouTubeに
                <Text style={[styles.text, styles.alert]}>
                  必ず限定公開でアップロード
                </Text>
                してください。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.title}>撮影の仕方</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.purpose}</Text>
              <Spacer height={24} />
              <Text style={styles.title}>投稿に関するNG事項</Text>
              <Spacer height={8} />
              <Text style={styles.text}>{data.campaign.avoidedSentence}</Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                YouTubeにアップロードした動画のURLを入力してください。
                {showGuide && (
                  <Text style={styles.alert}>
                    指定されているタグや提供表記がある場合は、必ず説明に加えてください。
                  </Text>
                )}
              </Text>

              {showGuide && (
                <>
                  {hashTag !== null && (
                    <>
                      <Spacer height={24} />
                      <View style={styles.row}>
                        <Text style={styles.title}>指定ハッシュタグ</Text>
                        <Spacer width={8} />
                        <CopyButton value={hashTag} />
                      </View>

                      <Spacer height={8} />
                      <Text style={styles.text}>{hashTag}</Text>
                    </>
                  )}

                  {data.campaign.socialNetwork.productUrl !== null && (
                    <>
                      <Spacer height={24} />
                      <View style={styles.row}>
                        <Text style={styles.title}>掲載して欲しいURL</Text>
                        <Spacer width={8} />
                        <CopyButton
                          value={data.campaign.socialNetwork.productUrl}
                        />
                      </View>
                      <Spacer height={8} />
                      <Text style={styles.text}>
                        {data.campaign.socialNetwork.productUrl}
                      </Text>
                    </>
                  )}

                  {data.campaign.socialNetwork.productSupply !== null && (
                    <>
                      <Spacer height={24} />
                      <View style={styles.row}>
                        <Text style={styles.title}>提供表記</Text>
                        <Spacer width={8} />
                        <CopyButton
                          value={data.campaign.socialNetwork.productSupply}
                        />
                      </View>

                      <Spacer height={8} />
                      <Text style={styles.text}>
                        {data.campaign.socialNetwork.productSupply}
                      </Text>
                    </>
                  )}
                </>
              )}

              <Spacer height={24} />

              {canEdit ? (
                <>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    name="url"
                    onChange={() => onChange(true)}
                    placeholder="YouTube URL"
                    placeholderTextColor={Colors.gray}
                    type="url"
                  />
                  {!isValid && errors.url !== undefined && (
                    <ErrorText error={errors.url.message} />
                  )}
                </>
              ) : (
                <CopyField value={data.url} />
              )}

              <Spacer height={16} />

              <TouchableOpacity
                onPress={() => openURL("https://www.youtube.com/")}
                style={styles.open}
              >
                <Text style={styles.guide}>YouTubeを開く</Text>
                <Spacer width={4} />
                <CustomIcon color={Colors.gray} name="outer" size={16} />
              </TouchableOpacity>
            </Card>
          </Wrapper>
          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {status !== "approved" && status !== "review" && (
        <>
          <Divider />
          <Card style={styles.action}>
            <GradientButton
              disabled={!postable}
              onPress={() => setConfirm(true)}
              textStyle={styles.actionText}
              title={
                status === "draft"
                  ? "下書きを作成する"
                  : status === "modify"
                  ? "下書きを修正する"
                  : "レビュー中"
              }
            />
            {!postable && (
              <>
                <Spacer height={8} />
                <Text style={styles.error}>入力内容に不備があります</Text>
              </>
            )}
          </Card>
          <InputAccessoryView nativeID={KeyboardId} />
        </>
      )}
      {confirm && (
        <ConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  title: {
    ...Fonts.lb100,
    color: Colors.gray,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 14,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  error: {
    color: Colors.orange,
  },
});
