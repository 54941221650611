/**
 * @generated SignedSource<<21bd8dcc9ce1eaf5d5e4c8e5e796a6c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceScreenQuery$variables = {};
export type InvoiceScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"InvoiceEdit">;
  };
};
export type InvoiceScreenQuery = {
  response: InvoiceScreenQuery$data;
  variables: InvoiceScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InvoiceEdit"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvoiceScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerInfo",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invoiceStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invoiceNo",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03ec24613d1c46d705f81d0c1c7c7f59",
    "id": null,
    "metadata": {},
    "name": "InvoiceScreenQuery",
    "operationKind": "query",
    "text": "query InvoiceScreenQuery {\n  influencer {\n    ...InvoiceEdit\n    id\n  }\n}\n\nfragment InvoiceEdit on Influencer {\n  profile {\n    invoiceStatus\n    invoiceNo\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "37a97f71b9eed775110db677a9e6d004";

export default node;
