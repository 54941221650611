import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import Dialog from "@components/molecules/dialog/Dialog";
import Divider from "@components/atoms/Divider";
import Button from "@components/atoms/Button";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { KeyboardId } from "@constants/App";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import { resolveError } from "@lib/util/error";

type Props = {
  email: string;
  resend: () => Promise<void>;
  confirm: (code: string) => Promise<void>;
};

type CodeInput = {
  code: string;
};

export default function AuthSignupConfirm({ email, confirm, resend }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CodeInput>({
    defaultValues: {
      code: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        code: string()
          .trim()
          .numberString("4桁の半角数字を入力してください")
          .length(4, "4桁の半角数字を入力してください")
          .required("4桁の半角数字を入力してください"),
      })
    ),
  });

  const onResend = useCallback(async () => {
    try {
      setLoading(true);
      await resend();
      setMessage("認証コードが再送信されました");
    } catch (e: unknown) {
      setError(resolveError(e).message);
    } finally {
      setLoading(false);
    }
  }, [resend]);

  const onConfirm = useCallback(async () => {
    await handleSubmit(async ({ code }: CodeInput) => {
      try {
        setLoading(true);
        await confirm(code);
      } catch (e: unknown) {
        setError(resolveError(e).message);
        setLoading(false);
      }
    })();
  }, [confirm, handleSubmit]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <Wrapper>
          <Card style={styles.content}>
            <Text style={styles.message}>
              認証コードを{email}
              に送信しました。届いた4桁の認証コードを入力してください。
            </Text>
            <Spacer height={24} />
            <TextField
              control={control}
              inputAccessoryViewID={KeyboardId}
              maxLength={4}
              name="code"
              type="numberString"
            />
            {!isValid && errors.code !== undefined && (
              <ErrorText error={errors.code.message} />
            )}

            <Spacer height={16} />

            <Text>
              メールが届かない場合は、受信拒否設定の確認と再送信をお願いしたします。
            </Text>

            <Spacer height={40} />

            <View style={styles.button}>
              <Button
                height={32}
                loading={loading}
                onPress={onResend}
                textStyle={styles.buttonText}
                title="再送信"
                width={160}
              />
            </View>
          </Card>
        </Wrapper>
      </ScrollView>
      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={onConfirm}
          title="登録を完了する"
        />
      </Card>
      {message !== null && (
        <Dialog message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.dialogError }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <InputAccessoryView
        label="send"
        nativeID={KeyboardId}
        onPress={onConfirm}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 24,
  },
  message: {
    ...Fonts.xlr160,
  },
  button: {
    alignItems: "center",
  },
  buttonText: {
    ...Fonts.lr100,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
