/**
 * @generated SignedSource<<5cb19727865ac71162a0137c4d7c85c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MyPageSettingPushTokenMutation$variables = {
  token: string;
};
export type MyPageSettingPushTokenMutation$data = {
  readonly savePushToken: {
    readonly token: string;
  };
};
export type MyPageSettingPushTokenMutation = {
  response: MyPageSettingPushTokenMutation$data;
  variables: MyPageSettingPushTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPageSettingPushTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SavePushTokenMutationPayload",
        "kind": "LinkedField",
        "name": "savePushToken",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyPageSettingPushTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SavePushTokenMutationPayload",
        "kind": "LinkedField",
        "name": "savePushToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52e1e31ee842d5eea6dd827255612107",
    "id": null,
    "metadata": {},
    "name": "MyPageSettingPushTokenMutation",
    "operationKind": "mutation",
    "text": "mutation MyPageSettingPushTokenMutation(\n  $token: String!\n) {\n  savePushToken(input: {token: $token}) {\n    token\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ac26fa3801ef8a0b23b0d259e9bd182";

export default node;
