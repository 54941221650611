import React, { useRef, useEffect, useState, useCallback } from "react";
import useAccount from "@hooks/useAccount";
import { graphql, fetchQuery, useMutation } from "react-relay/hooks";
import { AppState, Platform } from "react-native";
import { lastRetension } from "@constants/Env";
import SafeAreaView from "@components/molecules/SafeAreaView";
import * as Notifications from "expo-notifications";
import * as Linking from "expo-linking";
import {
  PagingComment,
  PagingCandidate,
  PagingPost,
  PushNotificationOptions,
  PushNotificationActionType,
} from "@constants/App";
import savePushToken from "@lib/util/pushToken";
import { RootCommentQuery } from "@generated/RootCommentQuery.graphql";
import { RootCandidatesQuery } from "@generated/RootCandidatesQuery.graphql";
import { RootUserRecordMutation } from "@generated/RootUserRecordMutation.graphql";
import * as Updates from "expo-updates";
import reloadApp from "@lib/util/realodApp";
import { start as startClarity } from "@lib/util/clarity";
import loadUpdates from "@lib/util/loadUpdates";
import days from "dayjs";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { canMoveLink } from "@lib/util/pushNotification";
import { schema } from "@navigation/LinkingConfiguration";
import Loading from "@components/atoms/Loading";
import _ from "lodash";
import RelayEnvironment from "../RelayEnvironment";

const userRecordMutation = graphql`
  mutation RootUserRecordMutation($input: CreateUserRecordMutationInput!) {
    createUserRecord(input: $input) {
      success
    }
  }
`;

const refetchComments = graphql`
  query RootCommentQuery(
    $influencerId: ID!
    $postId: ID!
    $postCount: Int!
    $commentCount: Int!
  ) {
    ...TodosPagination
      @arguments(influencerId: $influencerId, first: $postCount)
    post(id: $postId) {
      ...ChatPost
      ...ChatPagination @arguments(first: $commentCount)
    }
    commentReadTimeTotal(influencerId: $influencerId) {
      unreadTotalComments
    }
  }
`;

const refetchCandidates = graphql`
  query RootCandidatesQuery(
    $influencerId: ID!
    $candidateCount: Int!
    $postCount: Int!
  ) {
    ...ApplyingsPagination
      @arguments(influencerId: $influencerId, first: $candidateCount)
    ...TodosPagination
      @arguments(influencerId: $influencerId, first: $postCount)
  }
`;

export default function Root({ children }: { children: React.ReactNode }) {
  const { state: influencerId } = useAccount();
  const appState = useRef(AppState.currentState);
  const [loading, setLoading] = useState<boolean>(false);

  const [commitRetension] =
    useMutation<RootUserRecordMutation>(userRecordMutation);

  const recordRetension = useCallback(async (): Promise<void> => {
    const today = days().format("YYYYMMDD");
    const lastRecord = await AsyncStorage.getItem(lastRetension);
    // ログイン前や当日のりテンションを計測済みの場合は処理終了
    if (lastRecord === today || influencerId === "") {
      return;
    }

    const success = await new Promise<boolean>((resolve) => {
      commitRetension({
        variables: {
          input: {
            actionType: "retention",
          },
        },
        onCompleted({ createUserRecord }) {
          resolve(createUserRecord.success);
        },
      });
    });
    if (success) {
      await AsyncStorage.setItem(lastRetension, today);
    }
  }, [commitRetension, influencerId]);

  const refetch = useCallback(
    async (
      actionType: PushNotificationActionType,
      refetchId?: string | unknown,
      // MEMO: アプリをbackgroundで起動 > push通知をクリック > axios.postを"即時"呼び出すと
      // 原因不明だがAxiosError(code:ERR_NETWORK)が発生する。そのためのworkaround。
      delay: number = 0
    ) => {
      // ログイン前の場合は処理終了
      if (influencerId === "") {
        return;
      }
      try {
        if (delay !== 0) {
          setLoading(true);
        }
        switch (actionType) {
          // コメントが追加された、Postが更新された: やること一覧、対象のコメント一覧をRefetch
          case "CreatedComment":
          case "UpdatedPost":
          case "FinishedWork":
            if (typeof refetchId === "string") {
              await new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                  fetchQuery<RootCommentQuery>(
                    RelayEnvironment,
                    refetchComments,
                    {
                      postId: refetchId,
                      influencerId,
                      commentCount: PagingComment,
                      postCount: PagingPost,
                    },
                    { fetchPolicy: "network-only" }
                  ).subscribe({
                    next: () => resolve(),
                    error: (error: unknown) => reject(error),
                  });
                }, delay);
              });
            }
            break;
          // 応募が更新された: やること一覧、応募一覧をRefetch
          case "UpdatedCandidates":
            await new Promise<void>((resolve, reject) => {
              fetchQuery<RootCandidatesQuery>(
                RelayEnvironment,
                refetchCandidates,
                {
                  influencerId,
                  candidateCount: PagingCandidate,
                  postCount: PagingPost,
                },
                { fetchPolicy: "network-only" }
              ).subscribe({
                next: () => resolve(),
                error: (error: unknown) => reject(error),
              });
            });
            break;
          default:
        }
      } finally {
        if (delay !== 0) {
          setLoading(false);
        }
      }
    },
    [influencerId]
  );

  useEffect(() => {
    // アプリがForegroundに復帰した
    const appToForeground = AppState.addEventListener(
      "change",
      async (nextAppState) => {
        if (nextAppState === "active") {
          if (/inactive|background/.test(appState.current)) {
            await loadUpdates();
          }
          await recordRetension();
        }
        appState.current = nextAppState;
      }
    );

    // アプリをForegroundで起動中に通知を受け取った
    const notificateOnForeground =
      Notifications.addNotificationReceivedListener(async (notification) => {
        const { actionType, refetchId } = notification.request.content
          .data as PushNotificationOptions;

        if (!_.isNil(actionType)) {
          await refetch(actionType, refetchId);
        }
      });

    // 通知メッセージがクリックされた
    const pressNotification =
      Notifications.addNotificationResponseReceivedListener(
        async (response) => {
          const { url, actionType, refetchId } = response.notification.request
            .content.data as PushNotificationOptions;

          // アプリがForegroundで起動中でなければ取得データを更新
          if (AppState.currentState !== "active" && !_.isNil(actionType)) {
            await refetch(actionType, refetchId, 500);
          }
          if (canMoveLink()) {
            Linking.openURL(`${schema}${url}`);
          }
        }
      );

    // OTA経由で新しいbuildがダウンロードされた場合、アプリを再起動する
    const updateListenr =
      Platform.OS !== "web"
        ? Updates.addListener(async (event: Updates.UpdateEvent) => {
            try {
              if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
                await reloadApp();
              } else if (event.type === Updates.UpdateEventType.ERROR) {
                throw event;
              }
            } catch (e: unknown) {
              // エラー発生時は何もしない
            }
          })
        : null;

    return () => {
      appToForeground.remove();
      notificateOnForeground.remove();
      pressNotification.remove();
      if (Platform.OS !== "web" && updateListenr !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        updateListenr.remove();
      }
    };
    // TODO: useEffectだと複数回リッスンされている？ぽいので、hook関数に切り出す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ログインやサインアップでinfluencerIdがセットされたとき
  useEffect(() => {
    if (influencerId !== "") {
      recordRetension();
      savePushToken();
      startClarity(influencerId);
    }
  }, [influencerId, recordRetension]);

  return (
    <SafeAreaView>
      {children}
      {loading && <Loading mask />}
    </SafeAreaView>
  );
}
