import React, { useCallback, useState } from "react";
import { FlatList, StyleSheet, RefreshControl, Image } from "react-native";
import Divider from "@components/atoms/Divider";
import Loading from "@components/atoms/Loading";
import Spacer from "@components/atoms/Spacer";
import CampaignBig from "@components/molecules/List/CampaignBig";
import Campaign from "@components/molecules/List/Campaign";
import { usePaginationFragment, graphql } from "react-relay/hooks";
import {
  CampaignsCampaigns$key,
  CampaignsCampaigns$data,
} from "@generated/CampaignsCampaigns.graphql";
import _ from "lodash";
import { View, Wrapper, Text } from "@components/atoms/Themed";
import { PagingCampaign, CampaignTabs } from "@constants/App";
import { MaxWidth, ScreenWidth } from "@constants/Layout";
import Fonts from "@constants/Fonts";
import { navigate } from "@navigation/navigate";
import GradientButton from "@components/atoms/GradientButton";
import Colors from "@constants/Colors";
import { emptyWork } from "@components/atoms/AssetImage";

const NumColumns = 2;

const campaignsQuery = graphql`
  fragment CampaignsCampaigns on Query
  @refetchable(queryName: "CampaignsQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    tab: { type: CampaignTabs }
    selectedSns: { type: "[ParentMediaType!]" }
    selectedCategories: { type: "[CategoryTypes!]" }
    selectedHasMoney: { type: "Boolean" }
    selectedHasProduct: { type: "Boolean" }
  ) {
    campaigns(
      first: $first
      after: $after
      tab: $tab
      selectedSns: $selectedSns
      selectedCategories: $selectedCategories
      selectedHasMoney: $selectedHasMoney
      selectedHasProduct: $selectedHasProduct
    ) @connection(key: "CampaignsList_campaigns") {
      edges {
        node {
          id
          ...CampaignBigCampaign
          ...CampaignCampaign
        }
      }
    }
  }
`;

interface ScreenProps {
  hasNext: boolean;
  paging: boolean;
  refreshing: boolean;
  edges: CampaignsCampaigns$data["campaigns"]["edges"];
  fetch: (refresh: boolean) => void;
  tab: CampaignTabs;
}

function ScreenForWeb(props: ScreenProps) {
  const { edges, paging, hasNext, refreshing, fetch, tab } = props;
  const headEdge = _.head(edges);
  const lists = edges.slice(1);
  const items = _.chunk(lists, 2);
  return (
    <FlatList
      data={items}
      keyExtractor={(item, index) => `item-${index}`}
      ListEmptyComponent={
        edges.length === 0 ? (
          <View style={styles.empty}>
            <Image source={emptyWork} style={styles.emptyIcon} />
            <Spacer height={25} />
            <Text style={styles.emptyText}>検索結果はありませんでした</Text>
            <Spacer height={8} />
            <GradientButton
              onPress={() => navigate("CampaignFilter", { tab })}
              title="検索条件を変更する"
            />
          </View>
        ) : null
      }
      ListHeaderComponent={
        headEdge !== undefined ? (
          <Wrapper>
            <CampaignBig
              campaignDetailOnPress={() =>
                navigate("CampaignDetail", { id: headEdge.node.id })
              }
              campaignFragment={headEdge.node}
            />
          </Wrapper>
        ) : null
      }
      ListHeaderComponentStyle={{
        paddingBottom: 8,
      }}
      onEndReached={() => {
        if (hasNext && !paging) {
          fetch(false);
        }
      }}
      onEndReachedThreshold={0.5}
      refreshControl={
        <RefreshControl
          onRefresh={() => {
            if (!refreshing) {
              fetch(true);
            }
          }}
          refreshing={refreshing}
        />
      }
      renderItem={({ item }) => (
        <Wrapper>
          <View style={styles.webRow}>
            {item.map((row, index) => (
              <View key={`campaign-${row.node.id}`} style={styles.webItem}>
                {(index + 1) % 2 === 0 && <Divider height="100%" width={0.5} />}
                <Campaign
                  campaignDetailOnPress={() => {
                    navigate("CampaignDetail", { id: row.node.id });
                  }}
                  campaignFragment={row.node}
                />
                {(index + 1) % 2 !== 0 && <Divider height="100%" width={0.5} />}
              </View>
            ))}
            {item.length === 1 && (
              <View style={styles.webItem}>
                <Divider height="100%" width={0.5} />
              </View>
            )}
          </View>
        </Wrapper>
      )}
    />
  );
}

function ScreenForMobile(props: ScreenProps) {
  const { edges, paging, hasNext, refreshing, fetch, tab } = props;
  const headEdge = _.head(edges);
  const lists = edges.slice(1);
  return (
    <FlatList
      data={lists}
      keyExtractor={(item) => item.node.id}
      ListEmptyComponent={
        edges.length === 0 ? (
          <View style={styles.empty}>
            <Spacer height={24} />
            <Text style={styles.title}>
              現在、選択いただいた条件に合うキャンペーンはありません。
            </Text>
            <Spacer height={8} />
            <Text style={styles.text}>
              下記のボタンより条件を変えてキャンペーンを探してみましょう。
            </Text>
            <Spacer height={27} />
            <Image source={emptyWork} style={styles.emptyIcon} />
            <Spacer height={27} />
            <GradientButton
              onPress={() => navigate("CampaignFilter", { tab })}
              title="検索条件を変更する"
            />
          </View>
        ) : null
      }
      ListHeaderComponent={
        headEdge !== undefined ? (
          <CampaignBig
            campaignDetailOnPress={() =>
              navigate("CampaignDetail", { id: headEdge.node.id })
            }
            campaignFragment={headEdge.node}
          />
        ) : null
      }
      ListHeaderComponentStyle={{
        paddingBottom: 8,
      }}
      numColumns={NumColumns}
      onEndReached={() => {
        if (hasNext && !paging) {
          fetch(false);
        }
      }}
      onEndReachedThreshold={0.5}
      refreshControl={
        <RefreshControl
          onRefresh={() => {
            if (!refreshing) {
              fetch(true);
            }
          }}
          refreshing={refreshing}
        />
      }
      renderItem={({ item, index }) => (
        <View style={styles.item}>
          {(index + 1) % 2 === 0 && <Divider height="100%" width={0.5} />}
          <Campaign
            campaignDetailOnPress={() =>
              navigate("CampaignDetail", { id: item.node.id })
            }
            campaignFragment={item.node}
          />
          {(index + 1) % 2 !== 0 && <Divider height="100%" width={0.5} />}
        </View>
      )}
    />
  );
}

export default function Campaigns({
  campaigns,
  tab,
}: {
  campaigns: CampaignsCampaigns$key;
  tab: CampaignTabs;
}) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    campaignsQuery,
    campaigns
  );
  const { campaigns: list } = data;
  const { edges } = list;

  const fetch = useCallback(
    async (refresh: boolean) => {
      if (refresh) {
        setRefreshing(true);
        refetch(
          { first: PagingCampaign },
          {
            fetchPolicy: "network-only",
            onComplete: () => setRefreshing(false),
          }
        );
      } else {
        setPaging(true);
        loadNext(PagingCampaign, { onComplete: () => setPaging(false) });
      }
    },
    [loadNext, refetch]
  );

  return (
    <View style={styles.container}>
      {ScreenWidth >= MaxWidth ? (
        <ScreenForWeb
          edges={edges}
          fetch={fetch}
          hasNext={hasNext}
          paging={paging}
          refreshing={refreshing}
          tab={tab}
        />
      ) : (
        <ScreenForMobile
          edges={edges}
          fetch={fetch}
          hasNext={hasNext}
          paging={paging}
          refreshing={refreshing}
          tab={tab}
        />
      )}

      {paging && (
        <View style={styles.paging}>
          <Loading />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
  },
  item: {
    flex: 1 / NumColumns,
    flexDirection: "row",
  },
  webItem: {
    flexDirection: "row",
    flex: 1,
  },
  webRow: {
    flexDirection: "row",
  },
  paging: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    paddingVertical: 12,
  },
  empty: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingVertical: 48,
  },
  emptyIcon: {
    width: 120,
    height: 120,
  },
  emptyText: {
    ...Fonts.xlb100,
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.gray,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
