/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import ReactNavigationBottom from "@react-navigation/bottom-tabs";
import ReactNavigationNative from "@react-navigation/native";
import ReactNavigationStack from "@react-navigation/native-stack";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type SnsSignupUser = {
  id: string;
  name: string | null;
  email: string | null;
  sns_type: "Apple" | "Line";
};

export type RootStackParamList = {
  Root:
    | ReactNavigationNative.NavigatorScreenParams<RootTabParamList>
    | undefined;
  ThankYou: undefined;
  CampaignApply: {
    id: string;
  };
  CampaignDetail: {
    id: string;
  };
  CampaignInput: {
    id: string;
  };
  Chat: {
    id: string;
  };
  Ticket: {
    id: string;
  };
  PostDraft: {
    id: string;
  };
  PostReport: {
    id: string;
  };
  Auth?: {
    code?: string;
    liffRedirectUri?: string;
  };
  OAuthWeb: {
    code?: string;
    state: string;
  };
  OAuthApp: {
    code?: string;
    state: string;
  };
  AuthLogin: undefined;
  AuthReissue: undefined;
  AuthSignup: undefined;
  AuthSnsSignup: SnsSignupUser;
  AuthLanding: undefined;
  CategoryEdit: undefined;
  ProfileEdit: undefined;
  PersonalInformation: undefined;
  BankEdit: undefined;
  Report:
    | ReactNavigationNative.NavigatorScreenParams<ReportsTabParamList>
    | undefined;
  CampaignApplyHistory: undefined;
  SnsAssociation: {
    tab: "tiktok" | "youtube";
  };
  PersonalInformationEdit: undefined;
  AddressEdit: undefined;
  PhoneEdit: undefined;
  EmailEdit: undefined;
  Password: undefined;
  SnsLogin: undefined;
  Invoice: undefined;
  AuthLogout: undefined;
  AccountDeletion: undefined;
  PrivacyPolicy: undefined;
  TermsOfUse: undefined;
  Lp: undefined;
  ChangePassword: {
    token: string;
  };
  SignupThanks: undefined;
  CampaignFilter: {
    tab: string;
  };
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  ReactNavigationStack.NativeStackScreenProps<RootStackParamList, Screen>;

export type RootTabParamList = {
  Home?: {
    tab: keyof CampaignsTabParamList;
  };
  Favorite: undefined;
  Todo?: {
    tab: keyof TodoTabParamList;
  };
  MyPage: undefined;
  Profile: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
  ReactNavigationNative.CompositeScreenProps<
    ReactNavigationBottom.BottomTabScreenProps<RootTabParamList, Screen>,
    ReactNavigationStack.NativeStackScreenProps<RootStackParamList>
  >;

export type TodoTabParamList = {
  Working: undefined;
  Recruiting: undefined;
};

export type CampaignsTabParamList = {
  Finished: undefined;
  New: undefined;
  Popular: undefined;
  Closing: undefined;
  Recommended: undefined;
};

export type ReportsTabScreenProps<Screen extends keyof ReportsTabParamList> =
  ReactNavigationStack.NativeStackScreenProps<ReportsTabParamList, Screen>;

export type ReportsTabParamList = {
  Monthly: undefined;
  Yearly: undefined;
};

export const initialRoute = "Root";
export const initialTabScreen = "Home";
export const authRoute = "Auth";
export const OAuthRedirectWeb = "oauth/web";
