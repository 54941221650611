import React, { useRef, useState } from "react";
import { TextInput, Platform } from "react-native";
import { View } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import { useTheme } from "@react-navigation/native";
import {
  CommonProps,
  KeyboardTypeMap,
} from "@components/molecules/TextInput/types";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import InnerLabel, { Ref } from "@components/molecules/TextInput/InnerLabel";
import styles from "@components/molecules/TextInput/style";
import { Controller } from "react-hook-form";

interface Props extends CommonProps {
  /** キーボード種別 */
  type?: "text" | "email" | "phone" | "url" | "numberString";
  onChange?: (value: string) => void;
}

export default function TextField({
  type = "text",
  label,
  name,
  innerLabel,
  required,
  disabled,
  placeholder,
  style,
  placeholderTextColor = Colors.gray,
  inputAccessoryViewID,
  maxLength,
  control,
  onChange,
}: Props): JSX.Element {
  const { colors, dark } = useTheme();
  const ref = useRef<Ref>();
  const [onFocused, setOnFocused] = useState<boolean>(false);
  return (
    <View style={styles.container}>
      {label !== undefined && (
        <OuterLabel label={label} required={required === true} />
      )}

      <Controller
        control={control}
        name={name}
        render={({
          field: { value, onBlur, onChange: onChangeText },
          fieldState: { invalid },
        }) => (
          <TextInput
            allowFontScaling={false}
            autoCapitalize="none"
            autoCorrect={false}
            blurOnSubmit={false}
            editable={disabled !== true}
            inputAccessoryViewID={inputAccessoryViewID}
            keyboardType={KeyboardTypeMap[type][Platform.OS]}
            maxLength={maxLength}
            onBlur={() => {
              setOnFocused(false);
              if (innerLabel === true && value === "") {
                ref.current?.onBlur();
              }
              onBlur();
            }}
            onChangeText={(inputValue) => {
              onChangeText(inputValue);
              if (onChange !== undefined) {
                onChange(inputValue);
              }
            }}
            onFocus={() => {
              setOnFocused(true);
              if (innerLabel === true) {
                ref.current?.onFocus();
              }
            }}
            placeholder={innerLabel === true ? "" : placeholder}
            placeholderTextColor={placeholderTextColor}
            selectionColor={colors.text}
            style={[
              styles.form,
              {
                color: colors.text,
                backgroundColor: dark
                  ? colors.background
                  : disabled === true
                  ? Colors.gray20
                  : colors.card,
              },
              style,
              innerLabel !== undefined &&
                (onFocused || value !== "") && { paddingTop: 22 },
              invalid ? styles.formError : null,
            ]}
            underlineColorAndroid="transparent"
            value={value === null ? "" : value}
          />
        )}
      />
      {innerLabel !== undefined && (
        <InnerLabel
          innerRef={ref}
          placeholder={placeholder !== undefined ? placeholder : ""}
        />
      )}
    </View>
  );
}
