import React, { useCallback, useState } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, Card, Wrapper, View } from "@components/atoms/Themed";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import GradientButton from "@components/atoms/GradientButton";
import Spacer from "@components/atoms/Spacer";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import Dialog from "@components/molecules/dialog/Dialog";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { graphql, useMutation } from "react-relay/hooks";
import {
  AuthReissueMutation,
  AuthReissueMutation$data,
} from "@generated/AuthReissueMutation.graphql";
import { goBack } from "@navigation/navigate";
import { KeyboardId } from "@constants/App";
import Divider from "@components/atoms/Divider";

const reissuePasswordQuery = graphql`
  mutation AuthReissueMutation($email: String!) {
    reissuePassword(input: { email: $email }) {
      email
    }
  }
`;

type InputData = {
  email: string;
};
export default function AuthReissue() {
  const [dialogViewable, setDialogViewable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [commit] = useMutation<AuthReissueMutation>(reissuePasswordQuery);
  const [isError, setIsError] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<InputData>({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string()
          .trim()
          .email("正しいEmailを入力してください")
          .required("入力してください"),
      })
    ),
  });

  const reissuePassword = useCallback(async () => {
    await handleSubmit(async ({ email }: InputData) => {
      try {
        setLoading(true);
        await new Promise<AuthReissueMutation$data>((resolve, reject) => {
          commit({
            variables: { email },
            onCompleted(result) {
              resolve(result);
            },
            onError(error: Error) {
              reject(error);
            },
          });
        });
        setDialogViewable(true);
      } catch (e: unknown) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.content}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.container}>
              <Text style={styles.infoLabel}>
                ご登録されているメールアドレスにパスワード再設定のご案内を送ります。受信されるメール設定にて「@influencer-works.com」からのメールが受信できるように変更してください。
              </Text>

              <Spacer height={24} />

              <TextField
                control={control}
                innerLabel
                inputAccessoryViewID={KeyboardId}
                name="email"
                placeholder="メールアドレス"
                style={styles.inputField}
                type="email"
              />
              {!isValid && errors.email !== undefined && (
                <ErrorText error={errors.email.message} />
              )}
            </Card>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={reissuePassword}
          title="パスワード再設定メール送信"
        />
      </Card>
      {dialogViewable && (
        <Dialog
          buttons={[
            {
              title: "OK",
              type: "white",
              onPress: () => goBack(),
            },
          ]}
          message="再設定メールを送信しました"
          onClose={() => setDialogViewable(false)}
          subline="届きましたらメールに記載されているURLからパスワードの再設定へ進んでください。"
        />
      )}
      {isError && (
        <Dialog
          buttons={[{ title: "OK", type: "white" }]}
          customStyle={{ messageStyle: styles.orangeText }}
          message="送信エラー"
          onClose={() => setIsError(false)}
          subline="メールアドレスに送信が出来ませんでした。再度ボタンを押すか時間が経ってからやり直してください。"
        />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  container: {
    padding: 24,
  },
  infoLabel: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
  inputField: {
    height: 48,
    marginBottom: 24,
  },
  orangeText: {
    color: Colors.orange,
    textAlign: "center",
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
});
