import { TextStyle } from "react-native";

const font10 = 10;
const font11 = 11;
const font12 = 12;
const font13 = 13;
const font16 = 16;
const font20 = 20;
const font32 = 32;
const font36 = 36;
const font40 = 40;
const font56 = 56;

const RegularFontFamily: string = "HiraginoSans-W3";
const BoldFontFamily: string = "HiraginoSans-W6";
const EnglishBoldFontFamily: string = "System";
const SfProFontFamily: string = "System"; // "SF Pro Text"; SF ProはGoogle Fontsが対応していないので使う場合は別途購入？する必要あり

type FontsType = {
  xsr100: TextStyle;
  sr100: TextStyle;
  sr140: TextStyle;
  srlink140: TextStyle;
  sb100: TextStyle;
  mr100: TextStyle;
  lr100: TextStyle;
  lr130: TextStyle;
  lrlink130: TextStyle;
  lb100: TextStyle;
  lb130: TextStyle;
  xlr100: TextStyle;
  xlr160: TextStyle;
  xlb100: TextStyle;
  xlb160: TextStyle;
  xlblink100: TextStyle;
  xxlb140: TextStyle;
  enlr100: TextStyle;
  enxsb100: TextStyle;
  ensb100: TextStyle;
  enmb100: TextStyle;
  enlb100: TextStyle;
  enxlb100: TextStyle;
  sfmr100: TextStyle;
  sfxlr100: TextStyle;
};

const Fonts: FontsType = {
  xsr100: {
    fontFamily: RegularFontFamily,
    fontSize: font10,
    lineHeight: 13,
    fontWeight: "normal",
  },
  sr100: {
    fontFamily: RegularFontFamily,
    fontSize: font11,
    lineHeight: 14,
    fontWeight: "normal",
  },
  sr140: {
    fontFamily: RegularFontFamily,
    fontSize: font11,
    lineHeight: 15.4,
    fontWeight: "normal",
  },
  srlink140: {
    fontFamily: RegularFontFamily,
    fontSize: font11,
    lineHeight: 15.4,
    fontWeight: "normal",
    textDecorationLine: "underline",
  },
  sb100: {
    fontFamily: BoldFontFamily,
    fontSize: font11,
    lineHeight: 14,
    fontWeight: "bold",
  },
  mr100: {
    fontFamily: RegularFontFamily,
    fontSize: font12,
    lineHeight: 16,
    fontWeight: "normal",
  },
  lr100: {
    fontFamily: RegularFontFamily,
    fontSize: font13,
    lineHeight: 17,
    fontWeight: "300",
  },
  lr130: {
    fontFamily: RegularFontFamily,
    fontSize: font13,
    lineHeight: 18.2,
    fontWeight: "300",
  },
  lrlink130: {
    fontFamily: RegularFontFamily,
    fontSize: font13,
    lineHeight: 18.2 /* 140% */,
    fontWeight: "300",
    textDecorationLine: "underline",
  },
  lb100: {
    fontFamily: BoldFontFamily,
    fontSize: font13,
    lineHeight: 17,
    fontWeight: "bold",
  },
  lb130: {
    fontFamily: BoldFontFamily,
    fontSize: font13,
    lineHeight: 18.2 /* 140% */,
    fontWeight: "bold",
  },
  xlr100: {
    fontFamily: RegularFontFamily,
    fontSize: font16,
    lineHeight: 20,
    fontWeight: "normal",
  },
  xlr160: {
    fontFamily: RegularFontFamily,
    fontSize: font16,
    lineHeight: 25.6,
    fontWeight: "normal",
  },
  xlb100: {
    fontFamily: BoldFontFamily,
    fontSize: font16,
    lineHeight: 20,
    fontWeight: "bold",
  },
  xlb160: {
    fontFamily: BoldFontFamily,
    fontSize: font16,
    lineHeight: 25.6,
    fontWeight: "bold",
  },
  xlblink100: {
    fontFamily: BoldFontFamily,
    fontSize: font16,
    lineHeight: 20,
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  xxlb140: {
    fontFamily: BoldFontFamily,
    fontSize: font20,
    lineHeight: 32 /* 160% */,
    fontWeight: "bold",
  },
  enlr100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font13,
    lineHeight: 13,
    letterSpacing: -0.52 /* -0.04em=(-0.04 * 13) */,
    fontWeight: "bold",
  },
  enxsb100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font16,
    lineHeight: 16,
    fontWeight: "bold",
    letterSpacing: -0.64 /* -0.04em=(-0.04 * 16) */,
  },
  ensb100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font20,
    lineHeight: 20,
    fontWeight: "bold",
  },
  enmb100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font32,
    lineHeight: 32,
    fontWeight: "bold",
  },
  enlb100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font40,
    lineHeight: 40,
    fontWeight: "bold",
  },
  enxlb100: {
    fontFamily: EnglishBoldFontFamily,
    fontSize: font56,
    lineHeight: 56,
    fontWeight: "bold",
  },
  sfmr100: {
    fontFamily: SfProFontFamily,
    fontSize: font16,
    lineHeight: 16,
    fontWeight: "normal",
  },
  sfxlr100: {
    fontFamily: SfProFontFamily,
    fontSize: font36,
    lineHeight: 36,
    fontWeight: "normal",
  },
};

export default Fonts;
