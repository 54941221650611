import React, { useCallback } from "react";
import { StyleSheet } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import GradientButton from "@components/atoms/GradientButton";
import { squareIcon } from "@components/atoms/AssetImage";
import onLogout from "@lib/util/onLogout";

export default function FallbackExpired() {
  const reLogin = useCallback(async () => {
    await onLogout();
  }, []);
  return (
    <>
      <Spacer height={20} />
      <View style={styles.logoWrapper}>
        <View style={styles.squareIcon}>
          <ResponsiveImage height={148} source={squareIcon} width={595} />
        </View>
      </View>
      <Spacer height={20} />
      <Spacer height={30} />
      <View style={styles.textArea}>
        <Text style={styles.caption}>
          InfluencerWorksをご利用ありがとうございます。
          {"\n"}
          {"\n"}
          ログインが切れました。再度ログインを行ってください。
        </Text>
      </View>
      <Spacer height={30} />
      <View style={styles.buttonArea}>
        <GradientButton onPress={reLogin} title="ログイン画面へ" />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textArea: {
    paddingHorizontal: 16,
  },
  logoWrapper: {
    alignItems: "center",
  },
  squareIcon: {
    width: "100%",
    maxWidth: 390,
    height: 58,
  },
  caption: {
    ...Fonts.lr130,
  },
  buttonArea: {
    alignItems: "center",
    justifyContent: "center",
  },
});
