/**
 * @generated SignedSource<<2f13c8720f1fa869c4c979eefcbbedf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalInformationEditInfo$data = {
  readonly firstName: string | null;
  readonly firstNameKana: string | null;
  readonly lastName: string | null;
  readonly lastNameKana: string | null;
  readonly " $fragmentType": "PersonalInformationEditInfo";
};
export type PersonalInformationEditInfo$key = {
  readonly " $data"?: PersonalInformationEditInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonalInformationEditInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalInformationEditInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstNameKana",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastNameKana",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "2f8f2d30a79221aaaf8313671e295362";

export default node;
