/**
 * @generated SignedSource<<adfba3f1acbf9f1755995c3b770a4493>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketScreenQuery$variables = {
  id: string;
};
export type TicketScreenQuery$data = {
  readonly post: {
    readonly " $fragmentSpreads": FragmentRefs<"TicketPost">;
  };
};
export type TicketScreenQuery = {
  response: TicketScreenQuery$data;
  variables: TicketScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TicketScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TicketPost"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TicketScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentMediaType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gifting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "giftingDetail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Banner",
                "kind": "LinkedField",
                "name": "banners",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "file",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MCategory",
                "kind": "LinkedField",
                "name": "mCategory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SocialNetwork",
                "kind": "LinkedField",
                "name": "socialNetwork",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recruitmentEndOn",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2461c5fa70d1a6e0e0b3de807aecd2f8",
    "id": null,
    "metadata": {},
    "name": "TicketScreenQuery",
    "operationKind": "query",
    "text": "query TicketScreenQuery(\n  $id: ID!\n) {\n  post(id: $id) {\n    ...TicketPost\n    id\n  }\n}\n\nfragment CampaignVisitReservationCampaign on Campaign {\n  id\n  title\n  parentMediaType\n  gifting\n  giftingDetail\n  prType\n  banners {\n    file\n    id\n  }\n  mCategory {\n    name\n    id\n  }\n  socialNetwork {\n    recruitmentEndOn\n    id\n  }\n}\n\nfragment TicketPost on Post {\n  id\n  status\n  campaign {\n    ...CampaignVisitReservationCampaign\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4406f4fda6d223fa434fcf78d99c9088";

export default node;
