export type ErrorType = {
  code: string;
  message: string;
};

export function resolveError(e: unknown): ErrorType {
  if (e instanceof Error) {
    return {
      code: "Unknown",
      message: e.message,
    };
  }
  if (typeof e === "string") {
    return {
      code: "Unknown",
      message: e,
    };
  }
  if (e !== null && typeof e === "object" && "code" in e && "message" in e) {
    return {
      code: typeof e.code === "string" ? e.code : "Unknown",
      message:
        typeof e.message === "string"
          ? e.message
          : "予期せぬエラーが発生しました",
    };
  }
  return {
    code: "Unknown",
    message: "予期せぬエラーが発生しました",
  };
}
