import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";

export default function Label({
  label,
  required,
}: {
  label: string;
  required: boolean;
}) {
  return (
    <>
      <View style={styles.container}>
        <Text>{label}</Text>
        <Spacer width={4} />
        {required ? <Text style={styles.required}>＊</Text> : null}
      </View>
      <Spacer height={4} />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    ...Fonts.lr130,
  },
  required: {
    color: Colors.orange,
    fontSize: 10,
    fontWeight: "bold",
  },
});
