/**
 * @generated SignedSource<<ad5033df8ba7cd107f7383e3be2d4023>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatBank$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChatActionBank">;
  readonly " $fragmentType": "ChatBank";
};
export type ChatBank$key = {
  readonly " $data"?: ChatBank$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatBank">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatBank",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatActionBank"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "3e1ff5dad8b4386be1b0a975d64ad9f6";

export default node;
