import React, { useState, useEffect, useCallback } from "react";
import days from "dayjs";
import _ from "lodash";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import LinkableText from "@components/atoms/LinkableText";
import SelectableText from "@components/atoms/SelectableText";
import Avatar from "@components/atoms/Avatar";
import Spacer from "@components/atoms/Spacer";
import Stamp from "@components/atoms/Stamp";
import { View, Card, Text } from "@components/atoms/Themed";
import { CommentStamp } from "@constants/App";
import { isVideo } from "@lib/util/file";
import {
  StyleSheet,
  Image,
  TouchableOpacity,
  Animated,
  FlatList,
} from "react-native";
import openURL from "@lib/util/openUrl";
import {
  ChatMessage$key,
  ChatMessage$data,
} from "@generated/ChatMessage.graphql";
import useSound from "@hooks/useSound";
import { graphql, useFragment } from "react-relay/hooks";
import Icon from "@components/atoms/Icon";
import { ResizeMode, Video } from "expo-av";
import GradientButton from "@components/atoms/GradientButton";
import { navigate } from "@navigation/navigate";

const AnimationPosition = 200;
const AnimationDuration = 300;

const chatMessage = graphql`
  fragment ChatMessage on Comment {
    content
    action
    stamp
    extraInfo
    extraType
    createdAt
    commentable {
      userType
      avatar {
        file
      }
    }
    ogps {
      id
      title
      image
      url
      description
    }
    attachments {
      id
      file
      duration
      contentType
    }
    post {
      id
    }
  }
`;

type Props = {
  /** チャットメッセージ */
  commentFragment: ChatMessage$key;
  /** チャットメッセージ */
  animation: boolean;
  /** チャットメッセージ */
  completedAnimation: () => void;
  /** 画像プレビューを開く */
  openPreview: (index: number, items: ChatMessage$data["attachments"]) => void;
};
function Separator() {
  return <Spacer height={4} />;
}

function Ogp({ data }: { data: ChatMessage$data["ogps"][number] }) {
  return (
    <TouchableOpacity onPress={() => openURL(data.url)}>
      <Card style={styles.ogpCard}>
        {data.image !== null && (
          <Image
            source={{
              uri: data.image,
            }}
            style={styles.ogpImage}
          />
        )}

        <View style={styles.ogpBody}>
          <Text style={styles.ogpTitle}>{data.title}</Text>
          {data.description !== null && (
            <>
              <Spacer height={8} />
              <LinkableText style={styles.ogpText}>
                {_.truncate(data.description, { length: 50 })}
              </LinkableText>
            </>
          )}
        </View>
      </Card>
    </TouchableOpacity>
  );
}

function ImageCard({
  attachments,
  openPreview,
}: {
  attachments: ChatMessage$data["attachments"];
  openPreview: (index: number) => void;
}): JSX.Element {
  return (
    <Card style={styles.imageCardContainer}>
      <FlatList
        data={attachments.slice(0, 4)}
        ItemSeparatorComponent={Separator}
        keyExtractor={(item) => item.id}
        numColumns={2}
        renderItem={({ index, item }) => (
          <>
            {(index + 1) % 2 === 0 && <Spacer width={4} />}
            <TouchableOpacity onPress={() => openPreview(index)}>
              <View style={styles.thumbnailItem}>
                {isVideo({
                  uri: item.file,
                  contentType: item.contentType,
                }) ? (
                  <View style={styles.video}>
                    <Video
                      resizeMode={ResizeMode.CONTAIN}
                      shouldPlay={false}
                      source={{ uri: item.file }}
                      style={styles.image}
                      useNativeControls={false}
                      videoStyle={styles.image}
                    />
                    <View style={styles.play}>
                      <Icon color={Colors.white} name="play" size={32} />
                    </View>
                  </View>
                ) : (
                  <Image source={{ uri: item.file }} style={styles.image} />
                )}

                {attachments.length > 4 && index === 3 && (
                  <>
                    <View style={styles.mask} />
                    <View style={styles.iconWrap}>
                      <Icon color="white" name="pluss" size={40} />
                    </View>
                  </>
                )}
              </View>
            </TouchableOpacity>
          </>
        )}
      />

      {attachments.length > 4 && (
        <>
          <Spacer height={4} />
          <View style={styles.imageNumWrap}>
            <Text
              style={styles.imageNumText}
            >{`全${attachments.length.toString()}枚`}</Text>
          </View>
        </>
      )}
    </Card>
  );
}

function StampMessage({
  stamp,
  postId,
}: {
  stamp: CommentStamp;
  postId: string;
}) {
  return (
    <>
      <Card style={styles.stampWrapper}>
        <Stamp name={stamp} style={styles.stamp} />
        {stamp === "ticket" && (
          <>
            <Spacer height={16} />
            <Text style={styles.ticketText}>来店チケットが届きました！</Text>
            <Spacer height={16} />
            <GradientButton
              borderRadius={4}
              gradientColor={Colors.grGreen}
              onPress={() => {
                navigate("Ticket", { id: postId });
              }}
              title="チケットを確認"
              width="100%"
            />
          </>
        )}
      </Card>
      <Spacer height={16} />
    </>
  );
}

function ExtraMessage({ data }: { data: ChatMessage$data }) {
  if (data.extraInfo === null) {
    return null;
  }
  return (
    <>
      <Spacer height={8} />
      <View style={styles.wrapper}>
        <Card style={styles.body}>
          <Text style={styles.subline}>
            {data.extraType === "draft"
              ? "下書き内容"
              : data.extraType === "rejected"
              ? "差し戻し理由"
              : "投稿内容"}
          </Text>
          <SelectableText style={styles.text}>{data.extraInfo}</SelectableText>
        </Card>
      </View>
    </>
  );
}

function Item({
  data,
  isEffect,
  openPreview,
}: {
  data: ChatMessage$data;
  isEffect?: boolean;
  openPreview: (index: number) => void;
}) {
  const avatar =
    data.commentable.avatar !== null ? data.commentable.avatar.file : null;
  if (data.commentable.userType !== "Influencer") {
    return (
      <View style={styles.container}>
        <>
          {isEffect === true ? (
            <View style={styles.avatar} />
          ) : (
            <Avatar uri={avatar} userType={data.commentable.userType} />
          )}
          <Spacer width={16} />
        </>
        <View style={styles.sender}>
          <View style={styles.wrapper}>
            <Card style={styles.body}>
              {data.content !== null ? (
                <SelectableText style={styles.text}>
                  {data.content}
                </SelectableText>
              ) : (
                <Text style={styles.text}>{data.action}</Text>
              )}
            </Card>
          </View>
          {isEffect !== true && (
            <>
              {data.attachments.length > 0 && (
                <View style={styles.message}>
                  <Spacer height={8} />
                  <ImageCard
                    attachments={data.attachments}
                    openPreview={openPreview}
                  />
                </View>
              )}
              {data.extraType !== null && data.extraInfo !== null && (
                <ExtraMessage data={data} />
              )}

              <Spacer height={8} />

              {data.ogps.length > 0 &&
                data.ogps.map((row) => (
                  <View key={row.id}>
                    <Spacer height={8} />
                    <Ogp data={row} />
                  </View>
                ))}
              <Spacer height={8} />
              <View style={styles.date}>
                <Text style={styles.dateText}>
                  送信 {days(data.createdAt).format("M月D日 HH:mm")}
                </Text>
              </View>
            </>
          )}
        </View>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.you}>
        <View style={styles.wrapper}>
          <Card style={styles.body}>
            <SelectableText style={styles.text}>
              {data.content ?? ""}
            </SelectableText>
          </Card>
        </View>

        {isEffect !== true && (
          <>
            {data.attachments.length > 0 && (
              <View style={styles.message}>
                <Spacer height={8} />
                <ImageCard
                  key={data.attachments[0].id}
                  attachments={data.attachments}
                  openPreview={openPreview}
                />
              </View>
            )}

            {data.extraType !== null && data.extraInfo !== null && (
              <ExtraMessage data={data} />
            )}

            <Spacer height={8} />

            {data.ogps.length > 0 &&
              data.ogps.map((row) => (
                <View key={row.id}>
                  <Spacer height={8} />
                  <Ogp data={row} />
                </View>
              ))}
            <Spacer height={8} />
            <View style={styles.date}>
              <Text style={styles.dateText}>
                送信 {days(data.createdAt).format("M月D日 HH:mm")}
              </Text>
            </View>
          </>
        )}
      </View>
      <>
        <Spacer width={16} />
        {isEffect === true ? (
          <View style={styles.avatar} />
        ) : (
          <Avatar uri={avatar} userType={data.commentable.userType} />
        )}
      </>
    </View>
  );
}

function Effect({
  data,
  completedAnimation,
}: {
  data: ChatMessage$data;
  completedAnimation: () => void;
}) {
  const { state: popSound } = useSound();
  const [animate] = useState<Animated.Value>(
    new Animated.Value(AnimationPosition)
  );

  const animateItem = useCallback(async () => {
    const addAnimation = Animated.timing(animate, {
      toValue: 0,
      duration: AnimationDuration,
      useNativeDriver: false,
    });
    if (popSound !== null) {
      await popSound.replayAsync();
    }
    addAnimation.start(() => {
      completedAnimation();
      addAnimation.stop();
    });
  }, [completedAnimation, popSound, animate]);

  useEffect(() => {
    animateItem();
    return () => {
      completedAnimation();
    };
  }, [animate, animateItem, completedAnimation]);
  return (
    <Animated.View
      style={[
        styles.effect,
        {
          top: animate,
        },
      ]}
    >
      {data.stamp !== null && (
        <StampMessage
          postId={data.post.id}
          stamp={data.stamp as CommentStamp}
        />
      )}
      <Item data={data} isEffect openPreview={() => {}} />
    </Animated.View>
  );
}

export default function ChatMessage({
  commentFragment,
  animation,
  completedAnimation,
  openPreview,
}: Props) {
  const data = useFragment(chatMessage, commentFragment);
  if (animation) {
    return (
      <View style={styles.animated}>
        <View
          style={[
            animation && {
              opacity: 0,
            },
          ]}
        >
          {data.stamp !== null && (
            <StampMessage
              postId={data.post.id}
              stamp={data.stamp as CommentStamp}
            />
          )}
          <Item
            data={data}
            openPreview={(index) => openPreview(index, data.attachments)}
          />
        </View>

        {animation && (
          <Effect
            completedAnimation={() => {
              completedAnimation();
            }}
            data={data}
          />
        )}
      </View>
    );
  }

  return (
    <>
      {data.stamp !== null && (
        <StampMessage
          postId={data.post.id}
          stamp={data.stamp as CommentStamp}
        />
      )}
      <Item
        data={data}
        openPreview={(index) => openPreview(index, data.attachments)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  sender: {
    alignItems: "flex-start",
    flex: 1,
  },
  you: {
    alignItems: "flex-end",
    flex: 1,
  },
  message: {
    flex: 1,
  },
  ogpCard: {
    borderRadius: 6,
    overflow: "hidden",
    width: 240,
  },
  ogpImage: {
    width: "100%",
    height: 135,
    backgroundColor: Colors.gray,
    justifyContent: "center",
    alignItems: "center",
  },
  ogpBody: {
    padding: 16,
  },
  ogpTitle: {
    ...Fonts.lb130,
  },
  ogpText: {
    ...Fonts.sr140,
    color: Colors.gray,
  },
  wrapper: {
    maxWidth: "100%",
  },
  body: {
    padding: 16,
    borderRadius: 6,
  },
  text: {
    ...Fonts.lr130,
  },
  subline: {
    ...Fonts.mr100,
    color: Colors.gray,
  },
  date: {
    flexDirection: "row",
  },
  dateText: {
    ...Fonts.xsr100,
    color: Colors.gray,
  },
  stampWrapper: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 6,
  },
  stamp: {
    width: 300,
    height: 242,
  },
  ticketText: {
    ...Fonts.lb130,
    color: Colors.black,
  },
  animated: {
    position: "relative",
  },
  effect: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 999,
  },
  avatar: {
    width: 32,
  },
  thumbnailItem: {
    width: 104,
    height: 104,
    borderRadius: 4,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageCardContainer: {
    padding: 16,
    borderRadius: 4,
  },
  imageNumWrap: {
    alignItems: "flex-end",
  },
  imageNumText: {
    ...Fonts.sr100,
    color: Colors.gray,
  },
  mask: {
    position: "absolute",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4,
  },
  iconWrap: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  video: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.black0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.white,
  },
  play: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
