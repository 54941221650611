import React from "react";
import { Text, View, Card } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity, Image } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import campaignBanner from "@lib/util/campaignBanner";
import { CampaignVisitReservationCampaign$key } from "@generated/CampaignVisitReservationCampaign.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import { navigate } from "@navigation/navigate";
import { useTheme } from "@react-navigation/native";

const campaignVisitReservationFragment = graphql`
  fragment CampaignVisitReservationCampaign on Campaign {
    id
    title
    parentMediaType
    gifting
    giftingDetail
    prType
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentEndOn
    }
  }
`;

export default function CampaignVisitReservation({
  campaignFragment,
}: {
  campaignFragment: CampaignVisitReservationCampaign$key;
}) {
  const campaign = useFragment(
    campaignVisitReservationFragment,
    campaignFragment
  );
  const { colors } = useTheme();

  return (
    <>
      <Card style={styles.cardTop}>
        <TouchableOpacity
          onPress={() => navigate("CampaignDetail", { id: campaign.id })}
          style={styles.campaignInfo}
        >
          <View style={styles.image}>
            <Image
              source={campaignBanner(campaign.banners)}
              style={styles.image}
            />
          </View>
          <Spacer width={16} />
          <View style={styles.campaignExplanation}>
            <View style={styles.information}>
              <SNSIcons
                name={campaign.parentMediaType}
                style={styles.platform}
              />
              <Spacer width={8} />
              <View style={styles.category}>
                <Text style={styles.categoryText}>
                  {campaign.mCategory.name}
                </Text>
              </View>
            </View>
            <Spacer height={4} />
            <Text style={styles.explanation}>{campaign.title}</Text>
          </View>
        </TouchableOpacity>
      </Card>
      <View style={[styles.separate, { backgroundColor: colors.card }]}>
        <View>
          <View
            style={[
              styles.triangleLeftTop,
              { borderBottomColor: colors.background },
            ]}
          />
          <View
            style={[
              styles.triangleLeftBottom,
              { borderTopColor: colors.background },
            ]}
          />
        </View>
        <View style={styles.dot} />

        <View>
          <View
            style={[
              styles.triangleRightTop,
              { borderBottomColor: colors.background },
            ]}
          />
          <View
            style={[
              styles.triangleRightBottom,
              { borderTopColor: colors.background },
            ]}
          />
        </View>
      </View>
      <Card style={styles.cardBottom}>
        <View style={styles.info}>
          <View>
            <Text numberOfLines={2} style={styles.infoText}>
              提供内容
            </Text>
          </View>
          <Spacer width={16} />
          <View style={styles.giftingDetail}>
            <Text style={styles.infoText}>{campaign.giftingDetail}</Text>
          </View>
        </View>
      </Card>
    </>
  );
}

const styles = StyleSheet.create({
  cardTop: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  cardBottom: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    minHeight: 150,
  },
  campaignInfo: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 16,
  },
  image: {
    height: 90,
    width: 120,
    borderRadius: 4,
  },
  campaignExplanation: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  information: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  platform: {
    height: 24,
    width: 24,
  },
  category: {
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    paddingHorizontal: 8,
    borderColor: Colors.green,
    borderWidth: 1,
    borderRadius: 4,
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.green,
  },
  explanation: {
    color: Colors.black,
    textAlign: "left",
    ...Fonts.lb130,
  },
  info: {
    flexDirection: "row",
    padding: 16,
  },
  infoText: {
    ...Fonts.lr130,
  },
  separate: {
    flexDirection: "row",
    alignItems: "center",
  },
  dot: {
    height: 0.5,
    borderWidth: 1, // borderBottomWidthが効かないのでborderWidthで代用
    borderColor: Colors.gray20,
    borderStyle: "dotted",
    flex: 1,
  },
  triangleLeftTop: {
    borderLeftWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    width: 0,
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    height: 0,
  },
  triangleLeftBottom: {
    borderLeftWidth: 0,
    borderRightWidth: 10,
    borderTopWidth: 10,
    width: 0,
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    height: 0,
  },

  triangleRightTop: {
    borderLeftWidth: 10,
    borderRightWidth: 0,
    borderBottomWidth: 10,
    width: 0,
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    height: 0,
  },
  triangleRightBottom: {
    borderLeftWidth: 10,
    borderRightWidth: 0,
    borderTopWidth: 10,
    width: 0,
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    height: 0,
  },
  giftingDetail: {
    flex: 1,
  },
});
