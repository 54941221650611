import React, { useState } from "react";
import { FlatList, StyleSheet, RefreshControl } from "react-native";
import Divider from "@components/atoms/Divider";
import Loading from "@components/atoms/Loading";
import { usePaginationFragment, graphql } from "react-relay/hooks";
import { PagingCandidate } from "@constants/App";
import { View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import { ApplyingsPagination$key } from "@generated/ApplyingsPagination.graphql";
import Tutorial from "@components/organisms/Applyings/ApplyingTutorial";
import Campaign from "@components/organisms/Applyings/ApplyingCampaign";
import Spacer from "@components/atoms/Spacer";

const candidatesQuery = graphql`
  fragment ApplyingsPagination on Query
  @refetchable(queryName: "ApplyingsPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    influencerId: { type: "ID!" }
  ) {
    applyings(first: $first, after: $after, influencerId: $influencerId)
      @connection(key: "ApplyingsList_applyings") {
      edges {
        node {
          id
          ...ApplyingCampaign
        }
      }
    }
  }
`;

export default function Recruitings({
  candidates,
  move,
}: {
  candidates: ApplyingsPagination$key;
  move: (id: string) => void;
}) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    candidatesQuery,
    candidates
  );
  const { edges } = data.applyings;

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <FlatList
        data={edges}
        keyExtractor={(item) => item.node.id}
        ListEmptyComponent={Tutorial}
        ListHeaderComponentStyle={{
          paddingBottom: 8,
        }}
        onEndReached={() => {
          if (hasNext && !paging) {
            setPaging(true);
            loadNext(PagingCandidate, { onComplete: () => setPaging(false) });
          }
        }}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              if (!refreshing) {
                setRefreshing(true);
                refetch(
                  { first: PagingCandidate },
                  {
                    fetchPolicy: "network-only",
                    onComplete: () => setRefreshing(false),
                  }
                );
              }
            }}
            refreshing={refreshing}
          />
        }
        renderItem={({ item }) => (
          <Wrapper>
            <Card style={styles.item}>
              <Campaign
                campaignDetailOnPress={(id: string) => move(id)}
                candidateFragment={item.node}
              />
            </Card>
            <Divider color={Colors.gray20} height={1} />
          </Wrapper>
        )}
      />
      {paging && (
        <View style={styles.paging}>
          <Loading />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    padding: 16,
  },
  dividerWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  paging: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    paddingVertical: 12,
  },
});
