import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";
import { View } from "@components/atoms/Themed";

type Props = {
  /** 子要素。表示したい文字などを指定してください。 */
  children: React.ReactNode;
  /** ボーダーカラー */
  color?: string;
  /** 背景色カラー */
  backgroundColor?: string;
};

export default function Tag({
  children,
  color = Colors.green,
  backgroundColor,
}: Props) {
  const backgrond = useMemo(() => {
    if (backgroundColor !== undefined) {
      return { backgroundColor };
    }
    return null;
  }, [backgroundColor]);
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.tag,
          {
            borderColor: color,
          },
          backgrond,
        ]}
      >
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  tag: {
    paddingHorizontal: 4,
    borderWidth: 1,
    borderRadius: 4,
    height: 24,
    justifyContent: "center",
  },
});
