import React, { useCallback, useState } from "react";
import Modal from "@components/molecules/Modal";
import initializeUser from "@lib/util/initializeUser";
import useAccount from "@hooks/useAccount";
import { goBack, replace } from "@navigation/navigate";
import NavigationBar from "@components/molecules/NavigationBar";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { graphql, useMutation } from "react-relay/hooks";
import { AuthSignupSignupMutation } from "@generated/AuthSignupSignupMutation.graphql";
import { AuthSignupResendMutation } from "@generated/AuthSignupResendMutation.graphql";
import { AuthSignupConfirmMutation } from "@generated/AuthSignupConfirmMutation.graphql";
import Form from "@components/organisms/AuthSignup/AuthSignupForm";
import Confirm from "@components/organisms/AuthSignup/AuthSignupConfirm";
import recordConversion from "@lib/util/recordConversion";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import { resolveError } from "@lib/util/error";

const signupQuery = graphql`
  mutation AuthSignupSignupMutation($input: SignupMutationInput!) {
    signup(input: $input) {
      __typename
      ... on SignupToken {
        uid
        token
      }
      ... on SignupError {
        message
      }
    }
  }
`;

const resendQuery = graphql`
  mutation AuthSignupResendMutation(
    $input: SignupRegenerateCodeMutationInput!
  ) {
    signupRegenerateCode(input: $input) {
      __typename
      ... on SignupRegenerateCodeToken {
        token
      }
      ... on SignupRegenerateCodeError {
        message
      }
    }
  }
`;

const confirmQuery = graphql`
  mutation AuthSignupConfirmMutation($input: SignupConfirmMutationInput!) {
    signupConfirm(input: $input) {
      __typename
      ... on SignupConfirmSuccess {
        token
        refreshToken
      }
      ... on SignupConfirmError {
        message
      }
    }
  }
`;

type Account = {
  uid: string;
  token: string;
  email: string;
  password: string;
};

export default function AuthSignup() {
  const [commitSignup] = useMutation<AuthSignupSignupMutation>(signupQuery);
  const [commitResend] = useMutation<AuthSignupResendMutation>(resendQuery);
  const [commitConfirm] = useMutation<AuthSignupConfirmMutation>(confirmQuery);
  const [account, setAccount] = useState<Account | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { set: setInfluencerId } = useAccount();

  const signUp = useCallback(
    async (params: { name: string; email: string; password: string }) => {
      const result = await new Promise<{ token: string; uid: string }>(
        (resolve, reject) => {
          commitSignup({
            variables: {
              input: {
                ...params,
              },
            },
            onCompleted({ signup }) {
              if (signup.__typename === "SignupToken") {
                resolve(signup);
              } else {
                reject(
                  signup.__typename === "SignupError"
                    ? signup.message
                    : "サインアップに失敗しました。"
                );
              }
            },
          });
        }
      );
      setAccount({
        ...account,
        email: params.email,
        password: params.password,
        uid: result.uid,
        token: result.token,
      });
    },
    [account, commitSignup]
  );

  const resend = useCallback(async () => {
    try {
      if (account === null) {
        return;
      }
      const token = await new Promise<string>((resolve, reject) => {
        commitResend({
          variables: {
            input: {
              email: account.email,
              uid: account.uid,
            },
          },
          onCompleted({ signupRegenerateCode }) {
            if (
              signupRegenerateCode.__typename === "SignupRegenerateCodeToken"
            ) {
              resolve(signupRegenerateCode.token);
            } else {
              reject(
                new Error(
                  signupRegenerateCode.__typename ===
                  "SignupRegenerateCodeError"
                    ? signupRegenerateCode.message
                    : "サインアップに失敗しました。"
                )
              );
            }
          },
        });
      });
      setAccount({
        ...account,
        token,
      });
    } catch (e: unknown) {
      setError(resolveError(e).message);
      setAccount(null);
    }
  }, [account, commitResend]);

  const confirm = useCallback(
    async (code: string) => {
      if (account === null) {
        return;
      }
      const login = await new Promise<{ refreshToken: string; token: string }>(
        (resolve, reject) => {
          commitConfirm({
            variables: {
              input: {
                token: account.token,
                code,
              },
            },
            onCompleted({ signupConfirm }) {
              if (signupConfirm.__typename === "SignupConfirmSuccess") {
                resolve(signupConfirm);
              } else {
                reject(
                  signupConfirm.__typename === "SignupConfirmError"
                    ? signupConfirm.message
                    : "サインアップに失敗しました。"
                );
              }
            },
          });
        }
      );

      await SecureStoreManager.registerAccessTokenSet(
        login.token,
        login.refreshToken
      );
      const id = await initializeUser();
      await recordConversion();
      setAccount(null);
      setInfluencerId(id);
      replace("SignupThanks");
    },
    [account, commitConfirm, setInfluencerId]
  );

  return (
    <>
      <NavigationBar onBack={goBack} title="アカウント登録" />
      <Form signUp={signUp} />

      <Modal
        animationType="none"
        header
        headerTitle="認証コードを送信しました"
        onRequestClose={() => setAccount(null)}
        visible={account !== null}
      >
        {account !== null && (
          <Confirm confirm={confirm} email={account.email} resend={resend} />
        )}
        {error !== null && (
          <ErrorDialog message={error} onClose={() => setError(null)} />
        )}
      </Modal>
    </>
  );
}
