/**
 * @generated SignedSource<<e8d9beaab5492f440829cb35f6efc318>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CandidateStatus = "admin_approved" | "admin_rejected" | "agency_approved" | "agency_rejected" | "applied";
import { FragmentRefs } from "relay-runtime";
export type CampaignMainCampaignDetailUnitPrice$data = {
  readonly customPrice: number;
  readonly price: number;
  readonly status: CandidateStatus | null;
  readonly " $fragmentType": "CampaignMainCampaignDetailUnitPrice";
};
export type CampaignMainCampaignDetailUnitPrice$key = {
  readonly " $data"?: CampaignMainCampaignDetailUnitPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignMainCampaignDetailUnitPrice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignMainCampaignDetailUnitPrice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Candidate",
  "abstractKey": null
};

(node as any).hash = "21bbdef0445c27d34273ebc30ce012e4";

export default node;
