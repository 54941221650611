import _ from "lodash";
import { ImageRequireSource, ImageURISource } from "react-native";
import { defaultBanner } from "@components/atoms/AssetImage";

interface Banner {
  readonly file: string | null;
}
export default function campaignBanner(
  banners: readonly Banner[] | null
): ImageURISource | ImageRequireSource {
  const banner = _.head(banners);
  if (banner !== undefined && banner.file !== null) {
    return {
      uri: `${banner.file}`,
    };
  }
  return defaultBanner;
}
