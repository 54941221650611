import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { Text } from "@components/atoms/Themed";
import { graphql, useMutation } from "react-relay/hooks";
import {
  OAuthLoginMutation,
  SnsLoginSnsTypes,
} from "@generated/OAuthLoginMutation.graphql";
import { SignUp, Login, makeRedirectUri } from "@lib/util/oauth";
import { navigate } from "@navigation/navigate";
import useAccount from "@hooks/useAccount";
import onLogin from "@lib/util/onLogin";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";

const loginQuery = graphql`
  mutation OAuthLoginMutation($input: SnsLoginMutationInput!) {
    snsLogin(input: $input) {
      __typename
      ... on SnsLoginToken {
        token
        refreshToken
      }
      ... on Influencer {
        id
        email
        profile {
          commonName
        }
      }
      ... on SnsLoginError {
        message
      }
    }
  }
`;

type Props = {
  code: string;
  codeVerifier: string;
  platform: SnsLoginSnsTypes;
  onError: () => void;
};

export default function OAuthLogin({
  code,
  codeVerifier,
  platform,
  onError,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loginCommit] = useMutation<OAuthLoginMutation>(loginQuery);
  const { set: setInfluencerId } = useAccount();

  const login = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const result = await new Promise<SignUp | Login>((resolve, reject) => {
        loginCommit({
          variables: {
            input: {
              codeExchange: code,
              codeVerifier,
              redirectUri: makeRedirectUri(),
              snsType: platform,
            },
          },
          onCompleted({ snsLogin }) {
            if (snsLogin.__typename === "Influencer") {
              resolve({
                id: snsLogin.id,
                commonName: snsLogin.profile.commonName,
                email: snsLogin.email,
              });
            } else if (snsLogin.__typename === "SnsLoginToken") {
              resolve({
                token: snsLogin.token,
                refreshToken: snsLogin.refreshToken,
              });
            } else {
              reject(
                snsLogin.__typename === "SnsLoginError"
                  ? snsLogin.message
                  : `${platform}連携に失敗しました`
              );
            }
          },
        });
      });

      if ("id" in result) {
        navigate("AuthSnsSignup", {
          id: result.id,
          email: result.email,
          name: result.commonName,
          sns_type: platform,
        });
      } else if ("token" in result) {
        await onLogin(result, setInfluencerId);
      } else {
        throw new Error(JSON.stringify(result));
      }
    } catch (error: unknown) {
      onError();
    }
  }, [
    code,
    codeVerifier,
    loginCommit,
    loading,
    onError,
    platform,
    setInfluencerId,
  ]);

  useEffect(() => {
    login();
  }, [loading, login]);

  return (
    <>
      <Loading />
      <Spacer height={16} />
      <Text style={styles.text}>連携中...</Text>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    ...Fonts.xlb100,
  },
});
