import React from "react";
import { StyleSheet } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import AuthReissue from "@components/templates/AuthReissue";

export default function AuthReissueScreen(): JSX.Element {
  return (
    <View style={styles.content}>
      <NavigationBar onBack={goBack} title="パスワードを忘れた方" />
      <Spacer height={8} />
      <AuthReissue />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});
