import React, { useState, useCallback } from "react";
import {
  View,
  Animated,
  ViewStyle,
  TouchableWithoutFeedback,
  StyleSheet,
  StyleProp,
  Platform,
  Easing,
  GestureResponderEvent,
} from "react-native";
import Colors from "@constants/Colors";

type Props = {
  onPress: () => void;
  rippleColor?: string;
  rippleDuration?: number;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
};

const MaxOpacity = 0.4;

export default function Ripple({
  onPress,
  rippleColor = Colors.black,
  rippleDuration = 400,
  children,
  style,
  disabled = false,
}: Props) {
  const [lastPress, setLastPress] = useState<number>(0);
  const [opacity] = useState<Animated.Value>(new Animated.Value(MaxOpacity));
  const [top] = useState<Animated.Value>(new Animated.Value(0));
  const [left] = useState<Animated.Value>(new Animated.Value(0));
  const [height] = useState<Animated.Value>(new Animated.Value(0));
  const [width] = useState<Animated.Value>(new Animated.Value(0));
  const [borderRedius] = useState<Animated.Value>(new Animated.Value(0));
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  const handlePress = useCallback(
    ({ endTop, endLeft }: { endTop: number; endLeft: number }) => {
      if (disabled) {
        return;
      }
      const delta = new Date().getTime() - lastPress;
      const easing = Easing.circle;
      if (delta > rippleDuration) {
        setLastPress(new Date().getTime());
        const animate = Animated.parallel([
          Animated.timing(opacity, {
            toValue: 0,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
          Animated.timing(height, {
            toValue: maxHeight,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
          Animated.timing(width, {
            toValue: maxWidth,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
          Animated.timing(top, {
            toValue: endTop,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
          Animated.timing(left, {
            toValue: endLeft,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
          Animated.timing(borderRedius, {
            toValue: maxWidth / 2,
            duration: rippleDuration,
            useNativeDriver: false,
            easing,
          }),
        ]);
        animate.start(() => {
          height.setValue(0);
          width.setValue(0);
          borderRedius.setValue(0);
          opacity.setValue(MaxOpacity);
          animate.stop();
          onPress();
        });
      }
    },
    [
      borderRedius,
      disabled,
      height,
      lastPress,
      left,
      maxHeight,
      maxWidth,
      onPress,
      opacity,
      rippleDuration,
      top,
      width,
    ]
  );

  const onClick = useCallback(
    (event: GestureResponderEvent) => {
      let locationY = 0;
      let locationX = 0;

      if (Platform.OS !== "web") {
        locationY = event.nativeEvent.locationY;
        locationX = event.nativeEvent.locationX;
      } else {
        const nativeEvent = event.nativeEvent as unknown as PointerEvent;
        locationY = nativeEvent.offsetY;
        locationX = nativeEvent.offsetX;
      }
      top.setValue(locationY);
      left.setValue(locationX);
      const endTop = locationY - maxWidth / 2;
      const endLeft = locationX - maxWidth / 2;
      handlePress({ endTop, endLeft });
    },
    [handlePress, left, maxWidth, top]
  );

  return (
    <TouchableWithoutFeedback onPress={onClick}>
      <View
        onLayout={({ nativeEvent }) => {
          const maxRippleRadius = Math.sqrt(
            nativeEvent.layout.width ** 2 + nativeEvent.layout.height ** 2
          );

          setMaxWidth(maxRippleRadius * 2);
          setMaxHeight(maxRippleRadius * 2);

          setLoaded(true);
        }}
        style={[style, styles.contaner]}
      >
        {children}
        {loaded && (
          <Animated.View
            style={[
              styles.ripple,
              {
                borderRadius: borderRedius,
                width,
                height,
                opacity,
                backgroundColor: rippleColor,
                top,
                left,
              },
            ]}
          />
        )}
        <View style={styles.overlay} />
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  contaner: {
    overflow: "hidden",
  },
  ripple: {
    position: "absolute",
    zIndex: 9999999,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});
