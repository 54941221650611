import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Text, View } from "@components/atoms/Themed";
import { StyleSheet } from "react-native";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";

type Props = {
  /** 締切日 */
  day: string | Date | null;
};

export default function DeadLine({ day }: Props) {
  const [deadline, setDeadline] = useState<string | null>(null);
  useEffect(() => {
    if (day !== null) {
      const restDay = dayjs(dayjs(day).startOf("day")).diff(
        dayjs().startOf("day"),
        "day"
      );
      if (restDay < 0) {
        setDeadline("募集締め切り");
      } else if (restDay < 1) {
        setDeadline(`残り${dayjs().diff(dayjs(day), "hour")}時間`);
      } else if (restDay <= 3) {
        setDeadline(`残り${restDay}日`);
      }
    }
  }, [day]);
  if (deadline !== null) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{deadline}</Text>
      </View>
    );
  }
  return null;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 4,
    paddingRight: 4,
    height: 24,
    borderRadius: 4,
    backgroundColor: Colors.orange,
  },
  end: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 13.5,
    paddingVertical: 6.5,
    height: 24,
    borderRadius: 4,
    backgroundColor: Colors.gray,
  },
  text: {
    ...Fonts.sb100,
    justifyContent: "center",
    color: Colors.white,
  },
});
