/**
 * @generated SignedSource<<14a6893dbf22e0c2ae3cced1454a82f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MyPageSnsUnlinkMutation$variables = {
  tiktokUrl?: string | null;
  ytChannel?: string | null;
};
export type MyPageSnsUnlinkMutation$data = {
  readonly updateInfluencerInfo: {
    readonly __typename: "Influencer";
    readonly profile: {
      readonly tiktokUrl: string | null;
      readonly ytChannel: string | null;
    };
  } | {
    readonly __typename: "UpdateInfluencerInfoError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MyPageSnsUnlinkMutation = {
  response: MyPageSnsUnlinkMutation$data;
  variables: MyPageSnsUnlinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tiktokUrl"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ytChannel"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "tiktokUrl",
        "variableName": "tiktokUrl"
      },
      {
        "kind": "Variable",
        "name": "ytChannel",
        "variableName": "ytChannel"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tiktokUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ytChannel",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UpdateInfluencerInfoError",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPageSnsUnlinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencerInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InfluencerInfo",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyPageSnsUnlinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencerInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InfluencerInfo",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cffe55717b8c11209abab7aa6274928",
    "id": null,
    "metadata": {},
    "name": "MyPageSnsUnlinkMutation",
    "operationKind": "mutation",
    "text": "mutation MyPageSnsUnlinkMutation(\n  $tiktokUrl: String\n  $ytChannel: String\n) {\n  updateInfluencerInfo(input: {tiktokUrl: $tiktokUrl, ytChannel: $ytChannel}) {\n    __typename\n    ... on Influencer {\n      profile {\n        tiktokUrl\n        ytChannel\n        id\n      }\n      id\n    }\n    ... on UpdateInfluencerInfoError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "acf8cc3c76c0b1c78b0ba2a3b2640feb";

export default node;
