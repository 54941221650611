import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useImperativeHandle,
} from "react";
import {
  View as RnView,
  TouchableOpacity,
  Animated,
  TouchableWithoutFeedback,
  Platform,
  Modal,
} from "react-native";
import Icon from "@components/atoms/Icon";
import { Text, View, Card } from "@components/atoms/Themed";
import { Picker, PickerItemProps } from "@react-native-picker/picker";
import _ from "lodash";
import { useTheme } from "@react-navigation/native";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import { CommonProps } from "@components/molecules/TextInput/types";
import styles from "@components/molecules/TextInput/style";
import { Controller } from "react-hook-form";

export type Item = {
  label: string;
  value: string | number;
};

export type SelectboxRef = {
  updateDisplay: (value: string | number | null) => void;
};

const AnimationPosition = 500;
const AnimationDuration = 200;

interface Props extends CommonProps {
  items: PickerItemProps[];
  emptyValue?: string | number | null;
  initialValue?: string | number | null;
  onChange?: (value: string | number | null) => void;
  innerRef?: React.MutableRefObject<SelectboxRef | undefined>;
}

export default function Selectbox({
  items,
  initialValue,
  name,
  control,
  placeholder = "選択してください",
  label,
  disabled,
  required,
  emptyValue,
  onChange,
  innerRef,
}: Props) {
  const { colors, dark } = useTheme();
  const backgroundColor = dark ? colors.background : colors.card;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<
    string | number | null | undefined
  >(initialValue);
  const [animate] = useState<Animated.Value>(
    new Animated.Value(AnimationPosition)
  );

  const animateItem = useCallback((): void => {
    const addAnimation = Animated.timing(animate, {
      toValue: open ? 0 : AnimationPosition,
      duration: AnimationDuration,
      useNativeDriver: false,
    });

    addAnimation.start(() => {
      addAnimation.stop();
    });
  }, [open, animate]);

  useImperativeHandle(innerRef, () => ({
    updateDisplay(updateData: string | number | null) {
      setSelectedValue(updateData);
    },
  }));

  useEffect(() => {
    animateItem();
  }, [open, animateItem]);

  const displayName = useMemo<string>(() => {
    const display = _.head(items.filter((row) => row.value === selectedValue));
    return display !== undefined && display.label !== undefined
      ? display.label
      : placeholder;
  }, [items, selectedValue, placeholder]);

  const onClose = useCallback((): void => {
    const addAnimation = Animated.timing(animate, {
      toValue: AnimationPosition,
      duration: AnimationDuration,
      useNativeDriver: false,
    });

    addAnimation.start(() => {
      addAnimation.stop();
      setOpen(false);
    });
  }, [animate]);

  return (
    <View style={styles.container}>
      {label !== undefined && (
        <OuterLabel label={label} required={required === true} />
      )}

      {Platform.OS === "web" ? (
        <Controller
          control={control}
          name={name}
          render={({
            field: { value, onBlur, onChange: onChangeValue },
            fieldState: { invalid },
          }) => (
            <RnView id="selectWrapper">
              <Picker
                enabled={disabled !== true}
                onBlur={onBlur}
                onValueChange={(selected: string | number | null) => {
                  onChangeValue(selected);
                  setSelectedValue(selected);
                  if (onChange !== undefined) {
                    onChange(selected);
                  }
                  onBlur();
                }}
                selectedValue={value === null ? undefined : value}
                style={[
                  { color: colors.text },
                  invalid ? [styles.form, styles.formError] : styles.form,
                  disabled === true ? styles.disabled : { backgroundColor },
                ]}
              >
                {emptyValue !== undefined && (
                  <Picker.Item
                    color={colors.text}
                    label={placeholder}
                    value={emptyValue}
                  />
                )}
                {items.map((row) => (
                  <Picker.Item
                    key={`${row.label}-${row.value}`}
                    color={colors.text}
                    label={row.label}
                    value={row.value}
                  />
                ))}
              </Picker>
            </RnView>
          )}
        />
      ) : (
        <>
          <TouchableOpacity
            onPress={() => {
              if (disabled !== true) {
                setOpen(true);
              }
            }}
            style={[
              styles.form,
              disabled === true ? styles.disabled : { backgroundColor },
            ]}
          >
            <Text style={[styles.text, { color: colors.text }]}>
              {displayName}
            </Text>
            <View style={styles.icon}>
              <Icon color={colors.text} name="down" size={24} />
            </View>
          </TouchableOpacity>
          <Modal
            animationType="none"
            onRequestClose={onClose}
            transparent
            visible={open}
          >
            <View style={styles.modal}>
              <Card>
                <Controller
                  control={control}
                  name={name}
                  render={({
                    field: { value, onBlur, onChange: onChangeValue },
                  }) => (
                    <Picker
                      onBlur={onBlur}
                      onValueChange={(selected: string | number | null) => {
                        onChangeValue(selected);
                        setSelectedValue(selected);
                        if (onChange !== undefined) {
                          onChange(selected);
                        }
                        onBlur();
                      }}
                      selectedValue={value === null ? undefined : value}
                      style={{
                        color: colors.text,
                        backgroundColor: colors.background,
                      }}
                    >
                      {emptyValue !== undefined && (
                        <Picker.Item label={placeholder} value={emptyValue} />
                      )}

                      {items.map((row) => (
                        <Picker.Item
                          key={`${row.label}-${row.value}`}
                          color={colors.text}
                          label={row.label}
                          value={row.value}
                        />
                      ))}
                    </Picker>
                  )}
                />
              </Card>

              <TouchableWithoutFeedback onPress={onClose}>
                <RnView style={styles.backgroundLayer} />
              </TouchableWithoutFeedback>
            </View>
          </Modal>
        </>
      )}
    </View>
  );
}
