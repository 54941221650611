/**
 * @generated SignedSource<<b73a4b64786849ca0a376ecd3632ae8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type ChatStatus$data = {
  readonly campaign: {
    readonly hasDelivery: boolean;
    readonly hasExperienceGift: boolean;
    readonly mediaType: MediaType;
    readonly prType: CampaignPrType | null;
  };
  readonly status: PostStatus;
  readonly " $fragmentType": "ChatStatus";
};
export type ChatStatus$key = {
  readonly " $data"?: ChatStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatStatus">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasDelivery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasExperienceGift",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "b1f5742192e83343b6f8728d4360e562";

export default node;
