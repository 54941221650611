import React from "react";
import { StyleSheet } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import AuthLogout from "@components/templates/AuthLogOut";

export default function AuthLogoutScreen(): JSX.Element {
  return (
    <View style={styles.content}>
      <NavigationBar onBack={goBack} title="ログアウト" />
      <Spacer height={8} />
      <AuthLogout />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});
