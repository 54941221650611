import React, { useMemo } from "react";
import { useTheme } from "@react-navigation/native";
import { View, StyleSheet, DimensionValue } from "react-native";

type Props = {
  /** dividerの色を指定。色は `@constants/Colors` の定数を使用してください */
  color?: string;
  /** サイズを指定 */
  width?: DimensionValue;
  /** サイズを指定 */
  height?: DimensionValue;
};

export default function Divider({ color, width = "100%", height = 1 }: Props) {
  const { colors } = useTheme();
  const dynamicStyle = useMemo(
    () =>
      StyleSheet.create({
        divider: {
          backgroundColor: color ?? colors.border,
          width,
          height,
        },
      }),
    [color, colors, width, height]
  );
  return <View style={dynamicStyle.divider} />;
}
