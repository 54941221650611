/**
 * @generated SignedSource<<9da15c735fbdde94a992d35b3793ad63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailConfirmMutationInput = {
  clientMutationId?: string | null;
  code: string;
  token: string;
};
export type EmailEditConfirmChangedMutation$variables = {
  input: EmailConfirmMutationInput;
};
export type EmailEditConfirmChangedMutation$data = {
  readonly emailConfirm: {
    readonly __typename: "EmailConfirmError";
    readonly message: string;
  } | {
    readonly __typename: "Influencer";
    readonly email: string;
    readonly newEmail: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type EmailEditConfirmChangedMutation = {
  response: EmailEditConfirmChangedMutation$data;
  variables: EmailEditConfirmChangedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newEmail",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "EmailConfirmError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailEditConfirmChangedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "emailConfirm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailEditConfirmChangedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "emailConfirm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea0d924742a1c77631f8cdb3f1f2daf2",
    "id": null,
    "metadata": {},
    "name": "EmailEditConfirmChangedMutation",
    "operationKind": "mutation",
    "text": "mutation EmailEditConfirmChangedMutation(\n  $input: EmailConfirmMutationInput!\n) {\n  emailConfirm(input: $input) {\n    __typename\n    ... on Influencer {\n      newEmail\n      email\n      id\n    }\n    ... on EmailConfirmError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ee1c4ed45439a9d2c6d7aeeffd53cca";

export default node;
