import {
  StackActions,
  createNavigationContainerRef,
} from "@react-navigation/native";
import { Platform } from "react-native";
import pathConfig from "@navigation/pathConfig";
import { screenView as analyticsView } from "@lib/util/analytics";
import { screenView as clarityView } from "@lib/util/clarity";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import { getActionFromState, getStateFromPath } from "@react-navigation/core";
import { UniversalLinkPrefix } from "@constants/App";
import extractPathFromURL from "@react-navigation/native/src/extractPathFromURL";
import {
  RootStackParamList,
  RootTabParamList,
  initialRoute,
  initialTabScreen,
  SnsSignupUser,
} from "../types";

type Screens = {
  screen: keyof RootTabParamList;
  params?: unknown;
};

type Id = {
  id: string;
};

type Tab = {
  tab: string;
};

type Params = Screens | Id | Tab | SnsSignupUser | undefined;

export const navigationRef = createNavigationContainerRef();

function screenView(screen: string) {
  analyticsView(screen);
  clarityView(screen);
}

async function isSignin(): Promise<boolean> {
  const token = await SecureStoreManager.getAccessToken();
  return token !== null;
}

export function navigate(
  screen: keyof RootStackParamList,
  params?: Params
): void {
  if (navigationRef.isReady()) {
    // TODO: anyになっているので型定義を修正する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigationRef.navigate(screen as any, params);
    screenView(createPath(screen, params));
  }
}

export function replace(
  screen: keyof RootStackParamList,
  params?: Params
): void {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.replace(screen, params));
    screenView(createPath(screen, params));
  }
}

export async function goBack(): Promise<void> {
  if (navigationRef.isReady()) {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack();
    } else if (await isSignin()) {
      navigationRef.dispatch(
        StackActions.replace(initialRoute, { screen: initialTabScreen })
      );
    } else {
      navigationRef.dispatch(StackActions.replace("Auth"));
    }
  }
}

export function getCurrentRoute() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute();
  }
  return null;
}

export function canGoBack() {
  if (navigationRef.isReady()) {
    return navigationRef.canGoBack();
  }
  return false;
}

export function popToTop(): void {
  if (navigationRef.isReady() && Platform.OS !== "web") {
    navigationRef.dispatch(StackActions.popToTop);
  }
}

export function resetToRoot(): void {
  if (navigationRef.isReady()) {
    navigationRef.reset({
      index: 0,
      routes: [{ name: initialRoute, params: { screen: initialTabScreen } }],
    });
    screenView(createPath(initialRoute, { screen: initialTabScreen }));
  }
}

export function getPathFromUniversalLinks(url: string): string | null {
  const path = extractPathFromURL(UniversalLinkPrefix, url);
  if (path !== undefined && path !== url) {
    return path;
  }
  return null;
}

export function navigateByUrl(path: string): void {
  const state = getStateFromPath(path, pathConfig);
  if (state !== undefined) {
    const action = getActionFromState(state, pathConfig);
    if (navigationRef.isReady()) {
      if (action !== undefined) {
        navigationRef.dispatch({
          ...action,
          type: "REPLACE",
        });
      } else {
        navigationRef.reset(state);
      }
    }
  }
}

// FirebaseAnalyticsではURLをscreen_viewイベントに送信する
function createPath(screen: keyof RootStackParamList, params: Params) {
  if (params !== undefined) {
    if ("id" in params) {
      return `${screen}/${params.id}`;
    }
    if ("screen" in params) {
      return `${params.screen}`;
    }
  }

  return screen;
}
