/**
 * @generated SignedSource<<3c3b6e79be9ad4dac12c45d6de26fb01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignRelated$data = {
  readonly banners: ReadonlyArray<{
    readonly file: string | null;
  }>;
  readonly gifting: boolean;
  readonly id: string;
  readonly isFavoriteCheck: boolean;
  readonly mCategory: {
    readonly name: string;
  };
  readonly parentMediaType: ParentMediaType;
  readonly prType: CampaignPrType | null;
  readonly socialNetwork: {
    readonly recruitmentEndOn: any | null;
  };
  readonly title: string | null;
  readonly " $fragmentType": "CampaignRelated";
};
export type CampaignRelated$key = {
  readonly " $data"?: CampaignRelated$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignRelated">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignRelated",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentMediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gifting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavoriteCheck",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Banner",
      "kind": "LinkedField",
      "name": "banners",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "file",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MCategory",
      "kind": "LinkedField",
      "name": "mCategory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialNetwork",
      "kind": "LinkedField",
      "name": "socialNetwork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recruitmentEndOn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "121ccd3c12e7e168c0fbc7f2e8fef17a";

export default node;
