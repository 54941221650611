import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import {
  companyUrl,
  appVersion,
  questionUrl,
  runtimeVersion,
  env,
  cookiePolicyUrl,
  externalTransmissionPolicyUrl,
} from "@constants/Env";
import openContact from "@lib/util/openContact";
import Icon from "@components/atoms/Icon";
import * as WebBrowser from "expo-web-browser";
import { navigate } from "@navigation/navigate";
import { useTheme } from "@react-navigation/native";

export default function MyPageFooter() {
  const { colors } = useTheme();
  return (
    <Card style={styles.container}>
      <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync(companyUrl)}>
        <View style={[styles.item, styles.noBordered]}>
          <View style={styles.title}>
            <Text style={styles.text}>運営会社</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("TermsOfUse")}>
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>利用規約</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("PrivacyPolicy")}>
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>プライバシーポリシー</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          WebBrowser.openBrowserAsync(cookiePolicyUrl);
        }}
      >
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>クッキーポリシー</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          WebBrowser.openBrowserAsync(externalTransmissionPolicyUrl);
        }}
      >
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>外部送信ポリシー</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          WebBrowser.openBrowserAsync(questionUrl);
        }}
      >
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>よくあるご質問</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={openContact}>
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>お問い合わせ</Text>
          </View>
          <Icon color={colors.text} name="next" size={20} />
        </View>
      </TouchableOpacity>
      <View bordered style={styles.item}>
        <View style={styles.title}>
          <Text style={styles.text}>バージョン</Text>
        </View>
        <View>
          <Text style={styles.text}>ver {appVersion}</Text>
        </View>
      </View>
      {env !== "production" && (
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>RunTimeVersion</Text>
          </View>
          <View>
            <Text style={styles.text}>ver {String(runtimeVersion)}</Text>
          </View>
        </View>
      )}
      {env !== "production" && (
        <View bordered style={styles.item}>
          <View style={styles.title}>
            <Text style={styles.text}>環境</Text>
          </View>
          <View>
            <Text style={styles.text}>{env}</Text>
          </View>
        </View>
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  noBordered: {
    borderTopWidth: 0,
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
});
