import React, { useMemo } from "react";
import { View, Text, Card } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity, ImageBackground } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import CustomIcon from "@components/atoms/Icon";
import FavoriteButton from "@components/molecules/FavoriteButton";
import DeadLine from "@components/atoms/DeadLine";
import Tag from "@components/atoms/Tag";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import campaignBanner from "@lib/util/campaignBanner";
import { LinearGradient } from "expo-linear-gradient";
import { CampaignCampaign$key } from "@generated/CampaignCampaign.graphql";
import { useFragment, graphql } from "react-relay/hooks";
import dayjs from "dayjs";

const campaignsCard = graphql`
  fragment CampaignCampaign on Campaign {
    id
    parentMediaType
    title
    gifting
    prType
    isFavoriteCheck
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentEndOn
    }
  }
`;

export default function Campaign({
  campaignFragment,
  campaignDetailOnPress,
  withImageGradient,
}: {
  campaignFragment: CampaignCampaign$key;
  campaignDetailOnPress: () => void;
  withImageGradient?: boolean;
}) {
  const data = useFragment(campaignsCard, campaignFragment);
  const isExpired = useMemo<boolean>(() => {
    const restDay = dayjs(
      dayjs(data.socialNetwork.recruitmentEndOn).startOf("day")
    ).diff(dayjs().startOf("day"), "day");
    return restDay < 0;
  }, [data]);

  return (
    <TouchableOpacity
      onPress={campaignDetailOnPress}
      style={{ ...styles.container, opacity: isExpired ? 0.6 : 1.0 }}
    >
      <Card>
        <ImageBackground
          source={campaignBanner(data.banners)}
          style={styles.image}
        >
          <View style={styles.head}>
            <View style={styles.headWrapper}>
              <SNSIcons name={data.parentMediaType} style={styles.platform} />
              <FavoriteButton
                buttonSize={24}
                campaignId={data.id}
                iconSize={18}
                isFavoriteCheck={data.isFavoriteCheck}
              />
            </View>
          </View>
          {withImageGradient === true && (
            <LinearGradient
              colors={Colors.grBlack60}
              end={{
                x: 0,
                y: 0.4868,
              }}
              start={{
                x: 0,
                y: 0,
              }}
              style={styles.imageGr}
            />
          )}
        </ImageBackground>

        <View style={styles.campaignExplanation}>
          <Tag>
            <Text style={styles.categoryText}>{data.mCategory.name}</Text>
          </Tag>
          <Spacer height={8} />
          <View style={styles.title}>
            <Text numberOfLines={3} style={styles.explanation}>
              {data.title}
            </Text>
          </View>
          <View style={styles.tail}>
            <View style={styles.reward}>
              {data.gifting && data.prType === "rewarded" ? (
                <>
                  <CustomIcon color={Colors.gray} name="present" size={18} />
                  <Spacer width={14} />
                  <CustomIcon color={Colors.gray} name="money" size={18} />
                </>
              ) : data.gifting ? (
                <CustomIcon color={Colors.gray} name="present" size={18} />
              ) : data.prType === "rewarded" ? (
                <CustomIcon color={Colors.gray} name="money" size={18} />
              ) : null}
            </View>
            <DeadLine day={data.socialNetwork.recruitmentEndOn} />
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 296,
    width: "100%",
  },
  image: {
    position: "relative",
    height: 146,
    width: "100%",
  },
  head: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  headWrapper: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageGr: {
    height: "100%",
    width: "100%",
  },
  platform: {
    height: 24,
    width: 24,
  },
  campaignExplanation: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: 150,
    padding: 16,
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.green,
  },
  title: {
    flex: 1,
  },
  explanation: {
    ...Fonts.lb130,
  },
  tail: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  reward: {
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 24,
    flex: 1,
  },
});
