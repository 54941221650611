import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Icon from "@components/atoms/Icon";
import Tag from "@components/atoms/Tag";
import { navigate } from "@navigation/navigate";
import { graphql, useFragment } from "react-relay/hooks";
import { PersonalInformation$key } from "@generated/PersonalInformation.graphql";
import Spacer from "@components/atoms/Spacer";
import { useTheme } from "@react-navigation/native";
import _ from "lodash";

const personalInformation = graphql`
  fragment PersonalInformation on Influencer {
    firstName
    lastName
    firstNameKana
    lastNameKana
    phoneNumber
    email
    hasPassword
    prefectureName
    cityName
    townName
    bankAccount {
      id
    }
    profile {
      gender
      birthday
      invoiceStatus
    }
  }
`;

export default function PersonalInformation({
  influencerInfoFragment,
}: {
  influencerInfoFragment: PersonalInformation$key;
}) {
  const { colors } = useTheme();
  const data = useFragment(personalInformation, influencerInfoFragment);

  const fillPeronalInfo = useMemo<boolean>(
    () =>
      !!(
        !_.isEmpty(data.firstName) &&
        !_.isEmpty(data.lastName) &&
        !_.isEmpty(data.firstNameKana) &&
        !_.isEmpty(data.lastNameKana)
      ),
    [data]
  );

  const fillAddress = useMemo<boolean>(
    () =>
      !!(
        !_.isEmpty(data.prefectureName) &&
        !_.isEmpty(data.cityName) &&
        !_.isEmpty(data.townName)
      ),
    [data]
  );

  return (
    <Wrapper>
      <Card style={styles.menu}>
        <TouchableOpacity onPress={() => navigate("PersonalInformationEdit")}>
          <View style={[styles.item, styles.noBordered]}>
            <View style={styles.title}>
              <Text style={styles.text}>名前</Text>
              {!fillPeronalInfo && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("AddressEdit")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>登録住所</Text>
              {!fillAddress && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("PhoneEdit")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>電話番号</Text>
              {_.isEmpty(data.phoneNumber) && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("EmailEdit")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>メールアドレス</Text>
              {_.isEmpty(data.email) && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigate("Password")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>パスワード</Text>
              {!_.isEmpty(data.email) && data.hasPassword !== true && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("SnsLogin")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>SNSログイン</Text>
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("Invoice")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>インボイス</Text>
              {data.profile.invoiceStatus === "unset" && (
                <>
                  <Spacer width={16} />
                  <Tag backgroundColor={Colors.orange} color={Colors.orange}>
                    <Text style={styles.alert}>未設定</Text>
                  </Tag>
                </>
              )}
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>
      </Card>

      <Spacer height={16} />

      <Card style={styles.menu}>
        <TouchableOpacity onPress={() => navigate("AuthLogout")}>
          <View style={[styles.item, styles.noBordered]}>
            <View style={styles.title}>
              <Text style={styles.text}>ログアウト</Text>
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("AccountDeletion")}>
          <View bordered style={styles.item}>
            <View style={styles.title}>
              <Text style={styles.text}>アカウント削除</Text>
            </View>
            <Icon color={colors.text} name="next" size={20} />
          </View>
        </TouchableOpacity>
      </Card>
    </Wrapper>
  );
}

const styles = StyleSheet.create({
  menu: {
    paddingHorizontal: 16,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  noBordered: {
    borderTopWidth: 0,
  },
  page: {
    ...Fonts.xlb160,
  },
  title: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  alert: {
    ...Fonts.sb100,
    color: Colors.white,
  },
});
