import React, { useEffect, useState, Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import ChangePassword from "@components/templates/ChangePassword";
import { graphql, useMutation } from "react-relay/hooks";
import { ChangePasswordScreenCheckMutation } from "@generated/ChangePasswordScreenCheckMutation.graphql";
import Dialog from "@components/molecules/dialog/Dialog";
import Colors from "@constants/Colors";
import { replace } from "@navigation/navigate";
import { RootStackScreenProps } from "../types";

const checkMutation = graphql`
  mutation ChangePasswordScreenCheckMutation(
    $input: CheckChangePasswordTokenMutationInput!
  ) {
    checkChangePasswordTokenMutation(input: $input) {
      __typename
      ... on CheckChangePasswordToken {
        message
      }
    }
  }
`;

export default function PasswordScreen({
  route,
}: RootStackScreenProps<"ChangePassword">): JSX.Element {
  const [commit] =
    useMutation<ChangePasswordScreenCheckMutation>(checkMutation);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    commit({
      variables: {
        input: {
          resetPasswordToken: route.params.token,
        },
      },
      onCompleted({ checkChangePasswordTokenMutation }) {
        if (
          checkChangePasswordTokenMutation.__typename ===
          "CheckChangePasswordToken"
        ) {
          setError(checkChangePasswordTokenMutation.message);
        }
      },
    });
  }, [commit, route]);

  return (
    <View style={styles.container}>
      {error !== null && (
        <Dialog
          buttons={[{ title: "OK", type: "white" }]}
          customStyle={{ messageStyle: styles.orangeText }}
          message="トークンエラー"
          onClose={() => replace("Auth")}
          subline={error}
        />
      )}
      <NavigationBar title="パスワード" />
      <Suspense fallback={<Loading size="large" />}>
        <ChangePassword token={route.params.token} />
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  orangeText: {
    color: Colors.orange,
    textAlign: "center",
  },
});
