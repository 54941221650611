/**
 * @generated SignedSource<<04c5cddbdeee680dc1e5b759df5f4564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneEditScreenQuery$variables = {};
export type PhoneEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"PhoneEdit">;
  };
};
export type PhoneEditScreenQuery = {
  response: PhoneEditScreenQuery$data;
  variables: PhoneEditScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PhoneEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PhoneEdit"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PhoneEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newPhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2af5060c8ba14d75dd8d7116d2bed6e6",
    "id": null,
    "metadata": {},
    "name": "PhoneEditScreenQuery",
    "operationKind": "query",
    "text": "query PhoneEditScreenQuery {\n  influencer {\n    ...PhoneEdit\n    id\n  }\n}\n\nfragment PhoneEdit on Influencer {\n  ...PhoneEditConfirm\n  ...PhoneEditForm\n}\n\nfragment PhoneEditConfirm on Influencer {\n  newPhone\n}\n\nfragment PhoneEditForm on Influencer {\n  phoneNumber\n}\n"
  }
};

(node as any).hash = "65c1a633df5bbacc59057119e8e6226a";

export default node;
