import React from "react";
import { Text, Card } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import DialogButton from "@components/molecules/dialog/DialogButton";
import {
  StyleSheet,
  Modal,
  TouchableWithoutFeedback,
  View,
  TextStyle,
  StyleProp,
} from "react-native";
import { useTheme } from "@react-navigation/native";

type ButtonLabel = "閉じる" | "キャンセル" | "OK";
type Props = {
  /** 表示するメッセージ */
  message: string;
  /** 表示するサブのメッセージ */
  subline?: string;
  /** ダイアログを×ボタンで閉じた時のイベントハンドラ */
  onClose: () => void;
  /** 下部ボタンの詳細設定 */
  buttons?: {
    title: ButtonLabel | string /* 表示するボタンリスト */;
    onPress?: () => void /* onClose以外でボタン固有のイベントハンドラがある場合指定 */;
    type: "white" | "blueGradient" | "orange";
  }[];
  /** CSSカスタマイズ */
  customStyle?: {
    messageStyle?: StyleProp<TextStyle>;
    sublineStyle?: StyleProp<TextStyle>;
  };
};

export default function Dialog({
  message,
  subline,
  onClose,
  buttons,
  customStyle,
}: Props): JSX.Element {
  const { colors } = useTheme();
  return (
    <Modal animationType="none" onRequestClose={onClose} transparent visible>
      <View style={styles.container}>
        <Card
          style={[styles.message, { backgroundColor: colors.notification }]}
        >
          <Text style={[styles.title, customStyle?.messageStyle]}>
            {message}
          </Text>
          {subline !== undefined && (
            <View>
              <Spacer height={16} />
              <Text style={[styles.text, customStyle?.sublineStyle]}>
                {subline}
              </Text>
            </View>
          )}
          <Spacer height={24} />
          <View style={styles.buttons}>
            {buttons === undefined ? (
              <DialogButton onPress={onClose} title="閉じる" type="white" />
            ) : (
              buttons.map((btn, index) => {
                const key = `dialog-button-${index}`;
                return (
                  <DialogButton
                    key={key}
                    /* 2つ目以降は前にspaceを付与 */
                    hasPreSpace={index > 0}
                    onPress={btn.onPress !== undefined ? btn.onPress : onClose}
                    title={btn.title}
                    type={btn.type}
                  />
                );
              })
            )}
          </View>
        </Card>
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={styles.backgroundLayer} />
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 10,
  },
  backgroundLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: Colors.overlayDark,
  },
  message: {
    padding: 16,
    borderRadius: 6,
    maxWidth: 345,
    width: "90%",
  },
  title: {
    ...Fonts.xxlb140,
    textAlign: "center",
  },
  text: {
    ...Fonts.xlr160,
  },
  buttons: {
    height: 50,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
