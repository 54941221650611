/**
 * @generated SignedSource<<bce6ea3a6f412eba9cd4a373983adf3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplyProduct$data = {
  readonly giftingDetail: string | null;
  readonly " $fragmentType": "ApplyProduct";
};
export type ApplyProduct$key = {
  readonly " $data"?: ApplyProduct$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyProduct">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyProduct",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "giftingDetail",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "789053d1af09079dfcf2cc4c31c0df4c";

export default node;
