import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";

export default function Spacer({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) {
  const dynamicStyle = useMemo(
    () =>
      StyleSheet.create({
        spacer: {
          width: width ?? 1,
          height: height ?? 1,
        },
      }),
    [width, height]
  );
  return <View style={dynamicStyle.spacer} />;
}
