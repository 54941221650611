import React, { useCallback } from "react";
import useMessage, { MessageData } from "@hooks/useMessage";
import Dialog from "@components/molecules/dialog/Dialog";
import Toast from "@components/atoms/Toast";
import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";

export default function MessageScreen({ message }: { message: MessageData }) {
  const { set: setMessage } = useMessage();
  const clear = useCallback(() => {
    setMessage(null);
  }, [setMessage]);

  if (message.mode === "dialiog") {
    return (
      <Dialog
        message={message.title}
        onClose={clear}
        subline={message.subline}
      />
    );
  }
  if (message.mode === "error") {
    return (
      <Dialog
        customStyle={{ messageStyle: styles.dialogError }}
        message={message.title}
        onClose={clear}
        subline={message.subline}
      />
    );
  }
  return <Toast message={message.title} onClose={clear} />;
}

const styles = StyleSheet.create({
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
