import React, { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { PostReport$key, PostReport$data } from "@generated/PostReport.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import Youtube from "@components/organisms/PostReport/PostReportYoutube";
import Tiktok from "@components/organisms/PostReport/PostReportTiktok";
import Twitter from "@components/organisms/PostReport/PostReportTwitter";
import PostReportInstagram from "@components/organisms/PostReport/PostReportInstagram";
import Cast from "@components/organisms/PostReport/PostReportCast";
import Other from "@components/organisms/PostReport/PostReportOther";
import { goBack, navigate, replace, canGoBack } from "@navigation/navigate";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import Dialog from "@components/molecules/dialog/Dialog";
import Loading from "@components/atoms/Loading";

const chatReport = graphql`
  fragment PostReport on Post {
    id
    campaign {
      mediaType
    }
    ...PostReportTwitter
    ...PostReportYoutube
    ...PostReportInstagram
    ...PostReportOther
    ...PostReportCast
    ...PostReportTiktok
  }
`;

function Content({
  data,
  setChange,
  setIsLoading,
}: {
  data: PostReport$data;
  setChange: (isChange: boolean) => void;
  setIsLoading: (loading: boolean) => void;
}) {
  const onConfirm = useCallback(() => {
    navigate("PostDraft", { id: data.id });
  }, [data]);

  switch (data.campaign.mediaType) {
    case "youtube":
      return (
        <Youtube
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "twitter_image":
    case "twitter_video":
      return (
        <Twitter
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "tiktok":
      return (
        <Tiktok
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "ins_story":
    case "ins_highlight":
      return (
        <PostReportInstagram
          hasFeed
          hasStory
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "ins_live":
      return (
        <PostReportInstagram
          hasLive
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "ins_video":
      return (
        <PostReportInstagram
          hasReel
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "ins_image":
      return (
        <PostReportInstagram
          hasFeed
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "cast":
      return (
        <Cast
          onChange={setChange}
          onClose={goBack}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    case "other":
      return (
        <Other
          onChange={setChange}
          onClose={goBack}
          onConfirm={onConfirm}
          onLoading={setIsLoading}
          postFragment={data}
        />
      );
    default:
      return null;
  }
}

export default function PostReport({
  postFragment,
}: {
  postFragment: PostReport$key;
}) {
  const data = useFragment(chatReport, postFragment);
  const [cancel, setCancel] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const onClose = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      replace("Chat", { id: data.id });
    }
  }, [data]);

  return (
    <View style={styles.container}>
      <NavigationBar
        onClose={() => {
          if (isChange) {
            setCancel(true);
          } else {
            onClose();
          }
        }}
        title="完了報告"
      />
      <Content data={data} setChange={setIsChange} setIsLoading={setLoading} />
      {isChange && cancel && (
        <Dialog
          buttons={[
            { title: "キャンセル", type: "white" },
            {
              title: "チャットに戻る",
              type: "blueGradient",
              onPress: () => onClose(),
            },
          ]}
          message="変更が保存されていません"
          onClose={() => setCancel(false)}
          subline="保存せずにチャットに戻ると変更が破棄されます。よろしいですか？"
        />
      )}
      {loading && <Loading mask />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
