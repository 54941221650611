import React, {
  useState,
  useEffect,
  Suspense,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { StyleSheet, ScrollView } from "react-native";
import { TabView, SceneMap, NavigationState } from "react-native-tab-view";
import { Text, Wrapper, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Report from "@components/templates/Report";
import Loading from "@components/atoms/Loading";
import Ripple from "@components/atoms/Ripple";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { ReportYearlyScreenQuery } from "@generated/ReportYearlyScreenQuery.graphql";
import { fetchYears } from "@lib/util/date";
import _ from "lodash";
import dayjs from "dayjs";

const Periods = fetchYears().map((year) => `${year}-01-01`);

type Route = {
  key: string;
  title: string;
};

const reportQuery = graphql`
  query ReportYearlyScreenQuery(
    $date: String!
    $searchType: ReportSearchType!
  ) {
    sales(date: $date, searchType: $searchType) {
      ...ReportSales
    }
    ...ReportPostList @arguments(date: $date, searchType: $searchType)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ReportYearlyScreenQuery>;
}) {
  const data = usePreloadedQuery<ReportYearlyScreenQuery>(
    reportQuery,
    queryReference
  );

  return (
    <Report postFragments={data} salesFragment={data.sales} searchType="year" />
  );
}

function TabBar(
  params: NavigationState<Route>,
  onTabPress: (index: number) => void
) {
  const { routes, index } = params;
  const scrollViewRef = useRef<ScrollView>(null);
  return (
    <Wrapper>
      <View style={styles.tabs}>
        <ScrollView
          ref={scrollViewRef}
          horizontal
          onContentSizeChange={() =>
            scrollViewRef?.current?.scrollToEnd({ animated: false })
          }
          showsHorizontalScrollIndicator={false}
        >
          <Card style={styles.tabContent}>
            {routes.map(({ key, title }, page) => (
              <Ripple
                key={key}
                onPress={() => onTabPress(page)}
                style={[styles.tabItem, page === index ? styles.active : null]}
              >
                <Text
                  style={[
                    styles.tabText,
                    page === index ? styles.activeText : null,
                  ]}
                >
                  {title}
                </Text>
              </Ripple>
            ))}
          </Card>
        </ScrollView>
      </View>
    </Wrapper>
  );
}

export default function ReportYearlyScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ReportYearlyScreenQuery>(reportQuery);
  const [index, setIndex] = useState(Periods.length - 1);
  const [routes] = useState<Route[]>(
    Periods.map((date) => ({
      key: date,
      title: dayjs(date).format("YYYY年"),
    }))
  );
  const [date, setDate] = useState<string>(dayjs().format("YYYY-01-01"));

  useEffect(() => {
    loadQuery({ date, searchType: "year" }, { fetchPolicy: "network-only" });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, date]);

  const renderScene = useMemo(() => {
    const screens: { [index: string]: () => JSX.Element } = {};
    Periods.forEach((row) => {
      screens[row] = () =>
        dayjs(row).format("YYYY-01-01") === date ? (
          <Suspense fallback={<Loading size="large" />}>
            {!_.isNil(queryReference) && (
              <ScreenContent queryReference={queryReference} />
            )}
          </Suspense>
        ) : (
          <Loading />
        );
    });
    return SceneMap(screens);
  }, [queryReference, date]);

  const changeTab = useCallback(
    (screen: number) => {
      if (routes[screen] !== null) {
        setDate(routes[screen].key);
        setIndex(screen);
      }
    },
    [routes]
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      onIndexChange={changeTab}
      renderScene={renderScene}
      renderTabBar={({ navigationState }) => TabBar(navigationState, changeTab)}
    />
  );
}

const styles = StyleSheet.create({
  tabs: {
    height: 44,
  },
  tabContent: {
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tabItem: {
    borderBottomWidth: 2,
    borderColor: Colors.transparent,
    borderStyle: "solid",
    width: 120,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    borderColor: Colors.green,
  },
  tabText: {
    color: Colors.black,
    textAlign: "center",
    ...Fonts.xlr100,
  },
  activeText: {
    color: Colors.green,
  },
  link: {
    color: Colors.blue,
    ...Fonts.xlr100,
  },
});
