import React from "react";
import { SupportType, ActionType } from "@lib/util/oauth";
import OAuthUnlink from "@components/molecules/OAuth/OAuthUnlink";
import OAuthLink from "@components/molecules/OAuth/OAuthLink";

type Props = {
  type: SupportType;
  action: ActionType;
  callbackUrl?: string;
  children: React.ReactNode;
  active?: boolean;
  useGuide?: boolean;
};

export default function OAuth({
  type,
  action,
  callbackUrl,
  children,
  active = false,
  useGuide = false,
}: Props) {
  if (active) {
    return <OAuthUnlink type={type}>{children}</OAuthUnlink>;
  }

  return (
    <OAuthLink
      action={action}
      callbackUrl={callbackUrl}
      type={type}
      useGuide={useGuide}
    >
      {children}
    </OAuthLink>
  );
}
