/**
 * @generated SignedSource<<cc25e3376fe88a0fd53c84ba7708a9bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActorUserType = "Agent" | "Influencer";
import { FragmentRefs } from "relay-runtime";
export type ChatMessage$data = {
  readonly action: string | null;
  readonly attachments: ReadonlyArray<{
    readonly contentType: string | null;
    readonly duration: number | null;
    readonly file: string;
    readonly id: string;
  }>;
  readonly commentable: {
    readonly avatar: {
      readonly file: string | null;
    } | null;
    readonly userType: ActorUserType | null;
  };
  readonly content: string | null;
  readonly createdAt: any;
  readonly extraInfo: string | null;
  readonly extraType: string | null;
  readonly ogps: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly image: string | null;
    readonly title: string;
    readonly url: string;
  }>;
  readonly post: {
    readonly id: string;
  };
  readonly stamp: string | null;
  readonly " $fragmentType": "ChatMessage";
};
export type ChatMessage$key = {
  readonly " $data"?: ChatMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatMessage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatMessage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "action",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Actor",
      "kind": "LinkedField",
      "name": "commentable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Avatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Ogp",
      "kind": "LinkedField",
      "name": "ogps",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Post",
      "kind": "LinkedField",
      "name": "post",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "f7f680e682e7e143a8795ab4dbfd1c9f";

export default node;
