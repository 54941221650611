/**
 * @generated SignedSource<<087becf21dee4c97a86c233f5df3bb29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryEditScreenQuery$variables = {};
export type CategoryEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"CategoryEditInfluencerCategories">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CategoryEditMCategories">;
};
export type CategoryEditScreenQuery = {
  response: CategoryEditScreenQuery$data;
  variables: CategoryEditScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CategoryEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CategoryEditInfluencerCategories"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CategoryEditMCategories"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CategoryEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerCategory",
            "kind": "LinkedField",
            "name": "influencerCategories",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mCategoryId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherContent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mCategoryKeyName",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MCategory",
        "kind": "LinkedField",
        "name": "mCategories",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keyName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "399ba38da36b54324ec47c33e7fdca60",
    "id": null,
    "metadata": {},
    "name": "CategoryEditScreenQuery",
    "operationKind": "query",
    "text": "query CategoryEditScreenQuery {\n  influencer {\n    ...CategoryEditInfluencerCategories\n    id\n  }\n  ...CategoryEditMCategories\n}\n\nfragment CategoryEditInfluencerCategories on Influencer {\n  influencerCategories {\n    mCategoryId\n    otherContent\n    mCategoryKeyName\n    id\n  }\n}\n\nfragment CategoryEditMCategories on Query {\n  mCategories {\n    id\n    name\n    keyName\n  }\n}\n"
  }
};
})();

(node as any).hash = "57c6ce1c64e4ff38a0c55376b99b134f";

export default node;
