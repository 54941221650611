/**
 * @generated SignedSource<<9be1be39ea3a5616fe7050fd3aeb4524>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type requirementForApplyCampaign$data = {
  readonly hasDelivery: boolean;
  readonly parentMediaType: ParentMediaType;
  readonly " $fragmentType": "requirementForApplyCampaign";
};
export type requirementForApplyCampaign$key = {
  readonly " $data"?: requirementForApplyCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"requirementForApplyCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "requirementForApplyCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentMediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDelivery",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "5ea7024cef27216d6deb926a22b10cd8";

export default node;
