import { commitMutation, graphql } from "react-relay/hooks";
import { onLogoutMutation } from "@generated/onLogoutMutation.graphql";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import reloadApp from "@lib/util/realodApp";
import RelayEnvironment from "../../RelayEnvironment";

const mutation = graphql`
  mutation onLogoutMutation($input: LogOutMutationInput!) {
    logOut(input: $input) {
      success
    }
  }
`;

export async function removeStorage(): Promise<void> {
  await SecureStoreManager.deleteAccessTokenSet();
  await reloadApp();
}

export default async function onLogout(): Promise<void> {
  await new Promise<void>((resolve) => {
    commitMutation<onLogoutMutation>(RelayEnvironment, {
      mutation,
      variables: {
        input: {},
      },
      onCompleted: () => {
        resolve();
      },
      onError: () => {
        resolve();
      },
    });
  });
  await SecureStoreManager.removeAndReload();
}
