/**
 * @generated SignedSource<<4538183d697534973db7b845fac7fdd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PasswordUser$data = {
  readonly hasPassword: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"PasswordEditInfluencer">;
  readonly " $fragmentType": "PasswordUser";
};
export type PasswordUser$key = {
  readonly " $data"?: PasswordUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PasswordUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PasswordUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassword",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PasswordEditInfluencer"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "8b52319f691ce4a2727c9c0c90ce869f";

export default node;
