/**
 * @generated SignedSource<<35cf9c76ffbb11e1a6c7fcf5cffb07a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyPageReport$data = {
  readonly totalPayment: number;
  readonly totalWorkCount: number;
  readonly " $fragmentType": "MyPageReport";
};
export type MyPageReport$key = {
  readonly " $data"?: MyPageReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyPageReport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyPageReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWorkCount",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "dead04498139d7db6401b3c38a1641a4";

export default node;
