/**
 * @generated SignedSource<<d5fae40ec7d0d81238b8215fa3f39af0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConversionActionType = "signup";
export type ConversionDevice = "desktop" | "mobile" | "tablet";
export type ConversionPlatform = "android" | "ios" | "web";
export type CreateConversionMutationInput = {
  actionType: ConversionActionType;
  clientMutationId?: string | null;
  device?: ConversionDevice | null;
  os?: string | null;
  platform?: ConversionPlatform | null;
  utmAdset?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  utmTerm?: string | null;
};
export type recordConversionMutation$variables = {
  input: CreateConversionMutationInput;
};
export type recordConversionMutation$data = {
  readonly createConversion: {
    readonly success: boolean;
  };
};
export type recordConversionMutation = {
  response: recordConversionMutation$data;
  variables: recordConversionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recordConversionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateConversionMutationPayload",
        "kind": "LinkedField",
        "name": "createConversion",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recordConversionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateConversionMutationPayload",
        "kind": "LinkedField",
        "name": "createConversion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f90ae127a6d8bdf82adfe7170c7aa319",
    "id": null,
    "metadata": {},
    "name": "recordConversionMutation",
    "operationKind": "mutation",
    "text": "mutation recordConversionMutation(\n  $input: CreateConversionMutationInput!\n) {\n  createConversion(input: $input) {\n    success\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "162d4a3aa4ee64e6e21c168edcb58912";

export default node;
