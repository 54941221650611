/**
 * @generated SignedSource<<2142fb304e0ae6176b79b8186e86494c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveBankAccountMutationInput = {
  accountNumberAttribute?: string | null;
  bankName?: string | null;
  branchName?: string | null;
  clientMutationId?: string | null;
  codeBranch?: string | null;
  id?: string | null;
  influencerId: string;
  ownerNameKana?: string | null;
  typeAccount?: string | null;
};
export type BankEditSaveMutation$variables = {
  input: SaveBankAccountMutationInput;
};
export type BankEditSaveMutation$data = {
  readonly saveBankAccount: {
    readonly __typename: "BankAccountError";
    readonly message: string;
  } | {
    readonly __typename: "Influencer";
    readonly bankAccount: {
      readonly accountNumberAttribute: string | null;
      readonly bankName: string | null;
      readonly branchName: string | null;
      readonly codeBranch: string | null;
      readonly id: string;
      readonly ownerNameKana: string | null;
      readonly typeAccount: string | null;
    } | null;
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type BankEditSaveMutation = {
  response: BankEditSaveMutation$data;
  variables: BankEditSaveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "saveBankAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumberAttribute",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branchName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "codeBranch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerNameKana",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeAccount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "Influencer",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "BankAccountError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BankEditSaveMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BankEditSaveMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "daa0fa0b46fef78331d5d2b35ddaf0bb",
    "id": null,
    "metadata": {},
    "name": "BankEditSaveMutation",
    "operationKind": "mutation",
    "text": "mutation BankEditSaveMutation(\n  $input: SaveBankAccountMutationInput!\n) {\n  saveBankAccount(input: $input) {\n    __typename\n    ... on Influencer {\n      id\n      bankAccount {\n        id\n        accountNumberAttribute\n        bankName\n        branchName\n        codeBranch\n        ownerNameKana\n        typeAccount\n      }\n    }\n    ... on BankAccountError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1afbc17899c20197458ebd639f60ebc6";

export default node;
