/**
 * @generated SignedSource<<1b1face74ac015c4f854beda1ccfed10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveAvatarMutationInput = {
  clientMutationId?: string | null;
  file: any;
};
export type ProfileEditAvatarMutation$variables = {
  input: SaveAvatarMutationInput;
};
export type ProfileEditAvatarMutation$data = {
  readonly saveAvatar: {
    readonly __typename: "Influencer";
    readonly avatarUrl: string | null;
  } | {
    readonly __typename: "SaveAvatarError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type ProfileEditAvatarMutation = {
  response: ProfileEditAvatarMutation$data;
  variables: ProfileEditAvatarMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "SaveAvatarError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileEditAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveAvatar",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileEditAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveAvatar",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "440d9a75e16a55cd88c7005fc5b8894a",
    "id": null,
    "metadata": {},
    "name": "ProfileEditAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileEditAvatarMutation(\n  $input: SaveAvatarMutationInput!\n) {\n  saveAvatar(input: $input) {\n    __typename\n    ... on Influencer {\n      avatarUrl\n      id\n    }\n    ... on SaveAvatarError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18ca4215dcfa6f4b3b4a55d9fdcaa723";

export default node;
