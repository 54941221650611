import { NativeModules, Platform } from "react-native";
import Constants, { AppOwnership } from "expo-constants";
import * as Updates from "expo-updates";
import { env } from "@constants/Env";

const availableEnv = ["staging", "production"];

export default async function reloadApp(): Promise<void> {
  if (
    Platform.OS !== "web" &&
    availableEnv.indexOf(env) === -1 &&
    (Constants.appOwnership === null ||
      Constants.appOwnership !== AppOwnership.Expo)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    NativeModules.DevSettings.reload();
  } else {
    await Updates.reloadAsync();
  }
}
