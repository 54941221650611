import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import BackHome from "@components/organisms/Fallback/FallbackBackHome";

export default function FallbackUnknown({ message }: { message?: string }) {
  return (
    <>
      <Spacer height={50} />
      <View style={styles.textArea}>
        <Text style={styles.caption}>
          {message ?? "予期せぬエラーが発生しました。"}
        </Text>
      </View>
      <Spacer height={30} />
      <BackHome />
    </>
  );
}

const styles = StyleSheet.create({
  textArea: {
    paddingHorizontal: 16,
  },
  caption: {
    ...Fonts.lr130,
  },
});
