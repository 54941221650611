/**
 * @generated SignedSource<<5d69f9457209126f07f7a6bc4b4dfa46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailRegenerateCodeMutationInput = {
  clientMutationId?: string | null;
};
export type CampaignInputEmailConfirmCodeMutation$variables = {
  input: EmailRegenerateCodeMutationInput;
};
export type CampaignInputEmailConfirmCodeMutation$data = {
  readonly emailRegenerateCode: {
    readonly __typename: "EmailRegenerateCodeError";
    readonly message: string;
  } | {
    readonly __typename: "EmailRegenerateCodeToken";
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CampaignInputEmailConfirmCodeMutation = {
  response: CampaignInputEmailConfirmCodeMutation$data;
  variables: CampaignInputEmailConfirmCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "emailRegenerateCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "EmailRegenerateCodeToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "EmailRegenerateCodeError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignInputEmailConfirmCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignInputEmailConfirmCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a8a5946603437b9546b074dda055de1",
    "id": null,
    "metadata": {},
    "name": "CampaignInputEmailConfirmCodeMutation",
    "operationKind": "mutation",
    "text": "mutation CampaignInputEmailConfirmCodeMutation(\n  $input: EmailRegenerateCodeMutationInput!\n) {\n  emailRegenerateCode(input: $input) {\n    __typename\n    ... on EmailRegenerateCodeToken {\n      token\n    }\n    ... on EmailRegenerateCodeError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9279a2107a2d679113942b26709b93cf";

export default node;
