import React from "react";
import { StyleProp, TextStyle } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import OuterLabel from "@components/molecules/TextInput/OuterLabel";
import styles from "@components/molecules/TextInput/style";
import CopyButton from "@components/atoms/CopyButton";
import _ from "lodash";

type Props = {
  /** フィールド表示名 */
  label?: string;
  /** 初期入力値 */
  value?: string | number | null;
  /** フィールドのスタイル */
  style?: StyleProp<TextStyle>;
  /** 入力必須フラグ */
  required?: boolean;
  /** 複数行フラグ */
  multiline?: boolean;
};

export default function CopyField({
  label,
  value,
  required,
  style,
  multiline,
}: Props): JSX.Element {
  const { colors, dark } = useTheme();

  return (
    <View style={styles.container}>
      {label !== undefined && (
        <OuterLabel label={label} required={required === true} />
      )}

      <View
        style={[
          styles.form,
          {
            color: colors.text,
            backgroundColor: dark ? colors.background : colors.card,
          },
          style,
        ]}
      >
        <View style={styles.copyArea}>
          <CopyButton value={`${!_.isNil(value) ? value : ""}`} />
        </View>

        <Text style={multiline === true ? styles.multilineText : styles.text}>
          {value}
        </Text>
      </View>
    </View>
  );
}
