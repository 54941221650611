import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GradientButton from "@components/atoms/GradientButton";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Divider from "@components/atoms/Divider";
import Button from "@components/atoms/Button";
import Toast from "@components/atoms/Toast";
import Fonts from "@constants/Fonts";
import { CampaignInputEmailConfirmEmail$key } from "@generated/CampaignInputEmailConfirmEmail.graphql";
import { CampaignInputEmailConfirmCodeMutation } from "@generated/CampaignInputEmailConfirmCodeMutation.graphql";
import { CampaignInputEmailConfirmSaveMutation } from "@generated/CampaignInputEmailConfirmSaveMutation.graphql";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { KeyboardId } from "@constants/App";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import NavigationBar from "@components/molecules/NavigationBar";
import { resolveError } from "@lib/util/error";

const emailQuery = graphql`
  fragment CampaignInputEmailConfirmEmail on Influencer {
    newEmail
  }
`;

const resendMutation = graphql`
  mutation CampaignInputEmailConfirmCodeMutation(
    $input: EmailRegenerateCodeMutationInput!
  ) {
    emailRegenerateCode(input: $input) {
      __typename
      ... on EmailRegenerateCodeToken {
        token
      }
      ... on EmailRegenerateCodeError {
        message
      }
    }
  }
`;

const confirmMuation = graphql`
  mutation CampaignInputEmailConfirmSaveMutation(
    $input: EmailConfirmMutationInput!
  ) {
    emailConfirm(input: $input) {
      __typename
      ... on Influencer {
        newEmail
        email
      }
      ... on EmailConfirmError {
        message
      }
    }
  }
`;

type InputData = {
  code: string;
};

export default function CampaignInputEmailConfirm({
  emailFragment,
  initialToken,
  hasNext,
  onClose,
}: {
  emailFragment: CampaignInputEmailConfirmEmail$key;
  initialToken: string;
  hasNext: boolean;
  onClose: () => void;
}) {
  const { newEmail } = useFragment(emailQuery, emailFragment);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const [token, setToken] = useState<string>(initialToken);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      code: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        code: string()
          .trim()
          .numberString("4桁の半角数字を入力してください")
          .length(4, "4桁の半角数字を入力してください")
          .required("4桁の半角数字を入力してください"),
      })
    ),
  });
  const [commitResend] =
    useMutation<CampaignInputEmailConfirmCodeMutation>(resendMutation);
  const [commitSave] =
    useMutation<CampaignInputEmailConfirmSaveMutation>(confirmMuation);

  const onResend = useCallback(async () => {
    try {
      setLoading(true);
      const result = await new Promise<string>((resolve, reject) => {
        commitResend({
          variables: {
            input: {},
          },
          onCompleted({ emailRegenerateCode }) {
            if (emailRegenerateCode.__typename === "EmailRegenerateCodeToken") {
              resolve(emailRegenerateCode.token);
            } else {
              reject(
                emailRegenerateCode.__typename === "EmailRegenerateCodeError"
                  ? emailRegenerateCode.message
                  : "認証コードを再送できませんでした"
              );
            }
          },
        });
      });
      setToken(result);
      setMessage("認証コードが再送されました");
    } catch (e: unknown) {
      setError(resolveError(e).message);
    } finally {
      setLoading(false);
    }
  }, [commitResend]);

  const onConfirm = useCallback(async () => {
    await handleSubmit(async ({ code }: InputData) => {
      try {
        if (code === null || `${code}`.length !== 4) {
          throw new Error("4桁の認証コードを入力してください");
        }
        setLoading(true);
        await new Promise<void>((resolve, reject) => {
          commitSave({
            variables: {
              input: {
                code,
                token,
              },
            },
            onCompleted({ emailConfirm }) {
              if (emailConfirm.__typename === "EmailConfirmError") {
                reject(emailConfirm.message);
              } else {
                resolve();
              }
            },
          });
        });
      } catch (e: unknown) {
        setError(resolveError(e).message);
        setLoading(false);
      }
    })();
  }, [commitSave, token, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="認証コードを送信しました" />
      <Spacer height={8} />
      <ScrollView>
        <Wrapper>
          <Card style={styles.content}>
            <Text style={styles.message}>
              認証コードを{newEmail}
              に送信しました。届いた4桁の認証コードを入力してください。
            </Text>

            <Spacer height={24} />
            <TextField
              control={control}
              inputAccessoryViewID={KeyboardId}
              maxLength={4}
              name="code"
              type="numberString"
            />
            {!isValid && errors.code !== undefined && (
              <ErrorText error={errors.code.message} />
            )}

            <Spacer height={16} />

            <Text>
              メールが届かない場合は、受信拒否設定の確認と再送信をお願いしたします。
            </Text>

            <Spacer height={40} />

            <View style={styles.button}>
              <Button
                height={32}
                loading={loading}
                onPress={onResend}
                textStyle={styles.buttonText}
                title="再送信"
                width={160}
              />
            </View>
          </Card>
        </Wrapper>
      </ScrollView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={onConfirm}
          title={hasNext ? "次へ" : "認証を完了する"}
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView
        label="send"
        nativeID={KeyboardId}
        onPress={onConfirm}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  message: {
    ...Fonts.xlr160,
  },
  content: {
    padding: 24,
    flex: 1,
  },
  button: {
    alignItems: "center",
  },
  buttonText: {
    ...Fonts.lr100,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
