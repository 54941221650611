import React from "react";
import { Text, View, Card } from "@components/atoms/Themed";
import { StyleSheet, Image } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import CustomIcon from "@components/atoms/Icon";
import DeadLine from "@components/atoms/DeadLine";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import { LinearGradient } from "expo-linear-gradient";
import Spacer from "@components/atoms/Spacer";
import dayjs from "dayjs";
import { ApplyCampaign$key } from "@generated/ApplyCampaign.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import campaignBanner from "@lib/util/campaignBanner";

const applyCampaign = graphql`
  fragment ApplyCampaign on Campaign {
    title
    parentMediaType
    gifting
    prType
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentEndOn
    }
  }
`;

export default function ApplyCampaign({
  campaignFragment,
}: {
  campaignFragment: ApplyCampaign$key;
}) {
  const campaign = useFragment(applyCampaign, campaignFragment);
  const restDay = dayjs(campaign.socialNetwork.recruitmentEndOn).diff(
    dayjs(),
    "day"
  );
  return (
    <Card style={styles.container}>
      <View style={styles.image}>
        <Image source={campaignBanner(campaign.banners)} style={styles.image} />
        <LinearGradient
          colors={Colors.grBlack60}
          end={{
            x: 0,
            y: 0.4068,
          }}
          start={{
            x: 0,
            y: 0,
          }}
          style={styles.imageGr}
        />
      </View>
      <Spacer width={16} />
      <View style={styles.campaignExplanation}>
        <View style={styles.information}>
          <SNSIcons name={campaign.parentMediaType} style={styles.platform} />
          <Spacer width={8} />
          <View style={styles.category}>
            <Text style={styles.categoryText}>{campaign.mCategory.name}</Text>
          </View>
        </View>
        <Spacer height={4} />
        <Text numberOfLines={2} style={styles.explanation}>
          {campaign.title}
        </Text>
        <Spacer height={4} />
        <View style={styles.bottomContext}>
          <View style={styles.reward}>
            {campaign.gifting && campaign.prType === "rewarded" ? (
              <>
                <CustomIcon color={Colors.gray} name="present" size={18} />
                <Spacer width={14} />
                <CustomIcon color={Colors.gray} name="money" size={18} />
              </>
            ) : campaign.gifting ? (
              <CustomIcon color={Colors.gray} name="present" size={18} />
            ) : campaign.prType === "rewarded" ? (
              <CustomIcon color={Colors.gray} name="money" size={18} />
            ) : null}
          </View>
          <View style={styles.deadlineBox}>
            <View style={styles.deadline}>
              <DeadLine day={campaign.socialNetwork.recruitmentEndOn} />
            </View>
          </View>
        </View>
      </View>
      {restDay < 0 && <View style={styles.mask} />}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 16,
  },
  image: {
    height: 90,
    width: 120,
    borderRadius: 4,
  },
  imageGr: {
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  campaignExplanation: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  information: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  platform: {
    height: 24,
    width: 24,
  },
  category: {
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    paddingHorizontal: 8,
    borderColor: Colors.green,
    borderWidth: 1,
    borderRadius: 4,
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.green,
  },
  explanationWrap: {
    flex: 1,
  },
  explanation: {
    color: Colors.black,
    textAlign: "left",
    ...Fonts.lb130,
  },
  bottomContext: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: 24,
    width: "100%",
  },
  deadline: {
    height: 24,
  },
  deadlineBox: {
    flex: 1,
    width: 166,
    height: 24,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  reward: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  mask: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 92,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.4,
  },
});
