import React from "react";
import {
  StyleSheet,
  Modal as RnModal,
  ModalProps,
  TouchableOpacity,
} from "react-native";
import SafeAreaView from "@components/molecules/SafeAreaView";
import { View, Text, Card } from "@components/atoms/Themed";
import Icon from "@components/atoms/Icon";
import Divider from "@components/atoms/Divider";
import Fonts from "@constants/Fonts";
import { MaxWidth } from "@constants/Layout";
import { useTheme } from "@react-navigation/native";

interface Props extends ModalProps {
  header?: boolean;
  headerTitle?: string;
}

export default function Modal({
  transparent,
  visible,
  animationType,
  presentationStyle,
  onRequestClose,
  children,
  header,
  headerTitle,
}: Props) {
  const { colors } = useTheme();
  return (
    <RnModal
      animationType={animationType}
      onRequestClose={onRequestClose}
      presentationStyle={presentationStyle}
      transparent={transparent}
      visible={visible}
    >
      <SafeAreaView>
        {header === true && (
          <>
            <Card style={styles.header}>
              <View style={styles.headerWrapper}>
                <TouchableOpacity onPress={onRequestClose} style={styles.close}>
                  <Icon name="close" size={24} />
                </TouchableOpacity>
                <Text style={styles.headerText}>{headerTitle ?? ""}</Text>
              </View>
            </Card>
            <Divider />
          </>
        )}
        {transparent === true ? (
          children
        ) : (
          <View
            style={[
              styles.background,
              {
                backgroundColor: colors.background,
              },
            ]}
          >
            {children}
          </View>
        )}
      </SafeAreaView>
    </RnModal>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 44,
    width: "100%",
  },
  headerWrapper: {
    maxWidth: MaxWidth,
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    textAlign: "center",
    ...Fonts.xlb100,
  },
  close: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    paddingHorizontal: 16,
    height: "100%",
  },
  background: {
    flex: 1,
  },
});
