import React, { useCallback, useEffect, useState } from "react";
import { Text, View } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import {
  StyleSheet,
  TouchableOpacity,
  Animated,
  GestureResponderEvent,
} from "react-native";
import { useTheme } from "@react-navigation/native";

const initialBottom = -200;

type Props = {
  message: string;
  onClose: () => void;
};

export default function Toast({ message, onClose }: Props): JSX.Element {
  const [animateBottom] = useState<Animated.Value>(
    new Animated.Value(initialBottom)
  );

  const { colors, dark } = useTheme();
  const close = useCallback((): void => {
    const animate = Animated.timing(animateBottom, {
      toValue: initialBottom,
      duration: 100,
      useNativeDriver: false,
    });
    animate.start(() => {
      onClose();
    });
  }, [animateBottom, onClose]);

  const pressClose = useCallback(
    (e: GestureResponderEvent) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    const animate = Animated.timing(animateBottom, {
      toValue: 100,
      duration: 150,
      useNativeDriver: false,
    });
    animate.start(() => animate.stop());
    setTimeout(() => close(), 4000);
    return () => {
      animate.stop();
    };
  }, [animateBottom, close]);

  return (
    <Animated.View
      style={[
        styles.animated,
        {
          bottom: animateBottom,
        },
      ]}
    >
      <View style={styles.wrapper}>
        <TouchableOpacity
          onPressIn={pressClose}
          style={[
            styles.content,
            { backgroundColor: dark ? colors.notification : Colors.black0 },
          ]}
        >
          <Text style={styles.text}>{message}</Text>
        </TouchableOpacity>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  animated: {
    position: "absolute",
    left: 0,
    width: "100%",
    zIndex: 999,
  },
  wrapper: {
    flex: 1,
    padding: 16,
  },
  content: {
    width: "100%",
    padding: 16,
    borderRadius: 6,
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    ...Fonts.lr100,
    color: Colors.white,
  },
});
