import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import PersonalInformationEdit from "@components/templates/PersonalInformationEdit";
import { PersonalInformationEditScreenQuery } from "@generated/PersonalInformationEditScreenQuery.graphql";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import _ from "lodash";

const personalInformationEditScreen = graphql`
  query PersonalInformationEditScreenQuery {
    influencer {
      ...PersonalInformationEditInfo
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<PersonalInformationEditScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<PersonalInformationEditScreenQuery>(
    personalInformationEditScreen,
    queryReference
  );
  return <PersonalInformationEdit personalInformationFragment={influencer} />;
}

export default function PersonalInformationEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<PersonalInformationEditScreenQuery>(
      personalInformationEditScreen
    );
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);
  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="名前" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
