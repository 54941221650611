import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import CampaignInput from "@components/templates/CampaignInput";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import SafeAreaView from "@components/molecules/SafeAreaView";
import { useTheme } from "@react-navigation/native";
import _ from "lodash";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { CampaignInputScreenQuery } from "@generated/CampaignInputScreenQuery.graphql";
import { RootStackScreenProps } from "../types";

const query = graphql`
  query CampaignInputScreenQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      __typename
      ... on Campaign {
        ...CampaignInputCampaign
      }
      ... on NotFoundError {
        message
      }
    }
    influencer {
      ...CampaignInputInfluencer
    }
    ...CampaignInputCategoryMCategories
    ...CampaignInputAddressPrefecture
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CampaignInputScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<CampaignInputScreenQuery>(
    query,
    queryReference
  );

  const { influencer, campaign } = data;
  if (campaign.__typename !== "Campaign") {
    throw new Error(
      campaign.__typename === "NotFoundError"
        ? campaign.message
        : "システムエラーが発生しました"
    );
  }

  return (
    <CampaignInput
      campaignFragment={campaign}
      categoryFragment={data}
      influencerFragment={influencer}
      prefectureFragment={data}
    />
  );
}

export default function CampaignInputScreen({
  route,
}: RootStackScreenProps<"CampaignInput">): JSX.Element {
  const { colors } = useTheme();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignInputScreenQuery>(query);
  React.useEffect(() => {
    loadQuery({
      campaignId: route.params.id,
    });

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route]);

  return (
    <SafeAreaView>
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <Suspense fallback={<Loading size="large" />}>
          {!_.isNil(queryReference) && (
            <ScreenContent queryReference={queryReference} />
          )}
        </Suspense>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
