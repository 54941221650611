import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import numberFormat from "@lib/util/numberFormat";
import { graphql, useFragment } from "react-relay/hooks";
import { MyPageReport$key } from "@generated/MyPageReport.graphql";
import { navigate } from "@navigation/navigate";

const myPageReport = graphql`
  fragment MyPageReport on Influencer {
    totalPayment
    totalWorkCount
  }
`;

export default function MyPageReport({
  reportFragment,
}: {
  reportFragment: MyPageReport$key;
}) {
  const data = useFragment(myPageReport, reportFragment);
  return (
    <Card style={styles.container}>
      <View style={styles.item}>
        <View style={styles.title}>
          <Text style={styles.page}>これまでの実績</Text>
        </View>
        <TouchableOpacity
          onPress={() => navigate("Report", { tab: "Monthly" })}
        >
          <Text style={styles.gray}>詳細</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.summary}>
        <View style={styles.summaryItem}>
          <Text style={styles.text}>キャンペーン</Text>
          <Spacer width={12} />
          <Text style={styles.count}>{data.totalWorkCount}</Text>
        </View>
        <View style={styles.summaryItemReward}>
          <Text style={styles.text}>受け取った報酬</Text>
          <Spacer width={12} />
          <Text style={styles.count}>
            {numberFormat(data.totalPayment, "¥")}
          </Text>
        </View>
      </View>
      <Spacer height={8} />
      <TouchableOpacity onPress={() => navigate("CampaignApplyHistory")}>
        <Text style={styles.history}>応募したキャンペーン</Text>
      </TouchableOpacity>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
  },
  page: {
    ...Fonts.xlb160,
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.lr100,
    color: Colors.black,
  },
  gray: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
  history: {
    ...Fonts.lr100,
    color: Colors.gray,
    textAlign: "right",
  },
  summary: {
    paddingVertical: 17.5,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: Colors.gray30,
    borderStyle: "solid",
    borderRadius: 4,
  },
  summaryItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  summaryItemReward: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  count: {
    ...Fonts.xlr100,
  },
});
