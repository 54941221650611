import React from "react";
import { StyleSheet, TouchableOpacity, ImageBackground } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import { LinearGradient } from "expo-linear-gradient";
import Spacer from "@components/atoms/Spacer";
import { CampaignRelated$key } from "@generated/CampaignRelated.graphql";
import SNSIcons from "@components/atoms/SNSIcon";
import CustomIcon from "@components/atoms/Icon";
import FavoriteButton from "@components/molecules/FavoriteButton";
import DeadLine from "@components/atoms/DeadLine";
import Tag from "@components/atoms/Tag";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import campaignBanner from "@lib/util/campaignBanner";
import { graphql, useFragment } from "react-relay/hooks";
import { replace } from "@navigation/navigate";

const campaignRelated = graphql`
  fragment CampaignRelated on Campaign {
    id
    title
    parentMediaType
    gifting
    prType
    isFavoriteCheck
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentEndOn
    }
  }
`;

type Props = {
  campaignFragment: CampaignRelated$key;
};
export default function CampaignRelated({ campaignFragment }: Props) {
  const campaign = useFragment(campaignRelated, campaignFragment);

  return (
    <>
      <Spacer width={16} />

      <TouchableOpacity
        onPress={() => replace("CampaignDetail", { id: campaign.id })}
      >
        <Card style={styles.card}>
          <ImageBackground
            imageStyle={styles.backgorund}
            source={campaignBanner(campaign.banners)}
            style={styles.image}
          >
            <View style={styles.head}>
              <View style={styles.headWrapper}>
                <SNSIcons
                  name={campaign.parentMediaType}
                  style={styles.platform}
                />
                <FavoriteButton
                  buttonSize={24}
                  campaignId={campaign.id}
                  iconSize={12}
                  isFavoriteCheck={campaign.isFavoriteCheck}
                />
              </View>
            </View>
            <LinearGradient
              colors={Colors.grBlack60}
              end={{
                x: 0,
                y: 0.4868,
              }}
              start={{
                x: 0,
                y: 0,
              }}
              style={styles.imageGr}
            />
          </ImageBackground>

          <View style={styles.campaignExplanation}>
            <Tag>
              <Text style={styles.categoryText}>{campaign.mCategory.name}</Text>
            </Tag>
            <Spacer height={8} />
            <View style={styles.title}>
              <Text style={styles.explanation}>{campaign.title}</Text>
            </View>
            <View style={styles.tail}>
              <View style={styles.reward}>
                {campaign.gifting && campaign.prType === "rewarded" ? (
                  <>
                    <CustomIcon color={Colors.gray} name="present" size={18} />
                    <Spacer width={14} />
                    <CustomIcon color={Colors.gray} name="money" size={18} />
                  </>
                ) : campaign.gifting ? (
                  <CustomIcon color={Colors.gray} name="present" size={18} />
                ) : campaign.prType === "rewarded" ? (
                  <CustomIcon color={Colors.gray} name="money" size={18} />
                ) : null}
              </View>
              <DeadLine day={campaign.socialNetwork.recruitmentEndOn} />
            </View>
          </View>
        </Card>
      </TouchableOpacity>
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    height: 312,
    width: 240,
    borderRadius: 4,
  },
  backgorund: {
    borderRadius: 4,
  },
  image: {
    position: "relative",
    height: 146,
    width: "100%",
  },
  head: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  headWrapper: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageGr: {
    height: "100%",
    width: "100%",
  },
  platform: {
    height: 24,
    width: 24,
  },
  campaignExplanation: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: 150,
    padding: 16,
    color: Colors.white,
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.green,
  },
  title: {
    flex: 1,
  },
  explanation: {
    ...Fonts.lb130,
  },
  tail: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  reward: {
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 24,
    flex: 1,
  },
});
