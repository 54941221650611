/**
 * @generated SignedSource<<f7bdbf94d1c5580ef9237a75e15f1370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveAttachmentsMutationInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type PostDraftTiktokRemoveMutation$variables = {
  input: RemoveAttachmentsMutationInput;
};
export type PostDraftTiktokRemoveMutation$data = {
  readonly removeAttachments: {
    readonly __typename: "Attachments";
    readonly attachments: ReadonlyArray<{
      readonly id: string;
    }>;
  } | {
    readonly __typename: "RemoveAttachmentsError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type PostDraftTiktokRemoveMutation = {
  response: PostDraftTiktokRemoveMutation$data;
  variables: PostDraftTiktokRemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "removeAttachments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "Attachments",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "RemoveAttachmentsError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostDraftTiktokRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostDraftTiktokRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ea25fdac15613e68b1e2ad8bc3c3c80",
    "id": null,
    "metadata": {},
    "name": "PostDraftTiktokRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation PostDraftTiktokRemoveMutation(\n  $input: RemoveAttachmentsMutationInput!\n) {\n  removeAttachments(input: $input) {\n    __typename\n    ... on Attachments {\n      attachments {\n        id\n      }\n    }\n    ... on RemoveAttachmentsError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25742055a5967d3958555ecd2a58fe50";

export default node;
