import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import NavigationBar from "@components/molecules/NavigationBar";
import {
  graphql,
  useQueryLoader,
  PreloadedQuery,
  usePreloadedQuery,
} from "react-relay/hooks";
import Apply from "@components/templates/Apply";
import { View, Text } from "@components/atoms/Themed";
import { CampaignApplyScreenQuery } from "@generated/CampaignApplyScreenQuery.graphql";
import { goBack } from "@navigation/navigate";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const campaignApply = graphql`
  query CampaignApplyScreenQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      __typename
      ... on Campaign {
        id
        ...Apply
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CampaignApplyScreenQuery>;
}): JSX.Element {
  const { campaign } = usePreloadedQuery<CampaignApplyScreenQuery>(
    campaignApply,
    queryReference
  );

  try {
    if (campaign.__typename !== "Campaign") {
      throw new Error(
        campaign.__typename === "NotFoundError"
          ? campaign.message
          : "システムエラーが発生しました"
      );
    }
    return <Apply campaignFragment={campaign} />;
  } catch (e) {
    // TODO: エラー発生時のデザインを考える
    return (
      <View>
        <Text>{(e as Error).message}</Text>
      </View>
    );
  }
}

export default function CampaignApplyScreen({
  route,
}: RootStackScreenProps<"CampaignApply">): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignApplyScreenQuery>(campaignApply);

  React.useEffect(() => {
    loadQuery({ campaignId: route.params.id });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="応募" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
