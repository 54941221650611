/**
 * @generated SignedSource<<efa907eb1fd10ce37e53eba6ac3658c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryTypes = "body_care" | "child_teaching" | "dance" | "diet" | "diy" | "fashion" | "gadget" | "game" | "gourmet_sweet_report" | "hair_care" | "kids" | "life_style" | "makeup" | "movie" | "nail" | "other" | "pet" | "ranking" | "skin_care" | "travel" | "yoga";
import { FragmentRefs } from "relay-runtime";
export type CampaignInputCategoryMCategories$data = {
  readonly mCategories: ReadonlyArray<{
    readonly id: string;
    readonly keyName: CategoryTypes;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CampaignInputCategoryMCategories";
};
export type CampaignInputCategoryMCategories$key = {
  readonly " $data"?: CampaignInputCategoryMCategories$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputCategoryMCategories">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputCategoryMCategories",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MCategory",
      "kind": "LinkedField",
      "name": "mCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0bd3b1ae9d375413a783282fe29199eb";

export default node;
