import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import { graphql, useFragment } from "react-relay/hooks";
import { PhoneEdit$key } from "@generated/PhoneEdit.graphql";
import Form from "@components/organisms/PhoneEdit/PhoneEditForm";
import Confirm from "@components/organisms/PhoneEdit/PhoneEditConfirm";
import Modal from "@components/molecules/Modal";

const query = graphql`
  fragment PhoneEdit on Influencer {
    ...PhoneEditConfirm
    ...PhoneEditForm
  }
`;

export default function PhoneEdit({
  phoneFragment,
}: {
  phoneFragment: PhoneEdit$key;
}) {
  const influencer = useFragment(query, phoneFragment);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <View style={styles.container}>
      <Form onComplete={() => setConfirm(true)} phoneFragment={influencer} />
      {confirm && (
        <Modal
          animationType="slide"
          header
          headerTitle="認証コードを送信しました"
          onRequestClose={() => setConfirm(false)}
          transparent
          visible
        >
          <Confirm
            onComplete={() => {
              setMessage("電話番号を変更しました");
              setConfirm(false);
            }}
            phoneFragment={influencer}
          />
        </Modal>
      )}
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
