/**
 * @generated SignedSource<<8b81c51c78e407b89e43ffed27dc927f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddressEditScreenQuery$variables = {};
export type AddressEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"AddressEditInfo">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AddressEditPrefecture">;
};
export type AddressEditScreenQuery = {
  response: AddressEditScreenQuery$data;
  variables: AddressEditScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddressEditInfo"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AddressEditPrefecture"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AddressEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prefectureName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cityName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "townName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "buildingName",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MPrefecture",
        "kind": "LinkedField",
        "name": "prefectures",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd4b16313c9e1fa26cf1e9ab2dfd8644",
    "id": null,
    "metadata": {},
    "name": "AddressEditScreenQuery",
    "operationKind": "query",
    "text": "query AddressEditScreenQuery {\n  influencer {\n    ...AddressEditInfo\n    id\n  }\n  ...AddressEditPrefecture\n}\n\nfragment AddressEditInfo on Influencer {\n  postalCode\n  prefectureName\n  cityName\n  townName\n  buildingName\n}\n\nfragment AddressEditPrefecture on Query {\n  prefectures {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd1c7637968c8349e3847467b16caba0";

export default node;
