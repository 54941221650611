import React from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import { graphql, useFragment } from "react-relay/hooks";
import { PasswordUser$key } from "@generated/PasswordUser.graphql";
import Spacer from "@components/atoms/Spacer";
import PasswordEdit from "@components/organisms/Password/PasswordEdit";
import PasswordCreate from "@components/organisms/Password/PasswordCreate";

const passwordQuery = graphql`
  fragment PasswordUser on Influencer {
    hasPassword
    ...PasswordEditInfluencer
  }
`;

export default function Password({
  passwordFragment,
}: {
  passwordFragment: PasswordUser$key;
}) {
  const data = useFragment(passwordQuery, passwordFragment);

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      {data.hasPassword ? (
        <PasswordEdit emailFragment={data} />
      ) : (
        <PasswordCreate />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
