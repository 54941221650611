import { useRef } from "react";

type PreventDoubleClick = {
  processing: boolean;
  onClick: () => void;
  onRelease: () => void;
};

export default function usePreventDoubleClick(): PreventDoubleClick {
  const processing = useRef(false);
  const onClick = () => {
    processing.current = true;
  };
  const onRelease = () => {
    processing.current = false;
  };

  return {
    processing: processing.current,
    onClick,
    onRelease,
  };
}
