import React from "react";
import { Text, View } from "@components/atoms/Themed";
import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";

type Props = { name: string };

export default function CategoryBadge({ name }: Props): JSX.Element {
  return (
    <View style={styles.row}>
      <View style={styles.categoryWrap}>
        <Text style={styles.category}>{name}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  categoryWrap: {
    paddingVertical: 6.5,
    paddingHorizontal: 8,
    borderColor: Colors.green,
    borderWidth: 1,
    borderRadius: 4,
  },
  category: {
    color: Colors.green,
    fontSize: 11,
  },
  row: {
    flexDirection: "row",
  },
});
