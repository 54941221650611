import React from "react";
import Dialog from "@components/molecules/dialog/Dialog";

export default function PostReportConfirmDialog({
  save,
  cancel,
}: {
  save: () => void;
  cancel: () => void;
}) {
  return (
    <Dialog
      buttons={[
        { title: "閉じる", type: "white" },
        { title: "送信する", type: "blueGradient", onPress: save },
      ]}
      message="送信確認"
      onClose={cancel}
      subline="作成した内容で完了報告を送信しますか？"
    />
  );
}
