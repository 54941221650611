/**
 * Themedの使い方については下記参考
 * https://github.com/nttdocomo-com/InfluencerWorks-app-frontend/pull/174#issuecomment-1288834734
 */
/* eslint react/jsx-props-no-spreading: 0 */
import React, { useMemo } from "react";
import {
  Text as DefaultText,
  View as DefaultView,
  StyleSheet,
} from "react-native";
import Fonts from "@constants/Fonts";
import { MaxWidth, ScreenWidth } from "@constants/Layout";
import { useTheme } from "@react-navigation/native";

type ViewProps = {
  bordered?: boolean;
} & DefaultView["props"];

export function Text(props: DefaultText["props"]) {
  const { style, ...otherProps } = props;
  const { colors, dark } = useTheme();
  // ダークモードの場合は文字を常に白にする
  if (dark) {
    return (
      <DefaultText
        style={[{ ...Fonts.mr100 }, style, { color: colors.text }]}
        {...otherProps}
      />
    );
  }
  return (
    <DefaultText
      style={[{ color: colors.text, ...Fonts.mr100 }, style]}
      {...otherProps}
    />
  );
}

/**
 * カード用のView
 */
export function Card(props: ViewProps) {
  const { style, bordered, ...otherProps } = props;
  const { colors } = useTheme();
  const border = useMemo(() => {
    if (bordered !== undefined) {
      return { borderColor: colors.border };
    }
    return null;
  }, [bordered, colors]);
  return (
    <DefaultView
      style={[style, { backgroundColor: colors.card }, border]}
      {...otherProps}
    />
  );
}

/**
 * 背景色指定なしの通常View。
 * borderColorはthemeで制御する
 */
export function View(props: ViewProps) {
  const { style, bordered, ...otherProps } = props;
  const { colors } = useTheme();
  const border = useMemo(() => {
    if (bordered !== undefined) {
      return { borderColor: colors.border };
    }
    return null;
  }, [bordered, colors]);
  return <DefaultView style={[border, style]} {...otherProps} />;
}

/**
 * 最大幅でセンタリングするラッパー
 * Desktopでデザイン崩れを起こさないために使用する。
 */
export function Wrapper({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  if (ScreenWidth >= MaxWidth) {
    return (
      <DefaultView style={styles.container}>
        <DefaultView style={styles.content}>{children}</DefaultView>
      </DefaultView>
    );
  }
  return children;
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  content: {
    maxWidth: MaxWidth,
    width: "100%",
  },
});
