import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import CampaignDetail from "@components/templates/CampaignDetail";
import Loading from "@components/atoms/Loading";
import useAccount from "@hooks/useAccount";
import Fonts from "@constants/Fonts";
import NavigationBar from "@components/molecules/NavigationBar";
import { View, Text } from "@components/atoms/Themed";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { CampaignDetailScreenQuery } from "@generated/CampaignDetailScreenQuery.graphql";
import { goBack, replace } from "@navigation/navigate";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import _ from "lodash";
import { RootStackScreenProps, initialRoute, initialTabScreen } from "../types";

const campaignDetailScreenQuery = graphql`
  query CampaignDetailScreenQuery($campaignId: ID!, $influencerId: ID!) {
    campaign(id: $campaignId) {
      __typename
      ... on Campaign {
        ...CampaignDetailCampaign
      }
      ... on NotFoundError {
        message
      }
    }
    influencer {
      ...CampaignActionCandidates
    }

    ...CampaignDetailRelatedList @arguments(campaignId: $campaignId)

    candidate(influencerId: $influencerId, campaignId: $campaignId) {
      ...CampaignDetailUnitPrice
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CampaignDetailScreenQuery>;
}): JSX.Element {
  const reference = usePreloadedQuery<CampaignDetailScreenQuery>(
    campaignDetailScreenQuery,
    queryReference
  );

  const { campaign } = reference;
  const { candidate } = reference;

  if (campaign.__typename !== "Campaign") {
    return (
      <View style={styles.expired}>
        <Text style={styles.error}>このキャンペーンは終了しました</Text>
        <Spacer height={24} />
        <GradientButton
          onPress={() => replace(initialRoute, { screen: initialTabScreen })}
          title="キャンペーンを探す"
        />
      </View>
    );
  }
  return (
    <CampaignDetail
      campaignFragment={campaign}
      candidateFragment={candidate}
      currentInfluencer={reference.influencer}
      relatedCampaignsFragment={reference}
    />
  );
}

export default function CampaignDetailScreen({
  route,
}: RootStackScreenProps<"CampaignDetail">): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignDetailScreenQuery>(campaignDetailScreenQuery);
  const { state: influencerId } = useAccount();
  React.useEffect(() => {
    loadQuery(
      {
        campaignId: route.params.id,
        influencerId,
      },
      { fetchPolicy: "network-only" }
    );

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route, influencerId]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="キャンペーン" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  expired: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    ...Fonts.xlr100,
  },
});
