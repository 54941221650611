import React from "react";
import OAuthRedirect from "@components/templates/OAuthRedirect";
import { RootStackScreenProps } from "../types";

export default function OAuthAppScreen({
  route,
}: RootStackScreenProps<"OAuthApp">) {
  const { code, state } = route.params;

  return <OAuthRedirect code={code} state={state} />;
}
