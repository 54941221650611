import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, Platform, TouchableOpacity } from "react-native";
import * as ExpoImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import Loading from "@components/atoms/Loading";
import _ from "lodash";
import { isVideo, getExtensionFromType } from "@lib/util/file";
import {
  AllowImageTypes,
  AllowVideoTypes,
  AllowImageTypeReg,
  AllowVideoTypeReg,
} from "@constants/App";
import { resolveError } from "@lib/util/error";

type Props = {
  /** 画像が選択されたときに発火するイベントハンドラ */
  onChange: (e: ImageFile | ImageFile[]) => void;
  /** エラーハンドラ */
  onError: (e: string) => void;
  /** 選択開始 */
  onPickStart?: () => void;
  /** 選択完了 */
  onPickEnd?: () => void;
  /** 選択した画像のリサイズ後のサイズ。スクエアにcrop & resize されます。 */
  resizeSize?: number;
  loading?: boolean;
  // 複数選択するかどうか
  allowsMultipleSelection?: boolean;
  // 複数選択の場合の最大選択数
  selectionLimit?: number;
  // 画像/動画 選択のタイプ Image/Video/All
  pickupMediaType?: ExpoImagePicker.MediaTypeOptions;
  // 動画の上限容量(mb)
  maxVideoSize?: number;
  // 画像の上限容量(mb)
  maxImageSize?: number;
};

type ResizeOption = {
  uri: string;
  width?: number;
  height?: number;
};

export type ImageFile = {
  uri: string;
  file: File;
};

function convertToMB(fileSize?: number): number | null {
  if (fileSize !== undefined) {
    return Math.floor((fileSize / (1000 * 1000)) * 10) / 10;
  }
  return null;
}

function isSupportedMimeType(type: string): boolean {
  return AllowImageTypeReg.test(type) || AllowVideoTypeReg.test(type);
}

function supportMimeTypes(mode: ExpoImagePicker.MediaTypeOptions): string[] {
  switch (mode) {
    case ExpoImagePicker.MediaTypeOptions.Images:
      return AllowImageTypes;
    case ExpoImagePicker.MediaTypeOptions.Videos:
      return AllowVideoTypes;
    case ExpoImagePicker.MediaTypeOptions.All:
    default:
      return AllowImageTypes.concat(AllowVideoTypes);
  }
}

export default function ImagePicker({
  onChange,
  onError,
  onPickStart = () => {},
  onPickEnd = () => {},
  resizeSize = 800,
  loading = false,
  allowsMultipleSelection = false,
  selectionLimit = 0,
  pickupMediaType = ExpoImagePicker.MediaTypeOptions.Images,
  maxVideoSize = 400,
  maxImageSize = 10,
}: Props): JSX.Element {
  const [processing, setProcessing] = useState<boolean>(false);

  const resize = useCallback(
    async ({
      uri,
      width,
      height,
    }: ResizeOption): Promise<ImageManipulator.ImageResult> => {
      const resizeOption = (): {
        width?: number | undefined;
        height?: number | undefined;
      } => {
        if (width != null && height == null) {
          return { width };
        }
        if (width == null && height != null) {
          return { height };
        }
        return { width, height };
      };

      const result = await ImageManipulator.manipulateAsync(
        uri,
        [
          {
            resize: resizeOption(),
          },
        ],
        {
          compress: 1,
          format: ImageManipulator.SaveFormat.JPEG,
        }
      );
      return result;
    },
    []
  );

  const pickupImage =
    useCallback(async (): Promise<ExpoImagePicker.ImagePickerResult> => {
      const result = await ExpoImagePicker.launchImageLibraryAsync({
        mediaTypes: pickupMediaType,
        allowsEditing: Platform.OS === "ios" && !allowsMultipleSelection,
        allowsMultipleSelection,
        selectionLimit,
        aspect: [1, 1],
        base64: true,
        quality: 1,
        orderedSelection: allowsMultipleSelection,
      });
      return result;
    }, [allowsMultipleSelection, pickupMediaType, selectionLimit]);

  const pick = useCallback(async () => {
    try {
      const res = await pickupImage();
      if (res.canceled) {
        return;
      }

      if (Platform.OS === "web") {
        onPickStart();
      }

      const asset = _.head(res.assets);
      if (asset === undefined) {
        return;
      }

      const fileSize = convertToMB(asset.fileSize);
      if (fileSize !== null && fileSize > maxImageSize) {
        throw new Error(
          `${fileSize}MBの写真が選択されました。${maxImageSize}MB以下に圧縮して選択してください。`
        );
      }

      const result =
        asset.width > asset.height
          ? await resize({ uri: asset.uri, width: resizeSize })
          : await resize({ uri: asset.uri, height: resizeSize });

      const { uri } = result;
      const blob = await fetch(uri).then((r) => r.blob());
      const extension = getExtensionFromType(blob.type);

      const file = new File(
        [blob],
        Platform.OS === "web" ? `${Date.now()}.${extension}` : uri,
        { type: blob.type }
      );

      if (!isSupportedMimeType(file.type)) {
        throw new Error(
          `選択可能なファイルは${supportMimeTypes(pickupMediaType).join(
            ", "
          )}のいずれかです。`
        );
      }

      onChange({ uri, file });
    } catch (e: unknown) {
      onError(resolveError(e).message);
    } finally {
      onPickEnd();
    }
  }, [
    maxImageSize,
    onChange,
    onError,
    onPickEnd,
    onPickStart,
    pickupImage,
    pickupMediaType,
    resize,
    resizeSize,
  ]);

  const multiPick = useCallback(async () => {
    try {
      const res = await pickupImage();
      if (res.canceled) {
        return;
      }

      if (Platform.OS === "web") {
        onPickStart();
      }

      const resizeUriList = await Promise.all(
        res.assets.map(async (i) => {
          const fileSize = convertToMB(i.fileSize);
          if (isVideo({ uri: i.uri })) {
            if (fileSize !== null && fileSize > maxVideoSize) {
              throw new Error(
                `${fileSize}MBの動画が選択されました。${maxVideoSize}MB以下に圧縮して選択してください。`
              );
            }
            return i.uri;
          }

          if (fileSize !== null && fileSize > maxImageSize) {
            throw new Error(
              `${fileSize}MBの写真が選択されました。${maxImageSize}MB以下に圧縮して選択してください。`
            );
          }

          const resized =
            i.width > i.height
              ? await resize({ uri: i.uri, width: resizeSize })
              : await resize({ uri: i.uri, height: resizeSize });
          return resized.uri;
        })
      );

      const imageFileList = await Promise.all(
        resizeUriList.map(async (uri) => {
          const blob = await fetch(uri).then((response) => response.blob());
          const extension = getExtensionFromType(blob.type);
          const file = new File(
            [blob],
            Platform.OS === "web" ? `${Date.now()}.${extension}` : uri,
            { type: blob.type }
          );
          if (!isSupportedMimeType(file.type)) {
            throw new Error(
              `選択可能なファイルは${supportMimeTypes(pickupMediaType).join(
                ", "
              )}のいずれかです。`
            );
          }

          return { uri, file };
        })
      );

      onChange(imageFileList);
    } catch (e: unknown) {
      onError(resolveError(e).message);
    } finally {
      onPickEnd();
    }
  }, [
    maxImageSize,
    maxVideoSize,
    onChange,
    onError,
    onPickEnd,
    onPickStart,
    pickupImage,
    pickupMediaType,
    resize,
    resizeSize,
  ]);

  useEffect(() => {
    if (processing) {
      if (loading) {
        return;
      }
      if (Platform.OS !== "web") {
        onPickStart();
      }
      if (allowsMultipleSelection) {
        multiPick();
      } else {
        pick();
      }
    }
    setProcessing(false);
  }, [
    allowsMultipleSelection,
    loading,
    multiPick,
    onPickStart,
    pick,
    processing,
  ]);

  return (
    <TouchableOpacity
      onPress={() => setProcessing(true)}
      style={styles.container}
    >
      {loading ? (
        <Loading color={Colors.white} />
      ) : (
        <Icon color={Colors.white} name="camera" size={30} />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
