/**
 * @generated SignedSource<<1a29d3b0333c60248da9b25556facc5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceStatus = "applied" | "unapplied" | "unset";
import { FragmentRefs } from "relay-runtime";
export type requirementForApplyInfluencer$data = {
  readonly cityName: string | null;
  readonly email: string;
  readonly firstName: string | null;
  readonly influencerCategories: ReadonlyArray<{
    readonly categoryName: string;
  }>;
  readonly lastName: string | null;
  readonly linkingInstagram: boolean | null;
  readonly linkingTwitter: boolean | null;
  readonly phoneNumber: string | null;
  readonly postalCode: string | null;
  readonly prefectureName: string | null;
  readonly profile: {
    readonly birthday: any | null;
    readonly commonName: string | null;
    readonly gender: string | null;
    readonly invoiceStatus: InvoiceStatus;
    readonly tiktokUrl: string | null;
    readonly ytChannel: string | null;
  };
  readonly townName: string | null;
  readonly " $fragmentType": "requirementForApplyInfluencer";
};
export type requirementForApplyInfluencer$key = {
  readonly " $data"?: requirementForApplyInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"requirementForApplyInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "requirementForApplyInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefectureName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cityName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "townName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingInstagram",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingTwitter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commonName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ytChannel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerCategory",
      "kind": "LinkedField",
      "name": "influencerCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "e40ab26fa5e741e0b16bb54937cd1693";

export default node;
