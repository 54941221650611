import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, Switch } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import { projectId } from "@constants/Env";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { getPushTokenAsync } from "@lib/util/pushNotification";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { MyPageSetting$key } from "@generated/MyPageSetting.graphql";
import { MyPageSettingMutation } from "@generated/MyPageSettingMutation.graphql";
import useMessage from "@hooks/useMessage";
import { MyPageSettingPushTokenMutation } from "@generated/MyPageSettingPushTokenMutation.graphql";
import * as Notifications from "expo-notifications";
import { resolveError } from "@lib/util/error";

const myQuery = graphql`
  fragment MyPageSetting on Influencer {
    allowNotification
  }
`;

const saveMutation = graphql`
  mutation MyPageSettingMutation($allowNotification: Boolean) {
    updateInfluencer(input: { allowNotification: $allowNotification }) {
      __typename
      ... on Influencer {
        allowNotification
      }
      ... on UpdateInfluencerError {
        message
      }
    }
  }
`;

const savePushTokenMutation = graphql`
  mutation MyPageSettingPushTokenMutation($token: String!) {
    savePushToken(input: { token: $token }) {
      token
    }
  }
`;

export default function MyPageSetting({
  myFragment,
  openGuide,
}: {
  myFragment: MyPageSetting$key;
  openGuide: () => void;
}) {
  const { allowNotification } = useFragment(myQuery, myFragment);
  const [pushNotification, setPushNotification] =
    useState<boolean>(allowNotification);
  const [loading, setLoading] = useState<boolean>(false);
  const [commit] = useMutation<MyPageSettingMutation>(saveMutation);
  const [pushtokencommit] = useMutation<MyPageSettingPushTokenMutation>(
    savePushTokenMutation
  );
  const { set: setMessage } = useMessage();

  useEffect(() => {
    async function getToken() {
      const token = await getPushTokenAsync();
      if (token === null && pushNotification) {
        setPushNotification(false);
      }
    }
    getToken();
  }, [pushNotification]);

  const savePushToken = useCallback(
    async (token: string) => {
      await new Promise<void>((resolve) => {
        pushtokencommit({
          variables: {
            token,
          },
          onCompleted() {
            resolve();
          },
        });
      });
    },
    [pushtokencommit]
  );

  const pushTokenCheck = useCallback(async () => {
    const devisetoken = (
      await Notifications.getExpoPushTokenAsync({
        projectId,
      })
    ).data;
    savePushToken(devisetoken);
  }, [savePushToken]);

  const toggleNotification = useCallback(
    async (allow: boolean) => {
      try {
        if (loading) {
          return;
        }

        // 端末が許可していない場合、尚且つtoggleをtrueにした場合
        const token = await getPushTokenAsync();
        if (token === null && allow) {
          openGuide();
          return;
        }
        setLoading(true);
        // 非同期処理なためSiwtch処理にタイムラグが生じるので、画面上の表示をここで切り替える
        setPushNotification(allow);
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              allowNotification: allow,
            },
            onCompleted({ updateInfluencer }) {
              if (updateInfluencer.__typename === "Influencer") {
                setPushNotification(updateInfluencer.allowNotification);
                pushTokenCheck();
                setMessage({
                  title: "保存しました",
                  mode: "toast",
                });
                resolve();
              } else {
                reject(
                  new Error(
                    updateInfluencer.__typename === "UpdateInfluencerError"
                      ? updateInfluencer.message
                      : "変更できませんでした"
                  )
                );
              }
            },
          });
        });
      } catch (e: unknown) {
        const error = resolveError(e);
        setMessage({
          title: error.message,
          mode: "toast",
        });
      } finally {
        setLoading(false);
      }
    },
    [commit, loading, openGuide, pushTokenCheck, setMessage]
  );

  return (
    <Card style={styles.container}>
      <View style={styles.item}>
        <Text style={styles.page}>設定</Text>
      </View>
      <View style={styles.item}>
        <View style={styles.title}>
          <Text style={styles.text}>プッシュ通知</Text>
        </View>
        <View>
          <Switch
            onValueChange={(allow) => toggleNotification(allow)}
            value={pushNotification}
          />
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
  },
  page: {
    ...Fonts.xlb160,
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
});
