/* eslint camelcase: 0 */
import React from "react";
import { Text, View } from "@components/atoms/Themed";
import SNSIcon from "@components/atoms/SNSIcon";
import Spacer from "@components/atoms/Spacer";
import { StyleSheet } from "react-native";
import { MediaTypeNames } from "@constants/App";
import { graphql, useFragment } from "react-relay/hooks";
import { SNSBadge_mediaType$key } from "@generated/SNSBadge_mediaType.graphql";
import Colors from "@constants/Colors";

type Props = {
  fragment: SNSBadge_mediaType$key;
};

const query = graphql`
  fragment SNSBadge_mediaType on Campaign {
    parentMediaType
    mediaType
  }
`;

export default function SNSBadge({ fragment }: Props): JSX.Element {
  const { parentMediaType, mediaType } = useFragment<SNSBadge_mediaType$key>(
    query,
    fragment
  );
  return (
    <View style={styles.container}>
      <SNSIcon name={parentMediaType} style={styles.SNSIcon} />
      <Spacer width={8} />
      <Text>{MediaTypeNames[mediaType]}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    flexDirection: "row",
    borderRadius: 4,
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray20,
  },
  SNSIcon: {
    height: 24,
    width: 24,
  },
});
