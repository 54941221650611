import React, { ComponentProps } from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import { Ionicons } from "@expo/vector-icons";
import Colors from "@constants/Colors";
import { useTheme } from "@react-navigation/native";

export type GlyphNames = ComponentProps<typeof Ionicons>["name"];

export type IconName =
  | "back"
  | "bell"
  | "calendar"
  | "camera"
  | "check"
  | "checkbox"
  | "checkBoxFill"
  | "checkboxEmpty"
  | "checklist"
  | "close"
  | "copy"
  | "down"
  | "download"
  | "eye"
  | "eyeClose"
  | "filter"
  | "graph"
  | "heart"
  | "heartEmpty"
  | "home"
  | "minus"
  | "money"
  | "next"
  | "outer"
  | "play"
  | "pluss"
  | "present"
  | "rotate"
  | "search"
  | "share"
  | "shield"
  | "speech"
  | "trash"
  | "up"
  | "user";

const IconMap: {
  [key in IconName]: GlyphNames;
} = {
  back: "chevron-back",
  bell: "notifications-outline",
  calendar: "ios-calendar-sharp",
  camera: "camera-outline",
  check: "checkmark",
  checkbox: "checkbox-outline",
  checkBoxFill: "checkbox",
  checkboxEmpty: "square-outline",
  checklist: "list-outline",
  close: "close-outline",
  copy: "copy-outline",
  down: "chevron-down",
  download: "cloud-download-outline",
  eye: "eye-outline",
  eyeClose: "eye-off-outline",
  filter: "filter-outline",
  graph: "bar-chart-sharp",
  heart: "heart",
  heartEmpty: "heart-outline",
  home: "home-outline",
  minus: "remove-outline",
  money: "logo-yen",
  next: "chevron-forward",
  outer: "open-outline",
  play: "play-circle-outline",
  pluss: "add",
  present: "gift-outline",
  rotate: "refresh",
  search: "search-outline",
  share: "share-outline",
  shield: "shield",
  speech: "chatbox-outline",
  trash: "trash-outline",
  up: "chevron-up",
  user: "person-outline",
};

type Prpos = {
  /** 利用したいアイコン名 */
  name: IconName;
  /** アイコンのサイズを指定。すべてスクエア型になります。 */
  size: number;
  /** アイコンの色を指定。constants/Colors内の定数で指定してください。 */
  color?: string;
  /** ダークモードで色を変えるか？ */
  skipDarkModeColor?: boolean;
};

export default function Icon({
  name,
  size,
  color = Colors.black,
  skipDarkModeColor = false,
}: Prpos) {
  const { colors, dark } = useTheme();

  if (name === "money") {
    return (
      <View
        style={[
          styles.circle,
          {
            borderRadius: size / 2,
            borderColor: dark && !skipDarkModeColor ? colors.text : color,
            width: size,
            height: size,
          },
        ]}
      >
        <Ionicons
          color={dark && !skipDarkModeColor ? colors.text : color}
          name={IconMap[name]}
          size={size * 0.6}
        />
      </View>
    );
  }
  return (
    <Ionicons
      color={dark && !skipDarkModeColor ? colors.text : color}
      name={IconMap[name]}
      size={size}
    />
  );
}

const styles = StyleSheet.create({
  circle: {
    borderStyle: "solid",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
