/**
 * @generated SignedSource<<aae19731540335f13b67eac20eb07bdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputAddressPrefecture$data = {
  readonly prefectures: ReadonlyArray<{
    readonly name: string;
  }>;
  readonly " $fragmentType": "CampaignInputAddressPrefecture";
};
export type CampaignInputAddressPrefecture$key = {
  readonly " $data"?: CampaignInputAddressPrefecture$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputAddressPrefecture">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputAddressPrefecture",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MPrefecture",
      "kind": "LinkedField",
      "name": "prefectures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "db55a431cfe8f30907b8dd4073bd936d";

export default node;
