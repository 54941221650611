import { Platform } from "react-native";
import { login as sendAnalytics } from "@lib/util/analytics";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import initializeUser from "@lib/util/initializeUser";
import { resetToRoot } from "@navigation/navigate";

export default async function onLogin(
  data: { token: string; refreshToken: string },
  setInfluencerId: (id: string) => void
): Promise<void> {
  await sendAnalytics();
  await SecureStoreManager.registerAccessTokenSet(
    data.token,
    data.refreshToken
  );
  const influencerId = await initializeUser();
  setInfluencerId(influencerId);

  if (Platform.OS === "web") {
    window.location.href = "/";
  } else {
    resetToRoot();
  }
}
