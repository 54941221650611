import React, { useCallback } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import Divider from "@components/atoms/Divider";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { KeyboardId } from "@constants/App";
import { PasswordField, ErrorText } from "@components/molecules/TextInput";

type Props = {
  onLogin: (password: string) => Promise<void>;
  onReissue: () => void;
};

type InputData = {
  password: string;
};

export default function AuthSnsSignupPassword({ onLogin, onReissue }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        password: string().trim().required("入力してください"),
      })
    ),
  });

  const onPress = useCallback(async () => {
    await handleSubmit(async ({ password }: InputData) => {
      await onLogin(password);
    })();
  }, [onLogin, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                このEmailはすでに利用されています。パスワードを入力してログインしてください。
              </Text>

              <Spacer height={24} />

              <PasswordField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="パスワード"
                maxLength={35}
                name="password"
              />
              {!isValid && errors.password !== undefined && (
                <ErrorText error={errors.password.message} />
              )}
              <Spacer height={8} />
              <Text style={styles.hint}>
                半角英字（大/小文字）、数字の組み合わせ8文字以上
              </Text>
              <Spacer height={16} />

              <TouchableOpacity onPress={onReissue} style={styles.center}>
                <Text style={styles.grayText}>パスワードを忘れた方</Text>
              </TouchableOpacity>
            </Card>

            <Spacer height={40} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          onPress={onPress}
          title="ログイン"
        />
      </Card>
      <InputAccessoryView
        label="send"
        nativeID={KeyboardId}
        onPress={onPress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  message: {
    ...Fonts.xlr160,
  },
  content: {
    padding: 24,
    flex: 1,
  },
  button: {
    alignItems: "center",
  },
  buttonText: {
    ...Fonts.lr100,
  },
  hint: {
    ...Fonts.sr140,
    color: Colors.gray,
    marginBottom: 24,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  grayText: {
    color: Colors.gray,
    ...Fonts.lrlink130,
    textDecorationColor: Colors.gray,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
