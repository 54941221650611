import React, { useCallback, useEffect, useState } from "react";
import AuthEnter from "@components/templates/AuthEnter";
import Loading from "@components/atoms/Loading";
import { graphql, useMutation } from "react-relay/hooks";
import { AuthEnterScreenMutation } from "@generated/AuthEnterScreenMutation.graphql";
import { SignUp, Login, isLogin } from "@lib/util/oauth";
import { navigate, replace } from "@navigation/navigate";
import { Platform } from "react-native";
import { login as sendAnalytics } from "@lib/util/analytics";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import useAccount from "@hooks/useAccount";
import initializeUser from "@lib/util/initializeUser";
import { initialRoute, RootStackScreenProps } from "../types";

const query = graphql`
  mutation AuthEnterScreenMutation($input: SnsLoginMutationInput!) {
    snsLogin(input: $input) {
      __typename
      ... on SnsLoginToken {
        token
        refreshToken
      }
      ... on Influencer {
        id
        email
        profile {
          commonName
        }
      }
      ... on SnsLoginError {
        message
      }
    }
  }
`;

export default function AuthEnterScreen({
  route,
}: RootStackScreenProps<"Auth">): JSX.Element {
  const [commit] = useMutation<AuthEnterScreenMutation>(query);
  const [loading, setLoading] = useState<boolean>(false);
  const { set: setInfluencerId } = useAccount();

  const login = useCallback(
    async (codeExchange: string, redirectUri: string) => {
      try {
        if (loading) {
          return;
        }
        setLoading(true);

        const result = await new Promise<SignUp | Login>((resolve, reject) => {
          commit({
            variables: {
              input: {
                codeExchange,
                codeVerifier: "",
                redirectUri,
                snsType: "Line",
              },
            },
            onCompleted({ snsLogin }) {
              if (snsLogin.__typename === "Influencer") {
                resolve({
                  id: snsLogin.id,
                  commonName: snsLogin.profile.commonName,
                  email: snsLogin.email,
                });
              } else if (snsLogin.__typename === "SnsLoginToken") {
                resolve({
                  token: snsLogin.token,
                  refreshToken: snsLogin.refreshToken,
                });
              } else {
                reject(
                  snsLogin.__typename === "SnsLoginError"
                    ? snsLogin.message
                    : `Line連携に失敗しました`
                );
              }
            },
          });
        });

        if (isLogin(result)) {
          await sendAnalytics();
          await SecureStoreManager.registerAccessTokenSet(
            result.token,
            result.refreshToken
          );
          const influencerId = await initializeUser();
          setInfluencerId(influencerId);
          replace(initialRoute);
        } else {
          navigate("AuthSnsSignup", {
            id: result.id,
            email: result.email,
            name: result.commonName,
            sns_type: "Line",
          });
        }
      } catch (e: unknown) {
        // エラー発生時は何もしない
      } finally {
        setLoading(false);
      }
    },
    [commit, loading, setInfluencerId]
  );

  useEffect(() => {
    const code = route.params?.code;
    const uri = route.params?.liffRedirectUri;
    if (code !== undefined && uri !== undefined && Platform.OS === "web") {
      login(code, uri);
    }
  }, [route, login]);
  return (
    <>
      <AuthEnter />
      {loading && <Loading mask />}
    </>
  );
}
