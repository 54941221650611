/**
 * @generated SignedSource<<ba00396331fc8897a97bf75f014b7ac2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCommentReadTimeMutationInput = {
  clientMutationId?: string | null;
  influencerId: string;
  postId: string;
};
export type ChatScreenCommentReadTimeMutation$variables = {
  input: UpdateCommentReadTimeMutationInput;
};
export type ChatScreenCommentReadTimeMutation$data = {
  readonly updateCommentReadTime: {
    readonly __typename: "UpdateCommentReadTimeSuccess";
    readonly commentReadTime: {
      readonly id: string;
      readonly unreadCommentCount: number;
    };
    readonly commentReadTimeTotal: {
      readonly unreadTotalComments: number;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type ChatScreenCommentReadTimeMutation = {
  response: ChatScreenCommentReadTimeMutation$data;
  variables: ChatScreenCommentReadTimeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CommentReadTime",
  "kind": "LinkedField",
  "name": "commentReadTime",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCommentCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadTotalComments",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatScreenCommentReadTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateCommentReadTime",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentReadTimeTotal",
                "kind": "LinkedField",
                "name": "commentReadTimeTotal",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateCommentReadTimeSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatScreenCommentReadTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateCommentReadTime",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentReadTimeTotal",
                "kind": "LinkedField",
                "name": "commentReadTimeTotal",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateCommentReadTimeSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34766857ebc32d4df8a45821d6c5eb5a",
    "id": null,
    "metadata": {},
    "name": "ChatScreenCommentReadTimeMutation",
    "operationKind": "mutation",
    "text": "mutation ChatScreenCommentReadTimeMutation(\n  $input: UpdateCommentReadTimeMutationInput!\n) {\n  updateCommentReadTime(input: $input) {\n    __typename\n    ... on UpdateCommentReadTimeSuccess {\n      commentReadTime {\n        id\n        unreadCommentCount\n      }\n      commentReadTimeTotal {\n        unreadTotalComments\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b895486a6a20082350bb7216b26d2db0";

export default node;
