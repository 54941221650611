/**
 * @generated SignedSource<<e35478caf82c73c9c592a0f616005e96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryTypes = "body_care" | "child_teaching" | "dance" | "diet" | "diy" | "fashion" | "gadget" | "game" | "gourmet_sweet_report" | "hair_care" | "kids" | "life_style" | "makeup" | "movie" | "nail" | "other" | "pet" | "ranking" | "skin_care" | "travel" | "yoga";
import { FragmentRefs } from "relay-runtime";
export type CategoryEditMCategories$data = {
  readonly mCategories: ReadonlyArray<{
    readonly id: string;
    readonly keyName: CategoryTypes;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CategoryEditMCategories";
};
export type CategoryEditMCategories$key = {
  readonly " $data"?: CategoryEditMCategories$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryEditMCategories">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryEditMCategories",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MCategory",
      "kind": "LinkedField",
      "name": "mCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "338718cd82ec7d0a0abd203fcdd44316";

export default node;
