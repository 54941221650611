import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView, Modal } from "react-native";
import { object, string, number } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Spacer from "@components/atoms/Spacer";
import Button from "@components/atoms/Button";
import GradientButton from "@components/atoms/GradientButton";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { KeyboardId, ApplyMinPrice } from "@constants/App";
import useAccount from "@hooks/useAccount";
import Campaign from "@components/organisms/Apply/ApplyCampaign";
import Confirmation from "@components/organisms/Apply/ApplyConfirmation";
import Product from "@components/organisms/Apply/ApplyProduct";
import Reward from "@components/organisms/Apply/ApplyReward";
import Note from "@components/organisms/Apply/ApplyNote";
import { InputData } from "@components/organisms/Apply/type";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { Apply$key } from "@generated/Apply.graphql";
import { ApplyMutation } from "@generated/ApplyMutation.graphql";
import { replace, popToTop } from "@navigation/navigate";
import Loading from "@components/atoms/Loading";
import Divider from "@components/atoms/Divider";
import { resolveError } from "@lib/util/error";
import usePreventDoubleClick from "@hooks/usePreventDoubleClick";

const applyQuery = graphql`
  fragment Apply on Campaign {
    id
    prType
    ...ApplyCampaign
    ...ApplyReward
    ...ApplyProduct
    ...ApplyConfirmation
  }
`;

const applyMutation = graphql`
  mutation ApplyMutation($input: ApplyCampaignMutationInput!) {
    applyCampaign(input: $input) {
      __typename
      ... on Influencer {
        id
      }
      ... on ApplyCampaignError {
        message
      }
    }
  }
`;

type Props = {
  campaignFragment: Apply$key;
};

export default function CampaignApply({ campaignFragment }: Props) {
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const data = useFragment(applyQuery, campaignFragment);
  const [commit] = useMutation<ApplyMutation>(applyMutation);
  const { state: influencerId } = useAccount();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<InputData>({
    defaultValues: {
      customPrice: 0,
      note: null,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        customPrice: number()
          .test("allowEmpty", "入力してください", (value) => {
            if (data.prType === "rewarded") {
              return value !== null;
            }
            return true;
          })
          .test(
            "compare",
            `${ApplyMinPrice}円以上の金額を入力ください`,
            (value) => {
              if (data.prType === "rewarded") {
                return value !== undefined && value >= ApplyMinPrice;
              }
              return true;
            }
          )
          .default(0),
        note: string().notRequired().default(null),
      })
    ),
  });
  const desiredUnitPrice = getValues("customPrice");

  const apply = useCallback(async () => {
    await handleSubmit(async ({ note, customPrice }: InputData) => {
      try {
        if (processing) {
          return;
        }
        onClick();
        setModalVisible(false);
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                influencerId,
                campaignId: data.id,
                customPrice,
                note,
              },
            },
            onCompleted: ({ applyCampaign }) => {
              if (applyCampaign.__typename === "ApplyCampaignError") {
                reject(applyCampaign.message);
              } else {
                resolve();
              }
            },
            onError: () => {
              reject(new Error("申し込みに失敗しました。"));
            },
          });
        });
        // キャンペーン詳細画面がスタックされてるので、popToTopでリセットしてからThankyou画面に遷移する
        popToTop();
        replace("ThankYou");
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onRelease();
      }
    })();
  }, [
    commit,
    data,
    influencerId,
    processing,
    onClick,
    onRelease,
    handleSubmit,
  ]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <KeyboardAvoidingView>
          <Wrapper>
            <View style={styles.guide}>
              <Text style={styles.guideText}>
                まだ、キャンペーン応募は完了していません。
              </Text>
            </View>

            <Campaign campaignFragment={data} />

            <Spacer height={16} />
            <Product campaignFragment={data} />

            {data.prType === "rewarded" && (
              <>
                <Spacer height={16} />
                <Reward
                  campaignFragment={data}
                  control={control}
                  error={errors.customPrice?.message}
                />
              </>
            )}
            <Spacer height={16} />
            <Note control={control} error={errors.note?.message} />

            <Spacer height={24} />

            <Card style={styles.caution}>
              <Text style={styles.cautionText}>
                一度応募したキャンペーンは原則辞退できません。
              </Text>
            </Card>
            <Spacer height={40} />
          </Wrapper>
        </KeyboardAvoidingView>
      </ScrollView>

      <Divider />
      <Card style={styles.applyButtonWrap}>
        {data.prType === "rewarded" && !isValid ? (
          <Button
            disabled
            onPress={() => {}}
            textStyle={styles.condition}
            title="条件を入力してください"
          />
        ) : (
          <GradientButton
            onPress={() => setModalVisible(true)}
            title="この条件で応募する"
          />
        )}
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      {modalVisible && (
        <Modal
          animationType="fade"
          onRequestClose={() => setModalVisible(false)}
          transparent
          visible={modalVisible}
        >
          <View style={styles.campaignConfirmationWrap}>
            <Confirmation
              applyOnPress={apply}
              campaignFragment={data}
              cancelOnPress={() => setModalVisible(false)}
              desiredUnitPrice={desiredUnitPrice}
            />
          </View>
        </Modal>
      )}
      {processing && <Loading mask />}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  guide: {
    justifyContent: "center",
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  guideText: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  caution: {
    height: 50,
    padding: 16,
  },
  cautionText: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
  applyButtonWrap: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  condition: {
    color: Colors.gray40,
    ...Fonts.xlb100,
  },
  campaignConfirmationWrap: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.overlayDark,
  },
});
