import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { refreshTokenKey, tokenKey } from "@constants/Env";
import Cookies from "js-cookie";
import { Platform } from "react-native";
import reloadApp from "@lib/util/realodApp";

export class SecureStoreManager {
  static async deleteAccessTokenSet(): Promise<void> {
    await SecureStoreManager.deleteAccessToken();
    await SecureStoreManager.deleteRefreshToken();
    await SecureStoreManager.removeAllItems();
  }

  static async deleteAccessToken(): Promise<void> {
    if (Platform.OS === "web") {
      Cookies.remove(tokenKey);
    }
    await SecureStore.deleteItemAsync(tokenKey);
  }

  static async deleteRefreshToken(): Promise<void> {
    if (Platform.OS === "web") {
      Cookies.remove(refreshTokenKey);
    }
    await SecureStore.deleteItemAsync(refreshTokenKey);
  }

  static async registerAccessTokenSet(
    accessToken: string,
    refreshToken: string
  ): Promise<void> {
    await SecureStoreManager.registerAccessToken(accessToken);
    await SecureStoreManager.registerRefreshToken(refreshToken);
  }

  static async registerAccessToken(accessToken: string): Promise<void> {
    if (Platform.OS === "web") {
      SecureStoreManager.setCookey(tokenKey, accessToken);
      return;
    }
    await SecureStore.setItemAsync(tokenKey, accessToken);
  }

  static async registerRefreshToken(refreshToken: string): Promise<void> {
    if (Platform.OS === "web") {
      SecureStoreManager.setCookey(refreshTokenKey, refreshToken);
      return;
    }
    await SecureStore.setItemAsync(refreshTokenKey, refreshToken);
  }

  static async getAccessTokenSet(): Promise<{
    accessToken: string | null;
    refreshToken: string | null;
  }> {
    const accessToken = await SecureStoreManager.getAccessToken();
    const refreshToken = await SecureStoreManager.getRefreshToken();
    return { accessToken, refreshToken };
  }

  static async getAccessToken(): Promise<string | null> {
    if (Platform.OS === "web") {
      const t = Cookies.get(tokenKey);
      return t === undefined ? null : t;
    }
    const result = await SecureStore.getItemAsync(tokenKey);
    return result;
  }

  static async getRefreshToken(): Promise<string | null> {
    if (Platform.OS === "web") {
      const t = Cookies.get(refreshTokenKey);
      return t === undefined ? null : t;
    }
    const result = await SecureStore.getItemAsync(refreshTokenKey);
    return result;
  }

  static async removeAllItems(): Promise<void> {
    const keys = await AsyncStorage.getAllKeys();
    keys.forEach(async (key) => {
      await AsyncStorage.removeItem(key);
    });
  }

  static async removeAndReload(): Promise<void> {
    await SecureStoreManager.deleteAccessTokenSet();
    await reloadApp();
  }

  private static setCookey(key: string, value: string): void {
    Cookies.set(key, value, {
      expires: 30,
      secure: true,
      sameSite: "strict",
    });
  }
}
