import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { View, Text, Wrapper } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import CampaignMain from "@components/organisms/CampaignDetail/CampaignMain";
import CampaignReward from "@components/organisms/CampaignDetail/CampaignReward";
import CampaignProduct from "@components/organisms/CampaignDetail/CampaignProduct";
import CampaignContents from "@components/organisms/CampaignDetail/CampaignContents";
import CampaignCast from "@components/organisms/CampaignDetail/CampaignCast";
import CampaignQualification from "@components/organisms/CampaignDetail/CampaignQualification";
import RelatedCampaign from "@components/organisms/CampaignDetail/CampaignRelated";
import CampaignAction from "@components/organisms/CampaignDetail/CampaignAction";
import Spacer from "@components/atoms/Spacer";
import { graphql, useFragment } from "react-relay/hooks";
import { CampaignDetailCampaign$key } from "@generated/CampaignDetailCampaign.graphql";
import { CampaignDetailRelatedList$key } from "@generated/CampaignDetailRelatedList.graphql";
import { CampaignActionCandidates$key } from "@generated/CampaignActionCandidates.graphql";
import { CampaignDetailUnitPrice$key } from "@generated/CampaignDetailUnitPrice.graphql";
import Divider from "@components/atoms/Divider";

const campaignDetailCampaign = graphql`
  fragment CampaignDetailCampaign on Campaign {
    id
    mediaType
    ...CampaignReward
    ...CampaignCast
    ...CampaignQualification
    ...CampaignProduct
    ...CampaignMain
    ...CampaignContents
    ...CampaignActionCampaign
  }
`;

const campaignDetailRelated = graphql`
  fragment CampaignDetailRelatedList on Query
  @argumentDefinitions(campaignId: { type: "ID!" }) {
    relatedCampaigns(id: $campaignId) {
      id
      ...CampaignRelated
    }
  }
`;

const campaignDetailUnitPrice = graphql`
  fragment CampaignDetailUnitPrice on Candidate {
    ...CampaignMainCampaignDetailUnitPrice
  }
`;

const contentSpace = 16;

type Props = {
  campaignFragment: CampaignDetailCampaign$key;
  relatedCampaignsFragment: CampaignDetailRelatedList$key;
  candidateFragment: CampaignDetailUnitPrice$key | null;
  currentInfluencer: CampaignActionCandidates$key;
};

export default function Campaign({
  campaignFragment,
  relatedCampaignsFragment,
  candidateFragment,
  currentInfluencer,
}: Props) {
  const campaign = useFragment(campaignDetailCampaign, campaignFragment);
  const candidate = useFragment(campaignDetailUnitPrice, candidateFragment);
  const { relatedCampaigns } = useFragment(
    campaignDetailRelated,
    relatedCampaignsFragment
  );
  return (
    <>
      <ScrollView style={styles.content}>
        <Wrapper>
          <CampaignMain
            campaignFragment={campaign}
            candidateFragment={candidate}
          />
          <Spacer height={contentSpace} />
          <CampaignReward campaignFragment={campaign} />
          <Spacer height={contentSpace} />
          <CampaignProduct campaignFragment={campaign} />
          <Spacer height={contentSpace} />

          {campaign.mediaType === "cast" ? (
            <CampaignCast campaignFragment={campaign} />
          ) : (
            <CampaignContents campaignFragment={campaign} />
          )}

          <Spacer height={contentSpace} />
          <CampaignQualification campaignFragment={campaign} />
          <Spacer height={contentSpace} />

          {relatedCampaigns.length > 0 && (
            <>
              <View style={styles.related}>
                <Text style={styles.title}>類似のキャンペーン</Text>
              </View>

              <Spacer height={24} />
              <ScrollView horizontal>
                {relatedCampaigns.map((row) => (
                  <RelatedCampaign
                    key={`related-${row.id}`}
                    campaignFragment={row}
                  />
                ))}
              </ScrollView>
            </>
          )}
        </Wrapper>
        <Spacer height={contentSpace} />
      </ScrollView>
      <Divider />
      <CampaignAction
        campaignFragment={campaign}
        candidatesFragment={currentInfluencer}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  related: {
    paddingHorizontal: 16,
  },
  title: {
    ...Fonts.xlb160,
  },
});
