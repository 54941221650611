import { env } from "@constants/Env";
import * as Updates from "expo-updates";
import reloadApp from "@lib/util/realodApp";

export default async function loadUpdates(): Promise<void> {
  try {
    // OTA配信がないかチェックしてある場合はロード
    if (["staging", "production"].indexOf(env) !== -1) {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await reloadApp();
      }
    }
  } catch (e) {
    // エラー発生時は何もしない
  }
}
