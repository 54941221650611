/**
 * @generated SignedSource<<e899d49400af91c9d3cae13cd4427665>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputSnsInfluencer$data = {
  readonly linkingInstagram: boolean | null;
  readonly linkingTwitter: boolean | null;
  readonly profile: {
    readonly tiktokUrl: string | null;
    readonly ytChannel: string | null;
  };
  readonly " $fragmentType": "CampaignInputSnsInfluencer";
};
export type CampaignInputSnsInfluencer$key = {
  readonly " $data"?: CampaignInputSnsInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputSnsInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputSnsInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingInstagram",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingTwitter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ytChannel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "1fc6d3364c853ab7b00c15adda2d05aa";

export default node;
