import React, { useRef, useState, useMemo, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  Selectbox,
  SelectboxRef,
  ErrorText,
} from "@components/molecules/TextInput";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { AddressEditInfo$key } from "@generated/AddressEditInfo.graphql";
import { AddressEditPrefecture$key } from "@generated/AddressEditPrefecture.graphql";
import { AddressEditSearchMutation } from "@generated/AddressEditSearchMutation.graphql";
import { AddressEditUpdateMutation } from "@generated/AddressEditUpdateMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import Button from "@components/atoms/Button";
import GradientButton from "@components/atoms/GradientButton";
import { KeyboardId } from "@constants/App";
import Divider from "@components/atoms/Divider";

const addressEditInfoQuery = graphql`
  fragment AddressEditInfo on Influencer {
    postalCode
    prefectureName
    cityName
    townName
    buildingName
  }
`;

const addressEditPrefectureQuery = graphql`
  fragment AddressEditPrefecture on Query {
    prefectures {
      name
    }
  }
`;

const addressEditSearchMutation = graphql`
  mutation AddressEditSearchMutation($postalCode: String!) {
    postalCode(input: { postalCode: $postalCode }) {
      __typename
      ... on MPostalCode {
        postalCode
        mPrefectureName
        city
        town
      }
      ... on PostalCodeError {
        message
      }
    }
  }
`;

const addressEditUpdateMutation = graphql`
  mutation AddressEditUpdateMutation($input: UpdateInfluencerMutationInput!) {
    updateInfluencer(input: $input) {
      __typename
      ... on Influencer {
        postalCode
        prefectureName
        cityName
        townName
        buildingName
      }
      ... on UpdateInfluencerError {
        message
      }
    }
  }
`;

type AddressInput = {
  postalCode: string;
  prefectureName: string;
  cityName: string;
  townName: string;
  buildingName: string | null;
};

export default function AddressEdit({
  addressFragment,
  prefecturesFragment,
}: {
  addressFragment: AddressEditInfo$key;
  prefecturesFragment: AddressEditPrefecture$key;
}) {
  const data = useFragment(addressEditInfoQuery, addressFragment);
  const { prefectures } = useFragment(
    addressEditPrefectureQuery,
    prefecturesFragment
  );
  const prefectureItems = useMemo(
    () =>
      prefectures.map((row) => ({
        label: row.name,
        value: row.name,
      })),
    [prefectures]
  );
  const prefectureList = useMemo(
    () => prefectures.map((row) => row.name),
    [prefectures]
  );
  const prefectureRef = useRef<SelectboxRef>();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searched] = useMutation<AddressEditSearchMutation>(
    addressEditSearchMutation
  );
  const [postalCode, setPostalCode] = useState<string | null>(data.postalCode);
  const [commit] = useMutation<AddressEditUpdateMutation>(
    addressEditUpdateMutation
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddressInput>({
    defaultValues: {
      postalCode: data.postalCode ?? "",
      prefectureName: data.prefectureName ?? "",
      cityName: data.cityName ?? "",
      townName: data.townName ?? "",
      buildingName: data.buildingName,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        postalCode: string()
          .default("")
          .trim()
          .replace(/-/, "")
          .length(7, "7桁で入力してください")
          .required("入力してください")
          .numberString("半角数字で入力してください"),
        prefectureName: string()
          .default("")
          .trim()
          .oneOf(prefectureList, "選択してください")
          .required("入力してください"),
        cityName: string().default("").trim().required("入力してください"),
        townName: string().default("").trim().required("入力してください"),
        buildingName: string().default("").trim().nullable().toNull(),
      })
    ),
  });

  const search = useCallback(async () => {
    if (postalCode === null || postalCode.length !== 7) {
      return;
    }
    setLoading(true);
    await new Promise<void>((resolve) => {
      searched({
        variables: {
          postalCode,
        },
        onCompleted({ postalCode: result }) {
          if (result.__typename === "PostalCodeError") {
            setError(result.message);
          } else if (result.__typename === "MPostalCode") {
            setValue("prefectureName", result.mPrefectureName, {
              shouldValidate: true,
            });
            prefectureRef?.current?.updateDisplay(result.mPrefectureName);
            setValue("cityName", result.city, { shouldValidate: true });
            setValue("townName", result.town ?? "", { shouldValidate: true });
          }
          resolve();
        },
      });
    });
    setLoading(false);
  }, [searched, setValue, postalCode]);

  const save = useCallback(async () => {
    await handleSubmit(async (submitInput: AddressInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...submitInput,
            },
          },
          onCompleted({ updateInfluencer }) {
            if (updateInfluencer.__typename === "UpdateInfluencerError") {
              setError(updateInfluencer.message);
            } else {
              setMessage("保存しました");
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Spacer height={8} />
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                登録住所を入力してください。入力がない場合、キャンペーンに応募できません。
              </Text>
            </Card>

            <Spacer height={16} />
            <Card style={styles.content}>
              <View style={[styles.postalcode]}>
                <View style={styles.field}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="郵便番号"
                    name="postalCode"
                    onChange={(value: string) => setPostalCode(value ?? null)}
                    placeholder="郵便番号を登録してください"
                    required
                    type="phone"
                  />
                  {!isValid && errors.postalCode !== undefined && (
                    <ErrorText error={errors.postalCode.message} />
                  )}
                  <Spacer height={8} />
                  <Text style={styles.guide}>ハイフン(-)なし</Text>
                </View>
                <Spacer width={8} />
                <View style={styles.search}>
                  <Button
                    border={postalCode !== null}
                    disabled={postalCode === null}
                    height={50}
                    loading={loading}
                    onPress={search}
                    title="住所検索"
                    width={96}
                  />
                </View>
              </View>
              <Spacer height={24} />

              <View>
                <Selectbox
                  control={control}
                  emptyValue=""
                  initialValue={data.prefectureName}
                  innerRef={prefectureRef}
                  items={prefectureItems}
                  label="都道府県"
                  name="prefectureName"
                  required
                />
                {!isValid && errors.prefectureName !== undefined && (
                  <ErrorText error={errors.prefectureName.message} />
                )}
              </View>
              <Spacer height={24} />
              <View>
                <TextField
                  control={control}
                  inputAccessoryViewID={KeyboardId}
                  label="市区町村"
                  name="cityName"
                  placeholder="市区町村を登録してください"
                  required
                />
                {!isValid && errors.cityName !== undefined && (
                  <ErrorText error={errors.cityName.message} />
                )}
              </View>
              <Spacer height={24} />
              <View>
                <TextField
                  control={control}
                  inputAccessoryViewID={KeyboardId}
                  label="町名番地"
                  name="townName"
                  placeholder="町名番地を登録してください"
                  required
                />
                {!isValid && errors.townName !== undefined && (
                  <ErrorText error={errors.townName.message} />
                )}
              </View>
              <Spacer height={24} />
              <View>
                <TextField
                  control={control}
                  inputAccessoryViewID={KeyboardId}
                  label="建物名・部屋番号"
                  name="buildingName"
                  placeholder="建物名・部屋番号を登録してください"
                />
                {!isValid && errors.buildingName !== undefined && (
                  <ErrorText error={errors.buildingName.message} />
                )}
                <Spacer height={12} />
              </View>
            </Card>
            <Spacer height={100} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>

      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  postalcode: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  field: {
    flex: 1,
  },
  search: {
    width: 96,
    paddingTop: 20,
  },
  message: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
