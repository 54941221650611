/**
 * @generated SignedSource<<cbc1f81d9b929e3bc42693883f21e2c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputEmailConfirmEmail$data = {
  readonly newEmail: string | null;
  readonly " $fragmentType": "CampaignInputEmailConfirmEmail";
};
export type CampaignInputEmailConfirmEmail$key = {
  readonly " $data"?: CampaignInputEmailConfirmEmail$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputEmailConfirmEmail">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputEmailConfirmEmail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newEmail",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "fac3e2917f52326ab1eb900f1953ee38";

export default node;
