import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View, Wrapper, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Button from "@components/atoms/Button";
import GradientButton from "@components/atoms/GradientButton";
import GradientText from "@components/atoms/GradientText";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import { navigate, replace } from "@navigation/navigate";
import { thankYou } from "@components/atoms/AssetImage";

export default function ThankYou() {
  return (
    <Card style={styles.container}>
      <ScrollView>
        <Wrapper>
          <Card style={styles.container}>
            <View style={styles.thankYou}>
              <Text style={styles.campaignApplicationText}>
                キャンペーンの応募
              </Text>
              <Spacer height={16} />
              <GradientText
                grColor={Colors.grPink}
                height={56}
                style={styles.thankYouText}
              >
                Thank You
              </GradientText>
            </View>

            <View style={styles.illustWrapper}>
              <View style={styles.illust}>
                <ResponsiveImage height={294} source={thankYou} width={358} />
              </View>
            </View>

            <View style={styles.explanation}>
              <Text style={styles.explanationText}>
                お申し込みされたキャンペーンの結果が出るまで、もうしばらくお待ちください。結果が出ましたら「ワーク」に通知が行きます。
              </Text>
            </View>

            <View style={styles.buttons}>
              <Button
                onPress={() => {
                  navigate("Root", { screen: "Home" });
                }}
                title="キャンペーンへ"
              />

              <Spacer height={16} />

              <GradientButton
                onPress={() => {
                  replace("Root", {
                    screen: "Todo",
                    params: { tab: "Recruiting" },
                  });
                }}
                title="ワークの「応募中」へ"
              />
            </View>
            <Spacer height={16} />
          </Card>
        </Wrapper>
      </ScrollView>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  thankYou: {
    alignItems: "center",
    justifyContent: "center",
    height: 164,
    width: "100%",
  },
  campaignApplicationText: {
    ...Fonts.xxlb140,
    color: Colors.black,
  },
  thankYouText: {
    ...Fonts.enxlb100,
  },
  illustWrapper: {
    alignItems: "center",
  },
  illust: {
    height: 294,
    maxWidth: 358,
    width: "100%",
    marginHorizontal: 16,
  },
  explanation: {
    alignItems: "center",
    justifyContent: "center",
    height: 110,
    width: "100%",
    paddingHorizontal: 16,
  },
  explanationText: {
    alignItems: "center",
    justifyContent: "flex-start",
    ...Fonts.lr130,
    color: Colors.black,
  },
  buttons: {
    alignItems: "center",
  },
});
