/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from "react";
import {
  KeyboardAvoidingView as RnKeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";

type Props = {
  /** 子要素 */
  children: React.ReactNode;
  keyboardVerticalOffset?: number;
};

export default function KeyboardAvoidingView({
  children,
  keyboardVerticalOffset = 0,
}: Props): JSX.Element {
  const keyboradProps = useMemo(() => {
    switch (Platform.OS) {
      case "ios":
        return {
          style: styles.containar,
          behavior: "padding" as const,
          keyboardVerticalOffset,
        };
      case "android":
        return {
          style: styles.containar,
          behavior: "height" as const,
          keyboardVerticalOffset,
        };
      default:
        return {
          style: styles.containar,
          behavior: "height" as const,
          keyboardVerticalOffset: undefined,
        };
    }
  }, [keyboardVerticalOffset]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RnKeyboardAvoidingView
      behavior={keyboradProps.behavior}
      enabled
      keyboardVerticalOffset={keyboradProps.keyboardVerticalOffset}
      style={keyboradProps.style}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <>{children}</>
      </TouchableWithoutFeedback>
    </RnKeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  containar: {
    flex: 1,
  },
});
