/**
 * @generated SignedSource<<fbed67f3ae52d9bdec25dcda4430649b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignCast$data = {
  readonly socialNetwork: {
    readonly buildingName: string | null;
    readonly castContent: string | null;
    readonly castIntro: string | null;
    readonly castPlanName: string | null;
    readonly cityName: string | null;
    readonly deliveryOn: any | null;
    readonly postalCode: string | null;
    readonly prefectureName: string | null;
    readonly productUrl: string | null;
    readonly relatedCasts: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly relatedInfoUrl: string;
    }> | null;
    readonly townName: string | null;
  };
  readonly " $fragmentType": "CampaignCast";
};
export type CampaignCast$key = {
  readonly " $data"?: CampaignCast$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignCast">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignCast",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialNetwork",
      "kind": "LinkedField",
      "name": "socialNetwork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castPlanName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefectureName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cityName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "townName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buildingName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castIntro",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RelatedCast",
          "kind": "LinkedField",
          "name": "relatedCasts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relatedInfoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "77c7fb05fc604911e0016f870362938e";

export default node;
