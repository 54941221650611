/**
 * @generated SignedSource<<4172d507b6c94884e785f7a9629a7d69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
import { FragmentRefs } from "relay-runtime";
export type ApplyConfirmation$data = {
  readonly gifting: boolean;
  readonly giftingDetail: string | null;
  readonly prType: CampaignPrType | null;
  readonly " $fragmentType": "ApplyConfirmation";
};
export type ApplyConfirmation$key = {
  readonly " $data"?: ApplyConfirmation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyConfirmation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyConfirmation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gifting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "giftingDetail",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "872e098cd5aba3b4daf746a0fa6216ab";

export default node;
