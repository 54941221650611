import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import days from "dayjs";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, Card, View, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import {
  KeyboardId,
  OtherContentMaxLength,
  PostMaxImageSize,
  PostMaxVideoSize,
} from "@constants/App";
import Divider from "@components/atoms/Divider";
import AttachmentsUploader, {
  isUploadedFile,
} from "@components/molecules/AttachmentsUploader";
import { makeUploadablesFromFileList } from "@lib/util/relaySupport";
import { ImageFile } from "@components/molecules/ImagePicker";
import { MediaTypeOptions } from "expo-image-picker";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import { PostReportCast$key } from "@generated/PostReportCast.graphql";
import { PostReportCastMutation } from "@generated/PostReportCastMutation.graphql";
import {
  TextArea,
  TextField,
  ErrorText,
} from "@components/molecules/TextInput";
import ConfirmDialog from "@components/organisms/PostReport/PostReportConfirmDialog";
import { resolveError } from "@lib/util/error";

const chatReport = graphql`
  fragment PostReportCast on Post {
    id
    status
    campaign {
      socialNetwork {
        castEndAt
      }
    }
    postReport {
      supplementaryInfo
      url
      attachments {
        id
        file
        duration
        contentType
      }
    }
  }
`;

const postReportMutation = graphql`
  mutation PostReportCastMutation(
    $input: CreatePostReportsMutationInput!
    $connections: [ID!]!
  ) {
    createPostReports(input: $input) {
      __typename
      ... on CreatePostReportsSuccess {
        post {
          id
          type
          status
          url
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on CreatePostReportsError {
        message
      }
    }
  }
`;

type ReportInput = {
  url: string | null;
  supplementaryInfo: string | null;
};

export default function PostReportCast({
  postFragment,
  onClose,
  onChange,
  onLoading,
}: {
  postFragment: PostReportCast$key;
  onClose: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
}) {
  const data = useFragment(chatReport, postFragment);
  const [error, setError] = useState<string | null>(null);
  const [postable, setPostable] = useState<boolean>(false);
  const [commit] = useMutation<PostReportCastMutation>(postReportMutation);
  const [imageList, setImageList] = useState<ImageFile[]>([]);
  const [confirm, setConfirm] = useState<boolean>(false);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );
  const startDate = useMemo<days.Dayjs>(
    () => days(data.campaign.socialNetwork.castEndAt),
    [data]
  );
  const canPublish = useMemo<boolean>(
    () => days().format("YYYYMMDD") >= startDate.format("YYYYMMDD"),
    [startDate]
  );
  const uploaded = useMemo(() => {
    if (data.postReport === null) {
      return [];
    }
    return data.postReport.attachments.map((row) => row);
  }, [data]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ReportInput>({
    defaultValues: {
      supplementaryInfo: data.postReport?.supplementaryInfo ?? "",
      url: data.postReport?.url ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        supplementaryInfo: string()
          .default(null)
          .notRequired()
          .trim()
          .max(
            OtherContentMaxLength,
            `報告内容は${OtherContentMaxLength}文字以内で入力してください`
          )
          .nullable(),
        url: string()
          .default(null)
          .notRequired()
          .trim()
          .url("正しいURLを入力してください")
          .nullable(),
      })
    ),
  });

  const onChangeInput = useCallback(
    (column: "url" | "supplementaryInfo", value: string) => {
      if (column === "url" && value !== data.postReport?.url && value !== "") {
        onChange(true);
      } else if (
        column === "supplementaryInfo" &&
        value !== data.postReport?.supplementaryInfo &&
        value !== ""
      ) {
        onChange(true);
      } else {
        onChange(false);
      }
    },
    [onChange, data]
  );

  useEffect(() => {
    setPostable(isValid && canPublish);
  }, [isValid, imageList, canPublish]);

  const save = useCallback(async () => {
    await handleSubmit(async ({ url, supplementaryInfo }: ReportInput) => {
      try {
        setConfirm(false);
        onLoading(true);
        const files: File[] = [];
        imageList.forEach((image) => {
          if (!isUploadedFile(image)) {
            files.push(image.file);
          }
        });
        const uploadables = await makeUploadablesFromFileList(
          "variables.input.postReports.0.files",
          imageList.map((row) => row.file)
        );
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                postId: data.id,
                postReports: [
                  {
                    url,
                    supplementaryInfo,
                    files: imageList.map(() => null),
                    priority: 0,
                    mediaProductType: "media_product_none",
                  },
                ],
              },
              connections: [connectionID],
            },
            uploadables,
            onCompleted({ createPostReports }) {
              if (createPostReports.__typename === "CreatePostReportsSuccess") {
                resolve();
              } else {
                reject(
                  createPostReports.__typename === "CreatePostReportsError"
                    ? createPostReports.message
                    : "保存できませんでした"
                );
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onLoading(false);
      }
    })();
  }, [commit, connectionID, data, imageList, onClose, onLoading, handleSubmit]);

  return (
    <View style={styles.content}>
      <KeyboardAvoidingView keyboardVerticalOffset={48}>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />
            <Card style={styles.card}>
              <Text style={styles.text}>
                {startDate.format("M月D日")}
                になったら完了報告を行ってください。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                報告するURLがあれば入力してください。
              </Text>

              <Spacer height={16} />

              <TextField
                control={control}
                disabled={data.status !== "wait_publish"}
                inputAccessoryViewID={KeyboardId}
                name="url"
                onChange={(value: string) => onChangeInput("url", value)}
                placeholder="URL"
                placeholderTextColor={Colors.gray}
                type="url"
              />
              {!isValid && errors.url !== undefined && (
                <ErrorText error={errors.url.message} />
              )}
            </Card>

            <Card style={styles.card}>
              <Text style={styles.text}>
                添付ファイルがある場合はアップロードしてください。(最大10枚、写真
                {PostMaxImageSize}MB、動画{PostMaxVideoSize}MBまで)
              </Text>

              <Spacer height={16} />
              {data.status !== "wait_publish" && uploaded.length === 0 ? (
                <Text>動画・画像は選択されていません。</Text>
              ) : (
                <AttachmentsUploader
                  items={uploaded}
                  maxImageSize={PostMaxImageSize}
                  maxVideoSize={PostMaxVideoSize}
                  onChange={(e: ImageFile[]) => {
                    setImageList(e);
                    onChange(true);
                  }}
                  onMessage={setError}
                  pickupMediaType={MediaTypeOptions.All}
                  selectionLimit={10}
                  uploadable={data.status === "wait_publish"}
                />
              )}

              <Spacer height={16} />
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>報告内容を記載してください。</Text>

              <Spacer height={16} />

              <TextArea
                control={control}
                disabled={data.status !== "wait_publish"}
                inputAccessoryViewID={KeyboardId}
                label="報告内容"
                maxLength={OtherContentMaxLength}
                name="supplementaryInfo"
                numberOfLines={9}
                onChange={(value: string) =>
                  onChangeInput("supplementaryInfo", value)
                }
                useCounter
              />
              {!isValid && errors.supplementaryInfo !== undefined && (
                <ErrorText error={errors.supplementaryInfo.message} />
              )}
            </Card>
          </Wrapper>

          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {data.status === "wait_publish" && (
        <>
          <Divider />
          <Card style={styles.action}>
            {!canPublish && (
              <>
                <Text style={styles.alert}>
                  {startDate.format("M月D日")}
                  になるまでお待ち下さい
                </Text>
                <Spacer height={8} />
              </>
            )}
            <GradientButton
              disabled={!postable}
              onPress={() => {
                setConfirm(true);
              }}
              textStyle={styles.actionText}
              title="報告する"
            />
          </Card>
        </>
      )}
      {confirm && (
        <ConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  scroll: {
    flexGrow: 1,
  },
  card: {
    padding: 16,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    color: Colors.gray,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  draft: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  draftText: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
