import ReactNavigation from "@react-navigation/native";

const Colors = {
  /**------------------------------
   * 個別画面から呼び出される色
   ------------------------------*/
  // Base Color
  green: "#14CAE2",
  white: "#FFFFFF",
  white10: "#F7F9FF",
  black0: "#000000",
  black: "#292C35",
  gray: "#8E8E93",
  gray10: "#F4F4F4",
  gray20: "#EFEFEF",
  gray30: "#DEDEDE",
  gray40: "#CDCDCD",
  orange: "#FF5E2B",
  orange10: "#FFE9D9",
  blue: "#448EF6",
  // Gradient
  grPrimary: ["#5281D8", "#50C1C7"],
  grBlue: ["#7CBEF8", "#14CAE2"],
  grGreen: ["#14E2A4", "#14D6E2"],
  grPurple: ["#D694F3", "#A298F9"],
  grPink: ["#58CCE6", "#FF3CC8"],
  grBlack90: ["#00000000", "#191919"],
  grBlack60: ["#00000099", "#00000000"],
  // overlay
  overlayWhite: "#FFFFFF60",
  overlayDark: "#00000060",
  overlayDarkMedium: "#00000040",
  overlayDarkLight: "#00000030",
  // transparent
  transparent: "transparent",
  // SNS Button backgrounds
  lineGreen: "#06C755",
};

export const LightTheme: ReactNavigation.Theme = {
  colors: {
    background: Colors.gray10,
    text: Colors.black,
    border: Colors.gray20,
    card: Colors.white,
    notification: Colors.white,
    primary: Colors.green,
  },
  dark: false,
};

export const DarkTheme: ReactNavigation.Theme = {
  colors: {
    background: "#1F1D1B",
    text: Colors.white,
    border: Colors.gray20,
    card: "#000000",
    notification: "#343439",
    primary: Colors.green,
  },
  dark: true,
};

export default Colors;
