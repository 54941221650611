import React from "react";
import { View } from "@components/atoms/Themed";
import HashTag from "@components/atoms/HashTag";
import { StyleSheet } from "react-native";

type Props = {
  tags: Array<string>;
};

export default function HashTags({ tags }: Props): JSX.Element {
  return (
    <View style={styles.list}>
      {tags.map((tag, index) => {
        const key = `hash-${index}`;
        return (
          <View key={key} style={styles.item}>
            <HashTag>{tag}</HashTag>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  item: {
    padding: 4,
  },
});
