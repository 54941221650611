import React from "react";
import { StyleSheet } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import GradientButton from "@components/atoms/GradientButton";
import * as Linking from "expo-linking";
import { appStoreUrl } from "@constants/Env";
import { squareIcon } from "@components/atoms/AssetImage";

export default function FallbackUpdate() {
  return (
    <>
      <Spacer height={20} />
      <View style={styles.logoWrapper}>
        <View style={styles.squareIcon}>
          <ResponsiveImage height={148} source={squareIcon} width={595} />
        </View>
      </View>
      <Spacer height={20} />
      <Spacer height={30} />
      <View style={styles.textArea}>
        <Text style={styles.caption}>
          InfluencerWorksをご利用ありがとうございます。
          {"\n"}
          {"\n"}
          新しいアプリのバージョンがリリースされています。
          ストアよりアップデートしてください。
        </Text>
      </View>
      <Spacer height={30} />
      <View style={styles.buttonArea}>
        <GradientButton
          onPress={() => Linking.openURL(appStoreUrl)}
          title="アップデートする"
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  textArea: {
    paddingHorizontal: 16,
  },
  logoWrapper: {
    alignItems: "center",
  },
  squareIcon: {
    width: "100%",
    maxWidth: 390,
    height: 58,
  },
  caption: {
    ...Fonts.lr130,
  },
  buttonArea: {
    alignItems: "center",
    justifyContent: "center",
  },
});
