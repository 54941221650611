import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import _ from "lodash";
import { object, string, date } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  Selectbox,
  DateTimePicker,
  ErrorText,
} from "@components/molecules/TextInput";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { ProfileEdit$key } from "@generated/ProfileEdit.graphql";
import { ProfileEditAvatarMutation } from "@generated/ProfileEditAvatarMutation.graphql";
import { ProfileEditProfileMutation } from "@generated/ProfileEditProfileMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import Toast from "@components/atoms/Toast";
import Avatar from "@components/atoms/Avatar";
import GradientButton from "@components/atoms/GradientButton";
import { ProfileList, KeyboardId } from "@constants/App";
import ImagePicker, { ImageFile } from "@components/molecules/ImagePicker";
import { makeUploadablesFromFile } from "@lib/util/relaySupport";
import Divider from "@components/atoms/Divider";
import dayjs from "dayjs";

const AvatarSize = 120;

const profileQuery = graphql`
  fragment ProfileEdit on Influencer {
    avatarUrl
    profile {
      commonName
      gender
      birthday
    }
  }
`;

const updateAvatar = graphql`
  mutation ProfileEditAvatarMutation($input: SaveAvatarMutationInput!) {
    saveAvatar(input: $input) {
      __typename
      ... on Influencer {
        avatarUrl
      }
      ... on SaveAvatarError {
        message
      }
    }
  }
`;

const updateProfile = graphql`
  mutation ProfileEditProfileMutation(
    $input: UpdateInfluencerInfoMutationInput!
  ) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          commonName
          gender
          birthday
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type ProfileInput = {
  commonName: string;
  gender: string;
  birthday: Date;
};

const genderList = Object.keys(ProfileList.gender).map((column) => ({
  label: ProfileList.gender[column],
  value: column,
}));

export default function ProfileEdit({
  profileFragment,
}: {
  profileFragment: ProfileEdit$key;
}) {
  const { avatarUrl, profile } = useFragment(profileQuery, profileFragment);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ProfileInput>({
    defaultValues: {
      commonName: profile.commonName ?? "",
      gender: profile.gender ?? "",
      birthday: _.isEmpty(profile.birthday)
        ? dayjs().subtract(25, "years").toDate()
        : dayjs(profile.birthday).toDate(),
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        commonName: string().trim().required("入力してください"),
        gender: string()
          .required("選択してください")
          .oneOf(Object.keys(ProfileList.gender), "選択してください"),
        birthday: date().required("生年月日を選択してください"),
      })
    ),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [commitProfile] =
    useMutation<ProfileEditProfileMutation>(updateProfile);
  const [commitAvatar] = useMutation<ProfileEditAvatarMutation>(updateAvatar);

  const saveProfile = useCallback(async () => {
    await handleSubmit(
      async ({ commonName, gender, birthday }: ProfileInput) => {
        setLoading(true);
        await new Promise<void>((resolve) => {
          commitProfile({
            variables: {
              input: {
                commonName,
                gender,
                birthday: dayjs(birthday).format("YYYY-MM-DD"),
              },
            },
            onCompleted({ updateInfluencerInfo }) {
              if (
                updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
              ) {
                setError(updateInfluencerInfo.message);
              } else {
                setMessage("保存しました");
              }
              resolve();
            },
          });
        });
        setLoading(false);
      }
    )();
  }, [commitProfile, handleSubmit]);

  const saveAvatar = useCallback(
    async (imageList: ImageFile) => {
      setUploading(true);

      const uploadables = await makeUploadablesFromFile(
        "variables.input.file",
        imageList.file
      );

      await new Promise<void>((resolve) => {
        commitAvatar({
          variables: {
            input: {
              file: null,
            },
          },
          uploadables,
          onCompleted({ saveAvatar: result }) {
            if (result.__typename === "SaveAvatarError") {
              setError(result.message);
            } else {
              setMessage("保存しました");
            }
            resolve();
          },
        });
      });
      setUploading(false);
    },
    [commitAvatar]
  );

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Card style={styles.content}>
            <Wrapper>
              <View style={styles.avatar}>
                <Avatar
                  size={AvatarSize}
                  uri={avatarUrl}
                  userType="Influencer"
                />
                <View style={styles.camera}>
                  <View style={styles.cameraButton}>
                    <ImagePicker
                      loading={uploading}
                      onChange={(imageList) => {
                        saveAvatar(imageList as ImageFile);
                      }}
                      onError={setError}
                      resizeSize={200}
                    />
                  </View>
                </View>
              </View>

              <Spacer height={24} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="ニックネーム"
                maxLength={30}
                name="commonName"
              />
              {!isValid && errors.commonName !== undefined && (
                <ErrorText error={errors.commonName.message} />
              )}
              <Text style={styles.guide}>30文字まで</Text>

              <Spacer height={16} />
              <Selectbox
                control={control}
                emptyValue=""
                initialValue={profile.gender}
                items={genderList}
                label="性別"
                name="gender"
              />
              {!isValid && errors.gender !== undefined && (
                <ErrorText error={errors.gender.message} />
              )}

              <Spacer height={16} />

              <DateTimePicker
                control={control}
                initialValue={profile.birthday}
                label="生年月日"
                name="birthday"
              />
              {!isValid && errors.birthday !== undefined && (
                <ErrorText error={errors.birthday.message} />
              )}
            </Wrapper>
          </Card>

          <Spacer height={100} />
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />

      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={saveProfile}
          title="保存する"
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  camera: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  cameraButton: {
    width: AvatarSize,
    height: AvatarSize,
    borderRadius: AvatarSize / 2,
    backgroundColor: Colors.overlayDarkLight,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
