/**
 * @generated SignedSource<<e401aef1a831d034ddc0a8b42467a1dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaProductType = "instagram_feed" | "instagram_live" | "instagram_reel" | "instagram_story" | "media_product_none";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type PostReportInstagram$data = {
  readonly campaign: {
    readonly mediaType: MediaType;
    readonly socialNetwork: {
      readonly postStartOn: any | null;
    };
  };
  readonly id: string;
  readonly postReports: ReadonlyArray<{
    readonly attachments: ReadonlyArray<{
      readonly contentType: string | null;
      readonly duration: number | null;
      readonly file: string;
      readonly id: string;
    }>;
    readonly mediaProductType: MediaProductType;
    readonly url: string | null;
  }> | null;
  readonly status: PostStatus;
  readonly " $fragmentType": "PostReportInstagram";
};
export type PostReportInstagram$key = {
  readonly " $data"?: PostReportInstagram$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostReportInstagram">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostReportInstagram",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SocialNetwork",
          "kind": "LinkedField",
          "name": "socialNetwork",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postStartOn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostReport",
      "kind": "LinkedField",
      "name": "postReports",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaProductType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contentType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "a005a66b6f97c563626f11099fb721c2";

export default node;
