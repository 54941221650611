/**
 * @generated SignedSource<<a35002da20b31dae3aafa33e2fad041d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneEdit$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhoneEditConfirm" | "PhoneEditForm">;
  readonly " $fragmentType": "PhoneEdit";
};
export type PhoneEdit$key = {
  readonly " $data"?: PhoneEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhoneEdit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhoneEdit",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PhoneEditConfirm"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PhoneEditForm"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "3ec815c00c265ad4bb63a90f2197832a";

export default node;
