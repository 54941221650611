import React from "react";
import { StyleSheet } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { View, Text, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import CustomIcon from "@components/atoms/Icon";
import { graphql, useFragment } from "react-relay/hooks";
import { ApplyProduct$key } from "@generated/ApplyProduct.graphql";

const applyProduct = graphql`
  fragment ApplyProduct on Campaign {
    giftingDetail
  }
`;

export default function ApplyProduct({
  campaignFragment,
}: {
  campaignFragment: ApplyProduct$key;
}) {
  const campaign = useFragment(applyProduct, campaignFragment);
  return (
    <Card style={styles.container}>
      <View style={styles.head}>
        <CustomIcon color={Colors.black} name="present" size={18} />
        <Spacer width={7} />
        <Text style={styles.title}>商品提供</Text>
      </View>
      <Spacer height={24} />
      <View style={styles.info}>
        <View style={styles.row}>
          <Text style={styles.name}>
            {campaign.giftingDetail ?? "商品提供はありません"}
          </Text>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.black,
  },
  info: {
    flexDirection: "row",
  },
  row: {
    flex: 1,
  },
  name: {
    color: Colors.black,
    ...Fonts.xlb160,
  },
});
