import React, { useState } from "react";
import {
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import { useFragment, graphql } from "react-relay/hooks";
import { MyPageInfo$key } from "@generated/MyPageInfo.graphql";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Dialog from "@components/molecules/dialog/Dialog";
import Spacer from "@components/atoms/Spacer";
import Profile from "@components/organisms/MyPage/MyPageProfile";
import Category from "@components/organisms/MyPage/MyPageCategory";
import Report from "@components/organisms/MyPage/MyPageReport";
import Sns from "@components/organisms/MyPage/MyPageSns";
import PersonalInformation from "@components/organisms/MyPage/MyPagePersonalInformation";
import Footer from "@components/organisms/MyPage/MyPageFooter";
import Setting from "@components/organisms/MyPage/MyPageSetting";
import Icon from "@components/atoms/Icon";
import openURL from "@lib/util/openUrl";
import { navigate } from "@navigation/navigate";

const myPageQuery = graphql`
  fragment MyPageInfo on Influencer {
    ...MyPageCategory
    ...MyPageProfile
    ...MyPageSns
    ...MyPageReport
    ...MyPagePersonalInformation
    ...MyPageSetting
  }
`;

export default function MyPage({
  influencerInfo,
}: {
  influencerInfo: MyPageInfo$key;
}) {
  const [guideNotification, setGuideNotification] = useState<boolean>(false);
  const data = useFragment(myPageQuery, influencerInfo);

  return (
    <View style={styles.container}>
      <Card style={styles.head}>
        <Text style={styles.title}>マイページ</Text>
        <View style={styles.icon}>
          <TouchableOpacity onPress={() => navigate("Report")}>
            <Icon name="graph" size={18} />
          </TouchableOpacity>
        </View>
      </Card>
      <ScrollView>
        <Wrapper>
          <Spacer height={16} />
          <Profile profileFragment={data} />
          <Spacer height={16} />

          <Category categoryFragment={data} />
          <Spacer height={16} />

          <Report reportFragment={data} />
          <Spacer height={16} />

          <Sns snsFragment={data} />
          <Spacer height={16} />

          <PersonalInformation personalInformationFragment={data} />
          <Spacer height={16} />

          {Platform.OS !== "web" && (
            <>
              <Setting
                myFragment={data}
                openGuide={() => setGuideNotification(true)}
              />
              <Spacer height={16} />
            </>
          )}

          <Footer />
          <Spacer height={24} />
        </Wrapper>
      </ScrollView>
      {guideNotification && (
        <Dialog
          buttons={[
            { title: "閉じる", type: "white" },
            {
              title: "設定画面へ",
              type: "blueGradient",
              onPress: () => openURL("app-settings:"),
            },
          ]}
          customStyle={{ messageStyle: styles.dialogError }}
          message="プッシュ通知へのアクセスが拒否されています"
          onClose={() => setGuideNotification(false)}
          subline="端末の設定画面からプッシュ通知を許可してください"
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 44,
  },
  title: {
    ...Fonts.xlb100,
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 18,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
