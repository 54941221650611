import React, { useState, useEffect, useCallback } from "react";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import SNSIcon from "@components/atoms/SNSIcon";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";
import Tag from "@components/atoms/Tag";
import { View, Text, Card } from "@components/atoms/Themed";
import { StyleSheet, Image, TouchableOpacity } from "react-native";
import Loading from "@components/atoms/Loading";
import campaignBanner from "@lib/util/campaignBanner";
import { buildCodeAsync } from "@lib/util/oauth";
import { ContractFileName } from "@constants/App";
import { ChatCampaign$key } from "@generated/ChatCampaign.graphql";
import { ChatCampaignMutation } from "@generated/ChatCampaignMutation.graphql";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import useMessage from "@hooks/useMessage";
import downloadFile from "@lib/util/downloadFile";
import { navigate } from "@navigation/navigate";
import { resolveError } from "@lib/util/error";

const chatCampaign = graphql`
  fragment ChatCampaign on Post {
    campaign {
      id
      title
      gifting
      prType
      parentMediaType
      status
      mCategory {
        name
      }
      banners {
        file
      }
    }
  }
`;

const createPdf = graphql`
  mutation ChatCampaignMutation($input: RequestSignedUrlMutationInput!) {
    requestSignedUrl(input: $input) {
      __typename
      ... on RequestSignedUrlSuccess {
        uri
      }
      ... on RequestSignedUrlError {
        message
      }
    }
  }
`;

type Props = {
  campaignFragment: ChatCampaign$key;
};

export default function ChatCampaign({ campaignFragment }: Props) {
  const { set: setMessage } = useMessage();
  const { campaign } = useFragment(chatCampaign, campaignFragment);
  const [commit] = useMutation<ChatCampaignMutation>(createPdf);
  const [loading, setLoading] = useState<boolean>(false);
  const [pkce, setPkce] = useState<{
    codeVerifier: string;
    codeChallenge: string;
  } | null>(null);

  const generatePkce = useCallback(async () => {
    const { codeVerifier, codeChallenge } = await buildCodeAsync();
    setPkce({
      codeVerifier,
      codeChallenge,
    });
  }, []);

  useEffect(() => {
    generatePkce();
  }, [generatePkce]);

  const download = useCallback(async () => {
    try {
      if (loading || pkce === null) {
        return;
      }
      setLoading(true);
      const uri = await new Promise<string>((resolve, reject) => {
        commit({
          variables: {
            input: {
              downloadableId: campaign.id,
              downloadableType: "Candidate",
              codeChallenge: pkce.codeChallenge,
            },
          },
          onCompleted({ requestSignedUrl }) {
            if (requestSignedUrl.__typename === "RequestSignedUrlSuccess") {
              resolve(requestSignedUrl.uri);
            } else {
              reject(
                requestSignedUrl.__typename === "RequestSignedUrlError"
                  ? requestSignedUrl.message
                  : "契約書をダウンロードできませんでした"
              );
            }
          },
        });
      });
      await downloadFile(
        `${uri}?codeVerifier=${pkce.codeVerifier}`,
        ContractFileName
      );
    } catch (error: unknown) {
      setMessage({
        title: resolveError(error).message,
        mode: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [setMessage, commit, campaign, loading, pkce]);

  return (
    <Card style={[styles.container, styles.row]}>
      <TouchableOpacity
        onPress={() => navigate("CampaignDetail", { id: campaign.id })}
      >
        <Image source={campaignBanner(campaign.banners)} style={styles.image} />
      </TouchableOpacity>

      <Spacer width={16} />
      <View style={styles.item}>
        <View style={styles.row}>
          <SNSIcon name={campaign.parentMediaType} style={styles.socialIcon} />
          <Spacer width={8} />
          <Tag>
            <Text style={styles.categoryText}>{campaign.mCategory.name}</Text>
          </Tag>
        </View>
        <Spacer height={4} />
        <TouchableOpacity
          onPress={() => navigate("CampaignDetail", { id: campaign.id })}
        >
          <Text ellipsizeMode="tail" numberOfLines={2} style={styles.title}>
            {campaign.title}
          </Text>
        </TouchableOpacity>

        <Spacer height={7} />

        <View style={styles.row}>
          {campaign.gifting && (
            <Icon color={Colors.gray} name="present" size={18} />
          )}
          {campaign.gifting && campaign.prType === "rewarded" && (
            <Spacer width={14} />
          )}
          {campaign.prType === "rewarded" && (
            <Icon color={Colors.gray} name="money" size={18} />
          )}
          <Spacer width={14} />
          <TouchableOpacity
            disabled={loading || pkce === null}
            onPress={download}
            style={styles.row}
          >
            <Text style={styles.link}>業務委託契約書</Text>
            <Spacer width={4} />
            <Icon color={Colors.blue} name="download" size={18} />
            {loading && <Loading mask />}
          </TouchableOpacity>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  link: {
    color: Colors.blue,
    ...Fonts.sr140,
  },
  image: {
    width: 120,
    height: 90,
  },
  item: {
    flex: 1,
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  category: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.green,
    paddingHorizontal: 8,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.green,
  },
  title: {
    paddingTop: 10, // 絵文字の場合上に突き刺さるため
    ...Fonts.lb100,
  },
});
