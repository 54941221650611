/**
 * @generated SignedSource<<d9d7e934f2846f49492d63c39fd075a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SnsLoginSnsTypes = "Apple" | "Line";
export type SnsLoginMutationInput = {
  clientMutationId?: string | null;
  codeExchange: string;
  codeVerifier: string;
  redirectUri: string;
  snsType: SnsLoginSnsTypes;
};
export type AuthEnterScreenMutation$variables = {
  input: SnsLoginMutationInput;
};
export type AuthEnterScreenMutation$data = {
  readonly snsLogin: {
    readonly __typename: "Influencer";
    readonly email: string;
    readonly id: string;
    readonly profile: {
      readonly commonName: string | null;
    };
  } | {
    readonly __typename: "SnsLoginError";
    readonly message: string;
  } | {
    readonly __typename: "SnsLoginToken";
    readonly refreshToken: string;
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AuthEnterScreenMutation = {
  response: AuthEnterScreenMutation$data;
  variables: AuthEnterScreenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refreshToken",
      "storageKey": null
    }
  ],
  "type": "SnsLoginToken",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commonName",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "SnsLoginError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthEnterScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "snsLogin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InfluencerInfo",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthEnterScreenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "snsLogin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InfluencerInfo",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea9aaa77d0140cc8e05d9950d472e665",
    "id": null,
    "metadata": {},
    "name": "AuthEnterScreenMutation",
    "operationKind": "mutation",
    "text": "mutation AuthEnterScreenMutation(\n  $input: SnsLoginMutationInput!\n) {\n  snsLogin(input: $input) {\n    __typename\n    ... on SnsLoginToken {\n      token\n      refreshToken\n    }\n    ... on Influencer {\n      id\n      email\n      profile {\n        commonName\n        id\n      }\n    }\n    ... on SnsLoginError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b1438b5edde2bdf67eeb7b3b571263d";

export default node;
