import React, { useMemo } from "react";
import { StyleSheet, Image } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import Divider from "@components/atoms/Divider";
import Spacer from "@components/atoms/Spacer";
import Colors from "@constants/Colors";
import { toDo, inProgress, done } from "@components/atoms/AssetImage";

type Props = {
  status: "toDo" | "inProgress" | "done";
  text: string;
  isEnd: boolean;
  isStart: boolean;
};

export default function StepChart({ status, text, isStart, isEnd }: Props) {
  const imgSourse = useMemo(() => {
    switch (status) {
      case "toDo":
        return toDo;
      case "inProgress":
        return inProgress;
      case "done":
      default:
        return done;
    }
  }, [status]);

  const dividerColor = useMemo(
    () => (status === "inProgress" ? Colors.green : Colors.gray40),
    [status]
  );

  return (
    <View style={styles.step}>
      <View style={styles.bar}>
        {!isStart ? (
          <Divider color={dividerColor} height={2} width={18} />
        ) : (
          <Spacer width={18} />
        )}
        <Spacer width={5} />
        <Image source={imgSourse} style={styles.dot} />
        <Spacer width={5} />
        {!isEnd ? (
          <Divider color={dividerColor} height={2} width={18} />
        ) : (
          <Spacer width={18} />
        )}
      </View>
      <Spacer height={4} />

      <Text style={[styles.text, status === "inProgress" && styles.activeText]}>
        {text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  step: {
    justifyContent: "center",
    alignItems: "center",
    width: 62,
  },
  bar: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    ...Fonts.xsr100,
    color: Colors.gray,
  },
  activeText: {
    color: Colors.green,
  },
  dot: {
    height: 16,
    width: 16,
  },
});
