/**
 * @generated SignedSource<<444b121b2a9fe217994e812e08163a48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportSummaryData$data = {
  readonly __typename: "SalesError";
  readonly message: string;
  readonly " $fragmentType": "ReportSummaryData";
} | {
  readonly __typename: "SalesSummary";
  readonly date: any;
  readonly id: string;
  readonly totalMargin: number | null;
  readonly totalPayment: number | null;
  readonly totalSales: number | null;
  readonly totalTaxDeduction: number | null;
  readonly " $fragmentType": "ReportSummaryData";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ReportSummaryData";
};
export type ReportSummaryData$key = {
  readonly " $data"?: ReportSummaryData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportSummaryData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportSummaryData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalMargin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPayment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSales",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaxDeduction",
          "storageKey": null
        }
      ],
      "type": "SalesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "type": "SalesError",
      "abstractKey": null
    }
  ],
  "type": "Sales",
  "abstractKey": "__isSales"
};

(node as any).hash = "2e3cea6336bb3e350dd279b3d8e2eb7c";

export default node;
