import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  Selectbox,
  ErrorText,
} from "@components/molecules/TextInput";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { BankEdit$key } from "@generated/BankEdit.graphql";
import { BankEditSaveMutation } from "@generated/BankEditSaveMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import {
  BankTypeAccount,
  BankOwnerNamePattern,
  KeyboardId,
} from "@constants/App";
import Divider from "@components/atoms/Divider";

const bankEditQuery = graphql`
  fragment BankEdit on Influencer {
    id
    bankAccount {
      id
      accountNumberAttribute
      bankName
      branchName
      codeBranch
      ownerNameKana
      typeAccount
    }
  }
`;

const saveMutation = graphql`
  mutation BankEditSaveMutation($input: SaveBankAccountMutationInput!) {
    saveBankAccount(input: $input) {
      __typename
      ... on Influencer {
        id
        bankAccount {
          id
          accountNumberAttribute
          bankName
          branchName
          codeBranch
          ownerNameKana
          typeAccount
        }
      }
      ... on BankAccountError {
        message
      }
    }
  }
`;

type BankInput = {
  accountNumberAttribute: string;
  bankName: string;
  branchName: string;
  codeBranch: string;
  ownerNameKana: string;
  typeAccount: string;
};

const bankTypes = Object.keys(BankTypeAccount).map((column) => ({
  label: BankTypeAccount[column],
  value: column,
}));

export default function BankEdit({
  bankFragment,
}: {
  bankFragment: BankEdit$key;
}) {
  const { id, bankAccount } = useFragment(bankEditQuery, bankFragment);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<BankInput>({
    defaultValues: {
      accountNumberAttribute: bankAccount?.accountNumberAttribute ?? "",
      bankName: bankAccount?.bankName ?? "",
      branchName: bankAccount?.branchName ?? "",
      codeBranch: bankAccount?.codeBranch ?? "",
      ownerNameKana: bankAccount?.ownerNameKana ?? "",
      typeAccount: bankAccount?.typeAccount ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        accountNumberAttribute: string()
          .trim()
          .required("入力してください")
          .numberString("半角数字で入力してください"),
        bankName: string().trim().required("入力してください"),
        branchName: string().trim().required("入力してください"),
        codeBranch: string()
          .trim()
          .required("入力してください")
          .numberString("半角数字で入力してください"),
        ownerNameKana: string()
          .trim()
          .required("入力してください")
          .matches(
            BankOwnerNamePattern,
            "カタカナ全半角、アルファベット（大文字）、英数字、().-/のみで入力してください。"
          ),
        typeAccount: string()
          .required("選択してください")
          .oneOf(Object.keys(BankTypeAccount), "選択してください"),
      })
    ),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<BankEditSaveMutation>(saveMutation);

  const save = useCallback(async () => {
    await handleSubmit(async (bankInput: BankInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...bankInput,
              influencerId: id,
            },
          },
          onCompleted({ saveBankAccount }) {
            if (saveBankAccount.__typename === "Influencer") {
              setMessage("保存しました");
            } else {
              setError(
                saveBankAccount.__typename === "BankAccountError"
                  ? saveBankAccount.message
                  : "保存できませんでした"
              );
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, id, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Spacer height={8} />
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                報酬振り込みができる口座を入力してください。入力がない場合、キャンペーン終了後に報酬がもらえません。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.content}>
              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="銀行名"
                name="bankName"
              />
              {!isValid && errors.bankName !== undefined && (
                <ErrorText error={errors.bankName.message} />
              )}

              <Spacer height={16} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="支店番号"
                name="codeBranch"
                type="phone"
              />
              {!isValid && errors.codeBranch !== undefined && (
                <ErrorText error={errors.codeBranch.message} />
              )}
              <Spacer height={16} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="支店名"
                name="branchName"
              />
              {!isValid && errors.branchName !== undefined && (
                <ErrorText error={errors.branchName.message} />
              )}

              <Spacer height={16} />

              <Selectbox
                control={control}
                emptyValue=""
                items={bankTypes}
                label="口座種別"
                name="typeAccount"
              />
              {!isValid && errors.typeAccount !== undefined && (
                <ErrorText error={errors.typeAccount.message} />
              )}
              <Spacer height={16} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="口座番号"
                name="accountNumberAttribute"
                type="phone"
              />
              {!isValid && errors.accountNumberAttribute !== undefined && (
                <ErrorText error={errors.accountNumberAttribute.message} />
              )}
              <Spacer height={16} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="口座名義カナ"
                name="ownerNameKana"
              />
              {!isValid && errors.ownerNameKana !== undefined && (
                <ErrorText error={errors.ownerNameKana.message} />
              )}
            </Card>

            <Spacer height={80} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
