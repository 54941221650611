import React, { useMemo } from "react";
import {
  TextStyle,
  Text,
  View,
  StyleSheet,
  DimensionValue,
} from "react-native";
import Ripple from "@components/atoms/Ripple";
import Icon, { IconName } from "@components/atoms/Icon";
import Loading from "@components/atoms/Loading";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";

const IconWrapper = 1.7;

type Props = {
  /** ボタンに表示する文字列 */
  title: string;
  /** タップ時のイベント */
  onPress: () => void;
  /** ボタンに表示する文字のスタイル */
  textStyle?: TextStyle;
  /** 表示したいアイコン */
  icon?: IconName;
  /** アイコンのサイズ */
  iconSize?: number;
  /** アイコンの色 */
  iconColor?: string;
  /** ボタンの横幅 */
  width?: DimensionValue;
  /** ボタンの角の丸み */
  borderRadius?: number;
  /** ボタンの縦幅 */
  height?: DimensionValue;
  /** ボタンの背景色 */
  backgroundColor?: string;
  /** 通信中フラグ。trueの場合はボタンをタップできなくなる */
  loading?: boolean;
  /** ボタンがタップ可能か？ */
  disabled?: boolean;
  /** borderを設定するか否か */
  border?: boolean;
};

export default function Button({
  title,
  onPress,
  icon,
  iconSize = 30,
  loading,
  textStyle,
  iconColor = Colors.black,
  width = 285,
  height = 48,
  backgroundColor = Colors.white,
  disabled,
  borderRadius = 4,
  border = true,
}: Props): JSX.Element {
  const dynamicStyleR = useMemo(
    () =>
      StyleSheet.create({
        ripple: {
          width,
          height,
        },
      }),
    [width, height]
  );
  const dynamicStyleI = useMemo(
    () =>
      StyleSheet.create({
        icon: {
          width: iconSize * IconWrapper,
        },
      }),
    [iconSize]
  );

  return (
    <View
      style={[
        styles.container,
        border && styles.containerBorder,
        {
          width,
          height,
          borderRadius,
          backgroundColor,
        },
      ]}
    >
      <Ripple
        disabled={disabled === true || loading === true}
        onPress={onPress}
        rippleColor={(textStyle?.color as string) ?? Colors.black}
        style={dynamicStyleR.ripple}
      >
        <View style={styles.wrapper}>
          {icon !== undefined && (
            <View style={[styles.icon, dynamicStyleI.icon]}>
              <Icon color={iconColor} name={icon} size={iconSize} />
            </View>
          )}
          {disabled === true ? (
            <View style={[styles.button, styles.disabled]}>
              <Text style={[styles.text, textStyle, styles.disabledText]}>
                {title}
              </Text>
            </View>
          ) : (
            <View style={[styles.button]}>
              <Text style={[styles.text, textStyle]}>{title}</Text>
            </View>
          )}
          {loading === true && <Loading mask size="small" />}
        </View>
      </Ripple>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    overflow: "hidden",
  },
  containerBorder: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray30,
  },
  wrapper: {
    flex: 1,
  },
  button: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  disabled: {
    backgroundColor: Colors.gray20,
  },
  icon: {
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    ...Fonts.xlb100,
  },
  disabledText: {
    color: Colors.gray,
  },
});
