import React from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import PrivacyPolicy from "@components/templates/PrivacyPolicy";

export default function PrivacyPolicyScreen(): JSX.Element {
  return (
    <View style={styles.content}>
      <NavigationBar onClose={goBack} title="プライバシーポリシー" />
      <PrivacyPolicy />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});
