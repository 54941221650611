import React, { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import SNSIcon from "@components/atoms/SNSIcon";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import Button from "@components/atoms/Button";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { MyPageSns$key } from "@generated/MyPageSns.graphql";
import { MyPageSnsUnlinkMutation } from "@generated/MyPageSnsUnlinkMutation.graphql";
import { navigate } from "@navigation/navigate";
import OAuth from "@components/molecules/OAuth";
import useMessage from "@hooks/useMessage";
import Dialog from "@components/molecules/dialog/Dialog";

const myPageSns = graphql`
  fragment MyPageSns on Influencer {
    linkingInstagram
    linkingTwitter
    profile {
      ytChannel
      tiktokUrl
    }
  }
`;

const unlinkQuery = graphql`
  mutation MyPageSnsUnlinkMutation($tiktokUrl: String, $ytChannel: String) {
    updateInfluencerInfo(
      input: { tiktokUrl: $tiktokUrl, ytChannel: $ytChannel }
    ) {
      __typename
      ... on Influencer {
        profile {
          tiktokUrl
          ytChannel
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;
export default function MyPageSns({
  snsFragment,
}: {
  snsFragment: MyPageSns$key;
}) {
  const { set: setMessage } = useMessage();
  const { linkingInstagram, linkingTwitter, profile } = useFragment(
    myPageSns,
    snsFragment
  );
  const [commit] = useMutation<MyPageSnsUnlinkMutation>(unlinkQuery);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<"TikTok" | "YouTube" | null>(null);

  const unlink = useCallback(
    async (type: "TikTok" | "YouTube") => {
      setLoading(true);
      setConfirm(null);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            tiktokUrl: type === "TikTok" ? null : profile.tiktokUrl,
            ytChannel: type === "YouTube" ? null : profile.ytChannel,
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setMessage({
                title: `${type}連携を解除できませんでした`,
                mode: "toast",
              });
            } else {
              setMessage({
                title: `${type}連携を解除しました`,
                mode: "toast",
              });
            }
            resolve();
          },
        });
      });
      setLoading(false);
    },
    [commit, profile, setMessage]
  );

  return (
    <Card style={styles.container}>
      <View style={[styles.item, styles.noBordered]}>
        <Text style={styles.page}>連携SNS</Text>
      </View>

      <View style={[styles.item, styles.noBordered]}>
        <SNSIcon name="instagram" style={styles.socialIcon} />
        <Spacer width={16} />
        <View style={styles.title}>
          <Text style={styles.text}>Instagram</Text>
        </View>

        {linkingInstagram === true ? (
          <>
            <Text style={styles.active}>連携済</Text>
            <Spacer width={24} />
            <OAuth
              action="Linking"
              active
              callbackUrl="mypage"
              type="Instagram"
            >
              <View style={styles.button}>
                <Text style={styles.buttonText}>解除</Text>
              </View>
            </OAuth>
          </>
        ) : (
          <>
            <Text style={styles.text}>未設定</Text>
            <Spacer width={24} />
            <OAuth
              action="Linking"
              callbackUrl="mypage"
              type="Instagram"
              useGuide
            >
              <View style={[styles.button, styles.activeButton]}>
                <Text style={[styles.buttonText, styles.activeText]}>連携</Text>
              </View>
            </OAuth>
          </>
        )}
      </View>

      <View bordered style={styles.item}>
        <SNSIcon name="twitter" style={styles.socialIcon} />
        <Spacer width={16} />
        <View style={styles.title}>
          <Text style={styles.text}>X</Text>
        </View>

        {linkingTwitter === true ? (
          <>
            <Text style={styles.active}>連携済</Text>
            <Spacer width={24} />
            <OAuth action="Linking" active callbackUrl="mypage" type="Twitter">
              <View style={styles.button}>
                <Text style={styles.buttonText}>解除</Text>
              </View>
            </OAuth>
          </>
        ) : (
          <>
            <Text style={styles.text}>未設定</Text>
            <Spacer width={24} />
            <OAuth action="Linking" callbackUrl="mypage" type="Twitter">
              <View style={[styles.button, styles.activeButton]}>
                <Text style={[styles.buttonText, styles.activeText]}>連携</Text>
              </View>
            </OAuth>
          </>
        )}
      </View>

      <View bordered style={styles.item}>
        <SNSIcon name="youtube" style={styles.socialIcon} />
        <Spacer width={16} />
        <View style={styles.title}>
          <Text style={styles.text}>YouTube</Text>
        </View>
        {profile.ytChannel !== null ? (
          <>
            <Text style={styles.active}>連携済</Text>
            <Spacer width={24} />
            <Button
              height={32}
              loading={loading}
              onPress={() => setConfirm("YouTube")}
              textStyle={styles.buttonText}
              title="解除"
              width={64}
            />
          </>
        ) : (
          <>
            <Text style={styles.text}>未設定</Text>
            <Spacer width={24} />
            <Button
              backgroundColor={Colors.green}
              border={false}
              height={32}
              onPress={() => navigate("SnsAssociation", { tab: "youtube" })}
              textStyle={{
                ...styles.buttonText,
                ...styles.activeText,
              }}
              title="連携"
              width={64}
            />
          </>
        )}
      </View>
      <View bordered style={styles.item}>
        <SNSIcon name="tiktok" style={styles.socialIcon} />
        <Spacer width={16} />
        <View style={styles.title}>
          <Text style={styles.text}>TikTok</Text>
        </View>
        {profile.tiktokUrl !== null ? (
          <>
            <Text style={styles.active}>連携済</Text>
            <Spacer width={24} />
            <Button
              height={32}
              loading={loading}
              onPress={() => setConfirm("TikTok")}
              textStyle={styles.buttonText}
              title="解除"
              width={64}
            />
          </>
        ) : (
          <>
            <Text style={styles.text}>未設定</Text>
            <Spacer width={24} />
            <Button
              backgroundColor={Colors.green}
              border={false}
              height={32}
              onPress={() => navigate("SnsAssociation", { tab: "tiktok" })}
              textStyle={{
                ...styles.buttonText,
                ...styles.activeText,
              }}
              title="連携"
              width={64}
            />
          </>
        )}
      </View>
      {confirm !== null && (
        <Dialog
          buttons={[
            { title: "キャンセル", type: "white" },
            {
              title: "解除する",
              type: "orange",
              onPress: () => unlink(confirm),
            },
          ]}
          message={`${confirm}連携を解除しますか？`}
          onClose={() => setConfirm(null)}
          subline={`連携を解除すると${confirm}の案件に応募できなくなります`}
        />
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  noBordered: {
    borderTopWidth: 0,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  page: {
    ...Fonts.xlb160,
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  active: {
    color: Colors.green,
    ...Fonts.xlb100,
    textDecorationLine: "underline",
    textDecorationColor: Colors.green,
    textDecorationStyle: "solid",
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  buttonText: {
    ...Fonts.lr100,
  },
  activeText: {
    color: Colors.white,
    ...Fonts.lb130,
  },
  button: {
    width: 64,
    height: 32,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray30,
  },
  activeButton: {
    width: 64,
    height: 32,
    borderWidth: 0,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.green,
  },
});
