/**
 * @generated SignedSource<<4fb486387b6b680fdc6aafe5976f7798>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportSearchType = "month" | "year";
export type ReportMonthlyScreenQuery$variables = {
  date: string;
  searchType: ReportSearchType;
};
export type ReportMonthlyScreenQuery$data = {
  readonly sales: {
    readonly " $fragmentSpreads": FragmentRefs<"ReportSales">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReportPostList">;
};
export type ReportMonthlyScreenQuery = {
  response: ReportMonthlyScreenQuery$data;
  variables: ReportMonthlyScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  },
  {
    "kind": "Variable",
    "name": "searchType",
    "variableName": "searchType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportMonthlyScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sales",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReportSales"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ReportPostList"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportMonthlyScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sales",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isSales"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalMargin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPayment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalSales",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalTaxDeduction",
                "storageKey": null
              }
            ],
            "type": "SalesSummary",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "SalesError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "completedPosts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Candidate",
            "kind": "LinkedField",
            "name": "candidate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customPrice",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "post",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finishedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentMediaType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Banner",
                    "kind": "LinkedField",
                    "name": "banners",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "file",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13043f1be5f5310a5a4795fcf9ec050d",
    "id": null,
    "metadata": {},
    "name": "ReportMonthlyScreenQuery",
    "operationKind": "query",
    "text": "query ReportMonthlyScreenQuery(\n  $date: String!\n  $searchType: ReportSearchType!\n) {\n  sales(date: $date, searchType: $searchType) {\n    __typename\n    ...ReportSales\n    ... on SalesSummary {\n      id\n    }\n  }\n  ...ReportPostList_4lyisT\n}\n\nfragment CampaignSmallCandidate on Candidate {\n  id\n  price\n  customPrice\n  status\n  post {\n    id\n    status\n    finishedAt\n  }\n  campaign {\n    id\n    title\n    parentMediaType\n    banners {\n      file\n      id\n    }\n  }\n}\n\nfragment ReportPostList_4lyisT on Query {\n  completedPosts(date: $date, searchType: $searchType) {\n    id\n    candidate {\n      ...CampaignSmallCandidate\n      id\n    }\n  }\n}\n\nfragment ReportSales on Sales {\n  __isSales: __typename\n  ...ReportSummaryData\n}\n\nfragment ReportSummaryData on Sales {\n  __isSales: __typename\n  __typename\n  ... on SalesSummary {\n    date\n    id\n    totalMargin\n    totalPayment\n    totalSales\n    totalTaxDeduction\n  }\n  ... on SalesError {\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b2976398b6d29c3f2acf0b7b03b7a9c";

export default node;
