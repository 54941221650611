import React from "react";
import { Text, Card, Wrapper, View } from "@components/atoms/Themed";
import Ripple from "@components/atoms/Ripple";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { StyleSheet } from "react-native";
import ReactNavigation from "@react-navigation/material-top-tabs";

type Props = {
  params: ReactNavigation.MaterialTopTabBarProps;
};

export default function NavigationTodo({ params }: Props) {
  const { state, descriptors, navigation } = params;
  return (
    <Card>
      <Wrapper>
        <View style={styles.container}>
          {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const isFocused = state.index === index;

            return (
              <Ripple
                key={`tabbr-${route.key}`}
                onPress={() => navigation.navigate(route.name)}
                rippleColor={isFocused ? Colors.green : Colors.black0}
                style={[
                  styles.item,
                  isFocused
                    ? {
                        borderColor: Colors.black0,
                        borderBottomColor: Colors.green,
                        borderBottomWidth: 2,
                      }
                    : null,
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    isFocused
                      ? {
                          ...Fonts.xlb100,
                          color: Colors.green,
                        }
                      : null,
                  ]}
                >
                  {options.title}
                </Text>
              </Ripple>
            );
          })}
        </View>
      </Wrapper>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: Colors.black,
    textAlign: "center",
    ...Fonts.xlr100,
  },
});
