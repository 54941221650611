import React, { useState, useRef, useImperativeHandle } from "react";
import { StyleSheet, Animated } from "react-native";
import { Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";

// フォーカスがあたってる時とそうでないときの比率 (11px / 16px)
const InnerLabelFontSizeRate = 0.6875;

export type Ref = {
  onFocus: () => void;
  onBlur: () => void;
};

export default function Label({
  placeholder,
  innerRef,
}: {
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: React.MutableRefObject<any>;
}) {
  const [innerLabelWidth, setInnerLabelWidth] = useState<number>(1);
  const innerLabelAnim = useRef(new Animated.Value(0)).current;
  const innerLabelScale = innerLabelAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [1, InnerLabelFontSizeRate],
  });
  const unFocusecInnerLabelWidht = innerLabelWidth * InnerLabelFontSizeRate;
  const transrateX = (innerLabelWidth - unFocusecInnerLabelWidht) / 2;
  const innerLabelX = innerLabelAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -transrateX],
  });
  const innerLabelY = innerLabelAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -8],
  });

  useImperativeHandle(innerRef, () => ({
    onFocus() {
      Animated.timing(innerLabelAnim, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }).start();
    },
    onBlur() {
      Animated.timing(innerLabelAnim, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }).start();
    },
  }));

  return (
    <Animated.View
      ref={innerRef}
      style={[
        styles.container,
        {
          transform: [
            {
              translateX: innerLabelX,
            },
            {
              translateY: innerLabelY,
            },
            {
              scale: innerLabelScale,
            },
          ],
        },
      ]}
    >
      <Text
        onLayout={(e) => {
          setInnerLabelWidth(e.nativeEvent.layout.width);
        }}
        style={[styles.text]}
      >
        {placeholder}
      </Text>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 16,
    left: 12,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.gray,
  },
});
