import React from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Password from "@components/organisms/ChangePassword/ChangePassword";

export default function ChangePassword({ token }: { token: string }) {
  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <Password token={token} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
