import React, { useMemo } from "react";
import { Text, View } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity, Image } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import days from "dayjs";
import campaignBanner from "@lib/util/campaignBanner";
import { ApplyingCampaign$key } from "@generated/ApplyingCampaign.graphql";
import { graphql, useFragment } from "react-relay/hooks";

const recruitingCampaign = graphql`
  fragment ApplyingCampaign on Candidate {
    status
    campaign {
      id
      status
      parentMediaType
      title
      banners {
        file
      }
      socialNetwork {
        recruitmentEndOn
      }
    }
  }
`;

export default function ApplyingCampaign({
  candidateFragment,
  campaignDetailOnPress,
}: {
  candidateFragment: ApplyingCampaign$key;
  campaignDetailOnPress: (id: string) => void;
}) {
  const data = useFragment(recruitingCampaign, candidateFragment);
  const date = useMemo(
    () =>
      days(data.campaign.socialNetwork.recruitmentEndOn)
        .add(1, "days")
        .format("M月D日"),
    [data]
  );

  return (
    <TouchableOpacity
      onPress={() => campaignDetailOnPress(data.campaign.id)}
      style={styles.container}
    >
      <View
        style={[
          styles.todoInfo,
          data.status !== null && data.status.includes("reject")
            ? { opacity: 0.4 }
            : null,
        ]}
      >
        <Image
          source={campaignBanner(data.campaign.banners)}
          style={styles.image}
        />
        <Spacer width={16} />
        <View style={[styles.campaignExplanation]}>
          <View style={styles.head}>
            {data.status !== null && data.status.includes("reject") ? (
              <Text
                style={[
                  styles.todoDeadlineText,
                  {
                    color: Colors.black,
                  },
                ]}
              >
                不採用になりました。
              </Text>
            ) : (
              <Text
                style={[
                  styles.todoDeadlineText,
                  {
                    color: Colors.orange,
                  },
                ]}
              >
                {date}から選考が始まります
              </Text>
            )}
            <Spacer width={8} />
          </View>
          <Spacer height={8} />
          <View style={styles.title}>
            <SNSIcons
              name={data.campaign.parentMediaType}
              style={styles.platform}
            />
            <Spacer width={8} />
            <View style={styles.name}>
              <Text numberOfLines={2} style={[styles.explanation]}>
                {data.campaign.title}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    overflow: "hidden",
  },
  todoInfo: {
    flexDirection: "row",
  },
  image: {
    height: 54,
    width: 72,
    borderRadius: 4,
  },
  imageGr: {
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  campaignExplanation: {
    flex: 1,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: 1,
  },
  todoDeadlineText: {
    flexDirection: "row",
    textAlignVertical: "center",
    ...Fonts.lb130,
    color: Colors.orange,
  },
  platform: {
    height: 24,
    width: 24,
  },
  name: {
    flex: 1,
  },
  explanation: {
    color: Colors.black,
    textAlign: "left",
    ...Fonts.lr130,
  },
});
