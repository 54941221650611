import React from "react";
import { StyleSheet } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import CustomIcon from "@components/atoms/Icon";
import Button from "@components/atoms/Button";
import GradientButton from "@components/atoms/GradientButton";
import Divider from "@components/atoms/Divider";
import { graphql, useFragment } from "react-relay/hooks";
import { ApplyConfirmation$key } from "@generated/ApplyConfirmation.graphql";
import { ApplyPrice } from "@constants/App";

const applyConfirmation = graphql`
  fragment ApplyConfirmation on Campaign {
    prType
    gifting
    giftingDetail
  }
`;

export default function ApplyConfirmation({
  campaignFragment,
  desiredUnitPrice,
  applyOnPress,
  cancelOnPress,
}: {
  campaignFragment: ApplyConfirmation$key;
  desiredUnitPrice: number | null;
  applyOnPress: () => void;
  cancelOnPress: () => void;
}) {
  const campaign = useFragment(applyConfirmation, campaignFragment);
  return (
    <Card style={styles.container}>
      <Text style={styles.title}>応募の確認</Text>
      <>
        <Spacer height={16} />
        <View style={styles.giftingTitleWrap}>
          <CustomIcon color={Colors.gray} name="present" size={18} />
          <Spacer width={7} />
          <Text style={styles.giftingTitle}>商品提供</Text>
        </View>
        <Spacer height={8} />
        <View style={styles.giftingContentWrap}>
          <View style={styles.giftingDetailWrap}>
            <Text style={styles.giftingName}>
              {campaign.giftingDetail ?? "商品提供はありません"}
            </Text>
          </View>
        </View>
        <Spacer height={16} />
        <Divider />
      </>
      {campaign.prType === "rewarded" && desiredUnitPrice !== null && (
        <>
          <Spacer height={16} />
          <View style={styles.unitPriceTitleWrap}>
            <CustomIcon color={Colors.gray} name="money" size={18} />
            <Spacer width={7} />
            <Text style={styles.unitPriceTitle}>{ApplyPrice}</Text>
          </View>
          <Spacer height={8} />
          <View style={styles.row}>
            <Text style={styles.label}>1投稿あたりの金額</Text>

            <Text style={styles.content}>
              ¥{desiredUnitPrice.toLocaleString()}
            </Text>
          </View>
          <Spacer height={16} />
          <Divider />
        </>
      )}
      <Spacer height={16} />
      <Text style={styles.orangeText130}>
        一度応募したキャンペーンは原則辞退できません。
      </Text>
      <Spacer height={24} />
      <View style={styles.actionWrap}>
        <Button onPress={cancelOnPress} title="キャンセル" width={147} />
        <Spacer width={16} />
        <GradientButton
          borderRadius={4}
          onPress={applyOnPress}
          title="応募する"
          width={147}
        />
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 6,
  },
  title: {
    textAlign: "center",
    width: "100%",
    height: 28,
    ...Fonts.xxlb140,
  },
  giftingTitleWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  giftingTitle: {
    flex: 1,
    height: 12,
    color: Colors.gray,
    ...Fonts.mr100,
  },
  giftingContentWrap: {
    flexDirection: "row",
  },
  giftingDetailWrap: {
    flex: 1,
  },
  giftingImage: {
    width: 80,
    height: 60,
    backgroundColor: Colors.gray10,
  },
  giftingName: {
    color: Colors.black,
    ...Fonts.lb130,
  },
  giftingSizeWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  giftingSizeLabel: {
    ...Fonts.sr140,
    color: Colors.gray,
  },
  giftingSize: {
    color: Colors.black,
    ...Fonts.lb130,
  },
  unitPriceTitleWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  unitPriceTitle: {
    color: Colors.gray,
    ...Fonts.mr100,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  content: {
    color: Colors.black,
    ...Fonts.ensb100,
  },
  actionWrap: {
    height: 48,
    flexDirection: "row",
  },
  orangeText130: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
});
