/**
 * @generated SignedSource<<70e5d4c1ef81ca90284f99cdef820566>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupMutationInput = {
  clientMutationId?: string | null;
  email: string;
  name: string;
  password: string;
};
export type AuthSignupSignupMutation$variables = {
  input: SignupMutationInput;
};
export type AuthSignupSignupMutation$data = {
  readonly signup: {
    readonly __typename: "SignupError";
    readonly message: string;
  } | {
    readonly __typename: "SignupToken";
    readonly token: string;
    readonly uid: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AuthSignupSignupMutation = {
  response: AuthSignupSignupMutation$data;
  variables: AuthSignupSignupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "SignupToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "SignupError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthSignupSignupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthSignupSignupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b58b4ee29a40fd08496a6360ee29b583",
    "id": null,
    "metadata": {},
    "name": "AuthSignupSignupMutation",
    "operationKind": "mutation",
    "text": "mutation AuthSignupSignupMutation(\n  $input: SignupMutationInput!\n) {\n  signup(input: $input) {\n    __typename\n    ... on SignupToken {\n      uid\n      token\n    }\n    ... on SignupError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf3fad22bd46847e7408954241ea4bc6";

export default node;
