/**
 * @generated SignedSource<<efe9bb623673b469a3664f2d26d61429>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PasswordEditInfluencer$data = {
  readonly email: string;
  readonly " $fragmentType": "PasswordEditInfluencer";
};
export type PasswordEditInfluencer$key = {
  readonly " $data"?: PasswordEditInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PasswordEditInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PasswordEditInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "df1f591e63c644c4bd355be4b3ec3705";

export default node;
