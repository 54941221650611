import React, { useState, useCallback } from "react";
import Ripple from "@components/atoms/Ripple";
import Loading from "@components/atoms/Loading";
import Dialog from "@components/molecules/dialog/Dialog";
import { Alert, Platform } from "react-native";
import { SupportType } from "@lib/util/oauth";
import { graphql, useMutation } from "react-relay/hooks";
import { OAuthUnlinkMutation } from "@generated/OAuthUnlinkMutation.graphql";
import message from "@hooks/useMessage";
import { SnsTextGeneration } from "@lib/util/snsTextGeneration";

const unlinkSnsMutation = graphql`
  mutation OAuthUnlinkMutation($input: UnlinkSnsMutationInput!) {
    unlinkSns(input: $input) {
      __typename
      ... on Influencer {
        linkingInstagram
        linkingTwitter
        lineId
        appleId
        profile {
          twitterFollowers
          twitterId
          instaFollowers
          instaId
          instaUrl
        }
      }
      ... on UnlinkSnsError {
        message
      }
    }
  }
`;

type Props = {
  type: SupportType;
  children: React.ReactNode;
};

export default function OAuthUnlink({ type, children }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [commit] = useMutation<OAuthUnlinkMutation>(unlinkSnsMutation);
  const [confirm, setConfirm] = useState<boolean>(false);
  const { set: setMessage } = message();

  const onPress = useCallback(async (): Promise<void> => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      setConfirm(false);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              type,
            },
          },
          onCompleted({ unlinkSns }) {
            if (unlinkSns.__typename === "Influencer") {
              resolve();
            } else {
              setMessage({
                title:
                  unlinkSns.__typename === "UnlinkSnsError"
                    ? unlinkSns.message
                    : `${SnsTextGeneration(type)}連携を解除できませんでした`,
                mode: "toast",
              });
              resolve();
            }
          },
        });
      });
      setMessage({
        title: `${SnsTextGeneration(type)}連携を解除しました`,
        mode: "toast",
      });
    } catch (error: unknown) {
      setMessage({
        title: `${SnsTextGeneration(type)}連携を解除できませんでした`,
        mode: "toast",
      });
    } finally {
      setLoading(false);
    }
  }, [commit, setMessage, loading, type]);

  return (
    <>
      {confirm && (
        <Dialog
          buttons={[
            { title: "キャンセル", type: "white" },
            {
              title: "解除する",
              type: "orange",
              onPress,
            },
          ]}
          message={`${SnsTextGeneration(type)}連携を解除しますか？`}
          onClose={() => setConfirm(false)}
          subline={
            type !== "Apple" && type !== "Line"
              ? `連携を解除すると${SnsTextGeneration(
                  type
                )}の案件に応募できなくなります`
              : ""
          }
        />
      )}

      <Ripple
        disabled={loading}
        onPress={() => {
          if (Platform.OS === "web") {
            setConfirm(true);
          } else {
            Alert.alert(
              `${SnsTextGeneration(type)}連携を解除しますか？`,
              type !== "Apple" && type !== "Line"
                ? `連携を解除すると${SnsTextGeneration(
                    type
                  )}の案件に応募できなくなります`
                : "",
              [
                {
                  text: "キャンセル",
                  style: "cancel",
                },
                { text: "解除する", onPress },
              ]
            );
          }
        }}
      >
        {children}
        {loading && <Loading mask />}
      </Ripple>
    </>
  );
}
