import _ from "lodash";
import { Platform } from "react-native";
import { AllowVideoTypeReg } from "@constants/App";

interface VideoData {
  uri: string;
  contentType?: string | null;
}

export function isBase64Video(file: string): boolean {
  return /^data:video/.test(file);
}

export function isVideo(data: VideoData): boolean {
  if (!_.isNil(data.contentType)) {
    return AllowVideoTypeReg.test(data.contentType);
  }
  if (Platform.OS === "web") {
    const type = _.head(data.uri.split(";"));
    if (type !== undefined && /data:video/.test(type)) {
      return true;
    }
  }
  const extension = _.last(data.uri.split("."));
  if (extension !== undefined) {
    return ["mp4", "mov"].indexOf(extension.toLocaleLowerCase()) !== -1;
  }
  return false;
}

export function isImage(url: string): boolean {
  const ext = getExtension(url).toLowerCase();
  return ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif";
}

export function isRemoteUri(uri: string) {
  return /^http/.test(uri);
}

function getExtension(url: string): string {
  const data = url.split(".").pop();
  return data !== undefined ? data.trim() : "";
}

export function getExtensionFromType(type: string) {
  if (type === "video/quicktime") {
    return "mov";
  }
  return _.last(type.split("/"));
}
