import React, { useState } from "react";
import { TouchableOpacity, TextStyle, StyleSheet } from "react-native";
import { View, Text } from "@components/atoms/Themed";
import Icon from "@components/atoms/Icon";

type Props = {
  title: string;
  titleStyle?: TextStyle;
  children: React.ReactNode;
};

export default function Accordion({
  title,
  titleStyle,
  children,
}: Props): JSX.Element {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <View>
      <TouchableOpacity
        onPress={() => setExpanded(!isExpanded)}
        style={styles.spaceBetween}
      >
        <Text style={titleStyle}>{title}</Text>
        {isExpanded ? (
          <Icon name="up" size={24} />
        ) : (
          <Icon name="down" size={24} />
        )}
      </TouchableOpacity>
      {isExpanded && children}
    </View>
  );
}
const styles = StyleSheet.create({
  spaceBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
