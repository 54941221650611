/**
 * @generated SignedSource<<c31f347f2e3bf4d37ab8229347e0bbe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CandidateStatus = "admin_approved" | "admin_rejected" | "agency_approved" | "agency_rejected" | "applied";
import { FragmentRefs } from "relay-runtime";
export type CampaignActionCandidates$data = {
  readonly candidates: ReadonlyArray<{
    readonly campaignId: string | null;
    readonly id: string;
    readonly post: {
      readonly id: string;
    } | null;
    readonly status: CandidateStatus | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"requirementForApplyInfluencer">;
  readonly " $fragmentType": "CampaignActionCandidates";
};
export type CampaignActionCandidates$key = {
  readonly " $data"?: CampaignActionCandidates$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignActionCandidates">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignActionCandidates",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Candidate",
      "kind": "LinkedField",
      "name": "candidates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "campaignId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Post",
          "kind": "LinkedField",
          "name": "post",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "requirementForApplyInfluencer"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};
})();

(node as any).hash = "1176bc68fb1003f24d866e203d80db6d";

export default node;
