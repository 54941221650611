/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import ReactNavigation from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import pathConfig from "@navigation/pathConfig";
import { navigateByUrl, getPathFromUniversalLinks } from "@navigation/navigate";
import { RootStackParamList } from "../types";

export const schema = Linking.createURL("/");

const linking: ReactNavigation.LinkingOptions<RootStackParamList> = {
  prefixes: [schema],
  config: pathConfig,
  async getInitialURL(): Promise<string | null> {
    // deeplink
    const url = await Linking.getInitialURL();
    if (url != null) {
      return url;
    }
    const response = await Notifications.getLastNotificationResponseAsync();
    if (
      response !== null &&
      response.notification.request.content.data.url !== ""
    ) {
      return `${schema}${response.notification.request.content.data.url}`;
    }
    return null;
  },
  subscribe(Linkto) {
    // handle deeplink
    Linking.addEventListener("url", ({ url }: { url: string }) => {
      const path = getPathFromUniversalLinks(url);
      if (path !== null) {
        navigateByUrl(path);
      } else {
        Linkto(url);
      }
    });
  },
};

export default linking;
