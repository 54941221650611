// TODO: [20230113]AuthEnterと同じデザインを使っているが、広告運用を進めていく中でデザインを最適化していく。
import React, { useState, useEffect } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Button from "@components/atoms/Button";
import Spacer from "@components/atoms/Spacer";
import { navigate } from "@navigation/navigate";
import ResponsiveImage from "@components/atoms/ResponsiveImage";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { landing } from "@lib/util/recordConversion";
import OAuth from "@components/molecules/OAuth";
import Ripple from "@components/atoms/Ripple";
import SNSIcon from "@components/atoms/SNSIcon";
import { login, squareIcon } from "@components/atoms/AssetImage";

export default function AuthLanding() {
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    async function mounted() {
      await landing();
    }
    mounted();
  }, []);

  return (
    <Card style={styles.container}>
      <ScrollView>
        <Wrapper>
          <View style={styles.backImageWrapper}>
            <View style={styles.backImage}>
              <ResponsiveImage height={148} source={login} width={595} />
            </View>
          </View>
          <Spacer height={40} />
          <View style={styles.logoWrapper}>
            <View style={styles.squareIcon}>
              <ResponsiveImage height={77} source={squareIcon} width={270} />
            </View>
          </View>
          <Spacer height={58} />
          <Text style={styles.caption}>初めてご利用の方</Text>
          <Spacer height={16} />
          <View style={styles.buttons}>
            <OAuth action="Login" type="Line">
              <View style={styles.button}>
                <SNSIcon name="line" style={styles.icon} />
                <Spacer width={4} />
                <Text style={styles.buttonText}>LINEで登録</Text>
              </View>
            </OAuth>

            <Spacer height={16} />

            <Ripple onPress={() => navigate("AuthSignup")}>
              <View style={styles.email}>
                <SNSIcon name="email" style={styles.icon} />
                <Spacer width={4} />
                <Text style={styles.emailText}>Emailで登録</Text>
              </View>
            </Ripple>
          </View>
          <Spacer height={16} />
          <View style={styles.infoWrapper}>
            <Text style={styles.infoLabel}>登録することで、</Text>
            <TouchableOpacity onPress={() => navigate("TermsOfUse")}>
              <Text style={styles.linkLabel}>利用規約</Text>
            </TouchableOpacity>
            <Text style={styles.infoLabel}>と</Text>
            <TouchableOpacity onPress={() => navigate("PrivacyPolicy")}>
              <Text style={styles.linkLabel}>プライバシーポリシー</Text>
            </TouchableOpacity>
            <Text style={styles.infoLabel}>に同意したものとみなします。</Text>
          </View>
          <Spacer height={8} />
        </Wrapper>
      </ScrollView>
      <View style={styles.footer}>
        <Spacer height={12} />
        <Text style={styles.caption}>アカウントをお持ちの方</Text>
        <Spacer height={12} />
        <View style={styles.loginButton}>
          <Button onPress={() => navigate("AuthLogin")} title="ログイン" />
        </View>

        <Spacer height={24} />
      </View>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backImageWrapper: {
    position: "absolute",
    top: 50,
    left: 0,
    width: "100%",
    alignItems: "center",
  },
  backImage: {
    width: "100%",
    minHeight: 282,
  },
  logoWrapper: {
    alignItems: "center",
  },
  squareIcon: {
    width: "100%",
    maxWidth: 277,
    height: 77,
  },
  caption: {
    textAlign: "center",
    ...Fonts.lr100,
    color: Colors.gray,
  },
  buttons: {
    alignItems: "center",
  },
  infoWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 55,
  },
  infoLabel: {
    textAlign: "left",
    ...Fonts.sr100,
    color: Colors.black,
  },
  loginButton: {
    paddingHorizontal: 16,
  },
  linkLabel: {
    ...Fonts.sr100,
    lineHeight: 20,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: Colors.black,
  },
  footer: {
    borderTopColor: Colors.gray30,
    borderTopWidth: 1,
    borderStyle: "solid",
    alignItems: "center",
  },
  link: {
    ...Fonts.sr100,
    color: Colors.blue,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: Colors.blue,
  },
  email: {
    borderWidth: 1,
    borderColor: Colors.gray30,
    borderStyle: "solid",
    width: 280,
    height: 48,
    borderRadius: 4,
    paddingRight: 32,
    paddingLeft: 5,
    paddingVertical: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  emailText: {
    fontSize: 18,
    lineHeight: 48,
  },
  icon: {
    marginTop: 2,
    width: 24,
    height: 24,
  },
  button: {
    width: 280,
    height: 48,
    borderRadius: 4,
    paddingRight: 32,
    paddingLeft: 5,
    paddingVertical: 0,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: Colors.lineGreen,
    borderColor: Colors.lineGreen,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 18,
    lineHeight: 48,
    color: Colors.white,
    fontWeight: "500",
  },
});
