import React, { useState, useMemo } from "react";
import { StyleSheet, RefreshControl, FlatList } from "react-native";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { CampaignApplyHistoryPagination$key } from "@generated/CampaignApplyHistoryPagination.graphql";
import Spacer from "@components/atoms/Spacer";
import CampaignSmall from "@components/molecules/List/CampaignSmall";
import _ from "lodash";
import dayjs from "dayjs";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Divider from "@components/atoms/Divider";
import { PagingCandidate } from "@constants/App";

const fragmentInput = graphql`
  fragment CampaignApplyHistoryPagination on Query
  @refetchable(queryName: "CampaignApplyHistoryQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    influencerId: { type: "ID!" }
    orderBy: { type: "InfluencerCandidatesOrderBy" }
  ) {
    candidates(
      first: $first
      after: $after
      influencerId: $influencerId
      orderBy: $orderBy
    ) @connection(key: "CampaignApplyHistory_candidates") {
      edges {
        node {
          id
          createdAt
          ...CampaignSmallCandidate
        }
      }
    }
  }
`;

export default function CampaignApplyHistory({
  candidatesFragment,
}: {
  candidatesFragment: CampaignApplyHistoryPagination$key;
}) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    fragmentInput,
    candidatesFragment
  );

  const candidatesGroup = useMemo(
    () =>
      _.groupBy(data.candidates.edges, ({ node }) =>
        dayjs(node.createdAt).format("YYYY年M月")
      ),
    [data]
  );

  return (
    <FlatList
      data={Object.keys(candidatesGroup)}
      ListEmptyComponent={
        <Card style={styles.empty}>
          <Wrapper>
            <View style={styles.row}>
              <View style={styles.section}>
                <Text style={styles.sectionTitle}>
                  これまでの応募キャンペーン
                </Text>
              </View>
            </View>
            <Spacer height={16} />
            <Text style={styles.emptyText}>
              応募したキャンペーンはありません。
            </Text>
          </Wrapper>
        </Card>
      }
      onEndReached={() => {
        if (hasNext && !paging) {
          setPaging(true);
          loadNext(PagingCandidate, { onComplete: () => setPaging(false) });
        }
      }}
      refreshControl={
        <RefreshControl
          onRefresh={() => {
            if (!refreshing) {
              setRefreshing(true);
              refetch(
                { first: PagingCandidate },
                {
                  fetchPolicy: "network-only",
                  onComplete: () => setRefreshing(false),
                }
              );
            }
          }}
          refreshing={refreshing}
        />
      }
      renderItem={({ item, index }) => (
        <React.Fragment key={index}>
          <View style={styles.month}>
            <Text style={styles.monthText}>{item}</Text>
          </View>
          <Card style={styles.item}>
            <Wrapper>
              <Spacer height={24} />
              <View style={styles.row}>
                <View style={styles.section}>
                  <Text style={styles.sectionTitle}>
                    これまでの応募キャンペーン
                  </Text>
                </View>
              </View>
              <Spacer height={8} />
              {candidatesGroup[item].map(({ node }, counter) => (
                <React.Fragment key={node.id}>
                  {counter !== 0 && <Divider />}
                  <Spacer height={8} />
                  <CampaignSmall candidateFragment={node} />
                  <Spacer height={8} />
                </React.Fragment>
              ))}
              <Spacer height={16} />
            </Wrapper>
          </Card>
        </React.Fragment>
      )}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  month: {
    padding: 16,
  },
  monthText: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
  item: {
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    flex: 1,
    flexDirection: "row",
  },
  sectionTitle: {
    ...Fonts.lr100,
  },
  sectionCount: {
    ...Fonts.lr100,
  },
  empty: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  emptyText: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
});
