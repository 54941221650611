import React from "react";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import { StyleSheet } from "react-native";

export default function RewardProductLabel(): JSX.Element {
  return (
    <View style={styles.flexRowCenter}>
      <Icon color={Colors.gray} name="present" size={18} />
      <Spacer width={3} />
      <Text style={styles.grayText12}>商品提供</Text>
    </View>
  );
}
const styles = StyleSheet.create({
  flexRowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  grayText12: {
    fontSize: 12,
    color: Colors.gray,
  },
});
