import React from "react";
import { Text } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";

import styles from "@components/molecules/TextInput/style";

export default function ErrorText({ error }: { error?: string }): JSX.Element {
  return (
    <>
      <Spacer height={4} />
      <Text style={styles.error}>{error ?? "入力に誤りがあります"}</Text>
    </>
  );
}
