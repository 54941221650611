/**
 * @generated SignedSource<<301b9f5c7395e5267a40a0b4d02388b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignApplyScreenQuery$variables = {
  campaignId: string;
};
export type CampaignApplyScreenQuery$data = {
  readonly campaign: {
    readonly __typename: "Campaign";
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"Apply">;
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CampaignApplyScreenQuery = {
  response: CampaignApplyScreenQuery$data;
  variables: CampaignApplyScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "NotFoundError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignApplyScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Apply"
              }
            ],
            "type": "Campaign",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignApplyScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentMediaType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gifting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Banner",
                "kind": "LinkedField",
                "name": "banners",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "file",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MCategory",
                "kind": "LinkedField",
                "name": "mCategory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SocialNetwork",
                "kind": "LinkedField",
                "name": "socialNetwork",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recruitmentEndOn",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "advertiserName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "askingPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "giftingDetail",
                "storageKey": null
              }
            ],
            "type": "Campaign",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e19cc4bc70cf5021ce4014d886e83b52",
    "id": null,
    "metadata": {},
    "name": "CampaignApplyScreenQuery",
    "operationKind": "query",
    "text": "query CampaignApplyScreenQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    __typename\n    ... on Campaign {\n      id\n      ...Apply\n    }\n    ... on NotFoundError {\n      message\n    }\n  }\n}\n\nfragment Apply on Campaign {\n  id\n  prType\n  ...ApplyCampaign\n  ...ApplyReward\n  ...ApplyProduct\n  ...ApplyConfirmation\n}\n\nfragment ApplyCampaign on Campaign {\n  title\n  parentMediaType\n  gifting\n  prType\n  banners {\n    file\n    id\n  }\n  mCategory {\n    name\n    id\n  }\n  socialNetwork {\n    recruitmentEndOn\n    id\n  }\n}\n\nfragment ApplyConfirmation on Campaign {\n  prType\n  gifting\n  giftingDetail\n}\n\nfragment ApplyProduct on Campaign {\n  giftingDetail\n}\n\nfragment ApplyReward on Campaign {\n  mediaType\n  advertiserName\n  askingPrice\n  prType\n}\n"
  }
};
})();

(node as any).hash = "b4170dffa876879c0d11388d9dc490d0";

export default node;
