import { atom, useRecoilState } from "recoil";
import { ParentMediaType, CategoryTypes } from "@constants/App";

export type FilterData = {
  selectedSns: ParentMediaType[];
  selectedCategories: CategoryTypes[];
  selectedHasMoney: boolean;
  selectedHasProduct: boolean;
  filterStatus: boolean;
};

const filterAtom = atom<FilterData | null>({
  key: "Filter_Data",
  default: null,
});

export default function useFilterStatus() {
  const [state, setter] = useRecoilState(filterAtom);

  return {
    state,
    set: (data: FilterData | null): void => {
      setter(data);
    },
  };
}
