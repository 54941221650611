import { clarity } from "clarity-js";

export function initialize(clarityProjectId: string) {
  clarity.start({
    projectId: clarityProjectId,
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true,
  });
}
export function setCustomUserId(influencerId: string) {
  clarity.set("user_id", influencerId);
  clarity.identify(influencerId);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
export function setCurrentScreenName(screen: string) {}
