/**
 * @generated SignedSource<<454610b7d2dbe3a0290b789af151c3e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputAddressInfluencer$data = {
  readonly buildingName: string | null;
  readonly cityName: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly postalCode: string | null;
  readonly prefectureName: string | null;
  readonly townName: string | null;
  readonly " $fragmentType": "CampaignInputAddressInfluencer";
};
export type CampaignInputAddressInfluencer$key = {
  readonly " $data"?: CampaignInputAddressInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputAddressInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputAddressInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefectureName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cityName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "townName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildingName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "e57d187f636ba3506c7586f9604f07a3";

export default node;
