import { Platform } from "react-native";
import * as Linking from "expo-linking";
import { isURL } from "@lib/util/validate";

export default async function openURL(url: string): Promise<true> {
  if (Platform.OS === "web") {
    if (isURL(url)) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
    return true;
  }
  return Linking.openURL(url);
}
