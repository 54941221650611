import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import Loading from "@components/atoms/Loading";
import Spacer from "@components/atoms/Spacer";
import NavigationBar from "@components/molecules/NavigationBar";
import Fonts from "@constants/Fonts";
import { goBack } from "@navigation/navigate";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { ProfileEditScreenQuery } from "@generated/ProfileEditScreenQuery.graphql";
import ProfileEdit from "@components/templates/ProfileEdit";
import _ from "lodash";

const profileQuery = graphql`
  query ProfileEditScreenQuery {
    influencer {
      ...ProfileEdit
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ProfileEditScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<ProfileEditScreenQuery>(
    profileQuery,
    queryReference
  );
  return <ProfileEdit profileFragment={influencer} />;
}

export default function ProfileEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ProfileEditScreenQuery>(profileQuery);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="プロフィール編集" />
      <Spacer height={8} />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  addImagesLabel: {
    ...Fonts.lb100,
    textAlign: "right",
  },
});
