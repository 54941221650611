import React, { useState, useCallback } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import { Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Icon from "@components/atoms/Icon";
import * as Clipboard from "expo-clipboard";

export default function CopyButton({ value }: { value: string | null }) {
  const [copied, setCopied] = useState<boolean>(false);
  const copy = useCallback(async (): Promise<void> => {
    if (copied) {
      return;
    }
    setCopied(true);
    await Clipboard.setStringAsync(`${value === null ? "" : value}`);
    setTimeout(() => setCopied(false), 2000);
  }, [copied, value]);

  return (
    <TouchableOpacity onPress={copy} style={styles.copy}>
      {!copied ? (
        <>
          <Icon color={Colors.white} name="copy" size={14} />
          <Spacer width={4} />
          <Text style={styles.copyText}>Copy</Text>
        </>
      ) : (
        <>
          <Icon color={Colors.green} name="check" size={14} />
          <Spacer width={4} />
          <Text style={styles.copyText}>Copied</Text>
        </>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  copy: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    backgroundColor: Colors.gray,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  copyText: {
    color: Colors.white,
    ...Fonts.xsr100,
  },
  copiedText: {
    color: Colors.green,
    ...Fonts.xsr100,
  },
});
