import React from "react";
import { StyleProp, Text, TextStyle, View } from "react-native";

export default function MaskedViewForWeb({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  maskElement,
  children,
  style,
}: {
  maskElement: React.ReactNode;
  children: React.ReactNode;
  style?: StyleProp<TextStyle>;
}) {
  return (
    <View>
      <Text style={style}>{children}</Text>
    </View>
  );
}
