import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import { EmailEditScreenQuery } from "@generated/EmailEditScreenQuery.graphql";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import EmailEdit from "@components/templates/EmailEdit";
import _ from "lodash";

const emailEditScreen = graphql`
  query EmailEditScreenQuery {
    influencer {
      ...EmailEdit
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<EmailEditScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<EmailEditScreenQuery>(
    emailEditScreen,
    queryReference
  );
  return <EmailEdit emailFragment={influencer} />;
}
export default function EmailEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<EmailEditScreenQuery>(emailEditScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
