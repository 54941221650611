/**
 * @generated SignedSource<<22df5a92102fb18ee457f3e37c26880e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RefreshTokenMutationInput = {
  clientMutationId?: string | null;
  refreshToken: string;
};
export type refreshTokenMutation$variables = {
  input: RefreshTokenMutationInput;
};
export type refreshTokenMutation$data = {
  readonly refreshToken: {
    readonly refreshToken: string;
    readonly token: string;
  };
};
export type refreshTokenMutation = {
  response: refreshTokenMutation$data;
  variables: refreshTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshToken",
    "kind": "LinkedField",
    "name": "refreshToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "refreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "refreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "66b4bb323d2a88850ffce3043665b8d9",
    "id": null,
    "metadata": {},
    "name": "refreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation refreshTokenMutation(\n  $input: RefreshTokenMutationInput!\n) {\n  refreshToken(input: $input) {\n    token\n    refreshToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f83b2cf9523eb394949cf2b3468a8ea";

export default node;
