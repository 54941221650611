/**
 * @generated SignedSource<<f761662f242c02db47b2212ab4c9c40b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
import { FragmentRefs } from "relay-runtime";
export type Apply$data = {
  readonly id: string;
  readonly prType: CampaignPrType | null;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyCampaign" | "ApplyConfirmation" | "ApplyProduct" | "ApplyReward">;
  readonly " $fragmentType": "Apply";
};
export type Apply$key = {
  readonly " $data"?: Apply$data;
  readonly " $fragmentSpreads": FragmentRefs<"Apply">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Apply",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyCampaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyReward"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyProduct"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyConfirmation"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "dee7787cbd4787a0cfe027703836cbb6";

export default node;
