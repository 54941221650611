import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { PersonalInformationEditInfo$key } from "@generated/PersonalInformationEditInfo.graphql";
import { PersonalInformationEditUpdateMutation } from "@generated/PersonalInformationEditUpdateMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import { KeyboardId } from "@constants/App";
import Divider from "@components/atoms/Divider";

const personalInformationEditInfoQuery = graphql`
  fragment PersonalInformationEditInfo on Influencer {
    firstName
    lastName
    firstNameKana
    lastNameKana
  }
`;

const personalInformationEditUpdateMutation = graphql`
  mutation PersonalInformationEditUpdateMutation(
    $input: UpdateInfluencerMutationInput!
  ) {
    updateInfluencer(input: $input) {
      __typename
      ... on Influencer {
        firstName
        lastName
        firstNameKana
        lastNameKana
      }
      ... on UpdateInfluencerError {
        message
      }
    }
  }
`;

type PersonalInformation = {
  firstName: string;
  lastName: string;
  firstNameKana: string | null;
  lastNameKana: string | null;
};

export default function PersonalInformationEdit({
  personalInformationFragment,
}: {
  personalInformationFragment: PersonalInformationEditInfo$key;
}) {
  const data = useFragment(
    personalInformationEditInfoQuery,
    personalInformationFragment
  );
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PersonalInformation>({
    defaultValues: {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      firstNameKana: data.firstNameKana ?? "",
      lastNameKana: data.lastNameKana ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        firstName: string().trim().required("入力してください"),
        lastName: string().trim().required("入力してください"),
        firstNameKana: string()
          .default("")
          .kana("カナで入力してください")
          .nullable()
          .toNull(),
        lastNameKana: string()
          .default("")
          .kana("カナで入力してください")
          .nullable()
          .toNull(),
      })
    ),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [commit] = useMutation<PersonalInformationEditUpdateMutation>(
    personalInformationEditUpdateMutation
  );

  const save = useCallback(async () => {
    await handleSubmit(async (inputData: PersonalInformation) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...inputData,
            },
          },
          onCompleted({ updateInfluencer }) {
            if (updateInfluencer.__typename === "UpdateInfluencerError") {
              setError(updateInfluencer.message);
            } else {
              setMessage("保存しました");
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                本名を入力してください。入力がない場合、キャンペーンに応募できません。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.content}>
              <View style={styles.nameBox}>
                <View style={styles.nameInputBox}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="姓"
                    name="lastName"
                    placeholder="姓"
                    style={styles.kanaInput}
                  />
                  {!isValid && errors.lastName !== undefined && (
                    <ErrorText error={errors.lastName.message} />
                  )}
                </View>
                <View style={styles.nameInputBox}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="名"
                    name="firstName"
                    placeholder="名"
                    style={styles.kanaInput}
                  />
                  {!isValid && errors.firstName !== undefined && (
                    <ErrorText error={errors.firstName.message} />
                  )}
                </View>
              </View>
              <Spacer height={16} />

              <View style={styles.nameBox}>
                <View style={styles.nameInputBox}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="セイ"
                    name="lastNameKana"
                    placeholder="セイ"
                    style={styles.kanaInput}
                  />
                  {!isValid && errors.lastNameKana !== undefined && (
                    <ErrorText error={errors.lastNameKana.message} />
                  )}
                </View>
                <View style={styles.nameInputBox}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="メイ"
                    name="firstNameKana"
                    placeholder="メイ"
                    style={styles.kanaInput}
                  />
                  {!isValid && errors.firstNameKana !== undefined && (
                    <ErrorText error={errors.firstNameKana.message} />
                  )}
                </View>
              </View>
              <Spacer height={12} />
            </Card>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  message: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  nameInputBox: {
    flex: 1,
  },
  nameBox: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  kanaInput: {
    width: "90%",
  },
});
