/**
 * @generated SignedSource<<a1732be797200eb69de021b3efb9af09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalInformationEditScreenQuery$variables = {};
export type PersonalInformationEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"PersonalInformationEditInfo">;
  };
};
export type PersonalInformationEditScreenQuery = {
  response: PersonalInformationEditScreenQuery$data;
  variables: PersonalInformationEditScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalInformationEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalInformationEditInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonalInformationEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbd51af7bbf26e527ce5af3e95ef7929",
    "id": null,
    "metadata": {},
    "name": "PersonalInformationEditScreenQuery",
    "operationKind": "query",
    "text": "query PersonalInformationEditScreenQuery {\n  influencer {\n    ...PersonalInformationEditInfo\n    id\n  }\n}\n\nfragment PersonalInformationEditInfo on Influencer {\n  firstName\n  lastName\n  firstNameKana\n  lastNameKana\n}\n"
  }
};

(node as any).hash = "229fd3626fbf3ef0d4142575956b246a";

export default node;
