/**
 * @generated SignedSource<<1c41340053073959ef4a37712b692c46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceStatus = "applied" | "unapplied" | "unset";
import { FragmentRefs } from "relay-runtime";
export type PersonalInformation$data = {
  readonly bankAccount: {
    readonly id: string;
  } | null;
  readonly cityName: string | null;
  readonly email: string;
  readonly firstName: string | null;
  readonly firstNameKana: string | null;
  readonly hasPassword: boolean;
  readonly lastName: string | null;
  readonly lastNameKana: string | null;
  readonly phoneNumber: string | null;
  readonly prefectureName: string | null;
  readonly profile: {
    readonly birthday: any | null;
    readonly gender: string | null;
    readonly invoiceStatus: InvoiceStatus;
  };
  readonly townName: string | null;
  readonly " $fragmentType": "PersonalInformation";
};
export type PersonalInformation$key = {
  readonly " $data"?: PersonalInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonalInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstNameKana",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastNameKana",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassword",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefectureName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cityName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "townName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "fc326df9cac52f1d7f28fa73b118cf27";

export default node;
