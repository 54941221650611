import {
  initialize,
  setCustomUserId,
  setCurrentScreenName,
} from "react-native-clarity";
import { clarityProjectId } from "@constants/Env";
import { Platform } from "react-native";
import _ from "lodash";

// MEMO: 2023/11/10時点でClarityはiOSに対応していないので、iOSの場合は何もしない
// https://github.com/microsoft/clarity/issues/221

export function start(influencerId: string) {
  if (Platform.OS !== "ios" && !_.isEmpty(clarityProjectId)) {
    initialize(clarityProjectId);
    setCustomUserId(influencerId);
  }
}

export function screenView(screen: string) {
  if (Platform.OS !== "ios" && !_.isEmpty(clarityProjectId)) {
    setCurrentScreenName(screen);
  }
}
