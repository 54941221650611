import React, { useState, useCallback, useMemo, useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "@lib/util/yup";
import { useForm } from "react-hook-form";
import { View, Card, Wrapper } from "@components/atoms/Themed";
import GradientButton from "@components/atoms/GradientButton";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  Selectbox,
  ErrorText,
} from "@components/molecules/TextInput";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import {
  CampaignInputInvoiceInfluencer$key,
  InvoiceStatus,
} from "@generated/CampaignInputInvoiceInfluencer.graphql";
import { CampaignInputProfileMutation } from "@generated/CampaignInputProfileMutation.graphql";
import { KeyboardId, ProfileList } from "@constants/App";

const profileQuery = graphql`
  fragment CampaignInputInvoiceInfluencer on Influencer {
    profile {
      invoiceStatus
      invoiceNo
    }
  }
`;

const profileMutation = graphql`
  mutation CampaignInputInvoiceMutation(
    $input: UpdateInfluencerInfoMutationInput!
  ) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          invoiceStatus
          invoiceNo
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type Props = {
  profileFragment: CampaignInputInvoiceInfluencer$key;
  hasNext: boolean;
  onClose: () => void;
};

type ProfileInput = {
  invoiceStatus: InvoiceStatus;
  invoiceNo: string | null;
};

export default function CampaignInputInvoice({
  profileFragment,
  hasNext,
  onClose,
}: Props) {
  const { profile } = useFragment<CampaignInputInvoiceInfluencer$key>(
    profileQuery,
    profileFragment
  );
  const [status, setStatus] = useState<InvoiceStatus | null>(
    profile.invoiceStatus
  );
  const invoiceList = useMemo(
    () =>
      Object.keys(ProfileList.invoice_status).map((column) => ({
        label: ProfileList.invoice_status[column] as InvoiceStatus,
        value: column,
      })),
    []
  );

  const invoiceStatus = useMemo(
    () =>
      Object.keys(ProfileList.invoice_status).map(
        (key) => key as InvoiceStatus
      ),
    []
  );

  const [commit] = useMutation<CampaignInputProfileMutation>(profileMutation);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    resetField,
  } = useForm<ProfileInput>({
    defaultValues: {
      invoiceStatus: profile.invoiceStatus,
      invoiceNo: profile.invoiceNo,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        invoiceStatus: string()
          .required("選択してください")
          .oneOf(invoiceStatus, "選択してください"),
        invoiceNo: string()
          .default(null)
          .nullable()
          .test("checkInvoice", "13桁の半角数字を入力してください", (value) => {
            if (status === "applied") {
              return value !== null && /^[0-9]{13,13}$/.test(value);
            }
            return true;
          }),
      })
    ),
  });

  useEffect(() => {
    if (status !== "applied") {
      resetField("invoiceNo");
    }
  }, [status, resetField]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const save = useCallback(async () => {
    await handleSubmit(async (submitInput: ProfileInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...submitInput,
            },
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setError(updateInfluencerInfo.message);
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="インボイス登録" />

      <Divider />

      <KeyboardAvoidingView>
        <ScrollView>
          <Card style={styles.content}>
            <Wrapper>
              <Selectbox
                control={control}
                emptyValue=""
                initialValue={profile.invoiceStatus}
                items={invoiceList}
                label="インボイス登録は申請していますか？"
                name="invoiceStatus"
                onChange={(value) => {
                  setStatus(value as InvoiceStatus | null);
                }}
              />
              {!isValid && errors.invoiceStatus !== undefined && (
                <ErrorText error={errors.invoiceStatus.message} />
              )}

              <Spacer height={16} />
              <TextField
                key={`toggle-${status}`}
                control={control}
                disabled={status !== "applied"}
                inputAccessoryViewID={KeyboardId}
                label="登録番号"
                maxLength={13}
                name="invoiceNo"
                placeholder="Tを除く13桁の半角数字を入力してください"
                type="numberString"
              />
              {!isValid && errors.invoiceNo !== undefined && (
                <ErrorText error={errors.invoiceNo.message} />
              )}
            </Wrapper>
          </Card>
          <Spacer height={100} />
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title={hasNext ? "次へ" : "情報を登録し、応募する"}
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
