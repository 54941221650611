import React from "react";
import { StyleSheet } from "react-native";
import ReactForm from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { TextArea, ErrorText } from "@components/molecules/TextInput";
import { View, Text, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import CustomIcon from "@components/atoms/Icon";
import { KeyboardId, ApplyPrice } from "@constants/App";
import { InputData } from "@components/organisms/Apply/type";

export default function ApplyNote({
  control,
  error,
}: {
  control: ReactForm.Control<InputData>;
  error: string | undefined;
}) {
  return (
    <Card style={styles.container}>
      <View style={styles.head}>
        <CustomIcon color={Colors.black} name="speech" size={18} />
        <Spacer width={7} />
        <Text style={styles.title}>伝えたいこと</Text>
      </View>
      <Spacer height={24} />
      <View>
        <Text style={styles.description}>
          日程調整（優先日など）、アパレルの希望サイズ、アピールポイント、
          {ApplyPrice}
          の根拠など伝えたいことがあれば、下記の備考に記載してください。
        </Text>
      </View>
      <Spacer height={8} />
      <TextArea
        control={control}
        inputAccessoryViewID={KeyboardId}
        name="note"
        numberOfLines={9}
        placeholderTextColor={Colors.gray}
        style={styles.note}
      />
      {error !== undefined && <ErrorText error={error} />}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.black,
  },
  description: {
    color: Colors.gray,
    textAlign: "left",
    ...Fonts.lr130,
  },
  note: {
    ...Fonts.lr130,
  },
});
