import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";

export default StyleSheet.create({
  container: {
    width: "100%",
    position: "relative",
  },
  form: {
    position: "relative",
    paddingVertical: 4,
    minHeight: 50,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.gray30,
    borderRadius: 4,
    width: "100%",
    overflow: "hidden",
    fontSize: 16,
  },
  formError: {
    borderColor: Colors.orange,
  },
  disabled: {
    backgroundColor: Colors.gray20,
  },
  error: {
    color: Colors.orange,
    ...Fonts.lr130,
  },
  text: {
    ...Fonts.xlr100,
    lineHeight: 42,
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 50,
    width: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    flex: 1,
    justifyContent: "flex-end",
  },
  backgroundLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: Colors.overlayDark,
  },
  counter: {
    textAlign: "right",
    color: Colors.gray,
    ...Fonts.sr100,
  },
  copyArea: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  multilineText: {
    ...Fonts.xlr100,
  },
});
