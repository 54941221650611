import React from "react";
import { Text, View, Card } from "@components/atoms/Themed";
import Fonts from "@constants/Fonts";
import { StyleSheet } from "react-native";
import Spacer from "@components/atoms/Spacer";
import Accordion from "@components/molecules/Accordion";

type Props = {
  title: string;
  children?: React.ReactNode;
  activeAccordion?: boolean;
};

export default function CampaignSection({
  title,
  children,
  activeAccordion,
}: Props): JSX.Element {
  const contents = (
    <>
      <Spacer height={24} />
      <View>{children}</View>
    </>
  );
  return (
    <Card style={styles.container}>
      {activeAccordion === true ? (
        <Accordion title={title} titleStyle={styles.title}>
          {contents}
        </Accordion>
      ) : (
        <>
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>

          {contents}
        </>
      )}
    </Card>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  title: {
    ...Fonts.xlb160,
  },
});
