import React, { useState, useCallback } from "react";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Divider from "@components/atoms/Divider";
import GradientButton from "@components/atoms/GradientButton";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import Spacer from "@components/atoms/Spacer";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import NavigationBar from "@components/molecules/NavigationBar";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import Dialog from "@components/molecules/dialog/Dialog";
import { KeyboardId } from "@constants/App";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { EmailEditForm$key } from "@generated/EmailEditForm.graphql";
import { EmailEditFormMutation } from "@generated/EmailEditFormMutation.graphql";
import { resolveError } from "@lib/util/error";
import { goBack } from "@navigation/navigate";
import { ScrollView, StyleSheet } from "react-native";
import { graphql, useMutation, useFragment } from "react-relay/hooks";

const emaioQuery = graphql`
  fragment EmailEditForm on Influencer {
    email
  }
`;

const saveMutation = graphql`
  mutation EmailEditFormMutation($input: EmailChangeMutationInput!) {
    emailChange(input: $input) {
      __typename
      ... on EmailChangeToken {
        token
      }
      ... on EmailChangeDuplication {
        errorType
        message
      }
      ... on EmailChangeUnknown {
        errorType
        message
      }
    }
  }
`;

type EmailInput = {
  email: string;
};

export default function EmailEditForm({
  onComplete,
  emailFragment,
}: {
  onComplete: (token: string) => void;
  emailFragment: EmailEditForm$key;
}) {
  const { email } = useFragment(emaioQuery, emailFragment);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<EmailEditFormMutation>(saveMutation);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailInput>({
    defaultValues: {
      email: email ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string()
          .trim()
          .email("存在しないメールアドレスです")
          .required("入力してください"),
      })
    ),
  });

  const save = useCallback(async (): Promise<void> => {
    await handleSubmit(
      async ({ email: newEmail }: EmailInput): Promise<void> => {
        try {
          if (newEmail === null) {
            return;
          }
          setLoading(true);
          const token = await new Promise<string>((resolve, reject) => {
            commit({
              variables: {
                input: {
                  newEmail,
                },
              },
              onCompleted({ emailChange }) {
                if (emailChange.__typename === "EmailChangeToken") {
                  resolve(emailChange.token);
                } else {
                  reject(
                    emailChange.__typename === "EmailChangeDuplication" ||
                      emailChange.__typename === "EmailChangeUnknown"
                      ? emailChange.message
                      : "Emailを変更できませんでした"
                  );
                }
              },
            });
          });
          onComplete(token);
        } catch (e: unknown) {
          setError(resolveError(e).message);
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [commit, onComplete, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="メールアドレス" />
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <View style={styles.form}>
                <TextField
                  control={control}
                  inputAccessoryViewID={KeyboardId}
                  label="メールアドレス"
                  name="email"
                  type="email"
                />
                {!isValid && errors.email !== undefined && (
                  <ErrorText error={errors.email.message} />
                )}
                <Text style={styles.guide}>
                  登録アドレスになります。パスワードを設定することでログイン時にも使用することができます。
                </Text>
              </View>
            </Card>

            <View style={styles.message}>
              <Text style={styles.text}>
                メールアドレスを変更する場合、認証コードを送信します。{"\n"}
                届いた4桁の数字で認証を行ってください。
              </Text>
            </View>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="メールアドレスを変更する"
        />
      </Card>
      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.dialogError }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
  message: {
    padding: 16,
  },
  text: {
    color: Colors.gray,
    ...Fonts.lr130,
  },
  form: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  guide: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
