import React from "react";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import { View, Text } from "@components/atoms/Themed";
import { StyleSheet } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import { TodoComments$key } from "@generated/TodoComments.graphql";

const todoComments = graphql`
  fragment TodoComments on Post {
    lastComment {
      content
      action
    }
    influencerCommentReadTime {
      id
      readableId
      unreadCommentCount
    }
  }
`;

export default function TodoComments({
  fragmentRef,
}: {
  fragmentRef: TodoComments$key;
}) {
  const { lastComment, influencerCommentReadTime } = useFragment(
    todoComments,
    fragmentRef
  );
  if (lastComment === null) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View bordered style={styles.body}>
        <Text numberOfLines={2} style={styles.text}>
          {lastComment.content ?? lastComment.action}
        </Text>
      </View>
      {influencerCommentReadTime !== null &&
        influencerCommentReadTime.unreadCommentCount > 0 && (
          <>
            <Spacer width={8} />
            <View style={styles.badge}>
              <Text style={styles.badgeText}>
                {influencerCommentReadTime.unreadCommentCount > 99
                  ? "99+"
                  : influencerCommentReadTime.unreadCommentCount}
              </Text>
            </View>
          </>
        )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  body: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
  },
  text: {
    textAlignVertical: "center",
    ...Fonts.sr140,
    color: Colors.gray,
  },
  badge: {
    backgroundColor: Colors.orange,
    height: 24,
    width: 24,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  badgeText: {
    ...Fonts.enxsb100,
    lineHeight: 24,
    color: Colors.white,
  },
});
