/**
 * @generated SignedSource<<379db03d527af11afad081a1abd4d915>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputInfluencer$data = {
  readonly linkingInstagram: boolean | null;
  readonly linkingTwitter: boolean | null;
  readonly profile: {
    readonly tiktokUrl: string | null;
    readonly ytChannel: string | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputAddressInfluencer" | "CampaignInputCategoryCategories" | "CampaignInputEmailConfirmEmail" | "CampaignInputInvoiceInfluencer" | "CampaignInputProfileInfluencer" | "CampaignInputSnsInfluencer" | "requirementForApplyInfluencer">;
  readonly " $fragmentType": "CampaignInputInfluencer";
};
export type CampaignInputInfluencer$key = {
  readonly " $data"?: CampaignInputInfluencer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputInfluencer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputInfluencer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingInstagram",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkingTwitter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ytChannel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputProfileInfluencer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputSnsInfluencer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputAddressInfluencer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputCategoryCategories"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputEmailConfirmEmail"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputInvoiceInfluencer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "requirementForApplyInfluencer"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "a350d3efe2b5fa3d64cd8d0451ba5a02";

export default node;
