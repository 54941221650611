import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Dialog from "@components/molecules/dialog/Dialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { graphql, useMutation, useFragment } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import { PasswordPattern, KeyboardId } from "@constants/App";
import { PasswordEditChangeMutation } from "@generated/PasswordEditChangeMutation.graphql";
import { PasswordEditReissueMutation } from "@generated/PasswordEditReissueMutation.graphql";
import { PasswordEditInfluencer$key } from "@generated/PasswordEditInfluencer.graphql";
import { goBack } from "@navigation/navigate";
import useMessage from "@hooks/useMessage";
import Divider from "@components/atoms/Divider";
import { PasswordField, ErrorText } from "@components/molecules/TextInput";

const updateQuery = graphql`
  mutation PasswordEditChangeMutation($input: UpdatePasswordMutationInput!) {
    updatePassword(input: $input) {
      __typename
      ... on Influencer {
        hasPassword
      }
      ... on UpdatePasswordError {
        message
      }
    }
  }
`;

const reissueQuery = graphql`
  mutation PasswordEditReissueMutation($email: String!) {
    reissuePassword(input: { email: $email }) {
      email
    }
  }
`;

const emailQuery = graphql`
  fragment PasswordEditInfluencer on Influencer {
    email
  }
`;

type InputData = {
  currentPassword: string;
  password: string;
};

export default function PasswordEdit({
  emailFragment,
}: {
  emailFragment: PasswordEditInfluencer$key;
}) {
  const { email } = useFragment(emailQuery, emailFragment);
  const [reissue, setReissue] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      currentPassword: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        currentPassword: string().required("入力してください"),
        password: string()
          .trim()
          .matches(
            PasswordPattern,
            "半角英字、数字、記号を組み合わせてください"
          )
          .min(8, "8文字以上で入力してください")
          .required("入力してください"),
      })
    ),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const { set: setMessage } = useMessage();
  const [error, setError] = useState<string | null>(null);
  const [commitChange] = useMutation<PasswordEditChangeMutation>(updateQuery);
  const [commitReissue] =
    useMutation<PasswordEditReissueMutation>(reissueQuery);
  const save = useCallback(async () => {
    await handleSubmit(async (submitInput: InputData) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commitChange({
          variables: {
            input: {
              ...submitInput,
            },
          },
          onCompleted({ updatePassword }) {
            if (updatePassword.__typename === "UpdatePasswordError") {
              setError(updatePassword.message);
            } else {
              setMessage({
                title: "パスワードを変更しました",
                mode: "toast",
              });
              goBack();
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commitChange, setMessage, handleSubmit]);

  const reissuePassword = useCallback(async () => {
    setLoading(true);
    setReissue(false);
    await new Promise<void>((resolve, reject) => {
      commitReissue({
        variables: { email },
        onCompleted() {
          resolve();
        },
        onError(e: Error) {
          reject(e);
        },
      });
    });
    setLoading(false);
    setComplete(true);
  }, [commitReissue, email]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <PasswordField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="現在のパスワード"
                name="currentPassword"
              />
              {!isValid && errors.currentPassword !== undefined && (
                <ErrorText error={errors.currentPassword.message} />
              )}

              <Spacer height={24} />

              <PasswordField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="新しいパスワード"
                name="password"
              />
              {!isValid && errors.password !== undefined && (
                <ErrorText error={errors.password.message} />
              )}
              <Text style={styles.guide}>
                半角英字（大/小文字）、数字、記号（@:#-+_=等）を組み合わせ8文字以上
              </Text>
            </Card>
            <TouchableOpacity
              onPress={() => setReissue(true)}
              style={styles.message}
            >
              <Text style={[styles.guide, styles.underline]}>
                パスワードを忘れた方
              </Text>
            </TouchableOpacity>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="変更する"
        />
      </Card>

      {reissue && (
        <Dialog
          buttons={[
            { title: "キャンセル", type: "white" },
            {
              title: "送信する",
              type: "blueGradient",
              onPress: () => reissuePassword(),
            },
          ]}
          message="パスワードを再設定します"
          onClose={() => setReissue(false)}
          subline={`${email} 宛にパスワード再設定メールを送信します。よろしいですか？`}
        />
      )}

      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.error }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      {complete && (
        <Dialog
          message="再設定メールを送信しました"
          onClose={() => setComplete(false)}
          subline="届きましたらメールに記載されているURLからパスワードの再設定へ進んでください。"
        />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    padding: 16,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  error: {
    color: Colors.orange,
    textAlign: "center",
  },
  underline: {
    textDecorationColor: Colors.gray,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
  },
});
