import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { graphql, useMutation } from "react-relay/hooks";
import GradientButton from "@components/atoms/GradientButton";
import { PasswordPattern, KeyboardId } from "@constants/App";
import useMessage from "@hooks/useMessage";
import { ChangePasswordMutation } from "@generated/ChangePasswordMutation.graphql";
import Divider from "@components/atoms/Divider";
import { PasswordField, ErrorText } from "@components/molecules/TextInput";
import { replace } from "@navigation/navigate";

const updatePasswordReminderMutation = graphql`
  mutation ChangePasswordMutation(
    $input: UpdatePasswordReminderMutationInput!
  ) {
    updatePasswordReminder(input: $input) {
      __typename
      ... on Influencer {
        hasPassword
      }
      ... on UpdatePasswordReminderError {
        message
      }
    }
  }
`;

type InputData = {
  password: string;
};

export default function ChangePassword({ token }: { token: string }) {
  const { set: setMessage } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<ChangePasswordMutation>(
    updatePasswordReminderMutation
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        password: string()
          .trim()
          .matches(
            PasswordPattern,
            "半角英字、数字、記号を組み合わせてください"
          )
          .min(8, "8文字以上で入力してください")
          .required("入力してください"),
      })
    ),
  });

  const save = useCallback(async () => {
    await handleSubmit(async ({ password }: InputData) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              password,
              resetPasswordToken: token,
            },
          },
          onCompleted({ updatePasswordReminder }) {
            if (updatePasswordReminder.__typename === "Influencer") {
              setMessage({
                title: "パスワードを登録しました",
                mode: "toast",
              });
              replace("AuthLogin");
            } else if (
              updatePasswordReminder.__typename ===
              "UpdatePasswordReminderError"
            ) {
              setError(updatePasswordReminder.message);
            } else {
              setError("パスワードの登録に失敗しました");
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, token, setMessage, handleSubmit]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <PasswordField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="新しいパスワード"
                name="password"
              />
              {!isValid && errors.password !== undefined && (
                <ErrorText error={errors.password.message} />
              )}
              <Text style={styles.guide}>
                半角英字（大/小文字）、数字、記号（@:#-+_=等）を組み合わせ8文字以上
              </Text>
            </Card>
            <View style={styles.message}>
              <Text style={styles.guide}>
                パスワードを設定することでログイン時にメールアドレスでログインも可能になります
              </Text>
            </View>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="登録する"
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    padding: 16,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
