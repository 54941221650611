import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import { graphql, useFragment } from "react-relay/hooks";
import { EmailEdit$key } from "@generated/EmailEdit.graphql";
import Form from "@components/organisms/EmailEdit/EmailEditForm";
import Confirm from "@components/organisms/EmailEdit/EmailEditConfirm";
import Modal from "@components/molecules/Modal";

const emailEditQuery = graphql`
  fragment EmailEdit on Influencer {
    ...EmailEditConfirm
    ...EmailEditForm
  }
`;

export default function EmailEdit({
  emailFragment,
}: {
  emailFragment: EmailEdit$key;
}) {
  const influencer = useFragment(emailEditQuery, emailFragment);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  return (
    <View style={styles.container}>
      <Form emailFragment={influencer} onComplete={setToken} />
      {token !== null && (
        <Modal
          animationType="slide"
          header
          headerTitle="認証コードを送信しました"
          onRequestClose={() => setToken(null)}
          transparent
          visible
        >
          <Confirm
            emailFragment={influencer}
            initialToken={token}
            onComplete={() => {
              setMessage("Emailを変更しました");
              setToken(null);
            }}
          />
        </Modal>
      )}
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
