import React from "react";
import { Text, View } from "@components/atoms/Themed";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { StyleSheet } from "react-native";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import { CampaignQualification$key } from "@generated/CampaignQualification.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import SelectableText from "@components/atoms/SelectableText";

const campaignQualification = graphql`
  fragment CampaignQualification on Campaign {
    socialNetwork {
      castingCondition
    }
  }
`;

export default function CampaignQualification({
  campaignFragment,
}: {
  campaignFragment: CampaignQualification$key;
}) {
  const campaign = useFragment(campaignQualification, campaignFragment);
  return (
    <CampaignSection title="応募条件">
      {campaign.socialNetwork.castingCondition !== null ? (
        <View style={styles.wrap}>
          <Icon color={Colors.green} name="check" size={24} />
          <Spacer width={8} />
          <View style={styles.contentWrap}>
            <SelectableText style={styles.content}>
              {campaign.socialNetwork.castingCondition}
            </SelectableText>
          </View>
        </View>
      ) : (
        <Text style={styles.text}>条件なし</Text>
      )}
    </CampaignSection>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
  },
  contentWrap: {
    flex: 1,
  },
  content: {
    ...Fonts.xlb160,
  },
  text: {
    ...Fonts.xlr160,
  },
});
