import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import { FavoriteButtonCreateMutation } from "@generated/FavoriteButtonCreateMutation.graphql";
import { FavoriteButtonRemoveMutation } from "@generated/FavoriteButtonRemoveMutation.graphql";
import { useMutation, graphql, ConnectionHandler } from "react-relay/hooks";
import { Card } from "@components/atoms/Themed";
import useMessage from "@hooks/useMessage";
import { resolveError } from "@lib/util/error";

type Props = {
  /** アイコンのサイズ */
  iconSize: number;
  /** ボタンのサイズ */
  buttonSize?: number;
  /** いいね処理のためのid */
  campaignId: string;
  /** お気に入り済みかどうか？ */
  isFavoriteCheck: boolean;
  /** 処理成功時のメッセージを表示するか？ */
  showMessage?: boolean;
};

const createMutation = graphql`
  mutation FavoriteButtonCreateMutation(
    $input: CreateFavoriteMutationInput!
    $connections: [ID!]!
  ) {
    createFavorite(input: $input) {
      __typename
      ... on CreateFavoriteSuccess {
        campaign {
          isFavoriteCheck
        }
        favoriteEdge @prependEdge(connections: $connections) {
          cursor
          node {
            id
            campaign {
              id
              title
              mediaType
              gifting
              prType
              banners {
                file
              }
              mCategory {
                name
              }
              socialNetwork {
                recruitmentEndOn
              }
            }
          }
        }
      }
      ... on CreateFavoriteError {
        message
      }
    }
  }
`;

const removeMutation = graphql`
  mutation FavoriteButtonRemoveMutation(
    $input: RemoveFavoriteMutationInput!
    $connections: [ID!]!
  ) {
    removeFavorite(input: $input) {
      __typename
      ... on RemoveFavoriteSuccess {
        campaign {
          isFavoriteCheck
        }
        favorite {
          id @deleteEdge(connections: $connections)
        }
      }
      ... on RemoveFavoriteError {
        message
      }
    }
  }
`;

export default function FavoriteButton({
  iconSize,
  buttonSize,
  campaignId,
  isFavoriteCheck,
  showMessage = false,
}: Props) {
  const size = buttonSize ?? iconSize * 2;
  const { set: setMessage } = useMessage();
  const [isNotFavorite, setIsNotFavorite] = useState<boolean>(!isFavoriteCheck);
  const [createCommit] =
    useMutation<FavoriteButtonCreateMutation>(createMutation);
  const [removeCommit] =
    useMutation<FavoriteButtonRemoveMutation>(removeMutation);

  useEffect(() => {
    setIsNotFavorite(!isFavoriteCheck);
  }, [isFavoriteCheck]);

  const toggle = useCallback(async () => {
    try {
      const connectionID = ConnectionHandler.getConnectionID(
        "root",
        "FavoriteList_favorites"
      );
      // MEMO: 非同期だとハートの色のが変わるまで遅いので、タッチした時点で色を変える
      setIsNotFavorite(!isNotFavorite);

      const result = await new Promise<boolean>((resolve, reject) => {
        if (isFavoriteCheck) {
          removeCommit({
            variables: {
              input: {
                campaignId,
              },
              connections: [connectionID],
            },
            onCompleted({ removeFavorite }) {
              if (removeFavorite.__typename === "RemoveFavoriteSuccess") {
                resolve(removeFavorite.campaign.isFavoriteCheck);
              } else {
                reject(
                  new Error(
                    removeFavorite.__typename === "RemoveFavoriteError"
                      ? removeFavorite.message
                      : "お気に入りを削除できませんでした"
                  )
                );
              }
            },
          });
        } else {
          createCommit({
            variables: {
              input: {
                campaignId,
              },
              connections: [connectionID],
            },
            onCompleted({ createFavorite }) {
              if (createFavorite.__typename === "CreateFavoriteSuccess") {
                resolve(createFavorite.campaign.isFavoriteCheck);
              } else {
                reject(
                  new Error(
                    createFavorite.__typename === "CreateFavoriteError"
                      ? createFavorite.message
                      : "お気に入りを保存できませんでした"
                  )
                );
              }
            },
          });
        }
      });

      setIsNotFavorite(!result);
      if (showMessage) {
        setMessage({
          title: result
            ? "お気に入りに保存しました"
            : "お気に入りから削除しました",
          mode: "toast",
        });
      }
    } catch (error: unknown) {
      setMessage({
        title: resolveError(error).message,
        mode: "toast",
      });
    }
  }, [
    campaignId,
    createCommit,
    isFavoriteCheck,
    isNotFavorite,
    removeCommit,
    setMessage,
    showMessage,
  ]);

  return (
    <TouchableOpacity onPress={toggle}>
      <Card
        style={[
          styles.container,
          {
            width: size,
            height: size,
            borderRadius: size / 2,
          },
        ]}
      >
        {isNotFavorite ? (
          <Icon name="heartEmpty" size={iconSize} />
        ) : (
          <Icon
            color={Colors.orange}
            name="heart"
            size={iconSize}
            skipDarkModeColor
          />
        )}
      </Card>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: Colors.gray20,
    borderStyle: "solid",
  },
});
