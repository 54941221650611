import { StyleSheet } from "react-native";
import React, { useCallback, useMemo, useState } from "react";
import { PostDraft$key, PostDraft$data } from "@generated/PostDraft.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import Youtube from "@components/organisms/PostDraft/PostDraftYoutube";
import Tiktok from "@components/organisms/PostDraft/PostDraftTiktok";
import Twitter from "@components/organisms/PostDraft/PostDraftTwitter";
import Instagram from "@components/organisms/PostDraft/PostDraftInstagram";
import Other from "@components/organisms/PostDraft/PostDraftOther";
import { View } from "@components/atoms/Themed";
import { replace, canGoBack, goBack } from "@navigation/navigate";
import NavigationBar from "@components/molecules/NavigationBar";
import Dialog from "@components/molecules/dialog/Dialog";
import Loading from "@components/atoms/Loading";

const chatDraft = graphql`
  fragment PostDraft on Post {
    id
    status
    campaign {
      mediaType
    }
    ...PostDraftYoutube
    ...PostDraftTwitter
    ...PostDraftTiktok
    ...PostDraftOther
    ...PostDraftInstagram
  }
`;

function Content({
  data,
  setChange,
  setIsLoading,
}: {
  data: PostDraft$data;
  setChange: (isChange: boolean) => void;
  setIsLoading: (loading: boolean) => void;
}) {
  const status = useMemo<"draft" | "modify" | "review" | "approved">(() => {
    switch (data.status) {
      case "draft":
        return "draft";
      case "admin_rejected":
      case "agency_rejected":
      case "draft_with_reject":
        return "modify";
      case "wait_admin_review":
      case "wait_agency_review":
        return "review";
      default:
        return "approved";
    }
  }, [data.status]);

  const onClose = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      replace("Chat", { id: data.id });
    }
  }, [data]);

  const onChange = useCallback(
    (isChange: boolean) => {
      if (status === "draft" || status === "modify") {
        setChange(isChange);
      }
    },
    [status, setChange]
  );

  const onLoading = useCallback(
    (isLoading: boolean) => {
      if (status === "draft" || status === "modify") {
        setIsLoading(isLoading);
      }
    },
    [status, setIsLoading]
  );

  switch (data.campaign.mediaType) {
    case "youtube":
      return (
        <Youtube
          onChange={onChange}
          onClose={onClose}
          onLoading={onLoading}
          postFragment={data}
          status={status}
        />
      );
    case "twitter_image":
    case "twitter_video":
      return (
        <Twitter
          onChange={onChange}
          onClose={onClose}
          onLoading={onLoading}
          postFragment={data}
          status={status}
        />
      );
    case "tiktok":
      return (
        <Tiktok
          onChange={onChange}
          onClose={onClose}
          onLoading={onLoading}
          postFragment={data}
          status={status}
        />
      );
    case "ins_story":
    case "ins_highlight":
    case "ins_video":
    case "ins_image":
    case "ins_live":
      return (
        <Instagram
          onChange={onChange}
          onClose={onClose}
          onLoading={onLoading}
          postFragment={data}
          status={status}
        />
      );
    case "other":
      return (
        <Other
          onChange={onChange}
          onClose={onClose}
          onLoading={onLoading}
          postFragment={data}
          status={status}
        />
      );
    default:
      return null;
  }
}

export default function PostDraft({
  postFragment,
}: {
  postFragment: PostDraft$key;
}) {
  const data = useFragment(chatDraft, postFragment);
  const [cancel, setCancel] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onClose = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      replace("Chat", { id: data.id });
    }
  }, [data]);

  return (
    <View style={styles.container}>
      <NavigationBar
        onClose={() => {
          if (isChange) {
            setCancel(true);
          } else {
            onClose();
          }
        }}
        title="下書き"
      />
      <Content data={data} setChange={setIsChange} setIsLoading={setLoading} />
      {isChange && cancel && (
        <Dialog
          buttons={[
            { title: "キャンセル", type: "white" },
            {
              title: "チャットに戻る",
              type: "blueGradient",
              onPress: () => onClose(),
            },
          ]}
          message="変更が保存されていません"
          onClose={() => setCancel(false)}
          subline="チャットに戻ると変更が破棄されます。よろしいですか？"
        />
      )}
      {loading && <Loading mask />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
