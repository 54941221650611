import React, { useRef, useState, useMemo, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "@lib/util/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Dialog from "@components/molecules/dialog/Dialog";
import Divider from "@components/atoms/Divider";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { CampaignInputAddressInfluencer$key } from "@generated/CampaignInputAddressInfluencer.graphql";
import { CampaignInputAddressPrefecture$key } from "@generated/CampaignInputAddressPrefecture.graphql";
import { CampaignInputAddressSearchMutation } from "@generated/CampaignInputAddressSearchMutation.graphql";
import { CampaignInputAddressUpdateMutation } from "@generated/CampaignInputAddressUpdateMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import Button from "@components/atoms/Button";
import { KeyboardId } from "@constants/App";
import GradientButton from "@components/atoms/GradientButton";
import {
  TextField,
  Selectbox,
  SelectboxRef,
  ErrorText,
} from "@components/molecules/TextInput";

const addressEditInfoQuery = graphql`
  fragment CampaignInputAddressInfluencer on Influencer {
    postalCode
    prefectureName
    cityName
    townName
    buildingName
    firstName
    lastName
  }
`;

const addressEditPrefectureQuery = graphql`
  fragment CampaignInputAddressPrefecture on Query {
    prefectures {
      name
    }
  }
`;

const addressEditSearchMutation = graphql`
  mutation CampaignInputAddressSearchMutation($postalCode: String!) {
    postalCode(input: { postalCode: $postalCode }) {
      __typename
      ... on MPostalCode {
        postalCode
        mPrefectureName
        city
        town
      }
      ... on PostalCodeError {
        message
      }
    }
  }
`;

const addressEditUpdateMutation = graphql`
  mutation CampaignInputAddressUpdateMutation(
    $input: UpdateInfluencerMutationInput!
  ) {
    updateInfluencer(input: $input) {
      __typename
      ... on Influencer {
        postalCode
        prefectureName
        cityName
        townName
        buildingName
        firstName
        lastName
      }
      ... on UpdateInfluencerError {
        message
      }
    }
  }
`;

type AddressInput = {
  firstName: string;
  lastName: string;
  postalCode: string;
  prefectureName: string;
  cityName: string;
  townName: string;
  buildingName: string | null;
};

type Props = {
  addressFragment: CampaignInputAddressInfluencer$key;
  prefecturesFragment: CampaignInputAddressPrefecture$key;
  hasNext: boolean;
  onClose: () => void;
};

export default function CampaignInputAddress({
  addressFragment,
  prefecturesFragment,
  hasNext,
  onClose,
}: Props) {
  const data = useFragment(addressEditInfoQuery, addressFragment);
  const { prefectures } = useFragment(
    addressEditPrefectureQuery,
    prefecturesFragment
  );
  const [postalCode, setPostalCode] = useState<string | null>(data.postalCode);

  const prefectureItems = useMemo(
    () =>
      prefectures.map((row) => ({
        label: row.name,
        value: row.name,
      })),
    [prefectures]
  );

  const prefectureList = useMemo(
    () => prefectures.map((row) => row.name),
    [prefectures]
  );
  const prefectureRef = useRef<SelectboxRef>();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddressInput>({
    defaultValues: {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      postalCode: data.postalCode ?? "",
      prefectureName: data.prefectureName ?? "",
      cityName: data.cityName ?? "",
      townName: data.townName ?? "",
      buildingName: data.buildingName,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        firstName: string().trim().required("入力してください"),
        lastName: string().trim().required("入力してください"),
        postalCode: string()
          .default("")
          .trim()
          .replace(/-/, "")
          .length(7, "7桁で入力してください")
          .required("入力してください")
          .numberString("郵便番号は半角数字で入力してください"),
        prefectureName: string()
          .trim()
          .oneOf(prefectureList, "選択してください")
          .required("入力してください"),
        cityName: string().trim().required("入力してください"),
        townName: string().trim().required("入力してください"),
        buildingName: string().trim().default("").nullable().toNull(),
      })
    ),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searched] = useMutation<CampaignInputAddressSearchMutation>(
    addressEditSearchMutation
  );
  const [commit] = useMutation<CampaignInputAddressUpdateMutation>(
    addressEditUpdateMutation
  );

  const search = useCallback(async () => {
    if (postalCode === null || postalCode.length !== 7) {
      return;
    }
    setLoading(true);
    await new Promise<void>((resolve) => {
      searched({
        variables: {
          postalCode,
        },
        onCompleted({ postalCode: result }) {
          if (result.__typename === "PostalCodeError") {
            setError(result.message);
          } else if (result.__typename === "MPostalCode") {
            setValue("prefectureName", result.mPrefectureName);
            prefectureRef?.current?.updateDisplay(result.mPrefectureName);
            setValue("cityName", result.city);
            setValue("townName", result.town ?? "");
          }
          resolve();
        },
      });
    });
    setLoading(false);
  }, [searched, setValue, postalCode]);

  const save = useCallback(async () => {
    await handleSubmit(async (addressInput: AddressInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...addressInput,
            },
          },
          onCompleted({ updateInfluencer }) {
            if (updateInfluencer.__typename === "UpdateInfluencerError") {
              setError(updateInfluencer.message);
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="姓名・住所登録" />
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                提供商品の発送先になる本名・電話番号・住所を入力してください。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.content}>
              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="本名(姓)"
                maxLength={20}
                name="lastName"
                required
              />
              {!isValid && errors.lastName !== undefined && (
                <ErrorText error={errors.lastName.message} />
              )}

              <Spacer height={24} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="本名(名)"
                maxLength={20}
                name="firstName"
                required
              />
              {!isValid && errors.firstName !== undefined && (
                <ErrorText error={errors.firstName.message} />
              )}

              <Spacer height={24} />

              <View style={styles.postalcode}>
                <View style={styles.field}>
                  <TextField
                    control={control}
                    inputAccessoryViewID={KeyboardId}
                    label="郵便番号"
                    name="postalCode"
                    onChange={(value: string) => setPostalCode(value ?? null)}
                    placeholder="郵便番号を登録してください"
                    required
                    type="phone"
                  />
                  {!isValid && errors.postalCode !== undefined && (
                    <ErrorText error={errors.postalCode.message} />
                  )}
                  <Text style={styles.guide}>ハイフン(-)なし</Text>
                </View>
                <Spacer width={8} />
                <View style={styles.search}>
                  <Button
                    border={postalCode !== null}
                    disabled={postalCode === null}
                    height={50}
                    loading={loading}
                    onPress={search}
                    title="住所検索"
                    width={96}
                  />
                </View>
              </View>

              <Spacer height={24} />

              <Selectbox
                control={control}
                emptyValue=""
                innerRef={prefectureRef}
                items={prefectureItems}
                label="都道府県"
                name="prefectureName"
                required
              />
              {!isValid && errors.prefectureName !== undefined && (
                <ErrorText error={errors.prefectureName.message} />
              )}
              <Spacer height={24} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="市区町村"
                maxLength={100}
                name="cityName"
                required
              />
              {!isValid && errors.cityName !== undefined && (
                <ErrorText error={errors.cityName.message} />
              )}

              <Spacer height={24} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="町名番地"
                maxLength={100}
                name="townName"
                required
              />
              {!isValid && errors.townName !== undefined && (
                <ErrorText error={errors.townName.message} />
              )}

              <Spacer height={24} />

              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="建物名・部屋番号"
                maxLength={100}
                name="buildingName"
              />
              {!isValid && errors.buildingName !== undefined && (
                <ErrorText error={errors.buildingName.message} />
              )}
            </Card>
            <Spacer height={80} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title={hasNext ? "次へ" : "情報を登録し、応募する"}
        />
      </Card>
      {message !== null && (
        <Dialog message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <Dialog
          customStyle={{ messageStyle: styles.dialogError }}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  postalcode: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  field: {
    flex: 1,
  },
  search: {
    width: 96,
    paddingTop: 20,
  },
  message: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
