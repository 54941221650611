/**
 * @generated SignedSource<<dd2bc62d46f7b15b2ec620ed138bc998>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveAccountMutationInput = {
  clientMutationId?: string | null;
  influencerId: string;
};
export type AccountDeletionMutation$variables = {
  input: RemoveAccountMutationInput;
};
export type AccountDeletionMutation$data = {
  readonly removeAccount: {
    readonly __typename: "RemoveAccountError";
    readonly message: string;
  } | {
    readonly __typename: "RemoveAccountSuccess";
    readonly success: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AccountDeletionMutation = {
  response: AccountDeletionMutation$data;
  variables: AccountDeletionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "removeAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "RemoveAccountSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "RemoveAccountError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDeletionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountDeletionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d383555239024efb9ee9324d495aa4c7",
    "id": null,
    "metadata": {},
    "name": "AccountDeletionMutation",
    "operationKind": "mutation",
    "text": "mutation AccountDeletionMutation(\n  $input: RemoveAccountMutationInput!\n) {\n  removeAccount(input: $input) {\n    __typename\n    ... on RemoveAccountSuccess {\n      success\n    }\n    ... on RemoveAccountError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "955e4a3e50ad634bedcbe14ee795d936";

export default node;
