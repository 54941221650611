import React, { useMemo } from "react";
import { LinearGradient } from "expo-linear-gradient";
import {
  Text,
  TextStyle,
  Platform,
  StyleProp,
  StyleSheet,
  DimensionValue,
} from "react-native";
import MaskedView from "@react-native-masked-view/masked-view";
import Colors from "@constants/Colors";

type Props = {
  /** グラデーションテキストの高さを指定 */
  height: DimensionValue;
  /** 子要素。表示したいテキストなどを指定 */
  children: React.ReactNode;
  /** 文字に適用したいスタイル */
  style?: StyleProp<TextStyle>;
  grColor: string[];
};

export default function GradientText({
  grColor,
  height,
  children,
  style,
}: Props) {
  const dynamicStyle = useMemo(
    () =>
      StyleSheet.create({
        makedView: {
          height,
        },
      }),
    [height]
  );
  if (Platform.OS === "web") {
    return <Text style={[style, styles.colorGreen]}>{children}</Text>;
  }
  return (
    <MaskedView
      maskElement={<Text style={style}>{children}</Text>}
      style={dynamicStyle.makedView}
    >
      <LinearGradient
        colors={grColor}
        end={{
          x: 1,
          y: 0,
        }}
        start={{
          x: 0,
          y: 0,
        }}
      >
        <Text style={[style, styles.opacityZero]}>{children}</Text>
      </LinearGradient>
    </MaskedView>
  );
}

const styles = StyleSheet.create({
  colorGreen: { color: Colors.green },
  opacityZero: {
    opacity: 0,
  },
});
