import React, { useState } from "react";
import {
  FlatList,
  StyleSheet,
  RefreshControl,
  TouchableOpacity,
} from "react-native";
import Divider from "@components/atoms/Divider";
import { View, Wrapper, Card } from "@components/atoms/Themed";
import Loading from "@components/atoms/Loading";
import { usePaginationFragment, graphql } from "react-relay/hooks";
import { TodosPagination$key } from "@generated/TodosPagination.graphql";
import Spacer from "@components/atoms/Spacer";
import { PagingPost } from "@constants/App";
import Colors from "@constants/Colors";
import Campaign from "@components/organisms/Todo/TodoCampaign";
import LastComment from "@components/organisms/Todo/TodoComments";
import Tutorial from "@components/organisms/Todo/TodoTutorial";
import { navigate } from "@navigation/navigate";

const todosQuery = graphql`
  fragment TodosPagination on Query
  @refetchable(queryName: "TodosPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    influencerId: { type: "ID!" }
  ) {
    posts(first: $first, after: $after, influencerId: $influencerId)
      @connection(key: "TodosList_posts") {
      edges {
        node {
          id
          status
          ...TodoCampaign
          ...TodoComments
        }
      }
    }
  }
`;

export default function Todos({ posts }: { posts: TodosPagination$key }) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    todosQuery,
    posts
  );
  const { edges } = data.posts;
  return (
    <View style={styles.container}>
      <FlatList
        contentContainerStyle={styles.list}
        data={edges}
        keyExtractor={(item) => item.node.id}
        ListEmptyComponent={Tutorial}
        ListHeaderComponentStyle={{
          paddingBottom: 8,
        }}
        onEndReached={() => {
          if (hasNext && !paging) {
            setPaging(true);
            loadNext(PagingPost, { onComplete: () => setPaging(false) });
          }
        }}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              if (!refreshing) {
                setRefreshing(true);
                refetch(
                  { first: PagingPost },
                  {
                    fetchPolicy: "network-only",
                    onComplete: () => setRefreshing(false),
                  }
                );
              }
            }}
            refreshing={false}
          />
        }
        renderItem={({ item, index }) => (
          <Wrapper>
            {index !== 0 && <Divider color={Colors.gray20} height={1} />}
            <TouchableOpacity
              onPress={() => {
                navigate("Chat", { id: item.node.id });
              }}
            >
              <Card style={styles.item}>
                <Campaign fragmentRef={item.node} />
                <Spacer height={8} />
                <LastComment fragmentRef={item.node} />
              </Card>
              {item.node.status === "finished" && (
                <View style={styles.finished} />
              )}
            </TouchableOpacity>
          </Wrapper>
        )}
      />
      {paging && (
        <View style={styles.paging}>
          <Loading />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    paddingTop: 8,
    paddingBottom: 24,
  },
  item: {
    padding: 16,
  },
  paging: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    paddingVertical: 12,
  },

  finished: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: Colors.overlayWhite,
  },
});
