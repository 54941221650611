/**
 * @generated SignedSource<<ebcf3b9b6f2c0caad73f049ebd07f401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignDetailCampaign$data = {
  readonly id: string;
  readonly mediaType: MediaType;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignActionCampaign" | "CampaignCast" | "CampaignContents" | "CampaignMain" | "CampaignProduct" | "CampaignQualification" | "CampaignReward">;
  readonly " $fragmentType": "CampaignDetailCampaign";
};
export type CampaignDetailCampaign$key = {
  readonly " $data"?: CampaignDetailCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignDetailCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDetailCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignReward"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignCast"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignQualification"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignProduct"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignMain"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignContents"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignActionCampaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "3cfa816998d816e613b66db069a21cb3";

export default node;
