/**
 * @generated SignedSource<<53567c89273db897b68a15f4330863f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestSignedUrlMutationInput = {
  clientMutationId?: string | null;
  codeChallenge: string;
  downloadableId: string;
  downloadableType: string;
};
export type ChatCampaignMutation$variables = {
  input: RequestSignedUrlMutationInput;
};
export type ChatCampaignMutation$data = {
  readonly requestSignedUrl: {
    readonly __typename: "RequestSignedUrlError";
    readonly message: string;
  } | {
    readonly __typename: "RequestSignedUrlSuccess";
    readonly uri: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type ChatCampaignMutation = {
  response: ChatCampaignMutation$data;
  variables: ChatCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "requestSignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uri",
            "storageKey": null
          }
        ],
        "type": "RequestSignedUrlSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "RequestSignedUrlError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e3ee40ff7700606c3d4e3b91493b937c",
    "id": null,
    "metadata": {},
    "name": "ChatCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation ChatCampaignMutation(\n  $input: RequestSignedUrlMutationInput!\n) {\n  requestSignedUrl(input: $input) {\n    __typename\n    ... on RequestSignedUrlSuccess {\n      uri\n    }\n    ... on RequestSignedUrlError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eca029a26e4308dfebd99511a8399076";

export default node;
