import React, { useState, useEffect } from "react";
import { TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { graphql, useFragment } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import Icon from "@components/atoms/Icon";
import GradientButton from "@components/atoms/GradientButton";
import Divider from "@components/atoms/Divider";
import { ParentMediaType, ParentMediaTypeNames } from "@constants/App";
import SNSIcon from "@components/atoms/SNSIcon";
import Button from "@components/atoms/Button";
import { replace } from "@navigation/navigate";
import {
  CampaignFilter$key,
  CategoryTypes,
} from "@generated/CampaignFilter.graphql";
import useFilterStatus from "@hooks/useFilterStatus";

const categoryList = graphql`
  fragment CampaignFilter on Query {
    mCategories {
      id
      name
      keyName
    }
  }
`;

export default function CampaignFilter({
  listFragment,
  tab,
}: {
  listFragment: CampaignFilter$key;
  tab: string;
}) {
  const { set: setFilter, state: filters } = useFilterStatus();
  const { mCategories } = useFragment(categoryList, listFragment);
  const [selectedSnsTypes, setSelectedSnsTypes] = useState<ParentMediaType[]>(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState<CategoryTypes[]>(
    []
  );
  const [selectedHasMoney, setSelectedHasMoney] = useState<boolean>(false);
  const [selectedHasProduct, setSelectedHasProduct] = useState<boolean>(false);

  useEffect(() => {
    if (filters !== null) {
      setSelectedCategories(filters.selectedCategories);
      setSelectedSnsTypes(filters.selectedSns);
      setSelectedHasMoney(filters.selectedHasMoney);
      setSelectedHasProduct(filters.selectedHasProduct);
    }
  }, [filters]);

  const mMediaTypes: ParentMediaType[] = [
    "instagram",
    "twitter",
    "youtube",
    "tiktok",
    "cast",
    "other",
  ];

  function toggle(params: {
    selectedSns?: ParentMediaType;
    selectedCategory?: CategoryTypes;
    selectedMoney?: boolean;
    selectedProduct?: boolean;
  }): void {
    if (params.selectedSns !== undefined) {
      if (selectedSnsTypes.includes(params.selectedSns)) {
        setSelectedSnsTypes(
          selectedSnsTypes.filter((item) => item !== params.selectedSns)
        );
      } else {
        setSelectedSnsTypes([...selectedSnsTypes, params.selectedSns]);
      }
    } else if (params.selectedCategory !== undefined) {
      if (selectedCategories.includes(params.selectedCategory)) {
        setSelectedCategories(
          selectedCategories.filter((item) => item !== params.selectedCategory)
        );
      } else {
        setSelectedCategories([...selectedCategories, params.selectedCategory]);
      }
    } else if (params.selectedMoney !== undefined) {
      if (selectedHasMoney) {
        setSelectedHasMoney(false);
      } else {
        setSelectedHasMoney(true);
      }
    } else if (params.selectedProduct !== undefined) {
      if (selectedHasProduct) {
        setSelectedHasProduct(false);
      } else {
        setSelectedHasProduct(true);
      }
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <Wrapper>
          <Spacer height={24} />
          <View style={styles.content}>
            <View>
              <Text style={styles.title}>キャンペーンを行うSNS</Text>
              <Spacer height={4} />
              {mMediaTypes.map((item) => (
                <TouchableOpacity
                  key={`sns-${item}`}
                  onPress={() =>
                    toggle({
                      selectedSns: item,
                    })
                  }
                >
                  <Spacer height={12} />
                  <View style={styles.item}>
                    {selectedSnsTypes.includes(item) ? (
                      <Icon
                        color={Colors.green}
                        name="checkBoxFill"
                        size={24}
                      />
                    ) : (
                      <Icon
                        color={Colors.black}
                        name="checkboxEmpty"
                        size={24}
                      />
                    )}
                    <Spacer width={8} />
                    <SNSIcon name={item} style={{ height: 24, width: 24 }} />
                    <Spacer width={4} />
                    <Text style={styles.text}>
                      {ParentMediaTypeNames[item]}
                    </Text>
                  </View>
                  <Spacer height={12} />
                </TouchableOpacity>
              ))}
            </View>
            <Spacer height={12} />
            <Divider />
            <Spacer height={24} />
            <View>
              <Text style={styles.title}>キャンペーン商品のカテゴリー</Text>
              <Spacer height={4} />
              {mCategories.map((item) => (
                <TouchableOpacity
                  key={`category-${item.keyName}`}
                  onPress={() => toggle({ selectedCategory: item.keyName })}
                >
                  <Spacer height={12} />
                  <View style={styles.item}>
                    {selectedCategories.includes(item.keyName) ? (
                      <Icon
                        color={Colors.green}
                        name="checkBoxFill"
                        size={24}
                      />
                    ) : (
                      <Icon
                        color={Colors.black}
                        name="checkboxEmpty"
                        size={24}
                      />
                    )}
                    <Spacer width={8} />
                    <Text style={styles.text}>{item.name}</Text>
                  </View>
                  <Spacer height={12} />
                </TouchableOpacity>
              ))}
            </View>
            <Spacer height={12} />
            <Divider />
            <Spacer height={24} />
            <View>
              <Text style={styles.title}>報酬</Text>

              <TouchableOpacity onPress={() => toggle({ selectedMoney: true })}>
                <Spacer height={12} />
                <View style={styles.item}>
                  {selectedHasMoney ? (
                    <Icon color={Colors.green} name="checkBoxFill" size={24} />
                  ) : (
                    <Icon color={Colors.black} name="checkboxEmpty" size={24} />
                  )}
                  <Spacer width={8} />
                  <Text style={styles.text}>報酬金あり</Text>
                </View>
                <Spacer height={12} />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => toggle({ selectedProduct: true })}
              >
                <Spacer height={12} />
                <View style={styles.item}>
                  {selectedHasProduct ? (
                    <Icon color={Colors.green} name="checkBoxFill" size={24} />
                  ) : (
                    <Icon color={Colors.black} name="checkboxEmpty" size={24} />
                  )}
                  <Spacer width={8} />
                  <Text style={styles.text}>商品提供あり</Text>
                </View>
                <Spacer height={12} />
              </TouchableOpacity>
              <Spacer height={12} />
            </View>
          </View>
        </Wrapper>
      </ScrollView>
      <Card>
        <Wrapper>
          <View style={styles.buttons}>
            <View style={styles.button}>
              <Button
                onPress={() => {
                  setFilter({
                    selectedCategories: [],
                    selectedSns: [],
                    selectedHasMoney: false,
                    selectedHasProduct: false,
                    filterStatus: false,
                  });
                  replace("Root", {
                    screen: "Home",
                    params: {
                      tab,
                    },
                  });
                }}
                textStyle={styles.buttonText}
                title="リセット"
                width="100%"
              />
            </View>

            <Spacer width={8} />

            <View style={styles.button}>
              <GradientButton
                onPress={() => {
                  setFilter({
                    selectedCategories,
                    selectedSns: selectedSnsTypes,
                    selectedHasMoney,
                    selectedHasProduct,
                    filterStatus: true,
                  });
                  replace("Root", {
                    screen: "Home",
                    params: {
                      tab,
                    },
                  });
                }}
                textStyle={styles.buttonText}
                title="適用"
                width="100%"
              />
            </View>
          </View>
        </Wrapper>
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
  },
  title: {
    ...Fonts.xlb100,
  },
  item: {
    flexDirection: "row",
  },
  text: {
    textAlign: "center",
    ...Fonts.xlr160,
  },
  buttons: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  button: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    ...Fonts.xlb100,
  },
});
