import React from "react";
import { StyleProp, StyleSheet, TextStyle, Text as RnText } from "react-native";
import openURL from "@lib/util/openUrl";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import _ from "lodash";
import { Text } from "@components/atoms/Themed";

type Props = {
  /** テキストスタイル */
  style?: StyleProp<TextStyle>;
  /** 子要素となる文字列。入れ子式の<Text>などのJSXはサポートしていません */
  children?: string | string[] | null;
};

const url = /(https?:\/\/[a-zA-Z0-9-_.!'()*;/?:@&=+$,%#]+)/;
const phone = /^([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6})/;
const combined = new RegExp(`${url.source}|${phone.source}`, "g");

function ParsedText({
  style,
  content,
  key,
}: {
  style?: StyleProp<TextStyle>;
  content: string;
  key?: string;
}) {
  if (combined.test(content)) {
    const contents: string[] = content.split(combined);
    return (
      <>
        {contents.map((row: string, index: number) => {
          if (_.isNil(row)) {
            return null;
          }
          const textKey = `parsed-text-${index}`;
          if (combined.test(row)) {
            return (
              <RnText
                key={textKey}
                allowFontScaling={false}
                onPress={() => {
                  if (phone.test(row)) {
                    openURL(`tel://${row}`);
                  } else if (url.test(row)) {
                    openURL(row);
                  }
                }}
                style={[styles.text, style, { color: Colors.blue }]}
              >
                {row}
              </RnText>
            );
          }
          return (
            <Text
              key={textKey}
              allowFontScaling={false}
              style={[styles.text, style]}
            >
              {row}
            </Text>
          );
        })}
      </>
    );
  }
  return (
    <Text key={key} allowFontScaling={false} style={[styles.text, style]}>
      {content}
    </Text>
  );
}

export default function LinkableText({ style, children }: Props) {
  if (children === null || children === undefined) {
    return null;
  }
  return Array.isArray(children)
    ? children.map((content, index) =>
        ParsedText({ content, style, key: `text-${index}` })
      )
    : ParsedText({ content: children, style });
}

const styles = StyleSheet.create({
  text: {
    ...Fonts.mr100,
  },
  linkText: {
    color: Colors.blue,
  },
});
