import * as Sharing from "expo-sharing";
import * as Clipboard from "expo-clipboard";
import _ from "lodash";

export async function shareAsync(
  url: string,
  options?: Sharing.SharingOptions
) {
  const message: string[] = [];
  if (options !== undefined && options.dialogTitle !== undefined) {
    message.push(_.truncate(options.dialogTitle, { length: 60 }));
  }
  message.push(url);
  await Clipboard.setStringAsync(message.join("\n"));
}

export default {};
