import React, { useMemo } from "react";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { ApplyPrice } from "@constants/App";
import numberFormat from "@lib/util/numberFormat";

type Props = {
  customPrice: number;
  price: number;
  status: string;
  prType: string;
};

export default function DetailUnitPriceLabel({
  customPrice,
  price,
  status,
  prType,
}: Props): JSX.Element {
  const statusText = useMemo(() => {
    if (["applied", "office_approved", "admin_approved"].includes(status)) {
      return "応募中";
    }
    if (status === "agency_approved") {
      return "採択されました";
    }
    return "";
  }, [status]);

  const detailUnitPrice = useMemo(
    () => (status === "agency_approved" ? price : customPrice),
    [customPrice, price, status]
  );
  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <Text style={styles.status}>{statusText}</Text>
      </View>
      {["rewarded"].includes(prType) && (
        <View style={styles.price}>
          <Text style={styles.label}>{ApplyPrice}</Text>
          <Spacer width={8} />
          <Text style={styles.unitPrice}>
            {numberFormat(detailUnitPrice, "¥")}
          </Text>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.overlayDark,
    height: 52,
    paddingHorizontal: 16,
    justifyContent: "space-between",
  },
  box: {
    flex: 1,
    backgroundColor: "transparent",
  },
  price: {
    backgroundColor: "#0000000",
    flexDirection: "row",
    alignItems: "center",
  },
  status: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  label: {
    color: Colors.white,
    ...Fonts.sr100,
  },
  unitPrice: {
    color: Colors.white,
    ...Fonts.ensb100,
  },
});
