/**
 * @generated SignedSource<<56a9f2c85dc29b53ff3c0ed94b85c928>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignStatus = "admin_rejected" | "cancelled" | "candidate_defined" | "pre_recruiting" | "recruited" | "recruiting" | "wait_admin_approve" | "wait_candidate_define";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignActionCampaign$data = {
  readonly id: string;
  readonly isFavoriteCheck: boolean;
  readonly parentMediaType: ParentMediaType;
  readonly status: CampaignStatus | null;
  readonly " $fragmentSpreads": FragmentRefs<"requirementForApplyCampaign">;
  readonly " $fragmentType": "CampaignActionCampaign";
};
export type CampaignActionCampaign$key = {
  readonly " $data"?: CampaignActionCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignActionCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignActionCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentMediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavoriteCheck",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "requirementForApplyCampaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "9ebddb843a493c7567dd6a69a4f7e819";

export default node;
