import React from "react";
import { Text, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import SelectableText from "@components/atoms/SelectableText";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import { StyleSheet } from "react-native";

type Props = {
  title: string;
  content?: string | null;
  children?: React.ReactNode;
};
export default function TitleContent({
  title,
  content,
  children,
}: Props): JSX.Element {
  return (
    <View>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <Spacer height={8} />
      <View>
        <SelectableText style={styles.content}>{content ?? "-"}</SelectableText>
        {children}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  title: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  content: {
    ...Fonts.xlr160,
  },
});
