/**
 * @generated SignedSource<<7022e0725758cde42b95159f04197391>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BankEdit$data = {
  readonly bankAccount: {
    readonly accountNumberAttribute: string | null;
    readonly bankName: string | null;
    readonly branchName: string | null;
    readonly codeBranch: string | null;
    readonly id: string;
    readonly ownerNameKana: string | null;
    readonly typeAccount: string | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "BankEdit";
};
export type BankEdit$key = {
  readonly " $data"?: BankEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"BankEdit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BankEdit",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumberAttribute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "branchName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "codeBranch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerNameKana",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "typeAccount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};
})();

(node as any).hash = "00d2aa1c5808a1ef388e404bd2596504";

export default node;
