export const releaseChannel = process.env.ENV;

export async function reloadAsync(): Promise<void> {
  window.location.reload();
}

export async function checkForUpdateAsync(): Promise<{ isAvailable: boolean }> {
  return {
    isAvailable: false,
  };
}

// eslint-disable-next-line no-empty-function
export async function fetchUpdateAsync(): Promise<void> {}

export enum UpdateEventType {
  UPDATE_AVAILABLE = "updateAvailable",
  NO_UPDATE_AVAILABLE = "noUpdateAvailable",
  ERROR = "error",
}

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
export function addListener(listener: (event: unknown) => void) {}
