import React from "react";
import { navigate } from "@navigation/navigate";
import Loading from "@components/atoms/Loading";
import useAccount from "@hooks/useAccount";
import { RecruitingScreenQuery } from "@generated/RecruitingScreenQuery.graphql";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import Applyings from "@components/templates/Applyings";
import { PagingCandidate } from "@constants/App";
import _ from "lodash";

const recruitingQuery = graphql`
  query RecruitingScreenQuery($first: Int!, $influencerId: ID!) {
    ...ApplyingsPagination
      @arguments(first: $first, influencerId: $influencerId)
  }
`;

function ScreenContent({
  queryReference,
  move,
}: {
  queryReference: PreloadedQuery<RecruitingScreenQuery>;
  move: (id: string) => void;
}) {
  const data = usePreloadedQuery<RecruitingScreenQuery>(
    recruitingQuery,
    queryReference
  );
  return <Applyings candidates={data} move={move} />;
}

export default function RecruitingScreen() {
  const { state: influencerId } = useAccount();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<RecruitingScreenQuery>(recruitingQuery);
  React.useEffect(() => {
    loadQuery(
      {
        first: PagingCandidate,
        influencerId,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, influencerId]);

  return (
    <React.Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent
          move={(id) => navigate("CampaignDetail", { id })}
          queryReference={queryReference}
        />
      )}
    </React.Suspense>
  );
}
