/**
 * @generated SignedSource<<9094ba9b6528636639b5dc54125e0290>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type pushTokenMutation$variables = {
  token: string;
};
export type pushTokenMutation$data = {
  readonly savePushToken: {
    readonly token: string;
  };
};
export type pushTokenMutation = {
  response: pushTokenMutation$data;
  variables: pushTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pushTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SavePushTokenMutationPayload",
        "kind": "LinkedField",
        "name": "savePushToken",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pushTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SavePushTokenMutationPayload",
        "kind": "LinkedField",
        "name": "savePushToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65f525f8c063b04422c4edafe2d0d2c9",
    "id": null,
    "metadata": {},
    "name": "pushTokenMutation",
    "operationKind": "mutation",
    "text": "mutation pushTokenMutation(\n  $token: String!\n) {\n  savePushToken(input: {token: $token}) {\n    token\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "75abfc31973b6b6b57bdde94a2153b01";

export default node;
