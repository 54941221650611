import React, { useState, useCallback } from "react";
import Ripple from "@components/atoms/Ripple";
import Loading from "@components/atoms/Loading";
import {
  makeConfig,
  makeRedirectUri,
  sendLog,
  makeAuthorizationEndpoint,
  SupportType,
  ActionType,
} from "@lib/util/oauth";
import * as Linking from "expo-linking";
import Colors from "@constants/Colors";
import message from "@hooks/useMessage";
import { clickAction } from "@lib/util/analytics";
import InstagramGuide from "@components/molecules/InstagramGuide";
import { SnsTextGeneration } from "@lib/util/snsTextGeneration";

type Props = {
  type: SupportType;
  action: ActionType;
  callbackUrl?: string;
  children: React.ReactNode;
  useGuide?: boolean;
};

export default function OAuthLink({
  type,
  action,
  callbackUrl,
  children,
  useGuide = false,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [guide, setGuide] = useState<boolean>(false);
  const { set: setMessage } = message();

  const openAuthorizationEndpoint = useCallback(async (): Promise<void> => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      sendLog(type);
      const config = makeConfig(type);
      const url = await makeAuthorizationEndpoint({
        ...config,
        platform: type,
        callbackUrl,
        action,
        redirectUri: makeRedirectUri(),
      });
      await Linking.openURL(url);
    } catch (e) {
      setMessage({
        title: `${SnsTextGeneration(type)}連携できませんでした`,
        mode: "toast",
      });
    } finally {
      setLoading(false);
    }
  }, [type, loading, action, callbackUrl, setMessage]);

  const onPress = useCallback((): void => {
    if (useGuide) {
      switch (type) {
        case "Instagram":
          clickAction("OpenInstagramGuide");
          setGuide(true);
          return;
        default:
      }
    }
    openAuthorizationEndpoint();
  }, [useGuide, type, openAuthorizationEndpoint]);

  return (
    <>
      <Ripple disabled={loading} onPress={onPress} rippleColor={Colors.white}>
        {children}
        {loading && <Loading mask />}
      </Ripple>
      {guide && (
        <InstagramGuide
          onClose={() => setGuide(false)}
          onConfirmed={() => {
            openAuthorizationEndpoint();
            setGuide(false);
          }}
        />
      )}
    </>
  );
}
