import React, { Suspense, useEffect } from "react";
import Loading from "@components/atoms/Loading";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { MyPageScreenQuery } from "@generated/MyPageScreenQuery.graphql";
import MyPage from "@components/templates/MyPage";
import _ from "lodash";

const myPageQuery = graphql`
  query MyPageScreenQuery {
    influencer {
      ...MyPageInfo
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<MyPageScreenQuery>;
}) {
  const { influencer } = usePreloadedQuery<MyPageScreenQuery>(
    myPageQuery,
    queryReference
  );
  return <MyPage influencerInfo={influencer} />;
}

export default function MyPageScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MyPageScreenQuery>(myPageQuery);
  useEffect(() => {
    loadQuery({}, { fetchPolicy: "network-only" });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
