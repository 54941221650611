import React from "react";
import { StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import AccountDeletion from "@components/templates/AccountDeletion";
import Spacer from "@components/atoms/Spacer";

export default function AccountDeletionScreen(): JSX.Element {
  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="アカウント削除" />
      <Spacer height={8} />
      <AccountDeletion />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
