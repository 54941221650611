import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { graphql, useMutation, useFragment } from "react-relay/hooks";
import { PhoneEditForm$key } from "@generated/PhoneEditForm.graphql";
import { PhoneEditFormUpdateMutation } from "@generated/PhoneEditFormUpdateMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import { KeyboardId } from "@constants/App";
import GradientButton from "@components/atoms/GradientButton";
import Divider from "@components/atoms/Divider";
import { resolveError } from "@lib/util/error";

const phoneQuery = graphql`
  fragment PhoneEditForm on Influencer {
    phoneNumber
  }
`;

const updateQuery = graphql`
  mutation PhoneEditFormUpdateMutation($input: PhoneChangeMutationInput!) {
    phoneChange(input: $input) {
      __typename
      ... on Influencer {
        newPhone
      }
      ... on PhoneChangeError {
        errorType
        message
      }
    }
  }
`;

type PhoneInput = {
  newPhone: string;
};

export default function PhoneEditForm({
  onComplete,
  phoneFragment,
}: {
  onComplete: () => void;
  phoneFragment: PhoneEditForm$key;
}) {
  const { phoneNumber } = useFragment(phoneQuery, phoneFragment);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<PhoneEditFormUpdateMutation>(updateQuery);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<PhoneInput>({
    defaultValues: {
      newPhone: phoneNumber ?? "",
    },
    mode: "onChange",
    resolver: yupResolver(
      object().shape({
        newPhone: string()
          .trim()
          .required("入力してください")
          .phoneNumber({
            message: "存在しない電話番号です",
          })
          .replace(/-/g, "")
          .toNull(),
      })
    ),
  });
  const newPhoneInput = watch("newPhone");

  const save = useCallback(async () => {
    await handleSubmit(async ({ newPhone }: PhoneInput) => {
      try {
        setLoading(true);
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                newPhone,
              },
            },
            onCompleted({ phoneChange }) {
              if (phoneChange.__typename === "PhoneChangeError") {
                reject(phoneChange.message);
              } else {
                resolve();
              }
            },
          });
        });
        onComplete();
      } catch (e: unknown) {
        setError(resolveError(e).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [commit, handleSubmit, onComplete]);

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <Text style={styles.message}>
                万が一の際に連絡が取れるように電話番号を入力してください。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.content}>
              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="電話番号"
                name="newPhone"
                placeholder="連絡がとれる電話番号を登録してください"
                type="phone"
              />
              {!isValid && errors.newPhone !== undefined && (
                <ErrorText error={errors.newPhone.message} />
              )}
              <Text style={styles.guide}>ハイフン(-)なし</Text>
            </Card>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid || phoneNumber === newPhoneInput}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
