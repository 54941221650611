/**
 * @generated SignedSource<<aa073f6879eb97725e5130af4d70f017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SnsAssociationScreenQuery$variables = {};
export type SnsAssociationScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"SnsAssociation">;
  };
};
export type SnsAssociationScreenQuery = {
  response: SnsAssociationScreenQuery$data;
  variables: SnsAssociationScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SnsAssociationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SnsAssociation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SnsAssociationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerInfo",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tiktokUrl",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ytChannel",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "091ba04e4de000532d3ea2ae77abd449",
    "id": null,
    "metadata": {},
    "name": "SnsAssociationScreenQuery",
    "operationKind": "query",
    "text": "query SnsAssociationScreenQuery {\n  influencer {\n    ...SnsAssociation\n    id\n  }\n}\n\nfragment SnsAssociation on Influencer {\n  id\n  ...SnsAssociationTiktok\n  ...SnsAssociationYoutube\n}\n\nfragment SnsAssociationTiktok on Influencer {\n  profile {\n    tiktokUrl\n    id\n  }\n}\n\nfragment SnsAssociationYoutube on Influencer {\n  profile {\n    ytChannel\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc1ebed9986976435e6b8f6ac10eaaa9";

export default node;
