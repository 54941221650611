import React, { useState } from "react";
import * as WebBrowser from "expo-web-browser";
import { StyleSheet, Platform } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import { AppleSigninMutation } from "@generated/AppleSigninMutation.graphql";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Loading from "@components/atoms/Loading";
import * as AppleAuthentication from "expo-apple-authentication";
import { resolveError } from "@lib/util/error";

const query = graphql`
  mutation AppleSigninMutation($input: AppleSigninMutationInput!) {
    appleSignin(input: $input) {
      __typename
      ... on AppleSigninToken {
        token
        refreshToken
      }
      ... on Influencer {
        id
        email
        profile {
          commonName
        }
      }
      ... on AppleSigninError {
        message
      }
    }
  }
`;

WebBrowser.maybeCompleteAuthSession();

export type SignUp = {
  id: string;
  commonName: string | null;
  email: string | null;
};

export type Login = {
  token: string;
  refreshToken: string;
};

function isLogin(data: SignUp | Login): data is Login {
  return "token" in data;
}

type Props = {
  isSignup: boolean;
  onLogin: (token: Login) => void;
  onSignup: (user: SignUp) => void;
  onError: (error: string) => void;
};

export default function AppleSignin({
  isSignup,
  onLogin,
  onSignup,
  onError,
}: Props) {
  const [commit] = useMutation<AppleSigninMutation>(query);
  const [loading, setLoading] = useState<boolean>(false);

  async function login(
    appleId: string,
    name: string,
    email: string | null
  ): Promise<void> {
    try {
      if (loading) {
        return;
      }
      setLoading(true);

      const result = await new Promise<SignUp | Login>((resolve, reject) => {
        commit({
          variables: {
            input: {
              appleId,
              name,
              email,
            },
          },
          onCompleted({ appleSignin }) {
            if (appleSignin.__typename === "Influencer") {
              resolve({
                id: appleSignin.id,
                commonName: appleSignin.profile.commonName,
                email: appleSignin.email,
              });
            } else if (appleSignin.__typename === "AppleSigninToken") {
              resolve({
                token: appleSignin.token,
                refreshToken: appleSignin.refreshToken,
              });
            } else {
              reject(
                appleSignin.__typename === "AppleSigninError"
                  ? appleSignin.message
                  : "Apple連携に失敗しました"
              );
            }
          },
        });
      });

      if (isLogin(result)) {
        onLogin(result);
      } else {
        onSignup(result);
      }
    } catch (e: unknown) {
      onError(resolveError(e).message);
    } finally {
      setLoading(false);
    }
  }
  if (Platform.OS !== "ios") {
    return null;
  }

  return (
    <>
      <AppleAuthentication.AppleAuthenticationButton
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
        buttonType={
          isSignup
            ? AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP
            : AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN
        }
        cornerRadius={4}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });
            const { email, fullName, user } = credential;
            const names: string[] = [];
            if (fullName !== null) {
              if (fullName.familyName !== null) {
                names.push(fullName.familyName);
              }
              if (fullName.givenName !== null) {
                names.push(fullName.givenName);
              }
            }

            login(user, names.join(" "), email);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (e.code !== "ERR_CANCELED") {
              onError("Apple連携に失敗しました");
            }
          }
        }}
        style={styles.apple}
      />
      {loading && <Loading mask />}
    </>
  );
}

const styles = StyleSheet.create({
  icon: {
    position: "absolute",
    top: 6,
    left: 6,
    width: 34,
    height: 34,
  },
  button: {
    width: 280,
    height: 48,
    borderRadius: 4,
    paddingRight: 32,
    paddingLeft: 5,
    paddingVertical: 0,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: Colors.lineGreen,
    borderColor: Colors.lineGreen,
  },
  buttonText: {
    ...Fonts.ensb100,
    lineHeight: 48,
    color: Colors.white,
    paddingLeft: 63,
  },
  apple: {
    width: 280,
    height: 48,
  },
});
