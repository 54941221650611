/**
 * @generated SignedSource<<ed81630e3609645caef452448463f190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
export type PostDraftScreenQuery$variables = {
  id: string;
};
export type PostDraftScreenQuery$data = {
  readonly post: {
    readonly campaign: {
      readonly mediaType: MediaType;
    };
    readonly id: string;
    readonly status: PostStatus;
    readonly " $fragmentSpreads": FragmentRefs<"PostDraft">;
  };
};
export type PostDraftScreenQuery = {
  response: PostDraftScreenQuery$data;
  variables: PostDraftScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostDraftScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PostDraft"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostDraftScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "post",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purpose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avoidedSentence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SocialNetwork",
                "kind": "LinkedField",
                "name": "socialNetwork",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ytHashTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productSupply",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hashTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mentionTagContent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "file",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "script",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "910a19fe89dfa2361a95a9142f50dbe5",
    "id": null,
    "metadata": {},
    "name": "PostDraftScreenQuery",
    "operationKind": "query",
    "text": "query PostDraftScreenQuery(\n  $id: ID!\n) {\n  post(id: $id) {\n    id\n    status\n    campaign {\n      mediaType\n      id\n    }\n    ...PostDraft\n  }\n}\n\nfragment PostDraft on Post {\n  id\n  status\n  campaign {\n    mediaType\n    id\n  }\n  ...PostDraftYoutube\n  ...PostDraftTwitter\n  ...PostDraftTiktok\n  ...PostDraftOther\n  ...PostDraftInstagram\n}\n\nfragment PostDraftInstagram on Post {\n  id\n  content\n  script\n  attachments {\n    id\n    file\n    duration\n    contentType\n  }\n  campaign {\n    mediaType\n    purpose\n    avoidedSentence\n    socialNetwork {\n      hashTag\n      productUrl\n      mentionTagContent\n      id\n    }\n    id\n  }\n}\n\nfragment PostDraftOther on Post {\n  id\n  url\n  content\n  campaign {\n    purpose\n    avoidedSentence\n    socialNetwork {\n      hashTag\n      id\n    }\n    id\n  }\n  attachments {\n    id\n    file\n    duration\n    contentType\n  }\n}\n\nfragment PostDraftTiktok on Post {\n  id\n  title\n  content\n  campaign {\n    purpose\n    avoidedSentence\n    id\n  }\n  attachments {\n    id\n    file\n    duration\n    contentType\n  }\n}\n\nfragment PostDraftTwitter on Post {\n  id\n  content\n  attachments {\n    id\n    file\n    duration\n    contentType\n  }\n  campaign {\n    mediaType\n    purpose\n    avoidedSentence\n    socialNetwork {\n      hashTag\n      productUrl\n      mentionTagContent\n      id\n    }\n    id\n  }\n}\n\nfragment PostDraftYoutube on Post {\n  id\n  url\n  campaign {\n    purpose\n    avoidedSentence\n    socialNetwork {\n      ytHashTag\n      productUrl\n      productSupply\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "373ce71b7bd8e0aa0e646369e23975a8";

export default node;
