import React from "react";
import { StyleSheet } from "react-native";
import {
  SafeAreaProvider,
  SafeAreaView as RNSafeAreaView,
} from "react-native-safe-area-context";
import { useTheme } from "@react-navigation/native";

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
};

export default function SafeAreaView({ children, backgroundColor }: Props) {
  const { colors } = useTheme();

  return (
    <SafeAreaProvider
      style={{ backgroundColor: backgroundColor ?? colors.card }}
    >
      <RNSafeAreaView style={[styles.container]}>{children}</RNSafeAreaView>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
