import { commitMutation, Environment, graphql } from "react-relay/hooks";
import {
  refreshTokenMutation,
  refreshTokenMutation$data,
} from "@generated/refreshTokenMutation.graphql";
import { SecureStoreManager } from "@lib/util/secureStoreManager";

const refreshTokenQuery = graphql`
  mutation refreshTokenMutation($input: RefreshTokenMutationInput!) {
    refreshToken(input: $input) {
      token
      refreshToken
    }
  }
`;

export default async function refreshToken(
  relayEnvironment: Environment
): Promise<refreshTokenMutation$data> {
  const { accessToken: oldToken, refreshToken: oldRefreshToken } =
    await SecureStoreManager.getAccessTokenSet();
  if (oldToken === null || oldRefreshToken === null) {
    throw new Error("ログインが切れました");
  }
  const input = {
    refreshToken: oldRefreshToken,
  };

  const response = await new Promise<refreshTokenMutation$data>(
    (resolve, reject) => {
      commitMutation<refreshTokenMutation>(relayEnvironment, {
        mutation: refreshTokenQuery,
        variables: {
          input,
        },
        onCompleted: (res) => resolve(res),
        onError: async (err) => {
          await SecureStoreManager.removeAndReload();
          reject(err);
        },
      });
    }
  );
  await SecureStoreManager.registerAccessTokenSet(
    response.refreshToken.token,
    response.refreshToken.refreshToken
  );

  return response;
}
