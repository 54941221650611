/**
 * @generated SignedSource<<c3fac66b5790ef12bfa4fd788710a767>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileEditScreenQuery$variables = {};
export type ProfileEditScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfileEdit">;
  };
};
export type ProfileEditScreenQuery = {
  response: ProfileEditScreenQuery$data;
  variables: ProfileEditScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileEdit"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileEditScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerInfo",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commonName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthday",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6567fa91dc51e05e46d1d4ca722a4b2a",
    "id": null,
    "metadata": {},
    "name": "ProfileEditScreenQuery",
    "operationKind": "query",
    "text": "query ProfileEditScreenQuery {\n  influencer {\n    ...ProfileEdit\n    id\n  }\n}\n\nfragment ProfileEdit on Influencer {\n  avatarUrl\n  profile {\n    commonName\n    gender\n    birthday\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebefc35f16b0504e037d956a422d7d96";

export default node;
