import {
  appleClientId,
  facebookAppId,
  lineClientId,
  oauthUniversalLinkPath,
  oauthUniversalLinkRoot,
  twitterClientId,
} from "@constants/Env";
import { clickAction } from "@lib/util/analytics";
import AsyncStorage from "@react-native-async-storage/async-storage";
import days from "dayjs";
import * as Crypto from "expo-crypto";
import _ from "lodash";
import qs from "query-string";
import { Platform } from "react-native";
import { OAuthRedirectWeb } from "../../types";

const CHARSET =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const codeChallengeMethod = "S256";
const responseType = "code";
const authorizationName = "AUTHORIZATION_NAME";
type LoginMedia = "Line" | "Apple";
type LinkingMedia = "Instagram" | "Twitter";

type requestConfig = {
  scopes: string[];
  usePKCE: boolean;
  clientId: string;
  prompt?: string;
  extraParams?: {
    [index: string]: string;
  };
  discovery: {
    authorizationEndpoint: string;
  };
};

type requestParams = requestConfig & {
  redirectUri: string;
  callbackUrl?: string;
  platform: SupportType;
  action: ActionType;
};

export type SupportType = LoginMedia | LinkingMedia;
export type ActionType = "Login" | "Linking";

export function isLoginMedia(media: SupportType): media is LoginMedia {
  return media === "Line" || media === "Apple";
}

export type SignUp = {
  id: string;
  commonName: string | null;
  email: string | null;
};

export type Login = {
  token: string;
  refreshToken: string;
};

export function isLogin(data: SignUp | Login): data is Login {
  return "token" in data;
}

export type Authorization = {
  codeVerifier: string;
  csrfState: string;
  callbackUrl?: string;
  platform: SupportType;
  action: ActionType;
  expired: number;
};

export function sendLog(type: SupportType) {
  switch (type) {
    case "Apple":
      clickAction("LinkingApple");
      break;
    case "Line":
      clickAction("LinkingLine");
      break;
    case "Instagram":
      clickAction("LinkingInstagram");
      break;
    case "Twitter":
      clickAction("LinkingTwitter");
      break;
    default:
  }
}

export function makeRedirectUri(): string {
  if (Platform.OS === "web") {
    return `${window.location.origin}/${OAuthRedirectWeb}`;
  }
  return `${oauthUniversalLinkRoot}/${oauthUniversalLinkPath}`;
}

export function makeConfig(type: SupportType): requestConfig {
  switch (type) {
    case "Instagram":
      return {
        scopes: [
          "pages_read_engagement",
          "instagram_basic",
          "pages_show_list",
          "instagram_manage_insights",
          "public_profile",
          "business_management",
        ],
        clientId: facebookAppId,
        usePKCE: true,
        discovery: {
          authorizationEndpoint: "https://www.facebook.com/v14.0/dialog/oauth",
        },
      };
    case "Twitter":
      return {
        scopes: ["tweet.read", "users.read", "offline.access"],
        clientId: twitterClientId,
        usePKCE: true,
        discovery: {
          authorizationEndpoint: "https://twitter.com/i/oauth2/authorize",
        },
      };
    case "Apple":
      return {
        scopes: [],
        clientId: appleClientId,
        // AppleはPKCE対応していない
        usePKCE: false,
        discovery: {
          authorizationEndpoint: "https://appleid.apple.com/auth/authorize",
        },
      };
    case "Line":
    default:
      return {
        scopes: ["profile", "openid", "email"],
        clientId: lineClientId,
        usePKCE: true,
        prompt: "content",
        discovery: {
          authorizationEndpoint: "https://access.line.me/oauth2/v2.1/authorize",
        },
        extraParams: {
          bot_prompt: "aggressive",
        },
      };
  }
}

export async function makeAuthorizationEndpoint(
  request: requestParams
): Promise<string> {
  const params: Record<string, string> = {};
  const csrfState = generateRandom(8);
  const { codeVerifier, codeChallenge } = request.usePKCE
    ? await buildCodeAsync()
    : { codeVerifier: "", codeChallenge: "" };

  params.redirect_uri = request.redirectUri;
  params.state = csrfState;
  params.client_id = request.clientId;
  params.response_type = responseType;
  if (request.scopes.length > 0) {
    params.scope = request.scopes.join(" ");
  }

  if (request.extraParams !== undefined) {
    Object.keys(request.extraParams).forEach((key) => {
      if (request.extraParams !== undefined) {
        params[key] = request.extraParams[key];
      }
    });
  }

  if (request.prompt !== undefined) {
    params.prompt = request.prompt;
  }

  if (request.usePKCE) {
    params.code_challenge_method = codeChallengeMethod;
    params.code_challenge = codeChallenge;
  }
  const query = qs.stringify(params);
  const authorization = {
    codeVerifier,
    csrfState,
    callbackUrl: request.callbackUrl,
    platform: request.platform,
    action: request.action,
    expired: days().add(10, "minutes").unix(),
  };
  await AsyncStorage.setItem(authorizationName, JSON.stringify(authorization));
  return `${request.discovery.authorizationEndpoint}?${query}`;
}

export async function getAuthorizationInfo(): Promise<Authorization | null> {
  try {
    const data = await AsyncStorage.getItem(authorizationName);
    if (data === null) {
      throw new Error("連携に失敗しました");
    }
    const { codeVerifier, csrfState, callbackUrl, platform, action, expired } =
      JSON.parse(data);

    if (
      _.isNil(csrfState) ||
      _.isNil(platform) ||
      _.isNil(action) ||
      _.isNil(expired)
    ) {
      throw new Error("連携に失敗しました");
    }

    return {
      codeVerifier,
      csrfState,
      callbackUrl,
      platform,
      action,
      expired,
    };
  } catch (e) {
    return null;
  } finally {
    await removeAuthorizationInfo();
  }
}

export async function removeAuthorizationInfo() {
  await AsyncStorage.removeItem(authorizationName);
}

function getRandomValues(input: Uint8Array): Uint8Array {
  const output = input;
  let inputData = input;
  if (input.byteLength !== input.length) {
    inputData = new Uint8Array(input.buffer);
  }

  const bytes = Crypto.getRandomBytes(input.length);

  for (let i = 0; i < bytes.length; i += 1) {
    inputData[i] = bytes[i];
  }

  return output;
}

function convertBufferToString(buffer: Uint8Array): string {
  const state: string[] = [];
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join("");
}

function convertToUrlSafeString(b64: string): string {
  return b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export function generateRandom(size: number): string {
  const buffer = new Uint8Array(size);
  getRandomValues(buffer);
  return convertBufferToString(buffer);
}

async function deriveChallengeAsync(code: string): Promise<string> {
  const buffer = await Crypto.digestStringAsync(
    Crypto.CryptoDigestAlgorithm.SHA256,
    code,
    {
      encoding: Crypto.CryptoEncoding.BASE64,
    }
  );
  return convertToUrlSafeString(buffer);
}

export async function buildCodeAsync(
  size: number = 128
): Promise<{ codeChallenge: string; codeVerifier: string }> {
  const codeVerifier = generateRandom(size);
  const codeChallenge = await deriveChallengeAsync(codeVerifier);

  return { codeVerifier, codeChallenge };
}
