/**
 * @generated SignedSource<<8236de7d22b1e8f8bea62c2f0554c9a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
import { FragmentRefs } from "relay-runtime";
export type CampaignReward$data = {
  readonly askingPrice: number | null;
  readonly giftingDetail: string | null;
  readonly prType: CampaignPrType | null;
  readonly " $fragmentType": "CampaignReward";
};
export type CampaignReward$key = {
  readonly " $data"?: CampaignReward$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignReward">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignReward",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "giftingDetail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "askingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "1f82ed52eb40b3b0cd9ad4533a22a971";

export default node;
