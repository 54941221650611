/**
 * @generated SignedSource<<4d76728925dbc51f38b02ee08399f172>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type PostReportOther$data = {
  readonly campaign: {
    readonly socialNetwork: {
      readonly postStartOn: any | null;
    };
  };
  readonly id: string;
  readonly postReport: {
    readonly attachments: ReadonlyArray<{
      readonly contentType: string | null;
      readonly duration: number | null;
      readonly file: string;
      readonly id: string;
    }>;
    readonly supplementaryInfo: string | null;
    readonly url: string | null;
  } | null;
  readonly status: PostStatus;
  readonly " $fragmentType": "PostReportOther";
};
export type PostReportOther$key = {
  readonly " $data"?: PostReportOther$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostReportOther">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostReportOther",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SocialNetwork",
          "kind": "LinkedField",
          "name": "socialNetwork",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postStartOn",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostReport",
      "kind": "LinkedField",
      "name": "postReport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplementaryInfo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contentType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "7bfcadafa13da4f08dd8e05751045b2d";

export default node;
