import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import Avatar from "@components/atoms/Avatar";
import { graphql, useFragment } from "react-relay/hooks";
import { MyPageProfile$key } from "@generated/MyPageProfile.graphql";
import { navigate } from "@navigation/navigate";

const myPageProfile = graphql`
  fragment MyPageProfile on Influencer {
    avatarUrl
    profile {
      commonName
    }
  }
`;

export default function MyPageProfile({
  profileFragment,
}: {
  profileFragment: MyPageProfile$key;
}) {
  const data = useFragment(myPageProfile, profileFragment);
  return (
    <Card style={styles.container}>
      <View style={styles.item}>
        <Avatar size={80} uri={data.avatarUrl} userType="Influencer" />
        <Spacer width={16} />
        <View style={styles.info}>
          <Text style={styles.name}>{data.profile.commonName}</Text>
        </View>
        <TouchableOpacity onPress={() => navigate("ProfileEdit")}>
          <Text style={styles.link}>編集</Text>
        </TouchableOpacity>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
  },
  info: {
    flex: 1,
  },
  name: {
    ...Fonts.xlb160,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  link: {
    ...Fonts.lr100,
    color: Colors.gray,
  },
});
