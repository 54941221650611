import { UploadableMap } from "relay-runtime";
import _ from "lodash";
import { Buffer } from "buffer";

export async function makeUploadablesFromFileList(
  objectPath: string,
  files: File[]
): Promise<UploadableMap> {
  return Object.fromEntries<File>(
    Array.from(files, (file, i) => [`${objectPath}.${i}`, file])
  );
}

export async function makeUploadablesFromFile(
  objectPath: string,
  file: File
): Promise<UploadableMap> {
  return Object.fromEntries<File>([[objectPath, file]]);
}

export function decodeId(encodedId: string): string {
  return <string>_.last(Buffer.from(encodedId, "base64").toString().split(":"));
}
