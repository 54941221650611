import React, { useState } from "react";
import Button from "@components/atoms/Button";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import { StyleSheet } from "react-native";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import onLogout from "@lib/util/onLogout";
import { resolveError } from "@lib/util/error";

export default function AuthLogout() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  async function logout(): Promise<void> {
    setLoading(true);
    try {
      await onLogout();
    } catch (e: unknown) {
      setError(resolveError(e).message);
      setLoading(false);
    }
  }

  return (
    <Wrapper>
      <Card style={styles.container}>
        <View style={styles.textcontainer}>
          <Text style={styles.titleStyle}>ログアウトされます</Text>
          <Text style={styles.sublinestyle}>
            インフルエンサーワークスからログアウトしますか？
          </Text>
        </View>
        <View style={styles.buttonstyle}>
          <Button
            backgroundColor={Colors.orange}
            border={false}
            loading={loading}
            onPress={() => logout()}
            textStyle={{ ...Fonts.xlb100, color: Colors.white }}
            title="ログアウト"
          />
        </View>
        {error !== null && (
          <ErrorDialog message={error} onClose={() => setError(null)} />
        )}
      </Card>
    </Wrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  textcontainer: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  titleStyle: {
    textAlign: "center",
    ...Fonts.xxlb140,
    color: Colors.orange,
  },
  sublinestyle: {
    paddingHorizontal: 7.5,
    paddingTop: 16,
    ...Fonts.xlr100,
  },
  buttonstyle: {
    paddingVertical: 24,
    alignItems: "center",
    justifyContent: "center",
  },
});
