import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId,
} from "firebase/analytics";
import { firebaseConfig } from "@constants/Env";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const instance = getAnalytics(app);

export default function analytics() {
  return {
    setUserId(id: string) {
      return new Promise<void>((resolve) => {
        setUserId(instance, id);
        resolve();
      });
    },
    setUserProperties(params: { [key: string]: unknown }) {
      return new Promise<void>((resolve) => {
        setUserProperties(instance, params);
        resolve();
      });
    },
    logEvent(eventName: string, params?: { [key: string]: unknown }) {
      return new Promise<void>((resolve) => {
        logEvent(instance, eventName, params);
        resolve();
      });
    },
  };
}
