import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Wrapper, Text, Card } from "@components/atoms/Themed";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Toast from "@components/atoms/Toast";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { SnsAssociationTiktok$key } from "@generated/SnsAssociationTiktok.graphql";
import { SnsAssociationTiktokMutation } from "@generated/SnsAssociationTiktokMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import { KeyboardId, TiktokUrlPattern } from "@constants/App";
import GradientButton from "@components/atoms/GradientButton";
import Divider from "@components/atoms/Divider";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import openURL from "@lib/util/openUrl";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";

const tiktokQuery = graphql`
  fragment SnsAssociationTiktok on Influencer {
    profile {
      tiktokUrl
    }
  }
`;

const updateQuery = graphql`
  mutation SnsAssociationTiktokMutation(
    $input: UpdateInfluencerInfoMutationInput!
  ) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          tiktokUrl
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type InputData = {
  tiktokUrl: string | null;
};

export default function SnsAssociationTiktok({
  tiktokFragment,
}: {
  tiktokFragment: SnsAssociationTiktok$key;
}) {
  const { profile } = useFragment(tiktokQuery, tiktokFragment);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<SnsAssociationTiktokMutation>(updateQuery);
  const [changed, setChanged] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      tiktokUrl: profile.tiktokUrl,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        tiktokUrl: string()
          .default(null)
          .trim()
          .url("存在しないURLです")
          .matches(TiktokUrlPattern, "アカウントURLを入力してください")
          .nullable()
          .toNull(),
      })
    ),
  });

  const save = useCallback(async () => {
    await handleSubmit(async ({ tiktokUrl }: InputData) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: { tiktokUrl },
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setError(updateInfluencerInfo.message);
            } else {
              setMessage("保存しました");
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Card style={styles.content}>
              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="アカウントURL"
                name="tiktokUrl"
                onChange={(value: string) => {
                  setChanged(value !== profile.tiktokUrl);
                }}
                placeholder="https://www.tiktok.com/@"
                type="url"
              />
              {!isValid && errors.tiktokUrl !== undefined && (
                <ErrorText error={errors.tiktokUrl.message} />
              )}
              <Spacer height={16} />
              <TouchableOpacity
                onPress={() => openURL("https://tiktok.com/")}
                style={styles.open}
              >
                <Text>TikTokを開く</Text>
                <Spacer width={4} />
                <Icon color={Colors.gray} name="outer" size={16} />
              </TouchableOpacity>
            </Card>
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid || !changed}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>

      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
