import React, { useMemo } from "react";
import { View, Text, Card } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity, ImageBackground } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import Icon from "@components/atoms/Icon";
import Tag from "@components/atoms/Tag";
import FavoriteButton from "@components/molecules/FavoriteButton";
import DeadLine from "@components/atoms/DeadLine";
import Colors from "@constants/Colors";
import { LinearGradient } from "expo-linear-gradient";
import Fonts from "@constants/Fonts";
import Spacer from "@components/atoms/Spacer";
import campaignBanner from "@lib/util/campaignBanner";
import { useFragment, graphql } from "react-relay/hooks";
import { CampaignBigCampaign$key } from "@generated/CampaignBigCampaign.graphql";
import dayjs from "dayjs";

const campaignBig = graphql`
  fragment CampaignBigCampaign on Campaign {
    id
    parentMediaType
    title
    gifting
    prType
    isFavoriteCheck
    banners {
      file
    }
    mCategory {
      name
    }
    socialNetwork {
      recruitmentEndOn
    }
  }
`;

export default function CampaignBig({
  campaignFragment,
  campaignDetailOnPress,
}: {
  campaignFragment: CampaignBigCampaign$key;
  campaignDetailOnPress: () => void;
}) {
  const data = useFragment(campaignBig, campaignFragment);
  const isExpired = useMemo<boolean>(() => {
    const restDay = dayjs(
      dayjs(data.socialNetwork.recruitmentEndOn).startOf("day")
    ).diff(dayjs().startOf("day"), "day");
    return restDay < 0;
  }, [data]);

  return (
    <TouchableOpacity
      onPress={campaignDetailOnPress}
      style={{ opacity: isExpired ? 0.6 : 1.0 }}
    >
      <Card style={styles.container}>
        <ImageBackground
          source={campaignBanner(data.banners)}
          style={styles.image}
        >
          <View style={styles.head}>
            <View style={styles.headWrapper}>
              <SNSIcons name={data.parentMediaType} style={styles.platform} />
              <FavoriteButton
                buttonSize={40}
                campaignId={data.id}
                iconSize={28}
                isFavoriteCheck={data.isFavoriteCheck}
              />
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.info}>
              <View style={styles.item}>
                <Tag color={Colors.white}>
                  <Text style={styles.categoryText}>{data.mCategory.name}</Text>
                </Tag>
                <View style={styles.tail}>
                  {data.gifting && data.prType === "rewarded" ? (
                    <>
                      <Icon color={Colors.white} name="present" size={18} />
                      <Spacer width={14} />
                      <Icon color={Colors.white} name="money" size={18} />
                    </>
                  ) : data.gifting ? (
                    <Icon color={Colors.white} name="present" size={18} />
                  ) : data.prType === "rewarded" ? (
                    <Icon color={Colors.white} name="money" size={18} />
                  ) : null}
                  <Spacer width={8} />
                  <DeadLine day={data.socialNetwork.recruitmentEndOn} />
                </View>
              </View>
              <Spacer height={8} />
              <Text numberOfLines={2} style={styles.explanation}>
                {data.title}
              </Text>
            </View>
          </View>
          <LinearGradient
            colors={Colors.grBlack90}
            end={{
              x: 0,
              y: 1,
            }}
            start={{
              x: 0,
              y: 0.5,
            }}
            style={styles.imageGr}
          />
        </ImageBackground>
      </Card>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 260,
    width: "100%",
  },
  image: {
    height: 260,
    width: "100%",
    justifyContent: "flex-end",
  },
  head: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  headWrapper: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  body: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    bottom: 0,
    left: 0,
  },
  imageGr: {
    height: "100%",
    width: "100%",
  },
  platform: {
    height: 40,
    width: 40,
  },
  info: {
    padding: 16,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  categoryText: {
    ...Fonts.sb100,
    color: Colors.white,
  },
  explanation: {
    ...Fonts.xlr160,
    color: Colors.white,
  },
  tail: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
  },
});
