/**
 * @generated SignedSource<<877e0ccfe4ec86f27c9d158096630e48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignInputCampaign$data = {
  readonly id: string;
  readonly parentMediaType: ParentMediaType;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputSnsCampaign" | "requirementForApplyCampaign">;
  readonly " $fragmentType": "CampaignInputCampaign";
};
export type CampaignInputCampaign$key = {
  readonly " $data"?: CampaignInputCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentMediaType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignInputSnsCampaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "requirementForApplyCampaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "d25485413941bdfe1c36f22c09d8892c";

export default node;
