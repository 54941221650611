import React, { useState, useCallback } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { object, string, date } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { View, Text, Card, Wrapper } from "@components/atoms/Themed";
import GradientButton from "@components/atoms/GradientButton";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  DateTimePicker,
  Selectbox,
  ErrorText,
} from "@components/molecules/TextInput";
import _ from "lodash";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { CampaignInputProfileInfluencer$key } from "@generated/CampaignInputProfileInfluencer.graphql";
import { CampaignInputProfileMutation } from "@generated/CampaignInputProfileMutation.graphql";
import { KeyboardId, ProfileList } from "@constants/App";
import dayjs from "dayjs";

const profileQuery = graphql`
  fragment CampaignInputProfileInfluencer on Influencer {
    profile {
      commonName
      gender
      birthday
    }
  }
`;

const profileMutation = graphql`
  mutation CampaignInputProfileMutation(
    $input: UpdateInfluencerInfoMutationInput!
  ) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          commonName
          gender
          birthday
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type Props = {
  profileFragment: CampaignInputProfileInfluencer$key;
  hasNext: boolean;
  onClose: () => void;
};

type ProfileInput = {
  commonName: string;
  gender: string;
  birthday: Date;
};

const genderList = Object.keys(ProfileList.gender).map((column) => ({
  label: ProfileList.gender[column],
  value: column,
}));

export default function CampaignInputProfile({
  profileFragment,
  hasNext,
  onClose,
}: Props) {
  const { profile } = useFragment<CampaignInputProfileInfluencer$key>(
    profileQuery,
    profileFragment
  );

  const [commit] = useMutation<CampaignInputProfileMutation>(profileMutation);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ProfileInput>({
    defaultValues: {
      commonName: profile.commonName ?? "",
      gender: profile.gender ?? "",
      birthday: _.isEmpty(profile.birthday)
        ? dayjs().subtract(25, "years").toDate()
        : dayjs(profile.birthday).toDate(),
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        commonName: string().trim().required("入力してください"),
        gender: string()
          .required("選択してください")
          .oneOf(Object.keys(ProfileList.gender), "選択してください"),
        birthday: date().required("選択してください"),
      })
    ),
  });

  const save = useCallback(async () => {
    await handleSubmit(async (profileInput: ProfileInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              ...profileInput,
              birthday: dayjs(profileInput.birthday).format("YYYY-MM-DD"),
            },
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setError(updateInfluencerInfo.message);
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commit, handleSubmit]);

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="プロフィール登録" />

      <Divider />

      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />

            <Card style={styles.item}>
              <Text style={styles.message}>
                プロフィール情報を入力してください。
              </Text>
            </Card>

            <Spacer height={16} />

            <Card style={styles.item}>
              <TextField
                control={control}
                inputAccessoryViewID={KeyboardId}
                label="ニックネーム"
                maxLength={30}
                name="commonName"
              />
              {!isValid && errors.commonName !== undefined && (
                <ErrorText error={errors.commonName.message} />
              )}
              <Text style={styles.guide}>30文字まで</Text>

              <Spacer height={24} />

              <Selectbox
                control={control}
                emptyValue=""
                initialValue={profile.gender}
                items={genderList}
                label="性別"
                name="gender"
              />
              {!isValid && errors.gender !== undefined && (
                <ErrorText error={errors.gender.message} />
              )}

              <Spacer height={24} />

              <DateTimePicker
                control={control}
                initialValue={profile.birthday}
                label="生年月日"
                name="birthday"
              />
              {!isValid && errors.birthday !== undefined && (
                <ErrorText error={errors.birthday.message} />
              )}
              <Spacer height={24} />
            </Card>
            <Spacer height={80} />
          </Wrapper>
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />
      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title={hasNext ? "次へ" : "情報を登録し、応募する"}
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
  label: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelColumn: {
    marginRight: 4,
  },
  icon: {
    width: 24,
    height: 24,
  },
  sns: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    flex: 1,
  },
  text: {
    ...Fonts.xlr100,
    color: Colors.black,
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  buttonText: {
    ...Fonts.lb130,
    color: Colors.white,
  },
  link: {
    width: 64,
    height: 32,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: Colors.green,
  },
  linked: {
    color: Colors.green,
    ...Fonts.xlb100,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  guide: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  error: {
    color: Colors.orange,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
