import Spacer from "@components/atoms/Spacer";
import GradientButton from "@components/atoms/GradientButton";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import days from "dayjs";
import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { object, string } from "@lib/util/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, Card, View, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import { KeyboardId, TiktokUrlPattern } from "@constants/App";
import Divider from "@components/atoms/Divider";
import Icon from "@components/atoms/Icon";
import openURL from "@lib/util/openUrl";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay/hooks";
import { PostReportTiktok$key } from "@generated/PostReportTiktok.graphql";
import { PostReportTiktokMutation } from "@generated/PostReportTiktokMutation.graphql";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import ConfirmDialog from "@components/organisms/PostReport/PostReportConfirmDialog";
import { resolveError } from "@lib/util/error";

const chatReport = graphql`
  fragment PostReportTiktok on Post {
    id
    status
    campaign {
      socialNetwork {
        postStartOn
      }
    }
    postReport {
      url
    }
  }
`;

const postReportMutation = graphql`
  mutation PostReportTiktokMutation(
    $input: CreatePostReportsMutationInput!
    $connections: [ID!]!
  ) {
    createPostReports(input: $input) {
      __typename
      ... on CreatePostReportsSuccess {
        post {
          id
          type
          status
          url
        }
        commentEdges @prependEdge(connections: $connections) {
          cursor
          node {
            id
            content
            action
            stamp
            extraInfo
            extraType
            createdAt
            commentable {
              userType
              avatar {
                file
              }
            }
            ogps {
              id
              title
              image
              url
              description
            }
            attachments {
              id
              file
              duration
              contentType
            }
            post {
              id
            }
          }
        }
      }
      ... on CreatePostReportsError {
        message
      }
    }
  }
`;

type InputData = {
  url: string;
};

export default function PostReportTiktok({
  postFragment,
  onClose,
  onConfirm,
  onChange,
  onLoading,
}: {
  postFragment: PostReportTiktok$key;
  onClose: () => void;
  onConfirm: () => void;
  onChange: (isChange: boolean) => void;
  onLoading: (isLoading: boolean) => void;
}) {
  const data = useFragment(chatReport, postFragment);
  const [error, setError] = useState<string | null>(null);
  const [postable, setPostable] = useState<boolean>(false);
  const [commit] = useMutation<PostReportTiktokMutation>(postReportMutation);
  const [confirm, setConfirm] = useState<boolean>(false);
  const connectionID = ConnectionHandler.getConnectionID(
    data.id,
    "Chat_comments"
  );
  const canPublish = useMemo<boolean>(
    () =>
      days().format("YYYYMMDD") >=
      days(data.campaign.socialNetwork.postStartOn).format("YYYYMMDD"),
    [data]
  );
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InputData>({
    defaultValues: {
      url: data.postReport?.url ?? "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        url: string()
          .trim()
          .url("存在しないURLです")
          .matches(TiktokUrlPattern, "TikTok URLを入力してください")
          .required("入力してください"),
      })
    ),
  });

  const onChangeInput = useCallback(
    (value: string) => {
      if (value !== data.postReport?.url && value !== "") {
        onChange(true);
      } else {
        onChange(false);
      }
    },
    [data, onChange]
  );

  useEffect(() => {
    setPostable(isValid && canPublish);
  }, [isValid, canPublish]);

  const save = useCallback(async () => {
    await handleSubmit(async ({ url }: InputData) => {
      try {
        setConfirm(false);
        onLoading(true);
        await new Promise<void>((resolve, reject) => {
          commit({
            variables: {
              input: {
                postId: data.id,
                postReports: [
                  {
                    url,
                    priority: 0,
                    mediaProductType: "media_product_none",
                  },
                ],
              },
              connections: [connectionID],
            },
            onCompleted({ createPostReports }) {
              if (createPostReports.__typename === "CreatePostReportsSuccess") {
                resolve();
              } else {
                reject(
                  createPostReports.__typename === "CreatePostReportsError"
                    ? createPostReports.message
                    : "保存できませんでした"
                );
              }
            },
          });
        });
        onClose();
      } catch (e: unknown) {
        setError(resolveError(e).message);
        onLoading(false);
      }
    })();
  }, [commit, connectionID, data, onClose, onLoading, handleSubmit]);

  return (
    <View style={styles.content}>
      <KeyboardAvoidingView>
        <ScrollView>
          <Wrapper>
            <Spacer height={8} />
            <Card style={styles.card}>
              <Text style={styles.text}>
                {days(data.campaign.socialNetwork.postStartOn).format("M月D日")}
                になったらキャンペーン内容をもとに、
                <Text style={[styles.text, styles.alert]}>
                  下書き作成した内容をTikTokで公開
                </Text>
                してください。
              </Text>
              <Spacer height={8} />
              <TouchableOpacity onPress={onConfirm} style={styles.draft}>
                <Text style={styles.draftText}>下書き内容を確認</Text>
              </TouchableOpacity>
            </Card>

            <Spacer height={16} />

            <Card style={styles.card}>
              <Text style={styles.text}>
                公開したTikTokのURLを入力してください。
              </Text>

              <Spacer height={16} />

              <TextField
                control={control}
                disabled={data.status === "wait_agency_confirm_report"}
                inputAccessoryViewID={KeyboardId}
                name="url"
                onChange={onChangeInput}
                placeholder="TikTok URL"
                placeholderTextColor={Colors.gray}
                type="url"
              />
              {!isValid && errors.url !== undefined && (
                <ErrorText error={errors.url.message} />
              )}
              <Spacer height={13.5} />
              <TouchableOpacity
                onPress={() => openURL("https://www.tiktok.com/")}
                style={styles.open}
              >
                <Text style={styles.guide}>TikTokを開く</Text>
                <Spacer width={4} />
                <Icon color={Colors.gray} name="outer" size={16} />
              </TouchableOpacity>
              <Spacer height={13.5} />
            </Card>
          </Wrapper>

          <Spacer height={40} />
        </ScrollView>
      </KeyboardAvoidingView>

      {data.status !== "wait_agency_confirm_report" && (
        <>
          <Divider />
          <Card style={styles.action}>
            {!canPublish && (
              <>
                <Text style={styles.alert}>
                  {days(data.campaign.socialNetwork.postStartOn).format(
                    "M月D日"
                  )}
                  になるまでお待ち下さい
                </Text>
                <Spacer height={8} />
              </>
            )}
            <GradientButton
              disabled={!postable}
              onPress={() => {
                setConfirm(true);
              }}
              textStyle={styles.actionText}
              title="報告する"
            />
          </Card>
        </>
      )}
      {confirm && (
        <ConfirmDialog cancel={() => setConfirm(false)} save={save} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
      {error !== null && (
        <ErrorDialog
          message={error}
          onClose={() => setError(null)}
          title="報告内容を修正してください"
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  scroll: {
    flexGrow: 1,
  },
  card: {
    padding: 16,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.black,
  },
  alert: {
    ...Fonts.lb130,
    color: Colors.orange,
  },
  guide: {
    color: Colors.gray,
  },
  open: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  action: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    color: Colors.white,
    ...Fonts.xlb100,
  },
  draft: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  draftText: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
});
