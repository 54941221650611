/**
 * @generated SignedSource<<eeabef484446971c650472fe34cc987e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignDetailRelatedList$data = {
  readonly relatedCampaigns: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CampaignRelated">;
  }>;
  readonly " $fragmentType": "CampaignDetailRelatedList";
};
export type CampaignDetailRelatedList$key = {
  readonly " $data"?: CampaignDetailRelatedList$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignDetailRelatedList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDetailRelatedList",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "relatedCampaigns",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignRelated"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b33b6d81a2373068515ef647b89871ec";

export default node;
