import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ServiceStartYear, DraftLimitDay } from "@constants/App";

dayjs.extend(duration);

export function isExpired(date: string): boolean {
  return dayjs(date).endOf("day") < dayjs();
}

export function fetchYears(): string[] {
  const years: string[] = [];
  const currentYear = dayjs().year();
  for (let year = currentYear; year >= ServiceStartYear; year -= 1) {
    years.unshift(`${year}`);
  }
  return years;
}

export function fetchMonths(month: number): string[] {
  const dates: string[] = [];
  let current = dayjs().subtract(month, "months").startOf("month");
  const end = dayjs().startOf("month");
  while (current <= end) {
    dates.push(current.format("YYYY-MM-01"));
    current = current.add(1, "months");
  }
  return dates;
}

export function deadlineOfDraft(
  draftedAt: string | null,
  postEndOn: string
): dayjs.Dayjs | null {
  if (draftedAt === null) {
    return null;
  }
  const targetDraftedAt = dayjs(draftedAt, "YYYY-MM-DD").add(
    DraftLimitDay,
    "days"
  );
  const targetPostEndOn = dayjs(postEndOn);
  return targetDraftedAt < targetPostEndOn ? targetDraftedAt : targetPostEndOn;
}

export function secondToDuration(second: number) {
  return dayjs(second * 1000).format("mm:ss");
}
