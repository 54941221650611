/**
 * @generated SignedSource<<3bf86e0088f576198eb08b1addc53518>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostDraftOther$data = {
  readonly attachments: ReadonlyArray<{
    readonly contentType: string | null;
    readonly duration: number | null;
    readonly file: string;
    readonly id: string;
  }>;
  readonly campaign: {
    readonly avoidedSentence: string | null;
    readonly purpose: string | null;
    readonly socialNetwork: {
      readonly hashTag: string | null;
    };
  };
  readonly content: string | null;
  readonly id: string;
  readonly url: string | null;
  readonly " $fragmentType": "PostDraftOther";
};
export type PostDraftOther$key = {
  readonly " $data"?: PostDraftOther$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostDraftOther">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostDraftOther",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purpose",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avoidedSentence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SocialNetwork",
          "kind": "LinkedField",
          "name": "socialNetwork",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hashTag",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "file",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "6c09174ab5ac889e479a7bb8d6f59195";

export default node;
