/**
 * @generated SignedSource<<03765e5f98dfe868bd70d437412d873d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SnsLoginScreenQuery$variables = {};
export type SnsLoginScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"SnsLogin">;
  };
};
export type SnsLoginScreenQuery = {
  response: SnsLoginScreenQuery$data;
  variables: SnsLoginScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SnsLoginScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SnsLogin"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SnsLoginScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lineId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2114b4c247493c910dcca96a36d70d1",
    "id": null,
    "metadata": {},
    "name": "SnsLoginScreenQuery",
    "operationKind": "query",
    "text": "query SnsLoginScreenQuery {\n  influencer {\n    ...SnsLogin\n    id\n  }\n}\n\nfragment SnsLogin on Influencer {\n  lineId\n  appleId\n  email\n  hasPassword\n}\n"
  }
};

(node as any).hash = "3b4c5b2b8b854bcb6326a1fe05491b14";

export default node;
