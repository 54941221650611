/**
 * @generated SignedSource<<2e084314d13b3bd3517a78e91df1f1f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostDraftYoutube$data = {
  readonly campaign: {
    readonly avoidedSentence: string | null;
    readonly purpose: string | null;
    readonly socialNetwork: {
      readonly productSupply: string | null;
      readonly productUrl: string | null;
      readonly ytHashTag: string | null;
    };
  };
  readonly id: string;
  readonly url: string | null;
  readonly " $fragmentType": "PostDraftYoutube";
};
export type PostDraftYoutube$key = {
  readonly " $data"?: PostDraftYoutube$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostDraftYoutube">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostDraftYoutube",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purpose",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avoidedSentence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SocialNetwork",
          "kind": "LinkedField",
          "name": "socialNetwork",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ytHashTag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productSupply",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "f428f4f63a3fbdccce568f8166f489d2";

export default node;
