import React, { useCallback } from "react";
import { Platform, StyleSheet } from "react-native";
import { View } from "@components/atoms/Themed";
import GradientButton from "@components/atoms/GradientButton";
import reloadApp from "@lib/util/realodApp";

export default function FallbackBackHome() {
  const reload = useCallback(async () => {
    if (Platform.OS === "web") {
      window.location.href = "/";
    } else {
      await reloadApp();
    }
  }, []);
  return (
    <View style={styles.buttonArea}>
      <GradientButton onPress={reload} title="HOMEへ戻る" />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonArea: {
    alignItems: "center",
    justifyContent: "center",
  },
});
