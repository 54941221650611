import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { OtherCategory, KeyboardId, CategoryTypes } from "@constants/App";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import NavigationBar from "@components/molecules/NavigationBar";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import Spacer from "@components/atoms/Spacer";
import Icon from "@components/atoms/Icon";
import { Style } from "@components/molecules/TextInput";
import _ from "lodash";
import Divider from "@components/atoms/Divider";
import GradientButton from "@components/atoms/GradientButton";
import { CampaignInputCategoryCategories$key } from "@generated/CampaignInputCategoryCategories.graphql";
import {
  CampaignInputCategoryMCategories$key,
  CampaignInputCategoryMCategories$data,
} from "@generated/CampaignInputCategoryMCategories.graphql";
import { CampaignInputCategoryMutation } from "@generated/CampaignInputCategoryMutation.graphql";
import { useTheme } from "@react-navigation/native";

const categoryQuery = graphql`
  fragment CampaignInputCategoryCategories on Influencer {
    influencerCategories {
      mCategoryId
      otherContent
      mCategoryKeyName
    }
  }
`;

const categoryList = graphql`
  fragment CampaignInputCategoryMCategories on Query {
    mCategories {
      id
      name
      keyName
    }
  }
`;

const cgategoryMutation = graphql`
  mutation CampaignInputCategoryMutation(
    $input: UpdateInfluencerCategoriesMutationInput!
  ) {
    updateInfluencerCategories(input: $input) {
      __typename
      ... on Influencer {
        influencerCategories {
          mCategoryId
          categoryName
          otherContent
        }
      }
      ... on UpdateInfluencerCategoriesError {
        message
      }
    }
  }
`;

type Props = {
  categoryFragment: CampaignInputCategoryCategories$key;
  listFragment: CampaignInputCategoryMCategories$key;
  hasNext: boolean;
  onClose: () => void;
};

export default function CampaignInputCategory({
  categoryFragment,
  listFragment,
  hasNext,
  onClose,
}: Props) {
  let flatListRef: FlatList | null = null;
  const { influencerCategories } = useFragment(categoryQuery, categoryFragment);
  const { mCategories } = useFragment(categoryList, listFragment);
  const { colors } = useTheme();
  const initialCategories = useMemo(
    () => influencerCategories.map((row) => row.mCategoryId).sort(),
    [influencerCategories]
  );
  const otherCategory = useMemo(
    () =>
      _.head(
        influencerCategories.filter(
          (row) => row.mCategoryKeyName === OtherCategory
        )
      ),
    [influencerCategories]
  );

  const [selectedCategories, setCategories] =
    useState<string[]>(initialCategories);
  const [otherContent, setOtherContent] = useState<string | null>(
    otherCategory?.otherContent ?? null
  );
  const otherCategoryId = useMemo(
    () =>
      _.head(mCategories.filter((row) => row.keyName === OtherCategory))?.id,
    [mCategories]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] =
    useMutation<CampaignInputCategoryMutation>(cgategoryMutation);
  const [postable, setPostable] = useState<boolean>(false);

  useEffect(() => {
    const other = _.head(
      selectedCategories.filter((id) => id === otherCategoryId)
    );
    if (other !== undefined && otherContent !== null) {
      setPostable(otherCategory?.otherContent !== otherContent);
    }
  }, [selectedCategories, otherContent, otherCategoryId, otherCategory]);

  useEffect(() => {
    setPostable(!_.isEqual(selectedCategories, initialCategories));
  }, [selectedCategories, initialCategories]);

  const save = useCallback(async () => {
    if (
      otherCategoryId !== undefined &&
      selectedCategories.indexOf(otherCategoryId) !== -1 &&
      otherContent === null
    ) {
      setError("その他カテゴリーを入力してください");
      return;
    }
    setLoading(true);
    await new Promise<void>((resolve) => {
      commit({
        variables: {
          input: {
            categories: selectedCategories,
            otherContent,
          },
        },
        onCompleted({ updateInfluencerCategories }) {
          if (
            updateInfluencerCategories.__typename ===
            "UpdateInfluencerCategoriesError"
          ) {
            setError(updateInfluencerCategories.message);
          }
          resolve();
        },
      });
    });
    setLoading(false);
  }, [commit, selectedCategories, otherCategoryId, otherContent]);

  function toggle(
    data: CampaignInputCategoryMCategories$data["mCategories"][number]
  ): void {
    const filteredCategories = selectedCategories.filter(
      (categoryId: string) => data.id !== categoryId
    );
    if (selectedCategories.length === filteredCategories.length) {
      setCategories((categories) => _.concat(categories, data.id).sort());
      if (data.keyName === OtherCategory) {
        setTimeout(() => flatListRef?.scrollToEnd({ animated: false }), 100);
      }
    } else {
      setCategories(filteredCategories);
    }
  }

  return (
    <View style={styles.container}>
      <NavigationBar onClose={onClose} title="カテゴリ登録" />
      <KeyboardAvoidingView>
        <FlatList
          ref={(
            ref: FlatList<{
              readonly id: string;
              readonly keyName: CategoryTypes;
              readonly name: string;
            }> | null
          ) => {
            if (ref !== null && flatListRef === null) {
              flatListRef = ref;
            }
          }}
          data={mCategories}
          keyExtractor={(item) => item.keyName}
          ListFooterComponent={<Spacer height={24} />}
          ListHeaderComponent={
            <Wrapper>
              <Spacer height={8} />
              <Card style={styles.message}>
                <Text style={styles.guide}>
                  得意なカテゴリーを選択してください。
                </Text>
              </Card>
              <Spacer height={8} />
            </Wrapper>
          }
          renderItem={({ item }) => (
            <Wrapper>
              <TouchableOpacity onPress={() => toggle(item)}>
                <Card bordered style={styles.content}>
                  <View style={styles.label}>
                    <Text style={styles.name}>{item.name}</Text>
                  </View>

                  {selectedCategories.indexOf(item.id) !== -1 ? (
                    <View bordered style={[styles.icon, styles.active]}>
                      <Icon color={Colors.white} name="check" size={20} />
                    </View>
                  ) : (
                    <View bordered style={styles.icon}>
                      <Icon color={Colors.black} name="pluss" size={20} />
                    </View>
                  )}
                </Card>
              </TouchableOpacity>
              {item.keyName === OtherCategory &&
                selectedCategories.indexOf(item.id) !== -1 && (
                  <>
                    <Divider />
                    <Card style={styles.textField}>
                      <TextInput
                        inputAccessoryViewID={KeyboardId}
                        maxLength={10}
                        onChangeText={(value: string) => setOtherContent(value)}
                        placeholder="その他カテゴリーを入力"
                        style={[
                          Style.form,
                          {
                            color: colors.text,
                          },
                        ]}
                        value={otherContent ?? ""}
                      />
                    </Card>
                  </>
                )}
            </Wrapper>
          )}
        />
      </KeyboardAvoidingView>

      <Divider />

      <Card style={styles.footer}>
        <GradientButton
          disabled={!postable}
          loading={loading}
          onPress={save}
          title={hasNext ? "次へ" : "情報を登録し、応募する"}
        />
      </Card>
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderTopWidth: 1,
    borderStyle: "solid",
    alignItems: "center",
  },
  label: {
    flex: 1,
  },
  name: {
    ...Fonts.xlr100,
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: "solid",
    alignItems: "center",
    justifyContent: "center",
  },
  active: {
    backgroundColor: Colors.green,
  },
  textField: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  message: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  guide: {
    color: Colors.orange,
    ...Fonts.lb130,
  },
});
