/**
 * @generated SignedSource<<24087ec8190912bc17b622f39772a176>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPrType = "product_offer" | "rewarded";
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type ApplyReward$data = {
  readonly advertiserName: string | null;
  readonly askingPrice: number | null;
  readonly mediaType: MediaType;
  readonly prType: CampaignPrType | null;
  readonly " $fragmentType": "ApplyReward";
};
export type ApplyReward$key = {
  readonly " $data"?: ApplyReward$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyReward">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyReward",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "askingPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prType",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "030f3552e1056eccce58c3cd1cc67535";

export default node;
