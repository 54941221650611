/**
 * @generated SignedSource<<ef94440eafd91ebb3a4961a96cb25c43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportPostList$data = {
  readonly completedPosts: ReadonlyArray<{
    readonly candidate: {
      readonly " $fragmentSpreads": FragmentRefs<"CampaignSmallCandidate">;
    };
    readonly id: string;
  }>;
  readonly " $fragmentType": "ReportPostList";
};
export type ReportPostList$key = {
  readonly " $data"?: ReportPostList$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportPostList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "date"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportPostList",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "date",
          "variableName": "date"
        },
        {
          "kind": "Variable",
          "name": "searchType",
          "variableName": "searchType"
        }
      ],
      "concreteType": "Post",
      "kind": "LinkedField",
      "name": "completedPosts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Candidate",
          "kind": "LinkedField",
          "name": "candidate",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CampaignSmallCandidate"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "900ad3a0d8a9d484fadc0bbb6098bf10";

export default node;
