/**
 * @generated SignedSource<<5a735e0a3e7051467d244d5ed85f6a50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignQualification$data = {
  readonly socialNetwork: {
    readonly castingCondition: string | null;
  };
  readonly " $fragmentType": "CampaignQualification";
};
export type CampaignQualification$key = {
  readonly " $data"?: CampaignQualification$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignQualification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignQualification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialNetwork",
      "kind": "LinkedField",
      "name": "socialNetwork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "castingCondition",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "f2c6994e4a481acc3130884b0c14e763";

export default node;
