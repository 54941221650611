import React from "react";
import OAuthRedirect from "@components/templates/OAuthRedirect";
import { RootStackScreenProps } from "../types";

export default function OAuthWebScreen({
  route,
}: RootStackScreenProps<"OAuthWeb">) {
  const { code, state } = route.params;

  return <OAuthRedirect code={code} state={state} />;
}
