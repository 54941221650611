import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import { Platform } from "react-native";
import { getCurrentRoute } from "@navigation/navigate";
import { PushNotificationNotLinkScreens } from "@constants/App";
import _ from "lodash";
import { projectId } from "@constants/Env";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export async function getPushTokenAsync(): Promise<string | null> {
  if (!Device.isDevice || Platform.OS === "web") {
    return null;
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== "granted") {
    return null;
  }
  const token = (await Notifications.getExpoPushTokenAsync({ projectId })).data;
  return token;
}

export function canMoveLink(): boolean {
  const currentPath = getCurrentRoute();
  if (!_.isNil(currentPath)) {
    return PushNotificationNotLinkScreens.indexOf(currentPath.name) === -1;
  }
  return true;
}
