import AsyncStorage from "@react-native-async-storage/async-storage";

export async function deleteItemAsync(key: string): Promise<void> {
  await AsyncStorage.removeItem(key);
}

export async function getItemAsync(key: string): Promise<string | null> {
  const data = await AsyncStorage.getItem(key);
  return data;
}

export async function setItemAsync(key: string, value: string): Promise<void> {
  await AsyncStorage.setItem(key, value);
}
