import React, { Suspense } from "react";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import Loading from "@components/atoms/Loading";
import { CampaignsFinishedScreenQuery } from "@generated/CampaignsFinishedScreenQuery.graphql";
import { CampaignTabs, PagingCampaign } from "@constants/App";
import Campaigns from "@components/templates/Campaigns";
import useFilterStatus from "@hooks/useFilterStatus";
import _ from "lodash";

const query = graphql`
  query CampaignsFinishedScreenQuery(
    $first: Int!
    $tab: CampaignTabs!
    $selectedSns: [ParentMediaType!]
    $selectedCategories: [CategoryTypes!]
    $selectedHasMoney: Boolean
    $selectedHasProduct: Boolean
  ) {
    ...CampaignsCampaigns
      @arguments(
        first: $first
        tab: $tab
        selectedSns: $selectedSns
        selectedCategories: $selectedCategories
        selectedHasMoney: $selectedHasMoney
        selectedHasProduct: $selectedHasProduct
      )
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CampaignsFinishedScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<CampaignsFinishedScreenQuery>(
    query,
    queryReference
  );

  return <Campaigns campaigns={data} tab={CampaignTabs.Finished} />;
}

export default function CampaignsFinishedScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CampaignsFinishedScreenQuery>(query);
  const { state: filter } = useFilterStatus();
  React.useEffect(() => {
    loadQuery({
      first: PagingCampaign,
      tab: CampaignTabs.Finished,
      selectedSns: filter?.selectedSns,
      selectedCategories: filter?.selectedCategories,
      selectedHasMoney: filter?.selectedHasMoney,
      selectedHasProduct: filter?.selectedHasProduct,
    });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, filter]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
