/**
 * @generated SignedSource<<0e3d47491ef768b0c87ffa8ffe24ba91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SnsAssociationTiktok$data = {
  readonly profile: {
    readonly tiktokUrl: string | null;
  };
  readonly " $fragmentType": "SnsAssociationTiktok";
};
export type SnsAssociationTiktok$key = {
  readonly " $data"?: SnsAssociationTiktok$data;
  readonly " $fragmentSpreads": FragmentRefs<"SnsAssociationTiktok">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SnsAssociationTiktok",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "ad650217bf200e973d168487a3982ade";

export default node;
