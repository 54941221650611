import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import Divider from "@components/atoms/Divider";
import Spacer from "@components/atoms/Spacer";
import { Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import openURL from "@lib/util/openUrl";
import { graphql, useFragment } from "react-relay/hooks";
import { CampaignCast$key } from "@generated/CampaignCast.graphql";
import TitleContent from "./TitleContent";

const campaignCast = graphql`
  fragment CampaignCast on Campaign {
    socialNetwork {
      castPlanName
      deliveryOn
      castContent
      productUrl
      postalCode
      prefectureName
      cityName
      townName
      buildingName
      castIntro
      relatedCasts {
        id
        relatedInfoUrl
        name
      }
    }
  }
`;

export default function CampaignCast({
  campaignFragment,
}: {
  campaignFragment: CampaignCast$key;
}) {
  const { socialNetwork: cast } = useFragment(campaignCast, campaignFragment);
  return (
    <CampaignSection title="お仕事の内容">
      <TitleContent content={cast.castPlanName} title="企画(番組)名" />
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent content={cast.deliveryOn} title="配信日" />
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent content={cast.castContent} title="撮影内容" />

      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent content={cast.productUrl} title="関連URL" />

      {cast.relatedCasts !== null && cast.relatedCasts.length > 0 && (
        <>
          <Spacer height={16} />
          <Divider />
          <Spacer height={16} />

          <TitleContent title="ほか出演者情報">
            {cast.relatedCasts.map((row) => (
              <TouchableOpacity
                key={row.id}
                onPress={() => openURL(row.relatedInfoUrl)}
              >
                <Text style={styles.castName}>{row.name}</Text>
              </TouchableOpacity>
            ))}
          </TitleContent>
        </>
      )}

      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent
        content={`〒${cast.postalCode} ${cast.prefectureName}${cast.cityName}${cast.townName}${cast.buildingName}`}
        title="撮影場所"
      />

      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent
        content={cast.castIntro}
        title="出演時の役柄やポジション等"
      />
    </CampaignSection>
  );
}

const styles = StyleSheet.create({
  castName: {
    ...Fonts.xlr160,
    color: Colors.blue,
  },
});
