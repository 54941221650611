/* eslint camelcase: "off" */
import { commitMutation, graphql } from "react-relay/hooks";
import { conversionType } from "@constants/Env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  recordConversionMutation,
  ConversionDevice,
  ConversionPlatform,
  ConversionActionType,
  CreateConversionMutationInput,
} from "@generated/recordConversionMutation.graphql";
import { Platform } from "react-native";
import { signUp, landingForm } from "@lib/util/analytics";
import qs from "query-string";
import { getDeviceTypeAsync, osName, DeviceType } from "expo-device";
import _ from "lodash";
import RelayEnvironment from "../../RelayEnvironment";

const mutation = graphql`
  mutation recordConversionMutation($input: CreateConversionMutationInput!) {
    createConversion(input: $input) {
      success
    }
  }
`;

export class Conversion {
  device: ConversionDevice | null;

  os: string | null;

  platform: ConversionPlatform | null;

  actionType: ConversionActionType;

  utmAdset: string | null;

  utmCampaign: string | null;

  utmContent: string | null;

  utmMedium: string | null;

  utmSource: string | null;

  utmTerm: string | null;

  constructor(params: CreateConversionMutationInput) {
    this.device = !_.isNil(params.device) ? params.device : null;
    this.os = !_.isNil(params.os) ? params.os : null;
    this.platform = !_.isNil(params.platform) ? params.platform : null;
    this.actionType = params.actionType;
    this.utmAdset = !_.isNil(params.utmAdset) ? params.utmAdset : null;
    this.utmCampaign = !_.isNil(params.utmCampaign) ? params.utmCampaign : null;
    this.utmContent = !_.isNil(params.utmContent) ? params.utmContent : null;
    this.utmMedium = !_.isNil(params.utmMedium) ? params.utmMedium : null;
    this.utmSource = !_.isNil(params.utmSource) ? params.utmSource : null;
    this.utmTerm = !_.isNil(params.utmTerm) ? params.utmTerm : null;
  }
}

export async function landing() {
  try {
    if (Platform.OS !== "web") {
      return;
    }
    await landingForm();
    const {
      utm_adset,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
    } = qs.parse(window.location.search);

    // utmパラメーターがあれば広告効果を計測する
    if (
      !_.isNil(utm_adset) ||
      !_.isNil(utm_campaign) ||
      !_.isNil(utm_content) ||
      !_.isNil(utm_medium) ||
      !_.isNil(utm_source) ||
      !_.isNil(utm_term)
    ) {
      const device = await getDeviceTypeAsync();
      const conversion = new Conversion({
        os: osName,
        actionType: "signup",
        device:
          device === DeviceType.PHONE
            ? "mobile"
            : device === DeviceType.DESKTOP
            ? "desktop"
            : device === DeviceType.TABLET
            ? "tablet"
            : null,
        platform: Platform.OS,
        utmAdset: typeof utm_adset === "string" ? utm_adset : null,
        utmCampaign: typeof utm_campaign === "string" ? utm_campaign : null,
        utmContent: typeof utm_content === "string" ? utm_content : null,
        utmMedium: typeof utm_medium === "string" ? utm_medium : null,
        utmSource: typeof utm_source === "string" ? utm_source : null,
        utmTerm: typeof utm_term === "string" ? utm_term : null,
      });
      await AsyncStorage.setItem(conversionType, JSON.stringify(conversion));
    }
  } catch (e: unknown) {
    // エラー発生時は何もしない
  }
}

export default async function recordConversion(): Promise<void> {
  try {
    await signUp();
    const cache = await AsyncStorage.getItem(conversionType);

    if (cache !== null) {
      const input: Conversion = JSON.parse(cache);
      await new Promise<void>((resolve) => {
        commitMutation<recordConversionMutation>(RelayEnvironment, {
          mutation,
          variables: {
            input,
          },
          onCompleted: () => resolve(),
          onError: () => resolve(),
        });
      });
    }
  } catch (e: unknown) {
    // エラー発生時は何もしない
  } finally {
    await AsyncStorage.removeItem(conversionType);
  }
}
