import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { View } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";
import AddressEdit from "@components/templates/AddressEdit";
import { AddressEditScreenQuery } from "@generated/AddressEditScreenQuery.graphql";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import _ from "lodash";

const addressEditScreen = graphql`
  query AddressEditScreenQuery {
    influencer {
      ...AddressEditInfo
    }
    ...AddressEditPrefecture
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<AddressEditScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<AddressEditScreenQuery>(
    addressEditScreen,
    queryReference
  );
  return (
    <AddressEdit addressFragment={data.influencer} prefecturesFragment={data} />
  );
}

export default function AddressEditScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<AddressEditScreenQuery>(addressEditScreen);
  React.useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="登録住所" />
      <Suspense fallback={<Loading size="large" />}>
        {!_.isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
