import React, { useMemo } from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  StyleProp,
  ViewStyle,
} from "react-native";
import Colors from "@constants/Colors";

type Props = {
  /** ローディングアイコンの大きさ */
  size?: "small" | "large";
  /** ローディングアイコンの色 */
  color?: string;
  /** マスクをかけるかのフラグ。trueの場合は親要素に対して幅高さ100%のレイヤーが作成されます。 */
  mask?: boolean;
  /** マスクレイヤーの背景色 */
  maskColor?: string;
};

export default function Loading({
  size = "large",
  color = Colors.green,
  mask = false,
  maskColor = Colors.overlayWhite,
}: Props): JSX.Element {
  const loadingStyle = useMemo<StyleProp<ViewStyle>>(() => {
    if (mask) {
      return [
        styles.mask,
        {
          backgroundColor: maskColor,
        },
      ];
    }
    return styles.loading;
  }, [mask, maskColor]);
  return (
    <View style={loadingStyle}>
      <ActivityIndicator color={color} size={size} />
    </View>
  );
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mask: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
});
