import React from "react";
import { StyleSheet, Image } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { Text, View } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { emptyWork } from "@components/atoms/AssetImage";

export default function TodoTutorial() {
  return (
    <View style={styles.container}>
      <Spacer height={24} />
      <Text style={styles.title}>現在「進行中」キャンペーンはありません。</Text>
      <Spacer height={8} />
      <Text style={styles.text}>
        キャンペーンが決まったら、ここにやらなければいけないタスクのキャンペーンが表示されます。
        {"\n"}
        まずたくさんのキャンペーンに応募してみましょう。
      </Text>
      <Spacer height={25} />
      <Image source={emptyWork} style={styles.emptyIcon} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.gray,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  emptyIcon: {
    width: 96,
    height: 96,
  },
});
