import React from "react";
import { View, StyleSheet, Image } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { Text } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { emptyFavorite } from "@components/atoms/AssetImage";

export default function TodoTutorial() {
  return (
    <View style={styles.container}>
      <Spacer height={24} />
      <Text style={styles.title}>
        現在「気になる」キャンペーンはありません。
      </Text>
      <Spacer height={8} />
      <Text style={styles.text}>
        「気になる」キャンペーンを登録してみましょう。{"\n"}
        登録することで、あとで見ようと思ったキャンペーンも検索なしにすぐキャンペーンにアクセスできます。
      </Text>
      <Spacer height={27} />
      <Image source={emptyFavorite} style={styles.emptyIcon} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.gray,
  },
  text: {
    ...Fonts.lr130,
    color: Colors.gray,
  },
  emptyIcon: {
    width: 86,
    height: 86,
  },
});
