import { StyleSheet } from "react-native";
import { StatusBar } from "expo-status-bar";
import React from "react";
import Navigation from "@navigation";
import { RecoilRoot } from "recoil";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { ExceptionalError } from "@hooks/useExecptionalError";
import ErrorBoundary from "@components/molecules/ErrorBoundary";
import { View } from "@components/atoms/Themed";
import useInitializeApp from "@hooks/useInitializeApp";
import RelayEnvironment from "./RelayEnvironment";

function ScreenContent() {
  const initialized = useInitializeApp();
  if (!initialized) {
    return null;
  }
  return (
    <View style={styles.content}>
      <ErrorBoundary>
        <Navigation />
        <StatusBar />
      </ErrorBoundary>
    </View>
  );
}

export default function App() {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <RecoilRoot>
        <ScreenContent />
        <ExceptionalError />
      </RecoilRoot>
    </RelayEnvironmentProvider>
  );
}

const styles = StyleSheet.create({
  content: {
    height: "100%",
    width: "100%",
  },
});
