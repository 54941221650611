import { TextStyle, KeyboardTypeOptions, StyleProp } from "react-native";
import ReactForm from "react-hook-form";

export type KeybordType =
  | "text"
  | "email"
  | "password"
  | "numberString"
  | "numeric"
  | "phone"
  | "url";

export const KeyboardTypeMap: {
  [index in KeybordType]: {
    web: KeyboardTypeOptions;
    ios: KeyboardTypeOptions;
    android: KeyboardTypeOptions;
    windows: KeyboardTypeOptions;
    macos: KeyboardTypeOptions;
  };
} = {
  text: {
    web: "default",
    ios: "default",
    android: "default",
    windows: "default",
    macos: "default",
  },
  email: {
    web: "default",
    ios: "email-address",
    android: "email-address",
    windows: "default",
    macos: "default",
  },
  password: {
    web: "default",
    ios: "ascii-capable",
    android: "ascii-capable",
    windows: "default",
    macos: "default",
  },
  numberString: {
    web: "default",
    ios: "number-pad",
    android: "numeric",
    windows: "default",
    macos: "default",
  },
  numeric: {
    web: "default",
    ios: "number-pad",
    android: "numeric",
    windows: "default",
    macos: "default",
  },
  phone: {
    web: "default",
    ios: "phone-pad",
    android: "phone-pad",
    windows: "default",
    macos: "default",
  },
  url: {
    web: "default",
    ios: "url",
    android: "ascii-capable",
    windows: "default",
    macos: "default",
  },
};

export interface CommonProps {
  /** フィールド名 */
  name: string;
  /** ReactHookForm */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: ReactForm.Control<any>;
  /** フィールド表示名 */
  label?: string;
  /** 入力必須フラグ */
  required?: boolean;
  /** 入力可否フラグ */
  disabled?: boolean;
  /** プレースホルダー */
  placeholder?: string;
  /** 入力可能な最大文字数 */
  maxLength?: number;
  /** フィールドのスタイル */
  style?: StyleProp<TextStyle>;
  /** プレースホルダーの文字色 */
  placeholderTextColor?: string;
  /** InputAccessoryView紐付け用のID */
  inputAccessoryViewID?: string;
  /** ラベル区分 */
  innerLabel?: boolean;
}
