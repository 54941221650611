/**
 * @generated SignedSource<<b529ad399664c728cc73f022f2d823fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyPageInfo$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MyPageCategory" | "MyPagePersonalInformation" | "MyPageProfile" | "MyPageReport" | "MyPageSetting" | "MyPageSns">;
  readonly " $fragmentType": "MyPageInfo";
};
export type MyPageInfo$key = {
  readonly " $data"?: MyPageInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyPageInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyPageInfo",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPageCategory"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPageProfile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPageSns"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPageReport"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPagePersonalInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyPageSetting"
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "8a7041bb6651580ef9203484302e37d4";

export default node;
