import { atom, useRecoilState } from "recoil";

export type MessageData = {
  title: string;
  subline?: string;
  mode: "toast" | "dialiog" | "error";
};

const messageAtom = atom<MessageData | null>({
  key: "MESSAGE_DATA",
  default: null,
});

export default function useMessage() {
  const [state, setter] = useRecoilState(messageAtom);

  return {
    state,
    set: (data: MessageData | null) => {
      setter(data);
    },
  };
}
