import React, { useState, useCallback, useMemo, useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "@lib/util/yup";
import { useForm } from "react-hook-form";
import { View, Card, Wrapper } from "@components/atoms/Themed";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import InputAccessoryView from "@components/atoms/InputAccessoryView";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import {
  TextField,
  Selectbox,
  ErrorText,
} from "@components/molecules/TextInput";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { InvoiceEdit$key, InvoiceStatus } from "@generated/InvoiceEdit.graphql";
import { ProfileEditProfileMutation } from "@generated/ProfileEditProfileMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import Toast from "@components/atoms/Toast";
import GradientButton from "@components/atoms/GradientButton";
import { ProfileList, KeyboardId } from "@constants/App";
import Divider from "@components/atoms/Divider";
import NavigationBar from "@components/molecules/NavigationBar";
import { goBack } from "@navigation/navigate";

const profileQuery = graphql`
  fragment InvoiceEdit on Influencer {
    profile {
      invoiceStatus
      invoiceNo
    }
  }
`;

const updateProfile = graphql`
  mutation InvoiceEditMutation($input: UpdateInfluencerInfoMutationInput!) {
    updateInfluencerInfo(input: $input) {
      __typename
      ... on Influencer {
        profile {
          invoiceStatus
          invoiceNo
        }
      }
      ... on UpdateInfluencerInfoError {
        message
      }
    }
  }
`;

type ProfileInput = {
  invoiceStatus: InvoiceStatus;
  invoiceNo: string | null;
};

export default function InvoiceEdit({
  profileFragment,
}: {
  profileFragment: InvoiceEdit$key;
}) {
  const { profile } = useFragment(profileQuery, profileFragment);
  const invoiceList = Object.keys(ProfileList.invoice_status).map((column) => ({
    label: ProfileList.invoice_status[column],
    value: column,
  }));
  const invoiceStatus = useMemo(
    () =>
      Object.keys(ProfileList.invoice_status).map(
        (key) => key as InvoiceStatus
      ),
    []
  );
  const [status, setStatus] = useState<InvoiceStatus | null>(
    profile.invoiceStatus
  );
  const {
    control,
    handleSubmit,

    setValue,
    formState: { errors, isValid },
    trigger,
  } = useForm<ProfileInput>({
    defaultValues: {
      invoiceStatus: profile.invoiceStatus,
      invoiceNo: profile.invoiceNo,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        invoiceStatus: string()
          .required("選択してください")
          .oneOf(invoiceStatus, "選択してください"),
        invoiceNo: string()
          .default(null)
          .nullable()
          .when("invoiceStatus", {
            is: (value: string) => value === "applied",
            then: (schema) =>
              schema
                .required("入力してください")
                .test(
                  "checkInvoice",
                  "13桁の半角数字を入力してください",
                  (value) => {
                    if (status === "applied") {
                      return value !== null && /^[0-9]{13,13}$/.test(value);
                    }
                    return true;
                  }
                ),
          }),
      })
    ),
  });
  useEffect(() => {
    if (status !== "applied") {
      setValue("invoiceNo", null);
    }
  }, [status, setValue]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [commitProfile] =
    useMutation<ProfileEditProfileMutation>(updateProfile);

  const save = useCallback(async () => {
    await handleSubmit(async (submitInput: ProfileInput) => {
      setLoading(true);
      await new Promise<void>((resolve) => {
        commitProfile({
          variables: {
            input: {
              ...submitInput,
            },
          },
          onCompleted({ updateInfluencerInfo }) {
            if (
              updateInfluencerInfo.__typename === "UpdateInfluencerInfoError"
            ) {
              setError(updateInfluencerInfo.message);
            } else {
              setMessage("保存しました");
            }
            resolve();
          },
        });
      });
      setLoading(false);
    })();
  }, [commitProfile, handleSubmit]);
  return (
    <View style={styles.container}>
      <NavigationBar onBack={goBack} title="インボイス" />
      <Spacer height={8} />
      <KeyboardAvoidingView>
        <ScrollView>
          <Card style={styles.content}>
            <Wrapper>
              <Selectbox
                control={control}
                emptyValue=""
                initialValue={profile.invoiceStatus}
                items={invoiceList}
                label="インボイス登録は申請していますか？"
                name="invoiceStatus"
                onChange={(value) => {
                  setStatus(value as InvoiceStatus | null);

                  // unappliedに戻したときにエラーを消すための処理
                  if (value === "unapplied") {
                    trigger("invoiceNo");
                  }
                }}
              />
              {!isValid && errors.invoiceStatus !== undefined && (
                <ErrorText error={errors.invoiceStatus.message} />
              )}

              <Spacer height={16} />
              <TextField
                key={`toggle-${status}`}
                control={control}
                disabled={status !== "applied"}
                inputAccessoryViewID={KeyboardId}
                label="登録番号"
                maxLength={13}
                name="invoiceNo"
                placeholder="Tを除く13桁の半角数字を入力してください"
                type="numberString"
              />
              {!isValid && errors.invoiceNo !== undefined && (
                <ErrorText error={errors.invoiceNo.message} />
              )}
            </Wrapper>
          </Card>

          <Spacer height={100} />
        </ScrollView>
      </KeyboardAvoidingView>

      <Divider />

      <Card style={styles.footer}>
        <GradientButton
          disabled={!isValid}
          loading={loading}
          onPress={save}
          title="保存する"
        />
      </Card>
      {message !== null && (
        <Toast message={message} onClose={() => setMessage(null)} />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      <InputAccessoryView nativeID={KeyboardId} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
