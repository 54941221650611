import React, { Suspense } from "react";
import Loading from "@components/atoms/Loading";
import useAccount from "@hooks/useAccount";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { TodoScreenQuery } from "@generated/TodoScreenQuery.graphql";
import Todos from "@components/templates/Todos";
import { PagingPost } from "@constants/App";
import _ from "lodash";

const todoQuery = graphql`
  query TodoScreenQuery($influencerId: ID!, $first: Int!) {
    ...TodosPagination @arguments(influencerId: $influencerId, first: $first)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<TodoScreenQuery>;
}) {
  const data = usePreloadedQuery<TodoScreenQuery>(todoQuery, queryReference);
  return <Todos posts={data} />;
}

export default function TodoScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<TodoScreenQuery>(todoQuery);
  const { state: influencerId } = useAccount();
  React.useEffect(() => {
    loadQuery({
      first: PagingPost,
      influencerId,
    });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, influencerId]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
