import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import PostDraft from "@components/templates/PostDraft";
import Loading from "@components/atoms/Loading";
import SafeAreaView from "@components/molecules/SafeAreaView";
import { View } from "@components/atoms/Themed";
import { useTheme } from "@react-navigation/native";
import {
  graphql,
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from "react-relay/hooks";
import { PostDraftScreenQuery } from "@generated/PostDraftScreenQuery.graphql";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const chatQuery = graphql`
  query PostDraftScreenQuery($id: ID!) {
    post(id: $id) {
      id
      status
      campaign {
        mediaType
      }
      ...PostDraft
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<PostDraftScreenQuery>;
}) {
  const data = usePreloadedQuery<PostDraftScreenQuery>(
    chatQuery,
    queryReference
  );
  return <PostDraft postFragment={data.post} />;
}

export default function PostDraftScreen({
  route,
}: RootStackScreenProps<"PostDraft">) {
  const { colors } = useTheme();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<PostDraftScreenQuery>(chatQuery);
  React.useEffect(() => {
    loadQuery(
      {
        id: route.params.id,
      },
      { fetchPolicy: "network-only" }
    );

    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route]);

  return (
    <SafeAreaView>
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <Suspense fallback={<Loading size="large" />}>
          {!_.isNil(queryReference) && (
            <ScreenContent queryReference={queryReference} />
          )}
        </Suspense>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
