/**
 * @generated SignedSource<<f6bb6c66592345d6e6c597d91a306469>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignInputCategoryCategories$data = {
  readonly influencerCategories: ReadonlyArray<{
    readonly mCategoryId: string;
    readonly mCategoryKeyName: string;
    readonly otherContent: string | null;
  }>;
  readonly " $fragmentType": "CampaignInputCategoryCategories";
};
export type CampaignInputCategoryCategories$key = {
  readonly " $data"?: CampaignInputCategoryCategories$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignInputCategoryCategories">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignInputCategoryCategories",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerCategory",
      "kind": "LinkedField",
      "name": "influencerCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mCategoryId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mCategoryKeyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "654642047d04f2faa79ef163601ca9fb";

export default node;
