/**
 * @generated SignedSource<<8e10324e7ba902e298b118907f7e661a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInfluencerMutationInput = {
  allowNotification?: boolean | null;
  buildingName?: string | null;
  cityName?: string | null;
  clientMutationId?: string | null;
  firstName?: string | null;
  firstNameKana?: string | null;
  lastName?: string | null;
  lastNameKana?: string | null;
  phoneNumber?: string | null;
  postalCode?: string | null;
  prefectureName?: string | null;
  townName?: string | null;
};
export type CampaignInputAddressUpdateMutation$variables = {
  input: UpdateInfluencerMutationInput;
};
export type CampaignInputAddressUpdateMutation$data = {
  readonly updateInfluencer: {
    readonly __typename: "Influencer";
    readonly buildingName: string | null;
    readonly cityName: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly postalCode: string | null;
    readonly prefectureName: string | null;
    readonly townName: string | null;
  } | {
    readonly __typename: "UpdateInfluencerError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CampaignInputAddressUpdateMutation = {
  response: CampaignInputAddressUpdateMutation$data;
  variables: CampaignInputAddressUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefectureName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cityName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "townName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buildingName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UpdateInfluencerError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignInputAddressUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignInputAddressUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateInfluencer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Influencer",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f6f6445bbae1e2e7e02fa17130029a8",
    "id": null,
    "metadata": {},
    "name": "CampaignInputAddressUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CampaignInputAddressUpdateMutation(\n  $input: UpdateInfluencerMutationInput!\n) {\n  updateInfluencer(input: $input) {\n    __typename\n    ... on Influencer {\n      postalCode\n      prefectureName\n      cityName\n      townName\n      buildingName\n      firstName\n      lastName\n      id\n    }\n    ... on UpdateInfluencerError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3710c280e9a30a87d96bc8a408dd1c94";

export default node;
