import React, { Suspense } from "react";
import Loading from "@components/atoms/Loading";
import Chat from "@components/templates/Chat";
import {
  graphql,
  useMutation,
  useQueryLoader,
  usePreloadedQuery,
  PreloadedQuery,
} from "react-relay/hooks";
import { ChatScreenQuery } from "@generated/ChatScreenQuery.graphql";
import { PagingComment } from "@constants/App";
import { ChatScreenCommentReadTimeMutation } from "@generated/ChatScreenCommentReadTimeMutation.graphql";
import useAccount from "@hooks/useAccount";
import _ from "lodash";
import { RootStackScreenProps } from "../types";

const chatQuery = graphql`
  query ChatScreenQuery($id: ID!, $first: Int!) {
    post(id: $id) {
      id
      ...ChatPost
      ...ChatPagination @arguments(first: $first)
    }
    influencer {
      ...ChatBank
    }
  }
`;

const commentReadTimeMutation = graphql`
  mutation ChatScreenCommentReadTimeMutation(
    $input: UpdateCommentReadTimeMutationInput!
  ) {
    updateCommentReadTime(input: $input) {
      __typename
      ... on UpdateCommentReadTimeSuccess {
        commentReadTime {
          id
          unreadCommentCount
        }
        commentReadTimeTotal {
          unreadTotalComments
        }
      }
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ChatScreenQuery>;
}) {
  const data = usePreloadedQuery<ChatScreenQuery>(chatQuery, queryReference);

  return (
    <Chat
      bankFragment={data.influencer}
      commentsFragment={data.post}
      postFragment={data.post}
    />
  );
}

export default function ChatScreen({ route }: RootStackScreenProps<"Chat">) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ChatScreenQuery>(chatQuery);
  const [commitCommentReadTime] =
    useMutation<ChatScreenCommentReadTimeMutation>(commentReadTimeMutation);
  const { state: influencerId } = useAccount();

  React.useEffect(() => {
    loadQuery({
      id: route.params.id,
      first: PagingComment,
    });

    commitCommentReadTime({
      variables: {
        input: {
          influencerId,
          postId: route.params.id,
        },
      },
    });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, route, commitCommentReadTime, influencerId]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
