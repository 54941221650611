import React, { useState, useEffect, useCallback } from "react";
import {
  FlatList,
  StyleSheet,
  NativeSyntheticEvent,
  NativeScrollEvent,
  TouchableOpacity,
  Keyboard,
} from "react-native";
import { View, Wrapper, Card, Text } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import Loading from "@components/atoms/Loading";
import Divider from "@components/atoms/Divider";
import ErrorDialog from "@components/molecules/dialog/ErrorDialog";
import Post from "@components/organisms/Chat/ChatPost";
import ChatCampaign from "@components/organisms/Chat/ChatCampaign";
import ChatStatus from "@components/organisms/Chat/ChatStatus";
import Message from "@components/organisms/Chat/ChatMessage";
import ChatAction from "@components/organisms/Chat/ChatAction";
import ChatRecieveProductReport from "@components/organisms/Chat/ChatReportProductReceived";
import KeyboardAvoidingView from "@components/molecules/KeyboardAvoidingView";
import BrowseImage, { ImageList } from "@components/molecules/BrowseImage";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import {
  graphql,
  usePaginationFragment,
  useRefetchableFragment,
  useFragment,
} from "react-relay/hooks";
import { PagingComment } from "@constants/App";
import {
  ChatPagination$key,
  ChatPagination$data,
} from "@generated/ChatPagination.graphql";
import { ChatBank$key } from "@generated/ChatBank.graphql";
import { ChatPost$key } from "@generated/ChatPost.graphql";
import _ from "lodash";
import { navigate, goBack } from "@navigation/navigate";
import Spacer from "@components/atoms/Spacer";
import CustomIcon from "@components/atoms/Icon";
import { useTheme } from "@react-navigation/native";

const chatPost = graphql`
  fragment ChatPost on Post @refetchable(queryName: "ChatPostRefetchQuery") {
    id
    campaign {
      id
      title
    }
    ...ChatCampaign
    ...ChatActionPost
    ...ChatReportProductReceivedPost
    ...ChatPostData
    ...ChatStatus
  }
`;

const chatBankQuery = graphql`
  fragment ChatBank on Influencer {
    ...ChatActionBank
  }
`;

const chatPagination = graphql`
  fragment ChatPagination on Post
  @refetchable(queryName: "ChatCommentsPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    comments(first: $first, after: $after) @connection(key: "Chat_comments") {
      edges {
        node {
          id
          ...ChatMessage
        }
      }
    }
  }
`;

type Props = {
  commentsFragment: ChatPagination$key;
  postFragment: ChatPost$key;
  bankFragment: ChatBank$key;
};

export default function Chat({
  commentsFragment,
  postFragment,
  bankFragment,
}: Props) {
  let flatList: FlatList | null = null;
  const [post, postRefetch] = useRefetchableFragment(chatPost, postFragment);
  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    chatPagination,
    commentsFragment
  );
  const { campaign } = post;
  const { id, title } = campaign;
  const [expand, setExpand] = useState<ImageList | null>(null);
  const [error, setError] = useState<string | null>(null);
  const bank = useFragment(chatBankQuery, bankFragment);
  const { edges } = data.comments;
  const lastEdge = _.head(edges);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [paging, setPaging] = useState<boolean>(false);
  const [productRecieve, setProductRecieve] = useState<boolean>(false);
  const [lastId, setLastId] = useState<string | null>(null);
  const [campaignVisibility, setCampaignVisibility] = useState<boolean>(true);
  const BOTTOM_AREA_HEIGHT = 122;
  const { colors } = useTheme();
  const onPosted = useCallback(() => {
    flatList?.scrollToOffset({ offset: 0, animated: false });
    setTimeout(() => Keyboard.dismiss(), 100);
  }, [flatList]);

  useEffect(() => {
    if (loaded && lastEdge !== undefined) {
      setLastId(lastEdge.node.id);
    }
  }, [lastEdge, loaded]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const chkCampaignVisibility = (
    event: NativeSyntheticEvent<NativeScrollEvent>
  ) =>
    // キャンペーン情報が目視確認可能かどうか
    event.nativeEvent.layoutMeasurement.height +
      event.nativeEvent.contentOffset.y >=
    event.nativeEvent.contentSize.height - BOTTOM_AREA_HEIGHT;
  return (
    <KeyboardAvoidingView keyboardVerticalOffset={48}>
      <NavigationBar
        headerRight={<CustomIcon color={colors.text} name="rotate" size={24} />}
        noBorder
        onBack={goBack}
        onTapRightElement={() => {
          postRefetch({}, { fetchPolicy: "network-only" });
          refetch({}, { fetchPolicy: "network-only" });
        }}
        title="ワークチャット"
      />
      <View style={styles.container}>
        {!campaignVisibility && (
          <TouchableOpacity
            onPress={() => navigate("CampaignDetail", { id })}
            style={styles.wrap}
          >
            <Card>
              <Wrapper>
                <View style={styles.header}>
                  <View style={styles.headerText}>
                    <Text
                      ellipsizeMode="tail"
                      numberOfLines={1}
                      style={styles.headerFont}
                    >
                      {title}
                    </Text>
                  </View>
                  <View style={styles.rightView}>
                    <CustomIcon color={Colors.black} name="next" size={24} />
                  </View>
                </View>
              </Wrapper>
            </Card>
            <Divider />
          </TouchableOpacity>
        )}
        {paging && (
          <View style={styles.paging}>
            <Loading />
          </View>
        )}
        <FlatList
          ref={(
            ref: FlatList<ChatPagination$data["comments"]["edges"][number]>
          ) => {
            if (flatList === null) {
              flatList = ref;
            }
          }}
          contentContainerStyle={styles.timeline}
          data={edges}
          inverted
          keyExtractor={({ node }) => node.id}
          ListFooterComponent={
            <Wrapper>
              <Spacer height={8} />
              <ChatCampaign campaignFragment={post} />
              <ChatStatus postFragment={post} />
              <Spacer height={16} />
            </Wrapper>
          }
          onEndReached={() => {
            if (hasNext && !paging) {
              setPaging(true);
              loadNext(PagingComment, { onComplete: () => setPaging(false) });
            }
          }}
          onEndReachedThreshold={0.7}
          onLayout={() => {
            // 表示時に、onScrollイベント発火させてヘッダーのキャンペーンタイトルを表示するために実行している
            flatList?.scrollToOffset({ offset: 1, animated: false });
          }}
          onScroll={(event) => {
            setCampaignVisibility(chkCampaignVisibility(event));
          }}
          renderItem={({ item }) => (
            <Wrapper>
              <View style={styles.message}>
                <Message
                  animation={!!(lastId !== null && lastId === item.node.id)}
                  commentFragment={item.node}
                  completedAnimation={() => setLastId(null)}
                  openPreview={(index, items) => {
                    setExpand({
                      selectIndex: index,
                      imageList: items.map((row) => ({
                        id: row.id,
                        uri: row.file,
                        duration: row.duration,
                        contentType: row.contentType,
                      })),
                    });
                  }}
                />
              </View>
            </Wrapper>
          )}
          scrollEventThrottle={200}
        />
      </View>
      <ChatAction
        bankFragment={bank}
        openProductRecieve={() => setProductRecieve(true)}
        postFragment={post}
      />
      <Post onPosted={onPosted} postFragment={post} />
      {productRecieve && (
        <ChatRecieveProductReport
          onClose={() => setProductRecieve(false)}
          onError={setError}
          postFragment={post}
        />
      )}
      {error !== null && (
        <ErrorDialog message={error} onClose={() => setError(null)} />
      )}
      {expand !== null && (
        <BrowseImage
          imageList={expand.imageList}
          onClose={() => setExpand(null)}
          selectIndex={expand.selectIndex}
        />
      )}
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrap: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    height: 34,
  },
  headerText: {
    flex: 1,
    paddingVertical: 8,
  },
  headerFont: {
    textAlign: "left",
    ...Fonts.lr130,
  },
  rightView: {
    paddingVertical: 5,
    paddingLeft: 5,
  },
  timeline: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  message: {
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  paging: {
    position: "absolute",
    top: 50,
    left: 0,
    zIndex: 1,
    width: "100%",
    paddingVertical: 12,
  },
});
