/**
 * @generated SignedSource<<d090e7ac982766d08e1b1735a61bb3eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type PostReport$data = {
  readonly campaign: {
    readonly mediaType: MediaType;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"PostReportCast" | "PostReportInstagram" | "PostReportOther" | "PostReportTiktok" | "PostReportTwitter" | "PostReportYoutube">;
  readonly " $fragmentType": "PostReport";
};
export type PostReport$key = {
  readonly " $data"?: PostReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostReport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportTwitter"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportYoutube"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportInstagram"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportOther"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportCast"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostReportTiktok"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "748df0f87540d3965d64b8a85dff0138";

export default node;
