/* eslint react/jsx-props-no-spreading: off */
import React from "react";
import Colors from "@constants/Colors";
import { StyleSheet, View, DimensionValue } from "react-native";
import Button from "@components/atoms/Button";
import GradientButton from "@components/atoms/GradientButton";
import Spacer from "@components/atoms/Spacer";

type ButtonLabel = "閉じる" | "キャンセル" | "OK";
type Props = {
  title: ButtonLabel | string;
  onPress: () => void;
  type: "white" | "blueGradient" | "orange";
  hasPreSpace?: boolean; // ボタンの前に余白を設けるか否か
};
export default function DialogButton({
  title,
  onPress,
  type,
  hasPreSpace,
}: Props) {
  const commonProps = {
    title,
    onPress,
    width: "100%" as DimensionValue,
    height: 48,
  };
  return (
    <>
      {hasPreSpace === true && <Spacer width={16} />}
      <View style={styles.button}>
        {type === "white" ? (
          <Button backgroundColor={Colors.white} {...commonProps} />
        ) : type === "blueGradient" ? (
          <GradientButton
            gradientColor={Colors.grBlue}
            textStyle={styles.colorWhite}
            {...commonProps}
          />
        ) : type === "orange" ? (
          <Button
            backgroundColor={Colors.orange}
            textStyle={styles.colorWhite}
            {...commonProps}
            border={false}
          />
        ) : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  colorWhite: {
    color: Colors.white,
  },
});
