/**
 * @generated SignedSource<<2887dd5447c893debc2466b8d2578545>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CandidateStatus = "admin_approved" | "admin_rejected" | "agency_approved" | "agency_rejected" | "applied";
export type ParentMediaType = "cast" | "instagram" | "other" | "tiktok" | "twitter" | "youtube";
export type PostStatus = "admin_rejected" | "agency_rejected" | "draft" | "draft_with_reject" | "finished" | "product_shipped" | "wait_admin_review" | "wait_agency_confirm_report" | "wait_agency_review" | "wait_product_shipped" | "wait_publish" | "wait_visitation";
import { FragmentRefs } from "relay-runtime";
export type CampaignSmallCandidate$data = {
  readonly campaign: {
    readonly banners: ReadonlyArray<{
      readonly file: string | null;
    }>;
    readonly id: string;
    readonly parentMediaType: ParentMediaType;
    readonly title: string | null;
  };
  readonly customPrice: number;
  readonly id: string;
  readonly post: {
    readonly finishedAt: any | null;
    readonly id: string;
    readonly status: PostStatus;
  } | null;
  readonly price: number;
  readonly status: CandidateStatus | null;
  readonly " $fragmentType": "CampaignSmallCandidate";
};
export type CampaignSmallCandidate$key = {
  readonly " $data"?: CampaignSmallCandidate$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignSmallCandidate">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignSmallCandidate",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customPrice",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Post",
      "kind": "LinkedField",
      "name": "post",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finishedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentMediaType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Banner",
          "kind": "LinkedField",
          "name": "banners",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Candidate",
  "abstractKey": null
};
})();

(node as any).hash = "0685e6de0aca3e5a21575306d86a392e";

export default node;
