/**
 * @generated SignedSource<<823cf945797b7e73d3d56c450455a82e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailChangeMutationInput = {
  clientMutationId?: string | null;
  newEmail: string;
};
export type CampaignInputEmailFormMutation$variables = {
  input: EmailChangeMutationInput;
};
export type CampaignInputEmailFormMutation$data = {
  readonly emailChange: {
    readonly __typename: "EmailChangeDuplication";
    readonly errorType: string;
    readonly message: string;
  } | {
    readonly __typename: "EmailChangeToken";
    readonly token: string;
  } | {
    readonly __typename: "EmailChangeUnknown";
    readonly errorType: string;
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CampaignInputEmailFormMutation = {
  response: CampaignInputEmailFormMutation$data;
  variables: CampaignInputEmailFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "emailChange",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "EmailChangeToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "EmailChangeDuplication",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "EmailChangeUnknown",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignInputEmailFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignInputEmailFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "050600107b3989c8b763514ff71b1ad8",
    "id": null,
    "metadata": {},
    "name": "CampaignInputEmailFormMutation",
    "operationKind": "mutation",
    "text": "mutation CampaignInputEmailFormMutation(\n  $input: EmailChangeMutationInput!\n) {\n  emailChange(input: $input) {\n    __typename\n    ... on EmailChangeToken {\n      token\n    }\n    ... on EmailChangeDuplication {\n      errorType\n      message\n    }\n    ... on EmailChangeUnknown {\n      errorType\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b00aecfd6db5c1b5818abf772ef6a5d";

export default node;
