import React, { useRef, useEffect, useState } from "react";
import { Text, Card, View, Wrapper } from "@components/atoms/Themed";
import Ripple from "@components/atoms/Ripple";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { StyleSheet, ScrollView } from "react-native";
import ReactNavigation from "@react-navigation/material-top-tabs";
import NavigationBar from "@components/molecules/NavigationBar";
import Icon from "@components/atoms/Icon";
import { navigate } from "@navigation/navigate";
import useFilterStatus from "@hooks/useFilterStatus";

type Props = {
  params: ReactNavigation.MaterialTopTabBarProps;
};

const TabWidth = 120;

export default function NavigationCampaigns({ params }: Props) {
  const { state: filter } = useFilterStatus();
  const { state, descriptors, navigation } = params;
  const [currentIndex, setCurrentIndex] = useState<number>(state.index);
  const scrollRef = useRef<ScrollView>(null);
  useEffect(() => {
    if (state.index !== currentIndex) {
      setCurrentIndex(state.index);
      scrollRef.current?.scrollTo({ x: TabWidth * state.index - 20 });
    }
  }, [state, currentIndex]);

  return (
    <View>
      <NavigationBar
        headerRight={
          <>
            <Icon
              color={
                filter !== null && filter.filterStatus
                  ? Colors.blue
                  : Colors.black
              }
              name="filter"
              size={24}
            />
            {filter !== null && filter.filterStatus && (
              <View style={styles.budge} />
            )}
          </>
        }
        noBorder
        onTapRightElement={() =>
          navigate("CampaignFilter", {
            tab: state.routeNames[state.index],
          })
        }
      />

      <Card>
        <Wrapper>
          <View style={styles.container}>
            <ScrollView
              ref={scrollRef}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {state.routes.map((route, index) => {
                const { options } = descriptors[route.key];
                const isFocused = state.index === index;

                return (
                  <Ripple
                    key={`tabbr-${route.key}`}
                    onPress={() => navigation.navigate(route.name)}
                    rippleColor={isFocused ? Colors.green : Colors.black0}
                    style={[
                      styles.item,
                      isFocused ? styles.focusedButton : null,
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        isFocused ? styles.focusedText : null,
                      ]}
                    >
                      {options.title}
                    </Text>
                  </Ripple>
                );
              })}
            </ScrollView>
          </View>
        </Wrapper>
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 44,
  },
  budge: {
    width: 8,
    height: 8,
    borderRadius: 4,
    position: "absolute",
    top: 9,
    right: 9,
    backgroundColor: Colors.orange,
  },
  item: {
    width: TabWidth,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  focusedButton: {
    borderColor: Colors.black0,
    borderBottomColor: Colors.green,
    borderBottomWidth: 2,
  },
  text: {
    color: Colors.black,
    textAlign: "center",
    ...Fonts.xlr100,
  },
  focusedText: {
    ...Fonts.xlb100,
    color: Colors.green,
  },
});
