/**
 * @generated SignedSource<<136265b3b68ac6f5f6ffdc60d0d4aa17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceStatus = "applied" | "unapplied" | "unset";
import { FragmentRefs } from "relay-runtime";
export type InvoiceEdit$data = {
  readonly profile: {
    readonly invoiceNo: string | null;
    readonly invoiceStatus: InvoiceStatus;
  };
  readonly " $fragmentType": "InvoiceEdit";
};
export type InvoiceEdit$key = {
  readonly " $data"?: InvoiceEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceEdit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceEdit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InfluencerInfo",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNo",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Influencer",
  "abstractKey": null
};

(node as any).hash = "5e1636cf24372083171498aabd61a34a";

export default node;
