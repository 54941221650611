/**
 * @generated SignedSource<<94d25b45d3a63c0bead5e5e1a1fb1acc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthLoginMutation$variables = {
  email: string;
  password: string;
};
export type AuthLoginMutation$data = {
  readonly login: {
    readonly __typename: "LoginError";
    readonly message: string;
  } | {
    readonly __typename: "LoginToken";
    readonly refreshToken: string;
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AuthLoginMutation = {
  response: AuthLoginMutation$data;
  variables: AuthLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refreshToken",
            "storageKey": null
          }
        ],
        "type": "LoginToken",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "LoginError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4dbadc407b1e05e36d00bd86b7ff0f81",
    "id": null,
    "metadata": {},
    "name": "AuthLoginMutation",
    "operationKind": "mutation",
    "text": "mutation AuthLoginMutation(\n  $email: String!\n  $password: String!\n) {\n  login(input: {email: $email, password: $password}) {\n    __typename\n    ... on LoginToken {\n      token\n      refreshToken\n    }\n    ... on LoginError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fcbf4eb6410602a6128f9467bcc0561";

export default node;
