/**
 * @generated SignedSource<<c6ff850ad2b68fc264262a1664537d18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyPageScreenQuery$variables = {};
export type MyPageScreenQuery$data = {
  readonly influencer: {
    readonly " $fragmentSpreads": FragmentRefs<"MyPageInfo">;
  };
};
export type MyPageScreenQuery = {
  response: MyPageScreenQuery$data;
  variables: MyPageScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MyPageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyPageScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Influencer",
        "kind": "LinkedField",
        "name": "influencer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerCategory",
            "kind": "LinkedField",
            "name": "influencerCategories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InfluencerInfo",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commonName",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ytChannel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tiktokUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthday",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkingInstagram",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkingTwitter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalWorkCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastNameKana",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prefectureName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cityName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "townName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowNotification",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f459fa862a3982e1262f5e83c9516a6",
    "id": null,
    "metadata": {},
    "name": "MyPageScreenQuery",
    "operationKind": "query",
    "text": "query MyPageScreenQuery {\n  influencer {\n    ...MyPageInfo\n    id\n  }\n}\n\nfragment MyPageCategory on Influencer {\n  influencerCategories {\n    id\n    categoryName\n  }\n}\n\nfragment MyPageInfo on Influencer {\n  ...MyPageCategory\n  ...MyPageProfile\n  ...MyPageSns\n  ...MyPageReport\n  ...MyPagePersonalInformation\n  ...MyPageSetting\n}\n\nfragment MyPagePersonalInformation on Influencer {\n  firstName\n  lastName\n  firstNameKana\n  lastNameKana\n  phoneNumber\n  email\n  prefectureName\n  cityName\n  townName\n  bankAccount {\n    id\n  }\n  profile {\n    gender\n    birthday\n    id\n  }\n}\n\nfragment MyPageProfile on Influencer {\n  avatarUrl\n  profile {\n    commonName\n    id\n  }\n}\n\nfragment MyPageReport on Influencer {\n  totalPayment\n  totalWorkCount\n}\n\nfragment MyPageSetting on Influencer {\n  allowNotification\n}\n\nfragment MyPageSns on Influencer {\n  linkingInstagram\n  linkingTwitter\n  profile {\n    ytChannel\n    tiktokUrl\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "27cd27903290f1b6d9759a1e49467f6c";

export default node;
