import { Platform } from "react-native";
import * as MediaLibrary from "expo-media-library";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { isURL } from "@lib/util/validate";

const directory = `${FileSystem.cacheDirectory}influencer_works/`;
const UTI = "public.item";

function canSaveToMediaLibrary(mimeType: string): boolean {
  return /^(image|video|audio)/.test(mimeType);
}

async function ensureDirExists(): Promise<void> {
  const dirInfo = await FileSystem.getInfoAsync(directory);
  if (!dirInfo.exists) {
    await FileSystem.makeDirectoryAsync(directory, { intermediates: true });
  }
}

export default async function downloadFile(
  url: string,
  filename: string
): Promise<void> {
  if (isURL(url) === false) {
    throw new Error("このURLは存在しません");
  }
  if (Platform.OS === "web") {
    await downloadForWeb(url, filename);
    return;
  }
  await ensureDirExists();

  const downloadResumable = FileSystem.createDownloadResumable(
    url,
    `${directory}${filename}`
  );
  const res = await downloadResumable.downloadAsync();
  if (res === undefined) {
    throw new Error("ダウンロードできませんでした");
  }
  if (canSaveToMediaLibrary(res.headers["Content-Type"])) {
    await MediaLibrary.saveToLibraryAsync(res.uri);
  } else {
    await Sharing.shareAsync(res.uri, {
      UTI,
      mimeType: res.headers["Content-Type"],
    });
  }
}

async function downloadForWeb(url: string, filename: string) {
  // ファイルデータを取得
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch the file");
  }
  const blob = await response.blob();

  // ブラウザでダウンロード
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
