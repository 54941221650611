import React, { Suspense } from "react";
import {
  usePreloadedQuery,
  useQueryLoader,
  graphql,
  PreloadedQuery,
} from "react-relay/hooks";
import { FavoriteScreenQuery } from "@generated/FavoriteScreenQuery.graphql";
import Loading from "@components/atoms/Loading";
import Favorites from "@components/templates/Favorites";
import { PagingFavorite } from "@constants/App";
import { navigate } from "@navigation/navigate";
import _ from "lodash";

const favoriteScreenQuery = graphql`
  query FavoriteScreenQuery($first: Int!) {
    ...FavoritesPagination @arguments(first: $first)
  }
`;

function ScreenContent({
  queryReference,
  move,
}: {
  queryReference: PreloadedQuery<FavoriteScreenQuery>;
  move: (id: string) => void;
}) {
  const favorites = usePreloadedQuery<FavoriteScreenQuery>(
    favoriteScreenQuery,
    queryReference
  );
  return <Favorites favorites={favorites} move={move} />;
}

export default function FavoriteScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<FavoriteScreenQuery>(favoriteScreenQuery);
  React.useEffect(() => {
    loadQuery({ first: PagingFavorite });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <Suspense fallback={<Loading size="large" />}>
      {!_.isNil(queryReference) && (
        <ScreenContent
          move={(id) => navigate("CampaignDetail", { id })}
          queryReference={queryReference}
        />
      )}
    </Suspense>
  );
}
