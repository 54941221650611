import React from "react";
import { Text, Card, View } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import DialogButton from "@components/molecules/dialog/DialogButton";
import {
  StyleSheet,
  Modal,
  TouchableWithoutFeedback,
  View as RnView,
} from "react-native";

type Props = {
  /** タイトル */
  title?: string;
  /** 表示するメッセージ */
  message: string;
  /** 閉じるボタンを押したときのハンドラ */
  onClose: () => void;
};

export default function ErrorDialog({
  title = "エラーが発生しました",
  message,
  onClose,
}: Props): JSX.Element {
  return (
    <Modal animationType="none" onRequestClose={onClose} transparent visible>
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <Card style={styles.message}>
            <Text style={styles.title}>{title}</Text>
            <Spacer height={16} />
            <Text style={styles.text}>{message}</Text>
            <Spacer height={24} />
            <View style={styles.buttons}>
              <DialogButton onPress={onClose} title="閉じる" type="white" />
            </View>
          </Card>
        </View>
        <TouchableWithoutFeedback onPress={onClose}>
          <RnView style={styles.backgroundLayer} />
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 10,
  },
  backgroundLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: Colors.overlayDark,
  },
  wrapper: {
    padding: 24,
  },
  message: {
    padding: 16,
    borderRadius: 6,
    maxWidth: 345,
    minWidth: "90%",
  },
  title: {
    color: Colors.orange,
    ...Fonts.xxlb140,
    textAlign: "center",
  },
  text: {
    ...Fonts.xlr160,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
